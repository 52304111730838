import React, { useEffect, useState } from 'react'
import { message, Select, SelectProps } from 'antd'
import strings from '../../utils/Localization'
import useApi from '../../hooks/useApi'

const { Option } = Select

interface IProps extends SelectProps<any> {
  requestBody: {}
}

const SubeSelectOption: React.FC<IProps> = ({ placeholder, ...rest }) => {
  const [fetchLoading, setFetchLoading] = useState(false)
  const [subeData, setSubeData] = useState([])
  const api = useApi()

  useEffect(() => {
    fetchSube()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSube = async () => {
    try {
      setFetchLoading(true)
      const response = await api.post('/sube/get', {
        SortOptions: {
          ColumnName: 'Id',
          SortType: 'Asc',
        },
        SelectColumns: [
          {
            TableName: '',
            ColumnNames: ['id', 'subeAd'],
          },
        ],
      })
      if (response.status === 200) {
        setSubeData(response.data.data)
      }
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  return (
    <Select {...rest} placeholder={placeholder || strings?.subeSelectOption?.placeHolder} loading={fetchLoading}>
      {subeData.map((item: any, index: number) => (
        <Option key={index} value={String(item.id)}>
          {String(item?.subeAd).toLowerCase()}
        </Option>
      ))}
    </Select>
  )
}

export default SubeSelectOption
