import React, { useState, useEffect } from "react";
import Avatar from "../toolbox/Avatar";
import { Card, Divider, message, Space, Typography } from "antd";
import strings from "../../utils/Localization";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import GelirGiderBakiye from "./GelirGiderBakiye";

const { Title } = Typography;

const MyDivider = () => <Divider style={{ marginTop: 12, marginBottom: 12 }} />;

export default function Profil() {
    const [fetchLoading, setFetchLoading] = useState(true)
    const [data, setData] = useState({})
    const { gelirGiderGrupId } = useParams()
    const api = useApi();

    useEffect(() => {
        fetchGelirGiderGrup()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    const fetchGelirGiderGrup = async () => {
        try {
            const response = await api.post("/gelirgidergrup/get", {
                data: {
                    Id: gelirGiderGrupId
                },
                SelectColumns: [
                    {
                        TableName: '',
                        ColumnNames: ['ad', 'id'],
                    },
                ],
            })
            setData(response.data.data[0])
        } catch (error) {
            message.error(strings.mesaj.islemBasarisiz)
        }
        finally {
            setFetchLoading(false)
        }
    }

    return (
        <Card
            bodyStyle={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}
            loading={fetchLoading}
        >
            <Space direction="vertical" align="center" style={{ width: "100%" }}>
                <Avatar text={data.ad} />
                <Title level={4} style={{ marginTop: 8, marginBottom: 4 }}>
                    {data.ad}
                </Title>
            </Space>
            <MyDivider />
            <GelirGiderBakiye />
        </Card>
    )
}
