import React, { useState } from 'react'
import { Button, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import strings from '../../utils/Localization'
import md5 from 'md5'
import useApi from '../../hooks/useApi'
import useKullanici from '../../hooks/useKullanici'

const eMail = 'aymyazilim@gmail.com'
const sifre = '123456'

const buttonStyle = { marginTop: 16 }

export default function DemoButton() {
  const [fetchLoading, setFetchLoading] = useState(false)
  const navigate= useNavigate()
  const api = useApi()
  const { setAuthParameters } = useKullanici()

  const girisYap = async () => {
    try {
      setFetchLoading(true)
      const response = await api.post('/auth/login', {
        Email: eMail,
        Password: md5(sifre),
      })

      if (response.status === 200) {
        const { token, defaultSettings, refreshToken } = response.data.singleData
        setAuthParameters({ token, defaultSettings, refreshToken, eMail })
        navigate('/')
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }
  return (
    <Button type="text" size="middle" block style={buttonStyle} onClick={girisYap} loading={fetchLoading}>
      {strings.girisYap.components.demoButton.butonYazi}
    </Button>
  )
}
