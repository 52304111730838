import React, { useEffect, useState } from "react";
import { message, Select, SelectProps } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";

const { Option } = Select;

interface IProps extends SelectProps<any> {
  onSelectedUretimRecete: (selectedUretimRecete: any) => void;
}

const UretimReceteSelectOption: React.FC<IProps> = ({
  onSelectedUretimRecete = () => {},
  placeholder,
  ...rest
}) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [uretimReceteData, setUretimReceteData] = useState([]);
  const api = useApi();

  useEffect(() => {
    fetchUretimRecete();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUretimRecete = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/uretimrecete/get", {
        SortOptions: {
          ColumnName: "Id",
          SortType: "Asc",
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["id", "ad"],
          }
        ],
      });
      if (response.status === 200) {
        setUretimReceteData(response.data.data);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Select
      {...rest}
      placeholder={placeholder || strings?.uretimReceteSelectOption?.uretimRecetePlaceHolder}
      loading={fetchLoading}
      onSelect={(e: any) => {
        const selectedUretimRecete = uretimReceteData.find((x: any) => x.id === parseInt(e));
        selectedUretimRecete && onSelectedUretimRecete(selectedUretimRecete);
      }}
      onClear={() => onSelectedUretimRecete({})}
    >
      {uretimReceteData.map((item: any, index: number) => (
        <Option key={index} value={String(item.id)}>
          {item.ad}
        </Option>
      ))}
    </Select>
  );
};

export default UretimReceteSelectOption;
