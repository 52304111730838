import React, { useState } from "react";
import { Modal, Form, message, notification, Input } from "antd";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useApi from "../../hooks/useApi";

const formSize = "middle";

function StokBirimEkleModal({ visible = false, handleCancel }) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const { baslik, birimAd, birimAdPlaceHolder } = strings.stokBirimEkleModal;
  const dispatch = useDispatch();
  const api = useApi();

  const onFinish = async (values) => {
    try {
      setFetchLoading(true);
      const { ad } = values;

      const response = await api.post("/stokbirim", {
        ad: ad,
        isoKod: "-",
        kod: "-",
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      title={baslik}
      okButtonProps={{
        htmlType: "submit",
        form: "stokBirimEkleForm",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
    >
      <Form
        form={form}
        id="stokBirimEkleForm"
        onFinish={onFinish}
        layout="vertical"
        size="large"
      >
        <Form.Item name="ad" label={birimAd} rules={[{ required: true }]}>
          <Input placeholder={birimAdPlaceHolder} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default StokBirimEkleModal;
