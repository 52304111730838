import * as actionTypes from "./actionTypes";

export const setOdemePaket = (seciliPaket) => ({
  type: actionTypes.SET_ODEME_PAKET,
  payload: seciliPaket,
});

export const setResetOdemePaket = () => ({
  type: actionTypes.SET_RESET_ODEME_PAKET,
});

export const setOdemeFaturaAdres = (faturaAdres) => ({
  type: actionTypes.SET_ODEME_FATURA_ADRES,
  payload: faturaAdres,
});

export const setOdemeResetFaturaAdres = () => ({
  type: actionTypes.SET_RESET_ODEME_FATURA_ADRES,
});
