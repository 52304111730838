import useKullanici from "./useKullanici";

const useVarsayilanDeger = () => {
  const { getDefaultSettings } = useKullanici();
  const defaultSettings = JSON.parse(getDefaultSettings);

  const getDefaultSetting = (settingName) => {
    try {
      const result = defaultSettings.find((x) => x.ad === settingName);
      return result === undefined ? null : result.deger;
    } catch (error) {
      return null;
    }
  };
  const getDefaultSettingItem = (settingName) => {
    try {
      const result = defaultSettings.find((x) => x.ad === settingName);
      return result === undefined ? {} : result;
    } catch (error) {
      return {};
    }
  };

  return { getDefaultSetting, getDefaultSettingItem };
};

export default useVarsayilanDeger;
