const yuzdeHesala = (sayi, yuzdeOran) => parseFloat((sayi / 100) * yuzdeOran)

/**
 * örnek: a sayı b sayının %5'i dir
 */
const tutariYuzdeyeCevir = (a, b) => {
    const aDegeri = parseFloat(a * 100)
    const sonuc = parseFloat(aDegeri / b)
    return sonuc
}

/**
 * tip değer olarak 'yuzde' veya 'fiyat' parametlerini alabilir.
 * girilen tipe ve sayılara göre tutar hesaplanır.
 */
const tipTutarHesapla = (sayi, deger, tip) => {
    let tutar = 0
    if (tip === 'yuzde') {
        tutar = yuzdeHesala(sayi, deger)
    }
    else {
        tutar = parseFloat(deger)
    }

    return tutar
}

const faturaSatirHesaplama = ({
    miktar,
    birimFiyat,
    kalemIskontoArray,
    otvDeger,
    otvTip,
    oivDeger,
    oivTip,
    kdvOran,
}) => {

    const toplamBirimFiyat = parseFloat(birimFiyat * miktar)

    let iskontoTutar = 0
    if (kalemIskontoArray.length > 0) {
        kalemIskontoArray.map((item) => iskontoTutar += tipTutarHesapla(toplamBirimFiyat - iskontoTutar, item.deger, item.tip))
    }

    let indirimliFiyat = parseFloat(toplamBirimFiyat - iskontoTutar)

    const otvTutar = tipTutarHesapla(indirimliFiyat, otvDeger, otvTip)
    const otvOran = tutariYuzdeyeCevir(otvTutar, indirimliFiyat)
    let otvliFiyat = parseFloat(indirimliFiyat + otvTutar)

    const oivTutar = tipTutarHesapla(otvliFiyat, oivDeger, oivTip)
    const oivOran = tutariYuzdeyeCevir(oivTutar, otvliFiyat)

    const kdvTutar = yuzdeHesala(otvliFiyat, kdvOran)

    const kdv01 = kdvOran === 1 ? kdvTutar : 0
    const kdv08 = kdvOran === 8 ? kdvTutar : 0
    const kdv18 = kdvOran === 18 ? kdvTutar : 0

    const satirToplam = parseFloat(indirimliFiyat + otvTutar + oivTutar + kdvTutar)

    return {
        iskontoTutar,
        otvTutar,
        otvOran,
        oivOran,
        oivTutar,
        kdv01,
        kdv08,
        kdv18,
        satirToplam,
    }
}

export default faturaSatirHesaplama
export { yuzdeHesala, tutariYuzdeyeCevir }