import React from "react";
import {
  Image,
  Layout,
  Dropdown,
  Menu,
  Button,
  Row,
  Col,
  Typography,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Outlet, useNavigate } from "react-router-dom";
import Logo104 from "../assets/images/logo104.png";
import useKullanici from "../hooks/useKullanici";

const { Header } = Layout;

function OdemeLayout() {
  const navigate = useNavigate();
  const { cikisYap, getEMail } = useKullanici();

  const kullaniciMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => navigate("/Siparislerim")}>
        Siparişlerim
      </Menu.Item>
      <Menu.Item key="1" onClick={cikisYap}>
        Çıkış Yap
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ alignItems: "center", display: "flex" }}>
        <Image src={Logo104} width={48} preview={false} />
        <Typography.Title level={3} style={{ marginBottom: 0, marginLeft: 6 }}>
          EminHesap
        </Typography.Title>
        <Row justify="end" style={{ width: "100%" }}>
          <Col>
            <Dropdown overlay={kullaniciMenu} trigger={["click"]}>
              <Button type="text">
                {getEMail} <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Header>

      <Outlet />

    </Layout>
  );
}

export default OdemeLayout;
