import React from "react";
import { PageHeader, KasaListTable } from "../components/kasaListe";
import { Space } from "antd";

export default function KasaListe() {
  return (
    <>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <PageHeader />
        <KasaListTable />
      </Space>
    </>
  );
}
