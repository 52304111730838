import { Button, Space } from "antd";
import React, { useState } from "react";
import strings from "../../utils/Localization";

import KasaIslemEkleModal from "../kasaislem/KasaIslemEkleModal";
import KasadanKasayaVirmanEkleModal from "../kasaislem/KasadanKasayaVirmanEkleModal";
import KasadanBankayaVirmanEkleModal from "../kasaislem/KasadanBankayaVirmanEkleModal";

export default function IslemButton() {
  const [kasaGirisCikisModal, setKasaGirisCikisModal] = useState(false);
  const [kasayaVirmanVisible, setKasayaVirmanVisible] = useState(false);
  const [bankayaVirmanVisible, setBankayaVirmanVisible] = useState(false);
  const [girisMi, setGirisMi] = useState(null);
  const { islemMenu } = strings.kasaDetay.components;

  return (
    <>
      <Space>
        <Button
          onClick={() => {
            setGirisMi(true);
            setKasaGirisCikisModal(true);
          }}
          type="primary"
          size="large"
        >
          {islemMenu.girisYap}
        </Button>
        <Button
          onClick={() => {
            setGirisMi(false);
            setKasaGirisCikisModal(true);
          }}
          type="primary"
          size="large"
        >
          {islemMenu.cikisYap}
        </Button>
        <Button
          onClick={() => setKasayaVirmanVisible(true)}
          type="primary"
          size="large"
        >
          {islemMenu.kasayaVirman}
        </Button>
        <Button
          onClick={() => setBankayaVirmanVisible(true)}
          type="primary"
          size="large"
        >
          {islemMenu.bankayaVirman}
        </Button>
      </Space>
      <KasaIslemEkleModal
        visible={kasaGirisCikisModal}
        handleCancel={() => setKasaGirisCikisModal(false)}
        girisMi={girisMi}
      />
      <KasadanKasayaVirmanEkleModal
        visible={kasayaVirmanVisible}
        handleCancel={() => setKasayaVirmanVisible(false)}
      />
      <KasadanBankayaVirmanEkleModal
        visible={bankayaVirmanVisible}
        handleCancel={() => setBankayaVirmanVisible(false)}
      />
    </>
  );
}
