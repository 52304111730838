import React from "react";
import { Space, Layout as AntLayout, Image, Card } from "antd";
import Logo104 from "../assets/images/logo104.png";
import { Outlet } from "react-router-dom";

const formWidth = 380;
const formRadius = 24;

const formStyle = {
  borderRadius: formRadius,
  width: formWidth,
};

function SifremiUnuttumLayout() {
  return (
    <AntLayout>
      <Space
        direction="vertical"
        align="center"
        size="large"
        style={{ minHeight: "100vh", marginTop: 80 }}
      >
        <Image src={Logo104} width={104} />
        <Card style={formStyle}>
          <Outlet />
        </Card>
      </Space>
    </AntLayout>
  );
}

export default SifremiUnuttumLayout;
