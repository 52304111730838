import React from 'react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import strings from '../../utils/Localization'

const buttonStyle = { marginTop: 16 }

export default function SifremiUnuttumButon() {
    const navigate= useNavigate()

    return (
        <Button
            type="text"
            size="middle"
            block
            style={buttonStyle}
            onClick={() => navigate('/SifremiUnuttumMailDogrulama')}
        >{strings.girisYap.components.sifremiUnuttumButon.butonYazi}
        </Button>
    )
}
