import React, { useEffect } from "react";
import { PageHeader, Profil, KasaIslemListe } from "../components/kasaDetay";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchKasa } from "../redux/actions/kasaAction";
import useApi from "../hooks/useApi";

export default function KasaDetay() {
  const { kasaId } = useParams();
  const dispatch = useDispatch();
  const api = useApi();

  useEffect(() => {
    dispatch(fetchKasa(kasaId, api));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageHeader />
      <br />
      <Row gutter={24}>
        <Col xxl={6} xl={7} lg={24} md={24} xs={24}>
          <Profil />
          <br />
        </Col>
        <Col xxl={18} xl={17} lg={24} md={24} xs={24}>
          <KasaIslemListe />
        </Col>
      </Row>
      <br />
    </>
  );
}
