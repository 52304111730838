import { Button, Space } from "antd";
import React, { useState } from "react";
import strings from "../../utils/Localization";
import CariAlacaklandirmaEkleModal from "../cariIslem/CariAlacaklandirmaEkleModal";
import CariBorclandirmaEkleModal from "../cariIslem/CariBorclandirmaEkleModal";
import CariTahsilatEkleModal from "../cariIslem/CariTahsilatEkleModal";
import CariOdemeEkleModal from "../cariIslem/CariOdemeEkleModal";

export default function IslemButtonDropdown() {
  const [alacaklandirmaVisible, setAlacaklandirmaVisible] = useState(false);
  const [borclandirmaVisible, setBorclandirmaVisible] = useState(false);
  const [tahsilatEkleVisible, setTahsilatEkleVisible] = useState(false);
  const [odemeEkleVisible, setOdemeEkleVisible] = useState(false);

  return (
    <>
      <Space>
        <Button
          size="large"
          type="primary"
          onClick={() => setAlacaklandirmaVisible(true)}
        >
          {strings.cariDetay.components.islemMenu.alacaklandir}
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={() => setBorclandirmaVisible(true)}
        >
          {strings.cariDetay.components.islemMenu.borclandir}
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={() => setTahsilatEkleVisible(true)}
        >
          {strings.cariDetay.components.islemMenu.tahsilatYap}
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={() => setOdemeEkleVisible(true)}
        >
          {strings.cariDetay.components.islemMenu.odemeYap}
        </Button>
      </Space>
      <CariAlacaklandirmaEkleModal
        visible={alacaklandirmaVisible}
        handleCancel={() => setAlacaklandirmaVisible(false)}
      />
      <CariBorclandirmaEkleModal
        visible={borclandirmaVisible}
        handleCancel={() => setBorclandirmaVisible(false)}
      />
      <CariTahsilatEkleModal
        visible={tahsilatEkleVisible}
        handleCancel={() => setTahsilatEkleVisible(false)}
      />
      <CariOdemeEkleModal
        visible={odemeEkleVisible}
        handleCancel={() => setOdemeEkleVisible(false)}
      />
    </>
  );
}
