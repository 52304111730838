import { PageHeader, Row, Space, Tag, Col, Typography, Button } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import FetchListContent from "../components/toolbox/FetchListContent";
import useCurrency from "../hooks/useCurrency";
import { vadeGunTypes } from "../types/vadeGunTypes";
import { SearchOutlined } from "@ant-design/icons";
import strings from "../utils/Localization";

const { Text } = Typography;

function VadeliOdemeDetay() {
  const [dataSource, setDataSource] = useState({});
  const navigate= useNavigate();
  const { islemListTip = "", dovizId = null } = useParams();
  const { paraFormat } = useCurrency();
  const { vadeliOdemeDetay: localization } = strings;

  const getTitle = () => {
    switch (islemListTip) {
      case "gecmis":
        return localization.baslik.vadesiGecmisOdemeler;
      case "gelecek":
        return localization.baslik.vadesiGelecekOdemeler;
      case "hepsi":
        return localization.baslik.tumYapilacakOdemeler;
      default:
        return "";
    }
  };

  const getGunTip = () => {
    switch (islemListTip) {
      case "gecmis":
        return vadeGunTypes.GECMIS;
      case "gelecek":
        return vadeGunTypes.GELECEK;
      case "hespi":
        return vadeGunTypes.HEPSI;
      default:
        return null;
    }
  };

  const getShortType = () => {
    switch (islemListTip) {
      case "gecmis":
        return "Desc";
      case "gelecek":
        return "Asc";
      case "hespi":
        return "Asc";
      default:
        return "Asc";
    }
  };

  const columns = [
    {
      title: localization.firmaAdi,
      dataIndex: "firmaAdi",
      key: "firmaAdi",
      render: (text, row, index) => (
        <Link to={`/CariIslemDetay/${row.cariIslemTur}/${row.id}`}>
          <Text
            style={{ textTransform: "uppercase" }}
            strong
          >{`${row.CariKart.firmaAdi}`}</Text>
        </Link>
      ),
    },
    {
      title: localization.vadeTarihi,
      dataIndex: "vadeTarih",
      key: "vadeTarih",
      render: (text, row, index) => moment(text).format("DD.MM.YYYY"),
    },
    {
      title: localization.islemTarihi,
      dataIndex: "islemTarih",
      key: "islemTarih",
      render: (text, row, index) => moment(text).format("DD.MM.YYYY"),
    },
    {
      title: localization.evrakNo,
      dataIndex: "evrakNo",
      key: "evrakNo",
    },
    {
      title: localization.durum,
      dataIndex: "id",
      key: "id",
      width: "15%",
      render: (text, row, index) => {
        const diff = moment(row.vadeTarih).diff(moment(), "days");
        return (
          <Tag color={diff >= 0 ? "green" : "red"} style={{}}>{`${Math.abs(
            diff
          )} ${
            diff >= 0
              ? localization.vadesiGelecekGun
              : localization.vadesiGecmisGün
          }`}</Tag>
        );
      },
    },
    {
      title: localization.Tutar,
      dataIndex: "tutar",
      key: "tutar",
      render: (text, row, index) => (
        <>
          <Row>
            <Col span={8}>{localization.tutar}</Col>
            <Col span={12}>
              <Typography.Title level={5}>{`${row.doviz.simge}${paraFormat(
                text
              )}`}</Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col span={8}>{localization.OdenenTutar}</Col>
            <Col span={12}>
              <Typography.Title level={5}>{`${row.doviz.simge}${paraFormat(
                row.tamamlananTutar
              )}`}</Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col span={8}>{localization.kalanTutar}</Col>
            <Col span={12}>
              <Typography.Title level={5}>{`${row.doviz.simge}${paraFormat(
                parseFloat(text - row.tamamlananTutar)
              )}`}</Typography.Title>
            </Col>
          </Row>
        </>
      ),
    },

    {
      title: localization.islemler,
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => (
        <Button
          size="middle"
          type="primary"
          icon={<SearchOutlined />}
          shape="round"
          onClick={() =>
            navigate(`/CariIslemDetay/${row.cariIslemTur}/${row.id}`)
          }
        >
          {localization.detayaGit}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <PageHeader
          title={getTitle()}
          onBack={() => navigate(-1)}
          ghost={false}
        />
        <FetchListContent
          columns={columns}
          requestUrl="/cariislem/vadeliodemedetay"
          requestBody={{
            Data: {
              GunTip: getGunTip(),
              DovizId: dovizId,
            },
            SortOptions: {
              ColumnName: "VadeTarih",
              SortType: getShortType(),
            },
            SelectColumns: [
              {
                ColumnNames: [
                  "id",
                  "tutar",
                  "aciklama",
                  "vadeTarih",
                  "islemTarih",
                  "evrakNo",
                  "cariIslemTur",
                ],
                TableName: "",
              },
              {
                ColumnNames: ["id", "firmaAdi"],
                TableName: "CariKart",
              },
              {
                ColumnNames: ["Tutar"],
                Operator: "Sum",
                TableName: "AltCariIslem:tamamlananTutar",
              },
              {
                ColumnNames: ["id", "ad", "kod", "simge"],
                TableName: "doviz",
              },
            ],
          }}
          onDataSource={(e) => setDataSource(e)}
          dataSource={dataSource}
        />
      </Space>
    </>
  );
}

export default VadeliOdemeDetay;
