import React from "react";
import { Form, Row, Col, Input } from "antd";
import moment from "moment";
import Datepicker from "../toolbox/DatePicker";
import KasaSelectOption from "../toolbox/KasaSelectOption";
import BankaHesapSelectOption from "../toolbox/BankaHesapSelectOption";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import OdemeSekliSelectOption from "../toolbox/OdemeSekliSelectOption";
import { odemeSekliTypes } from "../../types/odemeSekliTypes";
import strings from "../../utils/Localization";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";
import CurrencyInput, { setFieldsAutoClear } from "../toolbox/CurrencyInput";

export default function TahsilatOdemeForm({
  onFinish,
  form,
  formSize = "middle",
  id,
  dovizDisabled = false,
  initialValues = {},
}) {
  const { getDefaultSetting } = useVarsayilanDeger();
  const { tahsilatOdemeForm } = strings.cariIslem.components;
  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.dovizId !== undefined) {
      form.setFieldsValue({
        kasaId: null,
        bankaHesapId: null,
      });
    }
  };
  return (
    <Form
      id={id}
      form={form}
      layout="vertical"
      size="middle"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      initialValues={{
        islemTarihi: moment(),
        evrakNo: "",
        aciklama: "",
        dovizId: getDefaultSetting("doviz"),
        odemeSekli: getDefaultSetting("odemeSekli"),
        tutar: 0,
        ...initialValues,
      }}
    >
      <Row gutter={24}>
        <Col flex={1}>
          <Form.Item
            label={tahsilatOdemeForm.islemTarihi}
            name="islemTarihi"
            rules={[{ required: true }]}
          >
            <Datepicker size={formSize} />
          </Form.Item>
          <Form.Item label={tahsilatOdemeForm.evrakNo} name="evrakNo" required>
            <Input />
          </Form.Item>
          <Form.Item label={tahsilatOdemeForm.aciklama} name="aciklama">
            <Input.TextArea rows={5} placeholder={""} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            name="dovizId"
            label={strings.dovizSelectOption.dovizBaslik}
            rules={[{ required: true }]}
          >
            <DovizSelectOption disabled={dovizDisabled} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.dovizId !== currentValues.dovizId
            }
          >
            {({ getFieldValue }) => {
              const currentDovizId = getFieldValue("dovizId");
              if (currentDovizId !== null) {
                return (
                  <Form.Item
                    name="odemeSekli"
                    label={tahsilatOdemeForm.odemeSekli}
                    rules={[{ required: true }]}
                  >
                    <OdemeSekliSelectOption hiddenAcikHesap />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.odemeSekli !== currentValues.odemeSekli ||
              prevValues.dovizId !== currentValues.dovizId
            }
          >
            {({ getFieldValue }) => {
              const currentOdemeSekli = getFieldValue("odemeSekli");
              const currentDovizId = getFieldValue("dovizId");
              if (currentOdemeSekli === odemeSekliTypes.NAKIT) {
                return (
                  <Form.Item
                    name="kasaId"
                    label={tahsilatOdemeForm.kasa}
                    rules={[{ required: true }]}
                  >
                    <KasaSelectOption
                      showDovizKod
                      requestBody={{
                        Data: {
                          dovizId: currentDovizId,
                        },
                      }}
                    />
                  </Form.Item>
                );
              } else if (
                currentOdemeSekli === odemeSekliTypes.KREDI_KARTI ||
                currentOdemeSekli === odemeSekliTypes.HAVALE
              ) {
                return (
                  <Form.Item
                    name="bankaHesapId"
                    label={tahsilatOdemeForm.bankaHesap}
                    rules={[{ required: true }]}
                  >
                    <BankaHesapSelectOption
                      size={formSize}
                      showDovizKod
                      requestBody={{
                        Data: {
                          dovizId: currentDovizId,
                        },
                      }}
                    />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
          <Form.Item
            name="tutar"
            label={tahsilatOdemeForm.tutar}
            rules={[{ required: true }]}
          >
            <CurrencyInput {...setFieldsAutoClear(form, "tutar")} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
