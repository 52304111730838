import React from "react";
import { PageHeader as MyPageHeader, Button } from "antd";
import { useNavigate } from "react-router-dom";
import strings from "../../utils/Localization";
import { SaveOutlined } from "@ant-design/icons";

const PageHeader = ({ onSaveLoading = false }) => {
    const navigate= useNavigate();
    const { baslik, altBaslik, kaydet } = strings.kullaniciDuzenle.components.pageHeader

    return (
        <MyPageHeader
            ghost={false}
            onBack={() => navigate(-1)}
            title={baslik}
            subTitle={altBaslik}
            extra={[
                <Button
                    type="primary"
                    size="large"
                    form="kullaniciDuzenleForm"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    loading={onSaveLoading}
                >
                    {kaydet}
                </Button>,
            ]}
        />
    )
}

export default PageHeader