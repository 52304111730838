import React from "react";
import { Col, Divider, Row, Space, Typography } from "antd";
import { useSelector } from "react-redux";

function CardPaketFaturaBilgi() {
  const odemeReducer = useSelector((state) => state.odemeReducer);

  return (
    <Row gutter={24}>
      <Col span={12}>
        <Typography.Title level={3}>Paket Bilgileri</Typography.Title>
        <Divider style={{ marginTop: 0 }} />
        <Space direction="vertical">
          <Typography.Title
            level={3}
            style={{ color: "#00000094", letterSpacing: -0.5 }}
          >
            {odemeReducer?.paket?.ad}
          </Typography.Title>
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Typography.Title level={3}>Toplam</Typography.Title>
            <Typography.Title level={3}>
              {odemeReducer?.paket?.odenecektutar} TL
            </Typography.Title>
          </Space>
          <Typography.Text type="secondary" style={{ fontSize: 15 }}>
            {odemeReducer?.paket?.periyot === 30 ? "Aylık" : "Yıllık"} olarak
            faturalandırılır
          </Typography.Text>
        </Space>
      </Col>
      <Col span={12}>
        <Typography.Title level={3}>Fatura Bilgileri</Typography.Title>
        <Divider style={{ marginTop: 0 }} />
        <Typography.Text>
          {odemeReducer?.faturaAdresi?.adSoyad}
          <br />
          {odemeReducer?.faturaAdresi?.adres}
        </Typography.Text>
      </Col>
    </Row>
  );
}

export default CardPaketFaturaBilgi;
