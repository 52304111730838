import React, { useState } from "react";
import { Menu, Space } from "antd";
import {
  TeklifSiparisListe,
} from "../components/teklifSiparis";
import { TeklifSiparisDurumListe } from "../components/teklifSiparisDurumTip";

function TeklifSiparis() {
  const [selectedMenu, setSelectedMenu] = useState('1')

  const contentRender = () => {
    switch (selectedMenu.key) {
      case '1':
        return <TeklifSiparisListe />
      case '2':
        return <TeklifSiparisDurumListe />
      default:
        return <TeklifSiparisListe />
    }
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <Menu
        onClick={(e) => setSelectedMenu(e)}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="horizontal"
      >
        <Menu.Item key="1">{'Teklif Sipariş Listesi'}</Menu.Item>
        <Menu.Item key="2">{'Durum Tip Listesi'}</Menu.Item>
      </Menu>
      {contentRender()}
    </Space>
  );
}

export default TeklifSiparis;
