import React from 'react'
import { PageHeader, CariEkstreRaporForm } from '../components/cariEkstreRapor'
import { Col, Space } from 'antd'

export default function CariRapor() {
    return (
        <>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <PageHeader />
                <Col xxl={8} xl={12} lg={24} md={24} sm={24} xs={24}>
                    <CariEkstreRaporForm />
                </Col>
            </Space>
        </>
    )
}
