import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import jwt_decode from 'jwt-decode'

const useKullanici = () => {
  const navigate= useNavigate()
  const cookies = new Cookies()

  const cikisYap = () => {
    const eMail = getEMail
    localStorage.clear()
    cookies.remove('token', { path: '/' })
    cookies.remove('refreshToken', { path: '/' })
    setEMail(eMail)
    navigate('/GirisYap')
  }

  const setAuthParameters = (props = { eMail: '', token: '', refreshToken: '', defaultSettings: {} }) => {
    setToken(props.token)
    setDefaultSettings(JSON.stringify(props.defaultSettings))
    setRefreshToken(props.refreshToken)
    setEMail(props.eMail)
  }

  const getToken = cookies.get('token')

  const setToken = (value) => cookies.set('token', value, { path: '/' })

  const getRefreshToken = cookies.get('refreshToken')

  const setRefreshToken = (value) => cookies.set('refreshToken', value, { path: '/' })

  const getEMail = localStorage.getItem('eMail')

  const setEMail = (value) => localStorage.setItem('eMail', value)

  const getVergiNumarasi = getToken && jwt_decode(getToken)?.VergiNo

  const getDefaultSettings = localStorage.getItem('defaultSettings')

  const setDefaultSettings = (value) => localStorage.setItem('defaultSettings', value)

  const getSubeId = getToken && jwt_decode(getToken)?.SubeId

  const getAbonelikBitisTarihi = getToken && jwt_decode(getToken)?.AbonelikBitisTarihi

  const getEFaturaAktifMi = getToken && jwt_decode(getToken)?.EFaturaAktifMi === 'True' ? true : false

  return {
    cikisYap,
    getToken,
    setToken,
    getRefreshToken,
    setRefreshToken,
    getEMail,
    setEMail,
    getDefaultSettings,
    setDefaultSettings,
    getVergiNumarasi,
    getSubeId,
    setAuthParameters,
    getAbonelikBitisTarihi,
    getEFaturaAktifMi,
  }
}

export default useKullanici
