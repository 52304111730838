import React, { useState, useEffect } from "react";
import FaturaLayout from "../components/faturaEkle/FaturaLayout";
import { Card, Form, message, notification } from "antd";
import { useLocation } from "react-router-dom";
import { faturaTypes } from "../types/faturaTypes";
import { odemeSekliTypes } from "../types/odemeSekliTypes";
import { cariIslemTurTypes } from "../types/cariIslemTurTypes";
import strings from "../utils/Localization";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useVarsayilanDeger from "../hooks/useVarsayilanDeger";
import { tutariYuzdeyeCevir, yuzdeHesala } from "../utils/faturaSatirHesaplama";
import useApi from "../hooks/useApi";

export default function FaturaEkle() {
  const [saveLoading, setSaveLoading] = useState(false);
  const [getSiparisLoading, setGetSiparisLoading] = useState(false);
  const [form] = Form.useForm();
  const {
    state = { faturaTip: faturaTypes.SATIS_FATURASI, siparisId: undefined },
  } = useLocation();
  const { faturaEkle: localization } = strings;
  const navigate= useNavigate();
  const { getDefaultSetting } = useVarsayilanDeger();
  const api = useApi();

  useEffect(() => {
    form.setFieldsValue({ faturaTip: state.faturaTip });
    if (state.siparisId !== undefined) {
      getSiparis();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        cariKartId,
        dovizId,
        odemeSekli,
        kasaId,
        bankaHesapId,
        faturaNo,
        faturaTarihi,
        faturaSaati,
        vadeTarihi,
        aciklama,
        stopajOran,
        stopajTutar,
        tevkifatOran,
        tevkifatTutar,
        toplamSatirIskontoTutar,
        araToplamTutar,
        araIskontoOran,
        araIskontoTutar,
        araIskontoTip,
        genelToplamTutar,
        faturaSatir = [
          {
            aciklama: "",
            stokId: null,
            varyantId: null,
            miktar: 1,
            birimFiyat: 0,
            kdvOran: 0,
            kdvTutar: 0,
            otvOran: 0,
            otvTutar: 0,
            toplam: 0,
            iskontoOran: 0,
            iskontoTip: "yuzde",
            iskontoTutar: 0,
          },
        ],
        firmaAdi,
        yetkili,
        vergiDairesi,
        vergiNumarasi,
        telefon,
        cepTelefonu,
        eMail,
        il,
        ilce,
        adres,
        sevkAdresi,
        sevkTarihi,
        sevkNo,
      } = values;
      let requestData = {
        SiparisHasFatura:
          state.siparisId === undefined
            ? null
            : [{ siparisId: state.siparisId }],
        CariKartId: cariKartId,
        DovizId: dovizId,
        FaturaTip: state.faturaTip, //Alış Faturası,Satış Faturası,Alış İade Faturası,Satış İade Faturası
        OdemeSekli: odemeSekli,
        Carpan: 1,
        FirmaAdi: firmaAdi,
        Yetkili: yetkili,
        VergIdairesi: vergiDairesi,
        VergiNumarasi: vergiNumarasi,
        Telefon: telefon,
        CepTelefonu: cepTelefonu,
        EMail: eMail,
        // CariKod: '',
        Il: il,
        Ilce: ilce,
        Adres: adres,
        SevkAdresi: sevkAdresi,
        FaturaNo: faturaNo,
        FaturaTarihi: faturaTarihi,
        SevkTarihi: sevkTarihi,
        SevkNumarasi: sevkNo,
        FaturaSaati: moment(faturaSaati).format("HH:mm:ss"),
        Aciklama: aciklama,
        StopajOran: stopajOran,
        StopajTutar: stopajTutar,
        TevkifatOran: tevkifatOran,
        TevkifatTutar: tevkifatTutar,
        FaturaSatirIskontoToplamTutar: toplamSatirIskontoTutar,
        Toplam: araToplamTutar,
        AraToplamIskontoOran: araIskontoOran,
        AraToplamIskontoTutar: araIskontoTutar,
        AraToplamIskontoTip: araIskontoTip === "yuzde" ? "Oran" : "Tutar",
        GenelToplam: genelToplamTutar,
        Faturasatir: faturaSatir
          .filter((x) => x.hidden === false)
          .map((item) => ({
            StokVaryantGrupId: item.stokVaryantGrupId,
            StokBirimId: item.stokBirimId,
            UrunAdi: item.urunAdi,
            Miktar: item.miktar,
            BirimFiyat: item.birimFiyat,
            Barkod: item.barkod,
            Aciklama: item.aciklama,
            OtvTutar: item.otvTutar,
            OtvOran: item.otvOran,
            GenelToplam: item.toplam,
            // OivTutar: 20,
            // OivOran: 25,
            KdvTutar: item.kdvTutar,
            KdvOran: item.kdvOran,
            Faturasatiriskonto: item.iskontoOran
              ? [
                  {
                    Oran: item.iskontoYuzde,
                    Tutar: item.iskontoTutar,
                    IskontoTip: item.iskontoTip === "yuzde" ? "Oran" : "Tutar",
                    UygulananTutar: item.iskontoUygulananTutar,
                    SiraNo: 0,
                  },
                ]
              : null,
            Stokislem: {
              Aciklama: item.aciklama,
              DovizId: dovizId,
              GirisMi: faturaGirisMi(),
              IslemTarihi: sevkTarihi,
              Miktar: item.miktar,
              StokBirimId: item.stokBirimId,
              StokDepoId: item.stokDepoId,
              StokVaryantGrupId: item.stokVaryantGrupId,
              BirimFiyat: item.birimFiyat,
            },
          })),
        Cariislembaglanti: {
          CariKartId: cariKartId,
          Cariislem: {
            CariKartId: cariKartId,
            DovizId: dovizId,
            CariIslemTur: cariIslemTur(),
            EvrakNo: faturaNo,
            Aciklama: `${faturaNo} numaralı faturanın ${cariIslemTur()} işlemidir`,
            Carpan: 1,
            Tutar: genelToplamTutar,
            VadeTarih: vadeTarihi,
            IslemTarih: faturaTarihi,
            GirisMi: faturaGirisMi(),
            OdemeSekli: odemeSekliTypes.ACIK_HESAP,
            AltCariIslem:
              odemeSekli !== odemeSekliTypes.ACIK_HESAP
                ? [
                    {
                      CariKartId: cariKartId,
                      DovizId: dovizId,
                      CariIslemTur:
                        cariIslemTur() === cariIslemTurTypes.BORC
                          ? cariIslemTurTypes.TAHSILAT
                          : cariIslemTurTypes.ODEME,
                      EvrakNo: faturaNo,
                      Aciklama: `${faturaNo} numaralı faturanın ${
                        cariIslemTur() === cariIslemTurTypes.BORC
                          ? cariIslemTurTypes.TAHSILAT
                          : cariIslemTurTypes.ODEME
                      } işlemidir`,
                      Carpan: 1,
                      Tutar: genelToplamTutar,
                      VadeTarih: vadeTarihi,
                      IslemTarih: faturaTarihi,
                      GirisMi: !faturaGirisMi(),
                      OdemeSekli: odemeSekli,
                      Cariislembaglanti: {
                        CariKartId: cariKartId,
                        BankaHesapId:
                          odemeSekli === odemeSekliTypes.HAVALE ||
                          odemeSekli === odemeSekliTypes.KREDI_KARTI
                            ? bankaHesapId
                            : null,
                        KasaId:
                          odemeSekli === odemeSekliTypes.NAKIT ? kasaId : null,
                        KasaIslem:
                          odemeSekli === odemeSekliTypes.NAKIT
                            ? {
                                KasaId: kasaId,
                                EvrakNo: faturaNo,
                                Aciklama: `${faturaNo} numaralı faturanın işlemidir`,
                                VirmanMi: 0,
                                GirisMi: !faturaGirisMi(),
                                Tutar: genelToplamTutar,
                                IslemTarihi: faturaTarihi,
                              }
                            : null,
                        BankaHesapIslem:
                          odemeSekli === odemeSekliTypes.KREDI_KARTI ||
                          odemeSekli === odemeSekliTypes.HAVALE
                            ? {
                                BankaHesapId: bankaHesapId,
                                EvrakNo: faturaNo,
                                Aciklama: `${faturaNo} numaralı faturanın işlemidir`,
                                VirmanMi: 0,
                                GirisMi: faturaGirisMi(),
                                Tutar: genelToplamTutar,
                                IslemTarihi: faturaTarihi,
                              }
                            : null,
                      },
                    },
                  ]
                : null,
          },
        },
      };
      setSaveLoading(true);
      const response = await api.post("/fatura", requestData);
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        if (state.siparisId === undefined) {
          navigate(-1);
        } else {
          navigate("/Fatura");
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setSaveLoading(false);
    }
  };

  const faturaGirisMi = () => {
    const faturaTip = form.getFieldValue("faturaTip");
    switch (faturaTip) {
      case faturaTypes.SATIS_FATURASI:
        return false;
      case faturaTypes.ALIS_FATURASI:
        return true;
      case faturaTypes.SATIS_IADE_FATURASI:
        return true;
      case faturaTypes.ALIS_IADE_FATURASI:
        return false;
      default:
        return null;
    }
  };
  const cariIslemTur = () => {
    const faturaTip = form.getFieldValue("faturaTip");
    switch (faturaTip) {
      case faturaTypes.SATIS_FATURASI:
        return cariIslemTurTypes.BORC;
      case faturaTypes.ALIS_FATURASI:
        return cariIslemTurTypes.ALACAK;
      case faturaTypes.SATIS_IADE_FATURASI:
        return cariIslemTurTypes.ALACAK;
      case faturaTypes.ALIS_IADE_FATURASI:
        return cariIslemTurTypes.BORC;
      default:
        return null;
    }
  };
  const getSiparis = async () => {
    try {
      setGetSiparisLoading(true);
      const response = await api.post("/teklifsiparis/get", {
        Data: {
          Id: state.siparisId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: [
              "id",
              "cariKartId",
              "dovizId",
              "tip",
              "firmaAdi",
              "yetkili",
              "vergiDairesi",
              "vergiNumarasi",
              "telefon",
              "cepTelefonu",
              "eMail",
              "cariKod",
              "teklifSiparisNo",
              "teklifSiparisTarihi",
              "gecerlilikTarihi",
              "aciklama",
              "stopajOran",
              "stopajTutar",
              "tevkifatOran",
              "tevkifatTutar",
              "toplam",
              "satirIskontoToplamTutar",
              "araToplamIskontoOran",
              "araToplamIskontoTutar",
              "araToplamIskontoTip",
              "genelToplam",
            ],
          },
          {
            TableName: "Teklifsiparissatir",
            ColumnNames: [
              "id",
              "teklifSiparisId",
              "stokVaryantGrupId",
              "stokBirimId",
              "urunAdi",
              "miktar",
              "birimFiyat",
              "barkod",
              "aciklama",
              "otvTutar",
              "otvOran",
              "oivTutar",
              "oivOran",
              "kdvTutar",
              "kdvOran",
              "genelToplam",
            ],
          },
          {
            TableName: "Teklifsiparissatir.Teklifsiparissatiriskonto",
            ColumnNames: [
              "id",
              "teklifSiparisSatirId",
              "oran",
              "tutar",
              "iskontoTip",
            ],
          },
          {
            TableName: "Teklifsiparissatir.StokVaryantGrup",
            ColumnNames: ["stokId"],
          },
          {
            TableName:
              "Teklifsiparissatir.StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger",
            ColumnNames: [
              "id",
              "stokVaryantId",
              "ad",
            ],
          },
        ],
      });
      if (response.status === 200) {
        const result = response.data.data[0];
        form.setFieldsValue({
          cariKartId: result.cariKartId,
          aciklama: result.aciklama,
          dovizId: String(result.dovizId),
          faturaSatir: result.Teklifsiparissatir.map(
            (teklifSiparisSatirItem) => {
              let satiriskontoLength =
                teklifSiparisSatirItem.Teklifsiparissatiriskonto.length;
              return {
                id: teklifSiparisSatirItem.id,
                siparisSatirMi:true,
                stokBirimId: teklifSiparisSatirItem.stokBirimId,
                urunAdi: teklifSiparisSatirItem.urunAdi,
                barkod: teklifSiparisSatirItem.barkod,
                stokId: teklifSiparisSatirItem.StokVaryantGrup.stokId,
                stokVaryantGrupId: teklifSiparisSatirItem.stokVaryantGrupId,
                varyantGrupAdi:
                  teklifSiparisSatirItem.StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger.map(
                    (varyantGrupItem) => {
                      return varyantGrupItem.StokVaryantDeger.ad + " - ";
                    }
                  ).join(""),
                aciklama: teklifSiparisSatirItem.aciklama,
                stokDepoId: getDefaultSetting("stokDepo"),
                miktar: teklifSiparisSatirItem.miktar,
                birimFiyat: teklifSiparisSatirItem.birimFiyat,
                kdvOran: teklifSiparisSatirItem.kdvOran,
                kdvTutar: teklifSiparisSatirItem.kdvTutar,
                otvOran: teklifSiparisSatirItem.otvOran,
                otvTutar: teklifSiparisSatirItem.otvTutar,
                toplam: teklifSiparisSatirItem.genelToplam,
                iskontoId:
                  satiriskontoLength === 0
                    ? null
                    : teklifSiparisSatirItem.Teklifsiparissatiriskonto[0].id,
                iskontoOran:
                  satiriskontoLength === 0
                    ? 0
                    : teklifSiparisSatirItem.Teklifsiparissatiriskonto[0].oran,
                iskontoTip:
                  satiriskontoLength === 0
                    ? "yuzde"
                    : teklifSiparisSatirItem.Teklifsiparissatiriskonto[0]
                        .iskontoTip === "Oran"
                    ? "yuzde"
                    : "tutar",
                iskontoTutar:
                  satiriskontoLength === 0
                    ? 0
                    : teklifSiparisSatirItem.Teklifsiparissatiriskonto[0].tutar,
                yeniSatirMi: true,
              };
            }
          ),
          araIskontoOran: result.araToplamIskontoOran,
          araIskontoTip:
            result.araToplamIskontoTip === "Oran" ? "yuzde" : "tutar",
          araIskontoTutar: result.araToplamIskontoTutar,
          araIskontoDeger:
            result.araToplamIskontoTip === "Oran"
              ? result.araToplamIskontoOran
              : result.araToplamIskontoTutar,
          stopajOran: result.stopajOran,
          stopajTutar: result.stopajTutar,
          tevkifatOran: result.tevkifatOran,
          tevkifatTutar: result.tevkifatTutar,
        });
        setTimeout(() => {
          setFaturaFooterFields();
        }, 1500);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setGetSiparisLoading(false);
    }
  };
  const setFaturaFooterFields = () => {
    const fields = form.getFieldsValue();
    const {
      faturaSatir,
      araIskontoDeger = 0,
      araIskontoTip = "yuzde",
      stopajOran = 0,
      tevkifatOran = 0,
    } = fields;
    let faturaSatirNoHidden = faturaSatir.filter((x) => x.hidden === false);

    const araToplam = faturaSatirNoHidden.reduce(
      (toplam, item) => (toplam += parseFloat(item.birimFiyat * item.miktar)),
      0
    );
    const toplamSatirIskontoTutar = faturaSatirNoHidden.reduce(
      (toplam, item) => (toplam += parseFloat(item.iskontoTutar)),
      0
    );

    const araIskontoTutar =
      araIskontoTip === "tutar"
        ? parseFloat(araIskontoDeger)
        : yuzdeHesala(araToplam - toplamSatirIskontoTutar, araIskontoDeger);

    const araIskontoOran =
      araIskontoTip === "yuzde"
        ? parseFloat(araIskontoDeger)
        : tutariYuzdeyeCevir(
            araIskontoDeger,
            araToplam - toplamSatirIskontoTutar
          );

    const brutToplam = parseFloat(
      araToplam - toplamSatirIskontoTutar - araIskontoTutar
    );

    const stopajTutar = yuzdeHesala(brutToplam, stopajOran);

    let toplamOtvTutar = faturaSatirNoHidden.reduce(
      (toplam, item) => (toplam += parseFloat(item.otvTutar)),
      0
    );
    toplamOtvTutar -= yuzdeHesala(toplamOtvTutar, araIskontoOran);

    let toplamKdv01Tutar = faturaSatirNoHidden.reduce(
      (toplam, item) =>
        item.kdvOran === 1 ? (toplam += item.kdvTutar) : (toplam += 0),
      0
    );
    toplamKdv01Tutar -= yuzdeHesala(toplamKdv01Tutar, araIskontoOran);

    let toplamKdv08Tutar = faturaSatirNoHidden.reduce(
      (toplam, item) =>
        item.kdvOran === 8 ? (toplam += item.kdvTutar) : (toplam += 0),
      0
    );
    toplamKdv08Tutar -= yuzdeHesala(toplamKdv08Tutar, araIskontoOran);

    let toplamKdv18Tutar = faturaSatirNoHidden.reduce(
      (toplam, item) =>
        item.kdvOran === 18 ? (toplam += item.kdvTutar) : (toplam += 0),
      0
    );
    toplamKdv18Tutar -= yuzdeHesala(toplamKdv18Tutar, araIskontoOran);

    const toplamKdvTutar = parseFloat(
      toplamKdv01Tutar + toplamKdv08Tutar + toplamKdv18Tutar
    );

    const tevkifatTutar = yuzdeHesala(toplamKdvTutar, parseFloat(tevkifatOran));

    const genelToplamTutar = parseFloat(
      araToplam -
        toplamSatirIskontoTutar -
        araIskontoTutar -
        stopajTutar +
        toplamKdvTutar +
        toplamOtvTutar -
        tevkifatTutar
    );

    form.setFields([
      { name: "araIskontoTutar", value: araIskontoTutar },
      { name: "araIskontoOran", value: araIskontoOran },
      { name: "araToplamTutar", value: araToplam },
      { name: "toplamSatirIskontoTutar", value: toplamSatirIskontoTutar },
      { name: "brutToplamTutar", value: brutToplam },
      { name: "stopajTutar", value: stopajTutar },
      { name: "toplamOtvTutar", value: toplamOtvTutar },
      { name: "toplamKdv01Tutar", value: toplamKdv01Tutar },
      { name: "toplamKdv08Tutar", value: toplamKdv08Tutar },
      { name: "toplamKdv18Tutar", value: toplamKdv18Tutar },
      { name: "toplamKdvTutar", value: toplamKdvTutar },
      { name: "tevkifatTutar", value: tevkifatTutar },
      { name: "genelToplamTutar", value: genelToplamTutar },
    ]);
  };

  if (getSiparisLoading) {
    return <Card loading={true} />;
  }

  return (
    <FaturaLayout
      form={form}
      title={state.faturaTip}
      saveButtonTitle={localization.kaydetButon}
      saveButtonLoading={saveLoading}
      onFinish={onFinish}
      setFaturaFooterFields={setFaturaFooterFields}
    />
  );
}
