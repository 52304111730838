import { Card } from 'antd'
import React from 'react'
import useCurrency from '../../hooks/useCurrency'
import strings from '../../utils/Localization'

interface IProps {
  doviz: {
    dovizAd: string
    dovizKod: string
    dovizSimge: string
  }
  giris: number
  cikis: number
  bakiye: number
}

const BakiyeCard: React.FC<IProps> = (props) => {
  const { paraFormat } = useCurrency()
  const  localization= strings.bakiyeCard

  const gridStyle: any = {
    width: '50%',
    textAlign: 'center',
    padding: 4,
  }

  const gridTitleStyle: any = {
    ...gridStyle,
    fontWeight: '600',
  }

  const { doviz, giris, cikis, bakiye } = props

  return (
    <Card>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {localization?.dovizAdi}
      </Card.Grid>
      <Card.Grid style={gridStyle}>{`${doviz.dovizAd} (${doviz.dovizKod})`}</Card.Grid>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {localization?.giris}
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        {doviz.dovizSimge}
        {paraFormat(giris)}
      </Card.Grid>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {localization?.cikis}
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        {doviz.dovizSimge}
        {paraFormat(cikis)}
      </Card.Grid>
      <Card.Grid style={gridTitleStyle} hoverable={false}>
        {localization?.bakiye}
      </Card.Grid>
      <Card.Grid style={{ ...gridStyle, marginBottom: 16 }}>
        {doviz.dovizSimge}
        {paraFormat(bakiye)}
      </Card.Grid>
    </Card>
  )
}

export default BakiyeCard
