import { Space, Menu } from "antd";
import React, { useState } from "react";
import strings from "../utils/Localization";

//Tab Screens
import CariGrupListe from "../components/cari/CariGrupListe";
import CariKartListe from "../components/cari/CariKartListe";

function Cari() {
  const [selectedMenu, setSelectedMenu] = useState({ key: "1" });
  const { cari: localization } = strings;

  const contentRender = () => {
    switch (selectedMenu.key) {
      case "1":
        return <CariKartListe />;
      case "2":
        return <CariGrupListe />;
      default:
        return <CariKartListe />;
    }
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Menu
        onClick={(e) => setSelectedMenu(e)}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="horizontal"
      >
        <Menu.Item key="1">{localization.menu.cariKart}</Menu.Item>
        <Menu.Item key="2">{localization.menu.carigrup}</Menu.Item>
      </Menu>
      {contentRender()}
    </Space>
  );
}

export default Cari;
