import React, { useEffect } from "react";
import { PageHeader, Button, Form, Typography, Space } from "antd";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { FileAddOutlined, SaveOutlined } from "@ant-design/icons";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";
import useEvrakNo from "../../hooks/useEvrakNo";
import FaturaHeader from "./FaturaHeader";
import FaturaSatir from "./FaturaSatir";
import FaturaFooter from "./FaturaFooter";
import strings from "../../utils/Localization";
const { Text } = Typography;

const formSize = "middle";

function FaturaLayout({
  title = "-",
  saveButtonTitle = "-",
  saveButtonLoading = false,
  form,
  onFinish,
  setFaturaFooterFields
}) {
  const { getDefaultSetting } = useVarsayilanDeger();
  const { getFaturaEvrakNo } = useEvrakNo();
  const {
    state = {  siparisId: undefined },
  } = useLocation();
  const { faturaEkle: localization } = strings;

  useEffect(() => {
    getFaturaEvrakNo().then((evrakNo) =>
      form.setFieldsValue({ faturaNo: evrakNo })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.dovizId !== undefined) {
      form.setFieldsValue({
        kasaId: undefined,
        bankaHesapId: undefined,
      });
    }
    if (changedValues.stopajOran !== undefined) {
      setFaturaFooterFields();
    }
    if (changedValues.tevkifatOran !== undefined) {
      setFaturaFooterFields();
    }

    if (
      changedValues.araIskontoTip !== undefined ||
      changedValues.araIskontoDeger !== undefined
    ) {
      setFaturaFooterFields();
    }
  };

  

  const firstOnFinish = (values) => {
    onFinish(values);
  };
  return (
    <div>
      <PageHeader
        title={
          <Text style={{ fontSize: 22 }}>
            <FileAddOutlined /> {title}  {state.siparisId!==undefined&&` (${localization.siparisiFaturayaCevir})`}
          </Text>
        }
        extra={
          <Button
            icon={<SaveOutlined />}
            type="primary"
            title="Kaydet"
            size="large"
            form="myForm"
            htmlType="submit"
            loading={saveButtonLoading}
          >
            {saveButtonTitle}
          </Button>
        }
        style={{ padding: 0, paddingBottom: 18 }}
      />
      <Form
        id="myForm"
        size={formSize}
        labelCol={{ span: 6 }}
        labelAlign="left"
        form={form}
        onValuesChange={onValuesChange}
        initialValues={{
          faturaTip: null,
          faturaTarihi: moment(),
          faturaSaati: moment(),
          vadeTarihi: moment(),
          sevkTarihi: moment(),
          dovizId: getDefaultSetting("doviz"),
          odemeSekli: getDefaultSetting("odemeSekli"),
          faturaNo: "",
          faturaSatir: [
            {
              stokId: null,
              stokVaryantGrupId: null,
              stokBirimId: null,
              stokDepoId: getDefaultSetting("stokDepo"),
              urunAdi: "",
              aciklama: "",
              miktar: 1,
              birimFiyat: 0,
              barkod: "",
              otvTutar: 0,
              otvOran: 0,
              kdvTutar: 0,
              kdvOran: 0,
              toplam: 0,
              iskontoOran: 0,
              iskontoTip: "yuzde",
              iskontoTutar: 0,
            },
          ],
          araToplamTutar: 0,
          toplamSatirIskontoTutar: 0,
          araIskontoDeger: 0,
          araIskontoOran: 0,
          araIskontoTip: "yuzde",
          araIskontoTutar: 0,
          brutToplamTutar: 0,
          stopajOran: 0,
          stopajTutar: 0,
          toplamOtvTutar: 0,
          toplamKdv01Tutar: 0,
          toplamKdv08Tutar: 0,
          toplamKdv18Tutar: 0,
          toplamKdvTutar: 0,
          tevkifatOran: 0,
          tevkifatTutar: 0,
          genelToplamTutar: 0,
        }}
        onFinish={firstOnFinish}
      >
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <FaturaHeader form={form} />
          <FaturaSatir form={form} setFooterFields={setFaturaFooterFields} />
          <FaturaFooter form={form} setFooterFields={setFaturaFooterFields} />
        </Space>
        <Form.Item name="faturaTip" initialValue={null} hidden noStyle />
      </Form>
      <br />
    </div>
  );
}

export default FaturaLayout;
