import React, { useState } from "react";
import { Form, Input, message, notification, Row, Col, InputNumber, Button, Card, PageHeader, Space } from "antd";
import strings from "../utils/Localization";
import { useNavigate } from 'react-router-dom'
import useApi from "../hooks/useApi";
import StokSelectOption from "../components/toolbox/StokSelectOption";
import DatePicker from "../components/toolbox/DatePicker";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import UretimReceteSelectOption from "../components/toolbox/UretimReceteSelectOption";
import moment from "moment";
import { SaveOutlined } from "@ant-design/icons";
import StokVaryantGrupSelectOption from "../components/toolbox/StokVaryantGrupSelectOption";

const formSize = "middle";

const minusIconStyle = { alignItems: 'center', justifyContent: 'center', display: 'flex' }

const UretimEkle = () => {
    const [fetchLoading, setFetchLoading] = useState(false);
    const [selectedStok, setSelectedStok] = useState({ Stokvaryantgrup: [] })
    const [firstRender, setFirstRender] = useState(true)
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const api = useApi();
    const localization = strings.uretimEkle;

    const onFinish = async (values) => {
        try {
            let stokVaryantGrupId;

            if (selectedStok.Stokvaryantgrup.length > 1) {
                stokVaryantGrupId = values.stokVaryantGrupId
            }
            else {
                stokVaryantGrupId = selectedStok.Stokvaryantgrup[0].id
            }

            const uretimMalzeme = values.uretimMalzeme.filter(x => x?.hidden !== true).map(item => {
                let malzemeStokVaryantGrupId;
                const { stokVaryantGrupId, selectedStok } = item

                if (selectedStok.Stokvaryantgrup.length > 1) {
                    malzemeStokVaryantGrupId = stokVaryantGrupId
                }
                else {
                    malzemeStokVaryantGrupId = selectedStok.Stokvaryantgrup[0].id
                }

                return {
                    ...item,
                    stokVaryantGrupId: malzemeStokVaryantGrupId
                }
            })

            setFetchLoading(true);
            const response = await api.post("/uretim", {
                UretimReceteId: values.uretimReceteId,
                StokVaryantGrupId: stokVaryantGrupId,
                EvrakNo: values.evrakNo,
                Baslik: values.baslik,
                Aciklama: values.aciklama,
                Miktar: values.miktar,
                UretimBaslangicTarihi: values.uretimBaslangicTarihi,
                uretimMalzeme:uretimMalzeme
            });

            if (response.status === 200) {
                notification["success"]({
                    message: strings.mesaj.basarliBaslik,
                    description: strings.mesaj.islemBasarili,
                });
                form.resetFields();
                navigate("/Uretim")
            }
        } catch (error) {
            message.error(error.message);
        } finally {
            setFetchLoading(false);
        }
    };

    const onValuesChange = (changedValues, allValues) => {

        if (changedValues?.uretimReceteId) {
            fetchUretimReceteMalzeme(changedValues.uretimReceteId)
        }

        if (changedValues?.miktar !== undefined) {
            const yeniMalzemePlanlama = allValues.uretimMalzeme.map(item => ({
                ...item,
                miktar: parseFloat(item.birimMiktar) * parseFloat(changedValues?.miktar)
            }))

            form.setFieldsValue({
                uretimMalzeme: yeniMalzemePlanlama
            })
        }

    }

    const fetchUretimReceteMalzeme = async (receteId) => {
        const response = await api.post('/uretimrecete/get', {
            Data: {
                Id: receteId,
            },
            SelectColumns: [
                {
                    ColumnNames: ['id', 'ad', 'stokVaryantGrupId', 'aciklama'],
                    TableName: '',
                },
                {
                    ColumnNames: ['id', 'stokId'],
                    TableName: 'stokVaryantGrup',
                },
                {
                    ColumnNames: ['stokVaryantGrupId', 'miktar'],
                    TableName: 'uretimrecetemalzeme',
                },
                {
                    ColumnNames: ['id', 'stokId'],
                    TableName: 'uretimrecetemalzeme.StokVaryantGrup',
                },
            ],
        })

        if (response.status === 200) {
            const data = response.data.data[0]
            form.setFieldsValue({
                stokId: data.stokVaryantGrup.stokId,
                stokVaryantGrupId: String(data.stokVaryantGrup.id),
                uretimMalzeme: response.data.data[0].uretimrecetemalzeme.map(item => ({
                    stokId: item.StokVaryantGrup.stokId,
                    stokVaryantGrupId: String(item.stokVaryantGrupId),
                    birimMiktar: item.miktar,
                    miktar: parseFloat(form.getFieldValue("miktar")) * item.miktar
                }))
            })
        }
    }

    const onSelectedStok = (e) => {
        setSelectedStok(e)

        if (firstRender) {
            form.setFieldsValue({
                selectedStok: e
            })
        }
        else {
            form.setFieldsValue({
                selectedStok: e,
                stokVaryantGrupId: undefined
            })
        }
        setFirstRender(false)

    }

    return (
        <Space style={{ width: '100%' }} direction="vertical" size={"large"}>
            <PageHeader
                title={localization.pageHeader.baslik}
                onBack={() => navigate(-1)}
                extra={[
                    <Button loading={fetchLoading} form="uretimEkleModal" htmlType="submit" icon={<SaveOutlined />} type="primary" size="large">{localization.pageHeader.kaydetButon}</Button>
                ]}
                style={{ padding: 0 }}
            />
            <Form
                name="uretimEkleModal"
                form={form}
                onFinish={onFinish}
                layout="vertical"
                size={formSize}
                onValuesChange={onValuesChange}
                initialValues={{
                    uretimBaslangicTarihi: moment(),
                    miktar: 1,
                    uretimMalzeme: [{
                        miktar: 1,
                        birimMiktar: 1
                    }]
                }}
            >
                <Card title={localization.uretimBilgileri}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="baslik"
                                label={localization.uretimAdi}
                                rules={[{ required: true }]}
                            >
                                <Input placeholder={localization.uretimAdiPlaceHolder} />
                            </Form.Item>
                            <Form.Item
                                name="uretimReceteId"
                                label={localization.uretimRecetesi}
                                rules={[{ required: true }]}
                            >
                                <UretimReceteSelectOption />
                            </Form.Item>
                            <Form.Item
                                name="stokId"
                                label={localization.uretilecekUrun}
                                rules={[{ required: true }]}
                            >
                                <StokSelectOption onSelectedStok={onSelectedStok} />
                            </Form.Item>
                            {selectedStok.Stokvaryantgrup.length > 1 && (
                                <Form.Item
                                    name="stokVaryantGrupId"
                                    label={localization.uretilecekUrunVaryanti}
                                    rules={[{ required: true }]}
                                >
                                    <StokVaryantGrupSelectOption stokId={selectedStok.id} />
                                </Form.Item>
                            )}
                            <Form.Item
                                name="miktar"
                                label={localization.miktar}
                                rules={[{ required: true }]}
                            >
                                <InputNumber style={{ width: '100%' }} placeholder={localization.miktarPlaceHolder} min={0} />
                            </Form.Item>

                        </Col>
                        <Col span={12}>

                            <Form.Item
                                name="uretimBaslangicTarihi"
                                label={localization.uretimBaslangicTarihi}
                                rules={[{ required: true }]}
                            >
                                <DatePicker allowClear={false} />
                            </Form.Item>
                            <Form.Item
                                name="evrakNo"
                                label={localization.evrakNo}
                            >
                                <Input placeholder={localization.evrakNoPlaceHolder} />
                            </Form.Item>
                            <Form.Item
                                name="aciklama"
                                label={localization.aciklama}
                            >
                                <Input.TextArea rows={4} placeholder={localization.aciklamaPlaceHolder} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <br />
                <Card title={localization.malzemeListesi}>
                    <Form.List name="uretimMalzeme">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name }) => (
                                    <UretimMalzemeItem name={name} form={form} />
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        {localization.uretimMalzemesiEkle}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>
            </Form>
        </Space>
    )
}

export default UretimEkle

const UretimMalzemeItem = ({ name, form }) => {
    const [selectedStok, setSelectedStok] = useState({ Stokvaryantgrup: [] })
    const [firstRender, setFirstRender] = useState(true)
    const [satirHidden, setSatirHidden] = useState(false)
    const localization = strings.uretimEkle;

    const onMalzemeListeBirimMiktarChange = (index, value) => {
        const yeniMalzemePlanlama = form.getFieldValue("uretimMalzeme")
        yeniMalzemePlanlama[index].miktar = parseFloat(form.getFieldValue("miktar")) * value
        form.setFieldsValue({ uretimMalzeme: yeniMalzemePlanlama })
    }

    const onSelectedStok = (e) => {
        setSelectedStok(e)
        if (firstRender) {
            form.setFields([
                {
                    name: ["uretimMalzeme", name, "selectedStok"],
                    value: e
                }
            ])
        }
        else {
            form.setFields([
                {
                    name: ["uretimMalzeme", name, "selectedStok"],
                    value: e
                },
                {
                    name: ["uretimMalzeme", name, "stokVaryantGrupId"],
                    value: undefined
                }
            ])
        }
        setFirstRender(false)
    }

    const remove = () => {
        setSatirHidden(true)
        form.setFields([{ name: ['uretimMalzeme', name, 'hidden'], value: true }])
    }

    if (satirHidden) {
        return null
    }

    return (
        <Row gutter={12}>
            <Col span={5}>
                <Form.Item name={[name, "stokId"]} label={localization.Uretimmalzeme.urun} rules={[{ required: true }]}>
                    <StokSelectOption onSelectedStok={onSelectedStok} />
                </Form.Item>
                {selectedStok.Stokvaryantgrup.length > 1 && (
                    <Form.Item label={localization.Uretimmalzeme.urunVaryanti} name={[name, "stokVaryantGrupId"]} rules={[{ required: true }]}>
                        <StokVaryantGrupSelectOption stokId={selectedStok.id} />
                    </Form.Item>
                )}
            </Col>
            <Col span={5}>
                <Form.Item name={[name, "birimMiktar"]} label={localization.Uretimmalzeme.birimMiktar} rules={[{ required: true }]}>
                    <InputNumber style={{ width: '100%' }} placeholder={localization.Uretimmalzeme.birimMiktar} onChange={(e) => { onMalzemeListeBirimMiktarChange(name, e) }} min={0} />
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item name={[name, "miktar"]} label={localization.Uretimmalzeme.miktar} rules={[{ required: true }]}>
                    <InputNumber style={{ width: '100%' }} placeholder={localization.Uretimmalzeme.miktarPlaceHolder} disabled />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name={[name, "aciklama"]} label={localization.Uretimmalzeme.aciklama} >
                    <Input.TextArea rows={4} placeholder={localization.Uretimmalzeme.aciklamaPlaceHolder} />
                </Form.Item>
            </Col>
            <Col span={1} style={minusIconStyle}>
                <MinusCircleOutlined onClick={remove} />
            </Col>
        </Row>
    )
}