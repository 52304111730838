import React, { useState } from 'react'
import { Button, Space, Card, Popconfirm, notification, message, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import CursorTableItem from '../toolbox/CursorTableItem'
import strings from '../../utils/Localization'
import FetchListContent from '../toolbox/FetchListContent'
import useApi from '../../hooks/useApi'

export default function StokListe() {
  const [dataSource, setDataSource] = useState({})
  const navigate= useNavigate()
  const api = useApi()
  const { stokListe } = strings.stokListe.components

  const columns = [
    {
      title: stokListe.stokAd,
      dataIndex: 'ad',
      key: 'ad',
      render: (text, row, index) => (
        <CursorTableItem
          text={`${text} (${row.islemSayisi})`}
          onClick={() => {
            if (row.varyantId.length === 0) {
              navigate(`/StokDetay/${row.id}/${row.varyantGrupId[0]}`)
            } else navigate(`/VaryantliStokDetay/${row.id}`)
          }}
        />
      ),
    },
    {
      title: stokListe.varyantSayisi,
      dataIndex: 'varyantSayi',
      key: 'varyantSayi',
    },
    {
      title: stokListe.stokKategori,
      dataIndex: 'kategoriAd',
      key: 'kategoriAd',
    },
    {
      title: stokListe.miktar,
      dataIndex: 'miktar',
      key: 'miktar',
    },
    {
      title: stokListe.birim,
      dataIndex: 'birim',
      key: 'birim',
    },
    {
      title: stokListe.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={stokListe.stokDetay}>
            <Button
              onClick={() => {
                if (row.varyantSayi === 1) {
                  navigate(`/StokDetay/${row.id}/${row.varyantGrupId[0]}`)
                } else if (row.varyantSayi > 1) {
                  navigate(`/VaryantliStokDetay/${row.id}`)
                }
              }}
              type="default"
              shape="circle"
              icon={<SearchOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={stokListe.stokDuzenle}>
            <Button
              onClick={() => {
                if (row.varyantSayi === 1) {
                  navigate(`/StokDuzenle/${row.id}`)
                } else if (row.varyantSayi > 1) {
                  navigate(`/VaryantliStokDuzenle/${row.id}`)
                }
              }}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={stokListe.stokSil}>
            <Popconfirm
              onConfirm={() => {
                if (row.islemSayisi > 0) {
                  message.error({
                    content: strings.mesaj.stokSilme,
                    style: { marginTop: '20vh' },
                  })
                } else remove(row.id)
              }}
              title={strings.mesaj.silmeIslemUyari}
            >
              <Button onClick={() => {}} type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const remove = async (stokId) => {
    try {
      const response = await api.delete(`/stok/${stokId}`, {})
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== stokId),
        })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  return (
    <>
      <Card
        title={stokListe.baslik}
        extra={[
          <Space>
            <Button type="primary" size="large" onClick={() => navigate('/StokEkle')}>
              {stokListe.hizliStokEkle}
            </Button>
            <Button type="primary" size="large" onClick={() => navigate('/VaryantliStokEkle')}>
              {stokListe.varyantliStokEkle}
            </Button>
          </Space>,
        ]}
      >
        <FetchListContent
          columns={columns}
          requestUrl="/stok/getstoklist"
          onDataSource={(e) => setDataSource(e)}
          dataSource={dataSource}
        />
      </Card>
    </>
  )
}
