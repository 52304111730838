import React, { useState } from 'react'
import { Card, Button, Space, Popconfirm, message, notification, Tooltip } from 'antd'
import strings from '../../utils/Localization'
import FetchListContent from '../toolbox/FetchListContent'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { PlusOutlined } from '@ant-design/icons'
import CursorTableItem from '../toolbox/CursorTableItem'
import useApi from '../../hooks/useApi'
import { useNavigate } from 'react-router-dom'

const UretimReceteListe = () => {
  const [dataSource, setDataSource] = useState({})
  const api = useApi()
  const navigate = useNavigate()
  const { uretimReceteListe: localization } = strings

  const columns = [
    {
      title: localization.ad,
      dataIndex: 'ad',
      key: 'ad',
      sorter: (a, b) => a.ad.localeCompare(b.ad),
      render: (text, row, index) => <CursorTableItem hideHand text={row.ad} />,
    },
    {
      title: localization.islemler,
      key: 'edit',
      dataIndex: 'edit',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={localization.uretimReceteDuzenle}>
            <Button
              onClick={() => navigate(`/UretimReceteDuzenle/${row.id}`)}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={localization.uretimReceteSil}>
            <Popconfirm title={strings.mesaj.silmeIslemUyari} onConfirm={() => remove(row.id)}>
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const remove = async (uretimReceteId) => {
    try {
      const response = await api.delete(`/uretimrecete/${uretimReceteId}`, {})
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== uretimReceteId),
        })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  return (
    <>
      <Card
        title={localization.uretimReceteListesi}
        extra={[
          <Button
            key="1"
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => navigate("/UretimReceteEkle")}
          >
            {localization.uretimReceteEkle}
          </Button>,
        ]}
      >
        <FetchListContent
          columns={columns}
          requestUrl="uretimrecete/get"
          dataSource={dataSource}
          onDataSource={(e) => setDataSource(e)}
          requestBody={{
            SelectColumns: [
              {
                ColumnNames: ['id', 'ad'],
                TableName: '',
              },
            ],
          }}
        />
      </Card>
    </>
  )
}

export default UretimReceteListe
