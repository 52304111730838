import React, { useState } from 'react'
import { Button, message, notification, PageHeader, Popconfirm, Space, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import strings from '../utils/Localization'
import FetchListContent from '../components/toolbox/FetchListContent'
import { SubeEkleModal, SubeDuzenleModal } from '../components/sube'
import useApi from '../hooks/useApi'

function Sube() {
  const [data, setData] = useState([])
  const [subeEkleModalVisible, setSubeEkleModalVisible] = useState(false)
  const [subeDuzenleModalVisible, setSubeDuzenleModalVisible] = useState(false)
  const [duzenleData, setDuzenleData] = useState(null)
  const navigate= useNavigate()
  const api = useApi()
  const localization = strings.sube

  const remove = async (subeId) => {
    try {
      const response = await api.delete(`/sube/${subeId}`, {})
      if (response.status === 200) {
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
        navigate(0)
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  const columns = [
    {
      title: localization.subeList.subeAd,
      dataIndex: 'subeAd',
      key: 'subeAd',
    },
    {
      title: localization.subeList.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={localization.subeList.subeDuzenle}>
            <Button
              onClick={() => {
                setDuzenleData(row)
                setSubeDuzenleModalVisible(true)
              }}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={localization.subeList.subeSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => {
                remove(row.id)
              }}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <>
      <PageHeader
        title={strings.sube.baslik}
        ghost={false}
        onBack={() => navigate(-1)}
        extra={
          <Button icon={<PlusOutlined />} onClick={() => setSubeEkleModalVisible(true)} type="primary" size="large">
            {localization.subeEkleButton}
          </Button>
        }
      />
      <br />
      <FetchListContent
        requestUrl={'/sube/get'}
        requestBody={{
          SortOptions: {
            ColumnName: 'Id',
            SortType: 'Asc',
          },
          SelectColumns: [
            {
              TableName: '',
              ColumnNames: ['subeAd', 'id'],
            },
          ],
        }}
        columns={columns}
        dataSource={data}
        onDataSource={(data) => setData(data)}
      />
      <SubeEkleModal visible={subeEkleModalVisible} handleCancel={() => setSubeEkleModalVisible(false)} />
      <SubeDuzenleModal
        visible={subeDuzenleModalVisible}
        handleCancel={() => setSubeDuzenleModalVisible(false)}
        data={duzenleData}
      />
    </>
  )
}

export default Sube
