import { Button, Space } from "antd";
import React, { useState } from "react";
import strings from "../../utils/Localization";
import VaryantliStokIslemEkleModal from "../varyantliStokIslem/VaryantliStokIslemEkleModal";

export default function IslemButton() {
  const { islemButon: localization } = strings.varyantliStokDetay.components;
  const [islemEkleModalVisible, setIslemEkleModalVisible] = useState(false);
  const [islemEkleGirisMi, setIslemEkleGirisMi] = useState(null);

  return (
    <>
      <Space>
        <Button
          onClick={() => {
            setIslemEkleGirisMi(true);
            setIslemEkleModalVisible(true);
          }}
          type="primary"
          size="large"
        >
          {localization.girisYap}
        </Button>
        <Button
          onClick={() => {
            setIslemEkleGirisMi(false);
            setIslemEkleModalVisible(true);
          }}
          type="primary"
          size="large"
        >
          {localization.cikisYap}
        </Button>
      </Space>
      <VaryantliStokIslemEkleModal
        handleCancel={() => setIslemEkleModalVisible(false)}
        girisMi={islemEkleGirisMi}
        visible={islemEkleModalVisible}
      />
    </>
  );
}
