import React from "react";
import { Col, Row } from "antd";
import registerBg from "../../assets/images/registerBG.jpg";
import styled from "styled-components";

const rowStyle = {
  minHeight: "100vh",
  paddingTop: 80,
  paddingLeft: 12,
  paddingRight: 12,
};

const MyLayout = styled.div`
  background-image: url(${registerBg});
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
`;

export default function Container({ children }) {
  return (
    <MyLayout>
      <Row type="flex" justify="center" align="top" style={{ ...rowStyle }}>
        <Col>{children}</Col>
      </Row>
    </MyLayout>
  );
}
