import { FormInstance, InputNumber, InputNumberProps } from "antd";
import React from "react";

interface IProps extends InputNumberProps {}

const CurrencyInput: React.FC<IProps> = ({
  decimalSeparator = ",",
  style = {},
  ...rest
}) => {
  return (
    <InputNumber
      {...rest}
      decimalSeparator={decimalSeparator}
      style={{ width: "100%", ...style }}
    />
  );
};

export default CurrencyInput;

export const setFieldsAutoClear = (
  form: FormInstance,
  name: string | string[]
) => {
  return {
    onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value === "0") {
        form.setFields([
          {
            name: name,
            value: null,
          },
        ]);
      }
    },
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value === "") {
        form.setFields([
          {
            name: name,
            value: 0,
          },
        ]);
      }
    },
  };
};
