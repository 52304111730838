import React from "react";
import { Spin, Typography, Space } from "antd";

function RaporYukleniyor() {
  return (
    <Space
      align="center"
      direction="vertical"
      style={{ width: "100%", marginTop: 48 }}
    >
      <Spin size="large" />
      <Typography.Title level={4}>Rapor Yükleniyor...</Typography.Title>
    </Space>
  );
}

export default RaporYukleniyor;
