import moment from "moment";

const initialFaturaSatir = {
  key: 0,
  height: 65,
  stokId: null,
  hizmetUrun: "",
  aciklama: "",
  aciklamaHidden: true,
  miktar: 1,
  birimFiyat: 0,
  birim: "adet",
  kalemIskontoArray: [],
  otvDeger: 0,
  otvHidden: true,
  otvTip: "yuzde",
  oivDeger: 0,
  oivHidden: true,
  oivTip: "yuzde",
  kdvOran: 0,
  satirToplam: 0,
};

const initialState = {
  theme: "light",
  language: "tr",
  selectedMenuName: "panel",
  token: null,
  fatura: {
    faturaUstBolum: {
      faturaAciklamasi: "",
      seciliMusteriId: null,
      seciliMusteriAd: "",
      irsaliyeliFaturaMi: true,
      dovizTip: "tl",
      duzenlemeTarihi: moment().format("D-MM-YYYY"),
      vadeTarihi: moment().format("D-MM-YYYY"),
      faturaNo: "",
    },
    initialFaturaSatir: { ...initialFaturaSatir },
    faturaSatir: [{ ...initialFaturaSatir }],
    faturaAltBolum: {
      genelIskontoDeger: 0,
      genelIskontoTip: "yuzde",
      genelIskontoHidden: true,
      stopajOran: 0,
      tevkifatOran: 0,
    },
  },
};

export default initialState;
