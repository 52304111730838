import React, { useEffect, useState } from 'react'
import useApi from '../../hooks/useApi'
import { useParams } from "react-router-dom";
import { Card, Collapse, message } from 'antd';
import { useSelector } from 'react-redux';
import BakiyeCard from '../toolbox/BakiyeCard';
import strings from '../../utils/Localization';

const { Panel } = Collapse;

const GelirGiderBakiye = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const api = useApi()
    const { gelirGiderGrupId } = useParams()
    const rerenderReducer = useSelector((state) => state.rerenderReducer);

    useEffect(() => {
        fetchSonBakiye()
    }, [rerenderReducer])// eslint-disable-line react-hooks/exhaustive-deps


    const fetchSonBakiye = async () => {
        try {
            const response = await api.post(`/gelirgider/getsonbakiye/${gelirGiderGrupId}`)
            setData(response.data.data)

        } catch (error) {
            message.error(error.message)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Card bodyStyle={{ padding: 0 }} loading={loading}>
            <Collapse defaultActiveKey={"0"}>
                {data.map((item, index) => {
                    const { dovizAd, dovizSimge, dovizKod, giren, cikan, bakiye } = item;
                    return (
                        <Panel
                            header={`${dovizKod} ${strings.gelirGiderDetay.components.gelirGiderBakiye.bakiye}`}
                            key={index}
                        >
                            <BakiyeCard
                                key={index}
                                doviz={{ dovizAd, dovizKod, dovizSimge }}
                                giris={giren}
                                cikis={cikan}
                                bakiye={bakiye}
                            />
                        </Panel>
                    );
                })}
            </Collapse>
        </Card>
    )
}

export default GelirGiderBakiye