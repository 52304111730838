import React from "react";
import { Col, PageHeader, Row, Button, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  Profil,
  StokIslemVeVaryantListe,
  IslemButton,
} from "../components/varyantliStokDetay";
import strings from "../utils/Localization";

function VaryantliStokDetay() {
  const navigate= useNavigate();
  const { stokId } = useParams();
  const { varyantliStokDetay: localization } = strings;
  return (
    <>
      <PageHeader
        onBack={() => navigate(-1)}
        ghost={false}
        title={localization.pageHeader.baslik}
        extra={
          <Space>
            <Button
              size="large"
              type="link"
              onClick={() => navigate(`/StokVaryantGrupEkle/${stokId}`)}
            >
              {localization.pageHeader.varyantGrupEkle}
            </Button>
            <IslemButton />
          </Space>
        }
      />
      <br />
      <Row gutter={24}>
        <Col xxl={6} xl={7} lg={24} md={24} xs={24}>
          <Profil />
          <br />
        </Col>
        <Col xxl={18} xl={17} lg={24} md={24} xs={24}>
          <StokIslemVeVaryantListe />
        </Col>
      </Row>
      <br />
    </>
  );
}

export default VaryantliStokDetay;
