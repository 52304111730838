import React, { useEffect, useState } from "react";
import { Modal, message, Timeline } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";
import moment from "moment";

const formSize = "middle";

function TekliSiparisDurumGecmisListModal({ visible = false, handleCancel, teklifSiparisId }) {
    const [data, setData] = useState([])
    const [fetchLoading, setFetchLoading] = useState(false);
    const api = useApi();
    const localization = strings.teklifSiparisDurumListModal;

    useEffect(() => {
        if (teklifSiparisId) {
            fetch(teklifSiparisId)
        }
    }, [teklifSiparisId])// eslint-disable-line react-hooks/exhaustive-deps

    const fetch = async (teklifSiparisId) => {
        try {
            setFetchLoading(true)
            const response = await api.post(`/teklifsiparisdurum/get`, {
                SortOptions: {
                    ColumnName: "id",
                    SortType: "Desc"
                },
                SelectColumns: [
                    {
                        ColumnNames: [
                            "id",
                            "teklifSiparisId",
                            "teklifSiparisDurumTipId",
                            "aciklama",
                            "islemTarihi"
                        ],
                        TableName: ""
                    },
                    {
                        ColumnNames: [
                            "id",
                            "tip",
                            "ad"
                        ],
                        TableName: "teklifSiparisDurumTip"
                    }
                ],
                Data: {
                    teklifSiparisId: teklifSiparisId
                }
            })
            setData(response.data.data)

        } catch (error) {
            message.error(error.message)
        }
        finally {
            setFetchLoading(false)
        }
    }

    return (
        <Modal
            title={localization.baslik}
            visible={visible}
            onCancel={handleCancel}
            handleCancel={handleCancel}
            okButtonProps={{
                size: formSize,
                loading: fetchLoading,
            }}
            cancelButtonProps={{ hidden: true }}
            onOk={handleCancel}
        >
            <Timeline>
                {data.map((item, index) => {
                    return (
                        <Timeline.Item key={index}>{`${item.teklifSiparisDurumTip.ad} - ${moment(item.islemTarihi).format("DD.MM.YYYY")}`}</Timeline.Item>
                    )
                })}
            </Timeline>
        </Modal>
    )
}

export default TekliSiparisDurumGecmisListModal