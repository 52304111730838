import React, { useState } from 'react'
import ListFetchContent from '../toolbox/FetchListContent'
import { useNavigate } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import strings from '../../utils/Localization'
import { Button, message, notification, PageHeader, Popconfirm, Space, Tag, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import CursorTableItem from '../toolbox/CursorTableItem'
import IslemButton from './IslemButton'
import TeklifSiparisDurumTipDuzenleModal from './TeklifSiparisDurumTipDuzenleModal'
import { teklifSiparisDurumTypes } from '../../types/teklifSiparisDurumTypes'

function TeklifSiparisDurumListe() {
  const [dataSource, setDataSource] = useState({})
  const [teklifSiparisDuzenleModalVisible, setTeklifSiparisDuzenleModalVisible] = useState(false)
  const [duzenleData, setDuzenleData] = useState({})
  const navigate= useNavigate()
  const api = useApi()
  const { teklifSiparisDurumListeTable, baslik } = strings.teklifSiparisDurum

  const remove = async (durumId) => {
    try {
      const response = await api.delete(`/teklifsiparisdurumtip/${durumId}`, {})
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== durumId),
        })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  const columns = [
    {
      title: teklifSiparisDurumListeTable.durumTipiAdi,
      dataIndex: 'ad',
      key: 'ad',
      render: (text, row, index) => <CursorTableItem hideHand text={row.ad} />
    },
    {
      title: teklifSiparisDurumListeTable.durumTip,
      dataIndex: 'tip',
      key: 'tip',
      render: (text, row, index) => {

        return <Tag color={row.tip === teklifSiparisDurumTypes.TEKLIF ? "green" : "blue"} style={{ fontSize: 14 }}>
          {row.tip}
        </Tag>
      }
    },
    {
      title: teklifSiparisDurumListeTable.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={teklifSiparisDurumListeTable.durumDuzenle}>
            <Button
              onClick={() => {
                setDuzenleData(row)
                setTeklifSiparisDuzenleModalVisible(true)
              }}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={teklifSiparisDurumListeTable.durumSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => {
                if (row.islemSayisi > 0) {
                  message.error({
                    content: strings.mesaj.kasaSilme,
                    style: { marginTop: '20vh' },
                  })
                } else remove(row.id)
              }}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]
  return (
    <>
      <PageHeader
        onBack={() => navigate(-1)}
        title={baslik}
        ghost={false}
        extra={<IslemButton />}
      />
      <br />
      <ListFetchContent
        columns={columns}
        requestUrl="/teklifsiparisdurumtip/get"
        requestBody={{
          "SelectColumns": [
            {
              "ColumnNames": [
                "id",
                "tip",
                "ad "
              ],
              "TableName": ""
            }
          ]
        }}
        onDataSource={(e) => setDataSource(e)}
        dataSource={dataSource}
      />
      <TeklifSiparisDurumTipDuzenleModal visible={teklifSiparisDuzenleModalVisible} handleCancel={() => setTeklifSiparisDuzenleModalVisible(false)} data={duzenleData} />
    </>
  )
}

export default TeklifSiparisDurumListe