import React from "react";
import { Alert } from "antd";
import { faturaTypes } from "../../types/faturaTypes";
import { odemeSekliTypes } from "../../types/odemeSekliTypes";
import strings from "../../utils/Localization";

function FaturaTahsilatOdemeUyari({ faturaTip, odemeSekli }) {
  if (odemeSekli === odemeSekliTypes.ACIK_HESAP) {
    return null;
  }

  const getCariIslemTur = () => {
    if (
      faturaTip === faturaTypes.ALIS_FATURASI ||
      faturaTip === faturaTypes.SATIS_IADE_FATURASI
    ) {
      return ["alacaklandırma", "ödeme"];
    } else {
      return ["borçlandırma", "tahsilat"];
    }
  };

  return (
    <Alert
      banner
      type="warning"
      showIcon
      message={strings.faturaTahsilatOdemeUyari.mesaj([
        getCariIslemTur()[0],
        getCariIslemTur()[1],
      ])}
      style={{ marginBottom: 16 }}
    />
  );
}

export default FaturaTahsilatOdemeUyari;
