import { applyMiddleware, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import configurePersist from './configurePersist'
import reducers from './index'

const pReducer = persistReducer(configurePersist, reducers)
const middleware = applyMiddleware(thunk)
const store = createStore(pReducer, middleware)
const persistor = persistStore(store)

export {
    store,
    persistor,
}