export const yuzdeHesala = (sayi, yuzdeOran) =>
  parseFloat((sayi / 100) * yuzdeOran)

/**
 * örnek: a sayı b sayının %5'i dir
 */
export const tutariYuzdeyeCevir = (a, b) => {
  if (b === 0) return 0
  const aDegeri = parseFloat(a * 100)
  const sonuc = parseFloat(aDegeri / b)
  return sonuc
}
