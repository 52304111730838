import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
} from "antd";
import { useNavigate } from "react-router-dom";
import { Title } from "../components/odemeLayout";
import useApi from "../hooks/useApi";
import { useDispatch, useSelector } from "react-redux";
import { setOdemeFaturaAdres } from "../redux/actions/odemeAction";
import MaskedInput from "antd-mask-input";

const { Content } = Layout

function FaturaAdresi() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fetchLoading, setFetchLoading] = useState(false);
  const api = useApi();
  const dispatch = useDispatch();
  const odemeReducer = useSelector((state) => state.odemeReducer);

  useEffect(() => {
    if (odemeReducer?.faturaAdresi?.adSoyad !== "") {
      form.setFieldsValue({ ...odemeReducer.faturaAdresi });
    } else {
      fetchFirmaBilgileri();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = (props) => {
    dispatch(setOdemeFaturaAdres({ ...props }));
    navigate("/OdemeYap");
  };

  const fetchFirmaBilgileri = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/firma/get", {
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["yetkili", "eMail", "adres", "telefon"],
          },
        ],
      });
      if (response.status === 200) {
        const { yetkili, telefon, eMail, adres } = response.data.data[0];
        form.setFields([
          {
            name: "adSoyad",
            value: yetkili,
          },
          {
            name: "telefon",
            value: telefon,
          },
          {
            name: "eMail",
            value: eMail,
          },
          {
            name: "adres",
            value: adres,
          },
        ]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Content>
      <Row style={{ justifyContent: 'center' }}>
        <Col xxl={12} xl={14} lg={16} md={20} xs={22} >
          <Title>Fatura Adresi</Title>
          <Card style={{ borderRadius: 12 }} loading={fetchLoading}>
            <Form
              form={form}
              layout="vertical"
              name="faturaAdresi"
              onFinish={onFinish}
            >
              <Form.Item
                name={"adSoyad"}
                label="Ad Soyad"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name={"telefon"}
                    label="Telefon"
                    rules={[{ required: true }]}
                  >
                    <MaskedInput mask="(111) 111 11 11" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={"eMail"}
                    label="E-Mail Adresi"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name={"adres"}
                label="Adres"
                rules={[{ required: true }]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              <Button
                size="large"
                style={{ float: "left" }}
                onClick={() => navigate(-1)}
              >
                Vazgeç
              </Button>
              <Button
                type="primary"
                size="large"
                style={{ float: "right" }}
                // onClick={() => navigate("/OdemeYap")}
                htmlType="submit"
                form="faturaAdresi"
              >
                Kaydet ve Devam Et
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>

    </Content>
  );
}

export default FaturaAdresi;
