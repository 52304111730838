import React, { useState } from "react";
import { Button, Card, Form, Select, message } from "antd";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import strings from "../../utils/Localization";
import { cariIslemTurTypes } from "../../types/cariIslemTurTypes";
import moment from "moment";
import { odemeSekliTypes } from "../../types/odemeSekliTypes";
import CariKartSelectOption from "../toolbox/CariKartSelectOption";
import CariGrupSelectOption from "../toolbox/CariGrupSelectOption";
import useApi from "../../hooks/useApi";

const { Option } = Select;

export default function CariIslemRaporForm() {
  const { cariIslemRaporForm } = strings.cariIslemRapor.components;
  const [fetchLoading, setFetchLoading] = useState(false);
  const api = useApi();

  const onFinish = async (values) => {
    try {
      const { tarih, islemTipi, odemeSekliTipi, cariKartId, cariGrupId } =
        values;

      const baslangicTarihi = moment(tarih[0]).format("YYYY-MM-DD");
      const bitisTarihi = moment(tarih[1]).format("YYYY-MM-DD");
      const pdfWindow = window.open(`${document.location.origin}/#/RaporYukleniyor`);
      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/cariislem",
        {
          BaslangicTarih: `${baslangicTarihi} 00:00:00`,
          BitisTarih: `${bitisTarihi} 23:59:59`,
          CariKartId: cariKartId === undefined ? 0 : cariKartId,
          CariGrupId: cariGrupId === undefined ? 0 : cariGrupId,
          IslemTur: islemTipi,
          OdemeSekli: odemeSekliTipi,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        pdfWindow.document.location.href = response.data.data;
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Card>
      <Form
        layout="vertical"
        size="middle"
        onFinish={onFinish}
        initialValues={{
          tarih: [moment().add("months", -12), moment()],
        }}
      >
        <Form.Item
          name="tarih"
          label={cariIslemRaporForm.tarihSeciniz}
          rules={[{ required: true }]}
        >
          <DatepickerRanger />
        </Form.Item>
        <Form.Item name="cariKartId" label={cariIslemRaporForm.cariSeciniz}>
          <CariKartSelectOption allowClear />
        </Form.Item>
        <Form.Item
          name="cariGrupId"
          label={strings.cariGrupSelectOption.cariGrupBaslik}
        >
          <CariGrupSelectOption allowClear />
        </Form.Item>

        <Form.Item
          name="odemeSekliTipi"
          label={cariIslemRaporForm.odemeSekliTip}
        >
          <Select defaultValue={null}>
            <Option value={null}>
              {cariIslemRaporForm.odemeSekliTipItem.tumIslemler}
            </Option>
            <Option value={odemeSekliTypes.ACIK_HESAP}>
              {cariIslemRaporForm.odemeSekliTipItem.acikHesap}
            </Option>
            <Option value={odemeSekliTypes.NAKIT}>
              {cariIslemRaporForm.odemeSekliTipItem.nakit}
            </Option>
            <Option value={odemeSekliTypes.KREDI_KARTI}>
              {cariIslemRaporForm.odemeSekliTipItem.krediKarti}
            </Option>
            <Option value={odemeSekliTypes.HAVALE}>
              {cariIslemRaporForm.odemeSekliTipItem.havale}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item name="islemTipi" label={cariIslemRaporForm.islemTipi}>
          <Select defaultValue={null}>
            <Option value={null}>
              {cariIslemRaporForm.islemTipItem.tumIslemler}
            </Option>
            <Option value={cariIslemTurTypes.TAHSILAT}>
              {cariIslemRaporForm.islemTipItem.tahsilat}
            </Option>
            <Option value={cariIslemTurTypes.ODEME}>
              {cariIslemRaporForm.islemTipItem.odeme}
            </Option>
            <Option value={cariIslemTurTypes.ALACAK}>
              {cariIslemRaporForm.islemTipItem.alacak}
            </Option>
            <Option value={cariIslemTurTypes.BORC}>
              {cariIslemRaporForm.islemTipItem.borclandirma}
            </Option>
          </Select>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ float: "right" }}
          size="large"
          loading={fetchLoading}
        >
          {cariIslemRaporForm.raporAlButon}
        </Button>
      </Form>
    </Card>
  );
}
