import React, { useState } from 'react'
import { Button, Space, Popconfirm, message, notification, Typography, Tooltip, Tag } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import strings from '../../utils/Localization'
import ListFetchContent from '../toolbox/FetchListContent'
import useCurrency from '../../hooks/useCurrency'
import { GelirGiderIslemDuzenleModal } from '../gelirGiderIslem'
import useApi from '../../hooks/useApi'
import { useParams } from "react-router-dom";
import moment from 'moment'

export default function GelirGiderDetayList() {
    const [dataSource, setDataSource] = useState({})
    const [gelirGiderIslemDuzenleModalVisible, setGelirGiderIslemDuzenleModalVisible] = useState(false)
    const [duzenleData, setDuzenleData] = useState(null)
    const { gelirGiderGrupList } = strings.gelirGiderDetay.components
    const { paraFormat } = useCurrency()
    const api = useApi()
    const { gelirGiderGrupId } = useParams()

    const columns = [
        {
            title: gelirGiderGrupList.islemTarih,
            dataIndex: 'islemTarih',
            key: 'islemTarih',
            sorter: (a, b) => a.islemTarih.length - b.islemTarih.length,
            render: (text) => moment(text).format('DD/MM/YYYY'),
            defaultSortOrder: 'descend',
        },
        {
            title: gelirGiderGrupList.aciklama,
            dataIndex: 'aciklama',
            key: 'aciklama',
        },
        {
            title: gelirGiderGrupList.islemTipi,
            key: 'islemTipi',
            render: (text, row, index) => {
                console.log(row)
                return (
                    <Tag color={row.gelirMi?"cyan":"red"}>{row.gelirMi?gelirGiderGrupList.gelir:gelirGiderGrupList.gider}</Tag>
                )
            }, 
        },
        {
            title: gelirGiderGrupList.tutar,
            dataIndex: 'tutar',
            key: 'tutar',
            render: (text, row, index) => {
                return (
                    <Typography.Title
                        level={5}
                        style={{
                            padding: 8,
                            color:row.gelirMi?"green":"red"
                        }}
                    >{`${row.doviz.simge}${paraFormat(text)}`}</Typography.Title>
                )
            },
        },
        {
            title: gelirGiderGrupList.islemler,
            dataIndex: 'islemler',
            key: 'islemler',
            render: (text, row, index) => (
                <Space size="middle">
                    <Tooltip title={gelirGiderGrupList.islemDuzenle}>
                        <Button
                            onClick={() => {
                                setDuzenleData(row)
                                setGelirGiderIslemDuzenleModalVisible(true)
                            }}
                            type="default"
                            shape="circle"
                            icon={<EditOutlined />}
                            size={'large'}
                        />
                    </Tooltip>
                    <Tooltip title={gelirGiderGrupList.islemSil}>
                        <Popconfirm
                            title={strings.mesaj.silmeIslemUyari}
                            onConfirm={() => {
                                if (row.islemSayisi > 0) {
                                    message.error({
                                        content: strings.mesaj.silmeIslemUyari,
                                        style: { marginTop: '20vh' },
                                    })
                                } else remove(row.id)
                            }}
                        >
                            <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ]

    const remove = async (gelirGiderIslemId) => {
        try {
            const response = await api.delete(`/gelirgider/${gelirGiderIslemId}`, {})
            if (response.status === 200) {
                setDataSource({
                    ...dataSource,
                    data: dataSource.data.filter((x) => x.id !== gelirGiderIslemId),
                })
                notification['success']({
                    message: strings.mesaj.basarliBaslik,
                    description: strings.mesaj.islemBasarili,
                })
            }
        } catch (error) {
            message.error(error.message)
        }
    }

    return (
        <>
            <ListFetchContent
                columns={columns}
                requestUrl="/gelirgider/get"
                requestBody={{
                    data: {
                        gelirGiderGrupId: gelirGiderGrupId
                    },
                    Includes:["Doviz"]
                }}
                onDataSource={(e) => setDataSource(e)}
                dataSource={dataSource}
            />
            <GelirGiderIslemDuzenleModal
                visible={gelirGiderIslemDuzenleModalVisible}
                handleCancel={() => setGelirGiderIslemDuzenleModalVisible(false)}
                data={duzenleData}
            />
        </>
    )
}
