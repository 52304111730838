import React, { useState } from 'react'
import { Button, Divider, Form, Input, message } from 'antd'
import DilSecim from '../navi/navBar/DilSecim'
import strings from '../../utils/Localization'
import { useNavigate } from 'react-router-dom'
import useApi from '../../hooks/useApi'
import useKullanici from '../../hooks/useKullanici'
const md5 = require('md5')

export default function GirisYapForm() {
  const [form] = Form.useForm()
  const [fetchLoading, setFetchLoading] = useState(false)
  const navigate= useNavigate()
  const api = useApi()
  const { setAuthParameters } = useKullanici()
  const { girisYapForm } = strings.girisYap.components

  const onFinish = async (values) => {
    try {
      setFetchLoading(true)
      const { eMail, sifre } = values
      const response = await api.post('/auth/login', {
        Email: eMail,
        Password: md5(sifre),
      })

      if (response.status === 200) {
        const { token, defaultSettings, refreshToken } = response.data.singleData
        setAuthParameters({ defaultSettings, eMail, refreshToken, token })
        navigate('/')
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form
      form={form}
      size="large"
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <DilSecim alignRight />
      <Divider />
      <Form.Item
        name="eMail"
        rules={[
          { required: true, message: girisYapForm.kurallar.emailZorunlu },
          { type: 'email', message: girisYapForm.kurallar.emailFormat },
        ]}
      >
        <Input placeholder={girisYapForm.eMailPlaceHolder} />
      </Form.Item>

      <Form.Item
        name="sifre"
        rules={[
          { required: true, message: girisYapForm.kurallar.sifreZorunlu },
          { min: 6, message: girisYapForm.kurallar.sifreMinUzunluk },
        ]}
      >
        <Input.Password placeholder={girisYapForm.sifrePlaceHolder} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block size="large" loading={fetchLoading}>
          {girisYapForm.girisYapButon}
        </Button>
      </Form.Item>
    </Form>
  )
}
