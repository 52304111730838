import React from 'react'
import { Col, Space } from 'antd'
import DepoStokRaporForm from '../components/DepoStokRapor/DepoStokRaporForm'
import PageHeader from '../components/DepoStokRapor/PageHeader'

export default function DepoStokRapor() {
  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <PageHeader />
      <Col xxl={8} xl={12} lg={24} md={24} sm={24} xs={24}>
        <DepoStokRaporForm />
      </Col>
    </Space>
  )
}
