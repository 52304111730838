import React from "react";
import {
  PageHeader,
  Profil,
  BankaHesapIslemListe,
} from "../components/BankaHesapDetay";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchBankaHesap } from "../redux/actions/bankaHesapAction";
import useApi from "../hooks/useApi";

export default function BankaHesapDetay() {
  const { bankaHesapId } = useParams();
  const dispatch = useDispatch();
  const api = useApi();

  useEffect(() => {
    dispatch(fetchBankaHesap(bankaHesapId, api));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageHeader />
      <br />
      <Row gutter={24}>
        <Col xxl={6} xl={7} lg={24} md={24} xs={24}>
          <Profil />
          <br />
        </Col>
        <Col xxl={18} xl={17} lg={24} md={24} xs={24}>
          <BankaHesapIslemListe />
        </Col>
      </Row>
      <br />
    </>
  );
}
