import Routes from "./Routes";
import { useSelector } from "react-redux";
import strings from "./utils/Localization";
import { ConfigProvider } from "antd";
import trTr from "antd/lib/locale/tr_TR";
import './App.less';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAXzjmegLPun1TuG2blp9Entsgd2xyaVvg",
  authDomain: "eminhesap-pro.firebaseapp.com",
  projectId: "eminhesap-pro",
  storageBucket: "eminhesap-pro.appspot.com",
  messagingSenderId: "553392372346",
  appId: "1:553392372346:web:03284f30ed2cff1448d9c3",
  measurementId: "G-R2RH3DP8DB"
};

initializeApp(firebaseConfig)

const App = () => {
  const currentLanguage = useSelector((state) => state.languageReducer);
  strings.setLanguage(currentLanguage);

  return (
    <ConfigProvider locale={trTr}>
      <Routes />
    </ConfigProvider>
  );
};

export default App;
