import React from "react";
import { Card, Divider, Typography } from "antd";
import { Container, Logo, KayitOlForm } from "../components/kayitOl";
import strings from "../utils/Localization";

const { Paragraph } = Typography;

const paragraphStyle = { textAlign: "center", letterSpacing: 1 };

export default function KayitOl() {
  return (
    <Container>
      <Logo />
      <Card style={{ borderRadius: 12 }}>
        <Paragraph style={paragraphStyle}>{strings.kayitOl.slogan}</Paragraph>
        <Divider />
        <KayitOlForm />
      </Card>
    </Container>
  );
}
