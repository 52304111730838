import React from 'react'
import { Alert, Typography } from 'antd'
import moment from 'moment'
import useKullanici from '../../hooks/useKullanici'
import strings from '../../utils/Localization'

function AbonelikSureUyari() {
  const { getAbonelikBitisTarihi } = useKullanici()

  if (!getAbonelikBitisTarihi) return null

  const abonelikKalanGun = Math.round(moment.duration(moment(getAbonelikBitisTarihi).diff(moment())).asDays())

  if (abonelikKalanGun > 15) return null
  
  return (
    <>
      <Alert
        message={
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {strings.navBar.abonelikSureUyari.uyariMesaji(abonelikKalanGun)}
          </Typography.Title>
        }
        type="warning"
        banner
      />
    </>
  )
}

export default AbonelikSureUyari
