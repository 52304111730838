import * as actionTypes from "../actions/actionTypes";

const initialState = [];

const dovizListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DOVIZ_LIST:
      return action.payload;
    case actionTypes.SET_RESET_DOVIZ_LIST:
      return initialState;
    default:
      return state;
  }
};

export default dovizListReducer;
