import React from "react";
import {Typography} from 'antd'

function Title({ style = {}, children }) {
  return (
    <Typography.Title
      level={2}
      type="secondary"
      style={{ marginTop: 36, color: "#000000a6", fontSize: 34, ...style }}
    >
      {children}
    </Typography.Title>
  );
}

export default Title;
