import { Col, Row } from 'antd'
import React from 'react'
import { PageHeader, GelirGiderDetayList, Profil } from '../components/gelirGiderDetay'

export default function GelirGiderDetay() {
  return (
    <>
      <PageHeader />
      <br />
      <Row gutter={24}>
        <Col xxl={6} xl={7} lg={24} md={24} xs={24}>
          <Profil />
        </Col>
        <Col xxl={18} xl={17} lg={24} md={24} xs={24}>
          <GelirGiderDetayList />
        </Col>
      </Row>

    </>
  )
}
