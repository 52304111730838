import React from 'react'
import { Row, Typography } from 'antd'
import { PhoneOutlined } from '@ant-design/icons';
import strings from '../../utils/Localization';

const { Text } = Typography
const telefonNumaraStyle = { marginLeft: 8 }

export default function IletisimBilgi() {
    return (
        <Row align="middle" justify="center">
            <PhoneOutlined />
            <Text style={telefonNumaraStyle}>{strings.girisYap.components.iletisimBilgi.telefonNumarasi}</Text>
        </Row>
    )
}
