import React, { useState } from 'react'
import { Form, Input, message, Modal, notification } from 'antd'
import strings from '../../utils/Localization';
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useApi from "../../hooks/useApi";

const formSize = "middle";

export default function GelirGiderGrupEkleModal({ visible = false, handleCancel}) {
    const [fetchLoading, setFetchLoading] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const api = useApi();
    const localization = strings.gelirGider.gelirGiderGrupEkleModal;

    const onFinish = async (props) => {
        try {
            const { ad } = props;

            setFetchLoading(true);
            await api.post("/gelirgidergrup", {
                ad: ad
            });

            notification["success"]({
                message: strings.mesaj.basarliBaslik,
                description: strings.mesaj.islemBasarili,
            });
            form.resetFields();
            handleCancel()
            dispatch(setRerender());
        } catch (error) {
            message.error(error.message)
        }
        finally {
            setFetchLoading(false)
        }

    }

    return (
        <Modal
            title={localization.baslik}
            visible={visible}
            onCancel={handleCancel}
            handleCancel={handleCancel}
            okButtonProps={{
                htmlType: "submit",
                form: "gelirGiderEkleModal",
                size: formSize,
                loading: fetchLoading,
            }}
            cancelButtonProps={{ size: formSize }}
        >
            <Form 
            name='gelirGiderEkleModal'
                form={form}
                layout="vertical"
                size={formSize}
                onFinish={onFinish}
            >
                <Form.Item name={"ad"}
                    label={localization.gelirGiderGrupAdi}
                    rules={[{ required: true }]}
                >
                    <Input placeholder={localization.gelirGiderGrupAdiPlaceHolder} />
                </Form.Item>
            </Form>
        </Modal>
    )
}
