import React from "react";
import { Select, SelectProps } from "antd";
import { odemeSekliTypes } from "../../types/odemeSekliTypes";
import strings from "../../utils/Localization";

const { Option } = Select;

interface IProps extends SelectProps<any> {
  hiddenAcikHesap?: boolean;
  hiddenNakit?: boolean;
  hiddenKrediKarti?: boolean;
  hiddenHavale?: boolean;
}

const OdemeSekliSelectOption: React.FC<IProps> = ({
  hiddenAcikHesap = false,
  hiddenNakit = false,
  hiddenKrediKarti = false,
  hiddenHavale = false,
  placeholder,
  ...rest
}) => {
  return (
    <Select
      {...rest}
      placeholder={
        placeholder || strings?.odemeSekliSelectOption?.odemeSekliPlaceHolder
      }
    >
      {!hiddenAcikHesap && (
        <Option value={odemeSekliTypes.ACIK_HESAP}>
          {strings?.odemeSekliSelectOption?.acikHesap}
        </Option>
      )}
      {!hiddenNakit && (
        <Option value={odemeSekliTypes.NAKIT}>
          {strings?.odemeSekliSelectOption?.nakit}
        </Option>
      )}
      {!hiddenKrediKarti && (
        <Option value={odemeSekliTypes.KREDI_KARTI}>
          {strings?.odemeSekliSelectOption?.krediKarti}
        </Option>
      )}
      {!hiddenHavale && (
        <Option value={odemeSekliTypes.HAVALE}>
          {strings?.odemeSekliSelectOption?.havale}
        </Option>
      )}
    </Select>
  );
};

export default OdemeSekliSelectOption;
