import React from 'react'
import { PageHeader, KasaRaporForm } from '../components/kasaRapor'
import { Col, Space } from 'antd'

export default function KasaRapor() {
    return (
        <>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <PageHeader />
                <Col xxl={8} xl={12} lg={24} md={24} sm={24} xs={24}>
                    <KasaRaporForm />
                </Col>
            </Space>
        </>
    )
}
