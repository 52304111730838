import { Button, Space } from "antd";
import React, { useState } from "react";
import strings from "../../utils/Localization";
import BankaHesapIslemEkleModal from "../bankaHesapIslem/BankaHesapIslemEkleModal";
import BankadanBankayaVirmanEkleModal from "../bankaHesapIslem/BankadanBankayaVirmanEkleModal";
import BankadanKasayaVirmanEkleModal from "../bankaHesapIslem/BankadanKasayaVirmanEkleModal";

export default function IslemButton() {
  const [
    bankaHesapGirisCikisModalVisible,
    setBankaHesapGirisCikisModalVisible,
  ] = useState(false);
  const [bankayaVirmanEkleModalVisible, setBankayaVirmanEkleModalVisible] =
    useState(false);
  const [kasayaVirmanEkleModalVisible, setKasayaVirmanEkleModalVisible] =
    useState(false);
  const [girisMi, setGirisMi] = useState(null);
  const { islemMenu } = strings.bankaHesapDetay.components;

  return (
    <>
      <Space>
        <Button
          onClick={() => {
            setGirisMi(true);
            setBankaHesapGirisCikisModalVisible(true);
          }}
          type="primary"
          size="large"
        >
          {islemMenu.girisYap}
        </Button>
        <Button
          onClick={() => {
            setGirisMi(false);
            setBankaHesapGirisCikisModalVisible(true);
          }}
          type="primary"
          size="large"
        >
          {islemMenu.cikisYap}
        </Button>
        <Button
          onClick={() => setBankayaVirmanEkleModalVisible(true)}
          type="primary"
          size="large"
        >
          {islemMenu.bankayaVirman}
        </Button>
        <Button
          onClick={() => setKasayaVirmanEkleModalVisible(true)}
          type="primary"
          size="large"
        >
          {islemMenu.kasayaVirman}
        </Button>
      </Space>
      <BankaHesapIslemEkleModal
        visible={bankaHesapGirisCikisModalVisible}
        handleCancel={() => setBankaHesapGirisCikisModalVisible(false)}
        girisMi={girisMi}
      />
      <BankadanBankayaVirmanEkleModal
        visible={bankayaVirmanEkleModalVisible}
        handleCancel={() => setBankayaVirmanEkleModalVisible(false)}
      />
      <BankadanKasayaVirmanEkleModal
        visible={kasayaVirmanEkleModalVisible}
        handleCancel={() => setKasayaVirmanEkleModalVisible(false)}
      />
    </>
  );
}
