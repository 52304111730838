import React from 'react'
import {  PageHeader as MyPageHeader } from 'antd'
import { useNavigate } from 'react-router-dom'
import strings from '../../utils/Localization'

export default function PageHeaderGeleEFaturaListe() {
  const navigate= useNavigate()
  const localization = strings.eFaturaGelenListe.pageHeader;

  return (
    <MyPageHeader
      ghost={false}
      onBack={() => navigate(-1)}
      title={localization.baslik}
      subTitle={localization.altBaslik}
    />
  )
}
