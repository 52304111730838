import React from 'react'
import { Button, PageHeader as MyPageHeader, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import strings from '../../utils/Localization'
import { faturaTypes } from '../../types/faturaTypes'

export default function PageHeaderFaturaListe() {
  const navigate = useNavigate()
  const { pageHeader: localization } = strings.faturaListe.components;

  return (
    <MyPageHeader
      ghost={false}
      onBack={() => navigate(-1)}
      title={localization.baslik}
      subTitle={localization.altBaslik}
      extra={
        <Space direction="horizontal">
          <Button
            type="primary"
            size="large"
            onClick={() =>
              navigate("/FaturaEkle", {
                state: {
                  faturaTip: faturaTypes.SATIS_FATURASI,
                }
              })
            }
          >
            {localization.yeniSatisFaturasi}
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() =>
              navigate("/FaturaEkle", {
                state: {
                  faturaTip: faturaTypes.ALIS_FATURASI,
                }
              })
            }
          >
            {localization.yeniAlisFaturasi}
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() =>
              navigate("/FaturaEkle", {
                state: {
                  faturaTip: faturaTypes.SATIS_IADE_FATURASI,
                }
              })
            }
          >
            {localization.yeniSatisIadeFaturasi}
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() =>
              navigate("/FaturaEkle", {
                state: {
                  faturaTip: faturaTypes.ALIS_IADE_FATURASI,
                }
              })
            }
          >
            {localization.yeniAlisIadeFaturasi}
          </Button>
        </Space>
      }
    />
  )
}
