import React, { useEffect, useState } from "react";
import { Form, message, Card, notification } from "antd";
import FaturaLayout from "../components/faturaDuzenle/FaturaLayout";
import strings from "../utils/Localization";
import { useParams } from "react-router-dom";
import moment from "moment";
import { faturaTypes } from "../types/faturaTypes";
import { cariIslemTurTypes } from "../types/cariIslemTurTypes";
import useApi from "../hooks/useApi";

function FaturaDuzenle() {
  const [form] = Form.useForm();
  const { faturaId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const api = useApi();
  const { faturaDuzenle: localization } = strings;

  useEffect(() => {
    fetchFatura();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchFatura = async () => {
    try {
      setLoading(true);
      const response = await api.post("/fatura/get", {
        Data: {
          Id: faturaId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: [
              "id",
              "cariKartId",
              "dovizId",
              "faturaTip",
              "odemeSekli",
              "carpan",
              "firmaAdi",
              "yetkili",
              "vergiDairesi",
              "vergiNumarasi",
              "telefon",
              "cepTelefonu",
              "eMail",
              "cariKod",
              "il",
              "ilce",
              "adres",
              "sevkAdresi",
              "faturaNo",
              "faturaTarihi",
              "sevkTarihi",
              "sevkNumarasi",
              "faturaSaati",
              "aciklama",
              "stopajOran",
              "stopajTutar",
              "tevkifatOran",
              "tevkifatTutar",
              "toplam",
              "araToplamIskontoOran",
              "araToplamIskontoTutar",
              "araToplamIskontoTip",
              "faturaSatirIskontoToplamTutar",
              "genelToplam",
              "faturaId",
            ],
          },
          {
            TableName: "Faturasatir",
            ColumnNames: [
              "id",
              "faturaId",
              "stokVaryantGrupId",
              "stokBirimId",
              "urunAdi",
              "miktar",
              "birimFiyat",
              "barkod",
              "aciklama",
              "otvTutar",
              "otvOran",
              "oivTutar",
              "oivOran",
              "kdvTutar",
              "kdvOran",
              "genelToplam",
            ],
          },
          {
            TableName: "Faturasatir.Faturasatiriskonto",
            ColumnNames: [
              "id",
              "faturaSatirId",
              "siraNo",
              "uygulananTutar",
              "oran",
              "tutar",
              "iskontoTip",
            ],
          },
          {
            TableName:
              "Faturasatir.StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger",
            ColumnNames: [
              "id",
              "stokVaryantId",
              "ad",
            ],
          },
          {
            TableName: "Faturasatir.Stokislem.StokDepo",
            ColumnNames: [
              "id",
              "ad",
            ],
          },
          {
            TableName: "Cariislembaglanti.CariIslem",
            ColumnNames: ["vadeTarih"],
          },
          {
            TableName:
              "Cariislembaglanti.CariIslem.AltCariIslem.Cariislembaglanti",
            ColumnNames: [
              "id",
              "cariIslemId",
              "bankaHesapIslemId",
              "kasaIslemId",
              "cariKartId",
              "bankaHesapId",
              "kasaId",
              "faturaId",
            ],
          },
          {
            TableName: "Doviz",
            ColumnNames: [
              "id",
              "simge",
              "kod",
              "ad",
            ],
          },
        ],
      });
      if (response.status === 200) {
        const result = response.data.data[0];
        form.setFieldsValue({
          faturaId: result.id,
          faturaTip: result.faturaTip,
          cariKartId: result.cariKartId,
          yetkili: result.yetkili,
          vergiDairesi: result.vergiDairesi,
          vergiNumarasi: result.vergiNumarasi,
          telefon: result.telefon,
          cepTelefonu: result.cepTelefonu,
          eMail: result.eMail,
          il: result.il,
          ilce: result.ilce,
          adres: result.adres,
          sevkAdresi: result.sevkAdresi,
          aciklama: result.aciklama,
          firmaAdi: result.firmaAdi,
          dovizId: String(result.Doviz.id),
          odemeSekli: result.odemeSekli,
          faturaTarihi: moment(result.faturaTarihi),
          faturaSaati: moment(result.faturaSaati, "HH:mm:ss"),
          vadeTarihi: moment(result.Cariislembaglanti.CariIslem.vadeTarih),
          sevkTarihi: moment(result.sevkTarihi),
          faturaNo: result.faturaNo,
          sevkNo: result.sevkNumarasi,
          faturaSatir: result.Faturasatir.map((faturaSatirItem) => {
            let satiriskontoLength = faturaSatirItem.Faturasatiriskonto.length;
            return {
              id: faturaSatirItem.id,
              stokBirimId: faturaSatirItem.stokBirimId,
              urunAdi: faturaSatirItem.urunAdi,
              barkod: faturaSatirItem.barkod,
              stokId: faturaSatirItem.stokVaryantGrupId,
              stokVaryantGrupId: faturaSatirItem.stokVaryantGrupId,
              aciklama: faturaSatirItem.aciklama,
              stokDepoId: String(faturaSatirItem.Stokislem.StokDepo.id),
              miktar: faturaSatirItem.miktar,
              birimFiyat: faturaSatirItem.birimFiyat,
              kdvOran: faturaSatirItem.kdvOran,
              kdvTutar: faturaSatirItem.kdvTutar,
              otvOran: faturaSatirItem.otvOran,
              otvTutar: faturaSatirItem.otvTutar,
              toplam: faturaSatirItem.genelToplam,
              iskontoId:
                satiriskontoLength === 0
                  ? null
                  : faturaSatirItem.Faturasatiriskonto[0].id,
              iskontoOran:
                satiriskontoLength === 0
                  ? 0
                  : faturaSatirItem.Faturasatiriskonto[0].oran,
              iskontoTip:
                satiriskontoLength === 0
                  ? "yuzde"
                  : faturaSatirItem.Faturasatiriskonto[0].iskontoTip === "Oran"
                  ? "yuzde"
                  : "tutar",
              iskontoTutar:
                satiriskontoLength === 0
                  ? 0
                  : faturaSatirItem.Faturasatiriskonto[0].tutar,
              iskontoSiraNo:
                satiriskontoLength === 0
                  ? 0
                  : faturaSatirItem.Faturasatiriskonto[0].siraNo,
              iskontoUygulananTutar:
                satiriskontoLength === 0
                  ? 0
                  : faturaSatirItem.Faturasatiriskonto[0].uygulananTutar,
              iskontoYuzde:
                satiriskontoLength === 0
                  ? 0
                  : faturaSatirItem.Faturasatiriskonto[0].oran,
              yeniSatirMi: false,
            };
          }),
          araIskontoOran: result.araToplamIskontoOran,
          araIskontoTip:
            result.araToplamIskontoTip === "Oran" ? "yuzde" : "tutar",
          araIskontoTutar: result.araToplamIskontoTutar,
          araIskontoDeger:
            result.araToplamIskontoTip === "Oran"
              ? result.araToplamIskontoOran
              : result.araToplamIskontoTutar,
          stopajOran: result.stopajOran,
          stopajTutar: result.stopajTutar,
          tevkifatOran: result.tevkifatOran,
          tevkifatTutar: result.tevkifatTutar,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      const {
        faturaId,
        faturaNo,
        firmaAdi,
        yetkili,
        vergiDairesi,
        vergiNumarasi,
        telefon,
        cepTelefonu,
        eMail,
        il,
        ilce,
        adres,
        sevkAdresi,
        faturaTarihi,
        vadeTarihi,
        faturaSaati,
        sevkTarihi,
        sevkNo,
        aciklama,
        stopajOran,
        stopajTutar,
        tevkifatOran,
        tevkifatTutar,
        araToplamTutar,
        araIskontoOran,
        araIskontoTutar,
        araIskontoTip,
        toplamSatirIskontoTutar,
        genelToplamTutar,
        faturaSatir,
      } = values;

      let requestData = {
        Id: faturaId,
        CariIslemAcikHesapAciklama: `${faturaNo} numaralı faturanın ${cariIslemTur()} işlemidir`,
        CariIslemOdemeAciklama: `${faturaNo} numaralı faturanın ${
          cariIslemTur() === "Borc" ? "Tahsilat" : "Odeme"
        } işlemidir`,
        FirmaAdi: firmaAdi,
        Yetkili: yetkili,
        VergiDairesi: vergiDairesi,
        VergiNumarasi: vergiNumarasi,
        Telefon: telefon,
        CepTelefonu: cepTelefonu,
        EMail: eMail,
        // CariKod: faturaFetchState.cariKod,
        Il: il,
        Ilce: ilce,
        Adres: adres,
        SevkAdresi: sevkAdresi,
        FaturaNo: faturaNo,
        FaturaTarihi: moment(faturaTarihi),
        SevkTarihi: moment(sevkTarihi),
        CariIslemVadeTarih: moment(vadeTarihi),
        SevkNumarasi: sevkNo,
        FaturaSaati: moment(faturaSaati).format("HH:mm:ss"),
        Aciklama: aciklama,
        StopajOran: stopajOran,
        StopajTutar: stopajTutar,
        TevkifatOran: tevkifatOran,
        TevkifatTutar: tevkifatTutar,
        Toplam: araToplamTutar,
        AraToplamIskontoOran: araIskontoOran,
        AraToplamIskontoTutar: araIskontoTutar,
        AraToplamIskontoTip: araIskontoTip === "yuzde" ? "Oran" : "Tutar",
        FaturaSatirIskontoToplamTutar: toplamSatirIskontoTutar,
        GenelToplam: genelToplamTutar,
        UpdatedAt: moment(),
        Faturasatir: faturaSatir
          .filter((x) => x.hidden === false)
          .map((faturaSatirItem) => {
            if (faturaSatirItem.id != null) {
              return {
                //eğer güncellenen satir ise
                Id: faturaSatirItem.id,
                Miktar: faturaSatirItem.miktar,
                BirimFiyat: faturaSatirItem.birimFiyat,
                Aciklama: faturaSatirItem.aciklama,
                OtvTutar: faturaSatirItem.otvTutar,
                OtvOran: faturaSatirItem.otvOran,
                // OivTutar: faturaSatirItem.oivTutar,
                // OivOran: faturaSatirItem.oivOran,
                KdvTutar: faturaSatirItem.kdvTutar,
                KdvOran: faturaSatirItem.kdvOran,
                UrunAdi: faturaSatirItem.urunAdi,
                UpdatedAt: moment(),
                Faturasatiriskonto: faturaSatirItem.iskontoOran
                  ? [
                      {
                        Id: faturaSatirItem.iskontoId,
                        Oran: faturaSatirItem.iskontoYuzde,
                        Tutar: faturaSatirItem.iskontoTutar,
                        IskontoTip:
                          faturaSatirItem.iskontoTip === "yuzde"
                            ? "Oran"
                            : "Tutar",
                        UygulananTutar: faturaSatirItem.iskontoUygulananTutar,
                        SiraNo: faturaSatirItem.iskontoSiraNo,
                      },
                    ]
                  : null,
                GenelToplam: faturaSatirItem.toplam,
              };
            } else {
              return {
                //eğer yeni eklenen satir ise
                StokVaryantGrupId: faturaSatirItem.stokVaryantGrupId,
                StokBirimId: faturaSatirItem.stokBirimId,
                UrunAdi: faturaSatirItem.urunAdi,
                Miktar: faturaSatirItem.miktar,
                BirimFiyat: faturaSatirItem.birimFiyat,
                Barkod: faturaSatirItem.barkod,
                Aciklama: faturaSatirItem.aciklama,
                OtvTutar: faturaSatirItem.otvTutar,
                OtvOran: faturaSatirItem.otvOran,
                // OivTutar: faturaSatirItem.oivTutar,
                // OivOran: faturaSatirItem.oivOran,
                KdvTutar: faturaSatirItem.kdvTutar,
                KdvOran: faturaSatirItem.kdvOran,
                Stokislem: {
                  StokDepoId: faturaSatirItem.stokDepoId,
                  GirisMi: faturaGirisMi(),
                },
                Faturasatiriskonto: faturaSatirItem.iskontoOran
                  ? [
                      {
                        Oran: faturaSatirItem.iskontoYuzde,
                        Tutar: faturaSatirItem.iskontoTutar,
                        IskontoTip:
                          faturaSatirItem.iskontoTip === "yuzde"
                            ? "Oran"
                            : "Tutar",
                        UygulananTutar: faturaSatirItem.iskontoUygulananTutar,
                        SiraNo: 0,
                      },
                    ]
                  : null,
                GenelToplam: faturaSatirItem.toplam,
              };
            }
          }),
      };
      setFetchLoading(true);
      const response = await api.put("/fatura/update", requestData);

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        fetchFatura();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const faturaGirisMi = () => {
    const faturaTip = form.getFieldValue("faturaTip");
    switch (faturaTip) {
      case faturaTypes.SATIS_FATURASI:
        return false;
      case faturaTypes.ALIS_FATURASI:
        return true;
      case faturaTypes.SATIS_IADE_FATURASI:
        return true;
      case faturaTypes.ALIS_IADE_FATURASI:
        return false;
      default:
        return null;
    }
  };
  const cariIslemTur = () => {
    const faturaTip = form.getFieldValue("faturaTip");
    switch (faturaTip) {
      case faturaTypes.SATIS_FATURASI:
        return cariIslemTurTypes.BORC;
      case faturaTypes.ALIS_FATURASI:
        return cariIslemTurTypes.ALACAK;
      case faturaTypes.SATIS_IADE_FATURASI:
        return cariIslemTurTypes.ALACAK;
      case faturaTypes.ALIS_IADE_FATURASI:
        return cariIslemTurTypes.BORC;
      default:
        return null;
    }
  };

  if (loading) {
    return <Card loading={loading} />;
  }

  return (
    <>
      <FaturaLayout
        onFinish={onFinish}
        form={form}
        saveButtonTitle={localization.guncelleButon}
        saveButtonLoading={fetchLoading}
        title={localization.baslik}
      />
    </>
  );
}

export default FaturaDuzenle;
