import React, { useState } from "react";
import { PageHeader, Space, Col, Card, Form, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import DatepickerRanger from "../components/toolbox/DatepickerRanger";
import strings from "../utils/Localization";
import CariKartSelectOption from "../components/toolbox/CariKartSelectOption";
import DovizSelectOption from "../components/toolbox/DovizSelectOption";
import FaturaTipSelectOption from "../components/toolbox/FaturaTipSelectOption";
import OdemeSekliSelectOption from "../components/toolbox/OdemeSekliSelectOption";
import moment from "moment";
import useVarsayilanDeger from "../hooks/useVarsayilanDeger";
import useApi from "../hooks/useApi";

function FaturaRapor() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const navigate= useNavigate();
  const { faturaRapor: localization } = strings;
  const { getDefaultSetting } = useVarsayilanDeger();
  const api = useApi();

  const onFinish = async (values) => {
    try {
      const { tarih, cariKartId, dovizId, faturaTip, odemeSekli } = values;

      const baslangicTarihi = moment(tarih[0]).format("YYYY-MM-DD");
      const bitisTarihi = moment(tarih[1]).format("YYYY-MM-DD");

      const pdfWindow = window.open(`${document.location.origin}/#/RaporYukleniyor`);
      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/fatura",
        {
          BaslangicTarih: `${baslangicTarihi} 00:00:00`,
          BitisTarih: `${bitisTarihi} 23:59:59`,
          CariKartId: cariKartId === undefined ? 0 : cariKartId,
          DovizId: dovizId === undefined ? 0 : dovizId,
          FaturaTip: faturaTip === undefined ? "" : faturaTip,
          OdemeSekli: odemeSekli === undefined ? "" : odemeSekli,
          format: "pdf",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        pdfWindow.document.location.href = response.data.data;
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Space style={{ width: "100%" }} size="middle" direction="vertical">
      <PageHeader
        title={localization.baslik}
        onBack={() => navigate(-1)}
        ghost={false}
      />
      <Col xxl={8} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card>
          <Form
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              tarih: [moment().add("months", -12), moment()],
              dovizId: getDefaultSetting("doviz"),
            }}
          >
            <Form.Item
              name="tarih"
              label={localization.tarihSeciniz}
              rules={[{ required: true }]}
            >
              <DatepickerRanger />
            </Form.Item>
            <Form.Item name="cariKartId">
              <CariKartSelectOption allowClear />
            </Form.Item>
            <Form.Item
              name="dovizId"
              label={strings.dovizSelectOption.dovizBaslik}
            >
              <DovizSelectOption allowClear />
            </Form.Item>
            <Form.Item
              name="faturaTip"
              label={strings.faturaSelectOption.faturaTip}
            >
              <FaturaTipSelectOption allowClear />
            </Form.Item>
            <Form.Item name="odemeSekli" label={localization.odemeSekliSeciniz}>
              <OdemeSekliSelectOption allowClear />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ float: "right" }}
                loading={fetchLoading}
              >
                {localization.faturaRaporuAl}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Space>
  );
}

export default FaturaRapor;
