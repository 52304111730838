import React, { useEffect, useState } from "react";
import { Card, Form, Input, Row, Col, Button, message, Alert } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";

export default function FirmaBilgileriForm() {
  const [form] = Form.useForm();
  const { firmaBilgileriForm } = strings.firmaBilgileri.components;
  const [fetchLoading, setFetchLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    fetchFirmaBilgileri();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchFirmaBilgileri = async () => {
    try {
      const response = await api.post("/firma/get", {
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: [
              "unvan",
              "yetkili",
              "vergIdairesi",
              "vergiNumarasi",
              "sektor",
              "eMail",
              "telefon",
              "cepTelefonu",
              "adres",
              "sehir",
              "ilce",
            ],
          },
        ],
      });
      if (response.status === 200) {
        const {
          unvan,
          yetkili,
          vergIdairesi,
          vergiNumarasi,
          sektor,
          eMail,
          telefon,
          cepTelefonu,
          adres,
          sehir,
          ilce,
        } = response.data.data[0];
        form.setFields([
          {
            name: "unvan",
            value: unvan,
          },
          {
            name: "yetkili",
            value: yetkili,
          },
          {
            name: "vergiDairesi",
            value: vergIdairesi,
          },
          {
            name: "vergiNumarasi",
            value: vergiNumarasi,
          },
          {
            name: "sektor",
            value: sektor,
          },
          {
            name: "eMail",
            value: eMail,
          },
          {
            name: "telefon",
            value: telefon,
          },
          {
            name: "cepTelefonu",
            value: cepTelefonu,
          },
          {
            name: "sehir",
            value: sehir,
          },
          {
            name: "ilce",
            value: ilce,
          },
          {
            name: "adres",
            value: adres,
          },
        ]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <>
      <Alert message={firmaBilgileriForm.guncellemeUyari} type="warning" closable style={{marginBottom:8}} />
      <Card loading={fetchLoading}>
        <Form form={form} layout="vertical" size="middle">
          <Row gutter={24}>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24}>
              <Form.Item name="unvan" label={firmaBilgileriForm.unvan} required>
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="yetkili"
                label={firmaBilgileriForm.yetkili}
                required
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="vergiDairesi"
                label={firmaBilgileriForm.vergiDairesi}
                required
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="vergiNumarasi"
                label={firmaBilgileriForm.vergiNumarasi}
                required
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="sektor"
                label={firmaBilgileriForm.sektor}
                required
              >
                <Input disabled />
              </Form.Item>
              <Form.Item name="eMail" label={firmaBilgileriForm.eMail} required>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24}>
              <Form.Item
                name="telefon"
                label={firmaBilgileriForm.telefon}
                required
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="cepTelefonu"
                label={firmaBilgileriForm.cepTelefonu}
                required
              >
                <Input disabled />
              </Form.Item>
              <Form.Item name="sehir" label={firmaBilgileriForm.sehir} required>
                <Input disabled />
              </Form.Item>
              <Form.Item name="ilce" label={firmaBilgileriForm.ilce} required>
                <Input disabled />
              </Form.Item>
              <Form.Item name="adres" label={firmaBilgileriForm.adres} required>
                <Input.TextArea disabled rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            size="large"
            onClick={() => message.info(firmaBilgileriForm.guncellemeUyari)}
          >
            {firmaBilgileriForm.guncelleButton}
          </Button>
        </Form>
      </Card>
    </>
  );
}
