import React from 'react'
import { Card, Form, Input, Row, Col, Divider, TimePicker } from 'antd'
import {
  FileTextOutlined,
  UserOutlined,
  SwapOutlined,
  CalendarOutlined,
  NumberOutlined,
  OrderedListOutlined,
} from '@ant-design/icons'
import Datepicker from '../toolbox/DatePicker'
import DovizSelectOption from '../toolbox/DovizSelectOption'
import OdemeSekliSelectOption from '../toolbox/OdemeSekliSelectOption'
import strings from '../../utils/Localization'
import FaturaTahsilatOdemeUyari from './FaturaTahsilatOdemeUyari'

const LabelIcon = ({ Icon, title }) => (
  <div style={{ fontSize: 15 }}>
    <Icon /> {title}
  </div>
)

function FaturaHeader({ form }) {
  const { faturaHeader: localization } = strings.faturaLayout

  return (
    <>
      <FaturaTahsilatOdemeUyari
        faturaTip={form.getFieldValue('faturaTip')}
        odemeSekli={form.getFieldValue('odemeSekli')}
      />
      <Form.Item name="cariKartId" hidden />
      <Form.Item name="yetkili" noStyle hidden initialValue="" />
      <Form.Item name="vergiDairesi" noStyle hidden initialValue="" />
      <Form.Item name="telefon" noStyle hidden initialValue="" />
      <Form.Item name="cepTelefonu" noStyle hidden initialValue="" />
      <Form.Item name="eMail" noStyle hidden initialValue="" />
      <Form.Item name="il" noStyle hidden initialValue="" />
      <Form.Item name="ilce" noStyle hidden initialValue="" />
      <Form.Item name="sevkAdresi" noStyle hidden initialValue="" />
      <Card>
        <Row gutter={24}>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="firmaAdi" label={<LabelIcon Icon={UserOutlined} title={localization.musteriSeciniz} />}>
              <Input disabled />
            </Form.Item>
            <Divider />
            <Form.Item
              rules={[{ required: true }]}
              label={localization.vergiNumarasi}
              name="vergiNumarasi"
              initialValue=""
            >
              <Input />
            </Form.Item>
            <Form.Item name="aciklama" label={<LabelIcon Icon={FileTextOutlined} title={localization.aciklama} />}>
              <Input.TextArea rows={3} placeholder={localization.aciklamaPlaceHolder} />
            </Form.Item>
            <Divider />
            <Form.Item
              name="dovizId"
              label={<LabelIcon Icon={SwapOutlined} title={localization.dovizTuru} />}
              rules={[{ required: true, message: strings.mesaj.gerekliBirAlan }]}
            >
              <DovizSelectOption disabled />
            </Form.Item>
            <Form.Item
              name="odemeSekli"
              label={<LabelIcon Icon={OrderedListOutlined} title={localization.odemeSekli} />}
              rules={[{ required: true, message: strings.mesaj.gerekliBirAlan }]}
            >
              <OdemeSekliSelectOption disabled />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              label={<LabelIcon Icon={CalendarOutlined} title={localization.duzenlemeTarihi} />}
              required
              style={{ marginBottom: 0 }}
            >
              <Row>
                <Form.Item name="faturaTarihi" style={{ flex: 1, marginRight: 8 }}>
                  <Datepicker allowClear={false} />
                </Form.Item>
                <Form.Item name="faturaSaati">
                  <TimePicker allowClear={false} />
                </Form.Item>
              </Row>
            </Form.Item>
            <Divider style={{ marginTop: 0 }} />
            <Form.Item
              name="vadeTarihi"
              label={<LabelIcon Icon={CalendarOutlined} title={localization.vadeTarihi} />}
              rules={[{ required: true }]}
            >
              <Datepicker allowClear={false} />
            </Form.Item>
            <Divider />
            <Form.Item
              name="sevkTarihi"
              label={<LabelIcon Icon={CalendarOutlined} title={localization.sevkTarihi} />}
              rules={[{ required: true }]}
            >
              <Datepicker allowClear={false} />
            </Form.Item>
            <Divider />
            <Form.Item name="faturaNo" label={<LabelIcon Icon={NumberOutlined} title={localization.faturaNo} />}>
              <Input placeholder={localization.faturaNoGiriniz} />
            </Form.Item>
            <Form.Item name="sevkNo" label={<LabelIcon Icon={NumberOutlined} title={localization.sevkNo} />}>
              <Input placeholder={localization.sevkNoPlaceHolder} />
            </Form.Item>
            <Divider style={{ marginTop: 0 }} />
            <Form.Item name="adres" label={<LabelIcon Icon={FileTextOutlined} title={localization.faturaAdresi} />}>
              <Input.TextArea rows={3} placeholder={localization.faturaAdresiPlaceHolder} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default FaturaHeader
