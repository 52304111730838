import React from 'react'
import { Space } from 'antd'
import { PageHeader, GelirGiderGrupList } from '../components/gelirGiderGrupListe'

function GelirGiderGrupListe() {
  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <PageHeader />
      <GelirGiderGrupList />
    </Space>
  )
}

export default GelirGiderGrupListe