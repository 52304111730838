import React from "react";
import { Modal, ModalProps, Typography } from "antd";

interface IProps extends ModalProps {}

const HataModal: React.FC<IProps> = ({ ...rest }) => {
  return (
    <Modal {...rest}>
      <Typography.Title level={3}>Uyarı</Typography.Title>
      <Typography.Text>
        Seçmiş olduğunu paket veya fatura adresi hatalı lütfen tekrar seçim
        işlemi yapınız
      </Typography.Text>
    </Modal>
  );
};

export default HataModal;
