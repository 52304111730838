import React from 'react'
import { Select, SelectProps } from 'antd'
import strings from '../../utils/Localization'
import { teklifSiparisDurumTypes } from '../../types/teklifSiparisDurumTypes'

const { Option } = Select

interface IProps extends SelectProps<any> {}

const TeklifSiparisDurumTipKategoriSelectOption: React.FC<IProps> = ({ placeholder, ...rest }) => {
  return (
    <Select {...rest} placeholder={placeholder || strings?.teklifSiparisDurumTipSelectOption?.placeholder}>
      <Option value={teklifSiparisDurumTypes.TEKLIF}>{strings?.teklifSiparisDurumTipSelectOption?.teklif}</Option>
      <Option value={teklifSiparisDurumTypes.SIPARIS}>{strings?.teklifSiparisDurumTipSelectOption?.siparis}</Option>
    </Select>
  )
}

export default TeklifSiparisDurumTipKategoriSelectOption
