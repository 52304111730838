import React, { useState, useEffect } from "react";
import { Card, message, Collapse } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useApi from "../../hooks/useApi";
import strings from "../../utils/Localization";
import BakiyeCard from "../toolbox/BakiyeCard";

const { Panel } = Collapse;

function CariBakiye() {
  const [cariSonBakiyeData, setCariSonBakiyeData] = useState([]);
  const { cariKartId } = useParams();
  const rerenderReducer = useSelector((state) => state.rerenderReducer);
  const api = useApi();

  useEffect(() => {
    fetchCariSonBakiye();
  }, [rerenderReducer]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCariSonBakiye = async () => {
    try {
      const response = await api.post(
        `carikart/getsonbakiye/${cariKartId}`,
        {}
      );
      if (response.status === 200) {
        setCariSonBakiyeData(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Collapse defaultActiveKey={"0"}>
        {cariSonBakiyeData.map((item, index) => {
          const { dovizAd, dovizSimge, dovizKod, giren, cikan, bakiye } = item;
          return (
            <Panel
              header={`${dovizKod} ${strings.cariBakiye.bakiye}`}
              key={index}
            >
              <BakiyeCard
                key={index}
                doviz={{ dovizAd, dovizKod, dovizSimge }}
                giris={giren}
                cikis={cikan}
                bakiye={bakiye}
              />
            </Panel>
          );
        })}
      </Collapse>
    </Card>
  );
}

export default CariBakiye;