import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, message, notification } from 'antd'
import strings from '../../utils/Localization'
import useApi from '../../hooks/useApi'
import { useNavigate } from 'react-router-dom'

export default function SubeDuzenleModal({ visible = false, handleCancel, data = null }) {
  const [form] = Form.useForm()
  const [fetchLoading, setFetchLoading] = useState(false)
  const navigate= useNavigate()
  const api = useApi()
  const { subeDuzenle: localization } = strings

  useEffect(() => {
    if (data !== null) {
      form.setFieldsValue({
        id: data.id,
        subeAd: data.subeAd,
      })
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const { id, subeAd } = values

      setFetchLoading(true)

      const response = await api.put('/sube', {
        Id: id,
        subeAd: subeAd,
      })

      if (response.status === 200) {
        form.resetFields()
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
        handleCancel()
        navigate(0)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: 'submit',
        form: 'subeDuzenleForm',
        loading: fetchLoading,
      }}
    >
      <Form form={form} name="subeDuzenleForm" layout="vertical" onFinish={onFinish}>
        <Form.Item hidden name={'id'} />
        <Form.Item name="subeAd" label={localization.subeAdi} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}
