import React, { useState } from 'react'
import { PageHeader, Space, Form, Col, Row, Card, Button, Select, message, notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import BankaHesapSelectOption from '../components/toolbox/BankaHesapSelectOption'
import BankaSelectOption from '../components/toolbox/BankaSelectOption'
import DepoSelectOption from '../components/toolbox/DepoSelectOption'
import DovizSelectOption from '../components/toolbox/DovizSelectOption'
import KasaSelectOption from '../components/toolbox/KasaSelectOption'
import StokBirimSelectOption from '../components/toolbox/StokBirimSelectOption'
import StokKategoriSelectOption from '../components/toolbox/StokKategoriSelectOption'
import OdemeSekliSelectOption from '../components/toolbox/OdemeSekliSelectOption'
import SubeSelectOption from '../components/toolbox/SubeSelectOption'
import strings from '../utils/Localization'
import { SaveOutlined } from '@ant-design/icons'
import useVarsayilanDeger from '../hooks/useVarsayilanDeger'
import useApi from '../hooks/useApi'
import useKullanici from '../hooks/useKullanici'

const { Option } = Select

function VarsayilanAyarlar() {
  const [form] = Form.useForm()
  const navigate= useNavigate()
  const [fetchLoading, setFetchLoading] = useState(false)
  const { varsayilanAyarlar: localization } = strings
  const { getDefaultSettingItem, getDefaultSetting } = useVarsayilanDeger()
  const api = useApi()
  const { setDefaultSettings } = useKullanici()

  const onValuesChange = (changedValues) => {
    if (changedValues.dovizId !== undefined) {
      form.setFieldsValue({
        kasaId: null,
        bankaHesapId: null,
      })
    }
  }

  const onFinish = async (values) => {
    try {
      const {
        stokBirimId,
        stokKategoriId,
        stokDepoId,
        bankaId,
        basamakSayisi,
        odemeSekli,
        dovizId,
        kasaId,
        bankaHesapId,
        subeId,
      } = values
      setFetchLoading(true)
      const response = await api.put('/varsayilanayar/degerupdate', [
        {
          Id: getDefaultSettingItem('stokBirim').id,
          deger: stokBirimId,
        },
        {
          Id: getDefaultSettingItem('stokKategori').id,
          deger: stokKategoriId,
        },
        {
          Id: getDefaultSettingItem('stokDepo').id,
          deger: stokDepoId,
        },
        {
          Id: getDefaultSettingItem('banka').id,
          deger: bankaId,
        },
        {
          Id: getDefaultSettingItem('basamakSayisi').id,
          deger: basamakSayisi,
        },
        {
          Id: getDefaultSettingItem('odemeSekli').id,
          deger: odemeSekli,
        },
        {
          Id: getDefaultSettingItem('doviz').id,
          deger: dovizId,
        },
        {
          Id: getDefaultSettingItem('kasa').id,
          deger: kasaId,
        },
        {
          Id: getDefaultSettingItem('bankaHesap').id,
          deger: bankaHesapId,
        },
        {
          Id: getDefaultSettingItem('sube').id,
          deger: subeId,
        },
      ])
      if (response.status === 200) {
        setDefaultSettings(JSON.stringify(response.data.data))
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <PageHeader title={localization.baslik} onBack={() => navigate(-1)} ghost={false} />
      <Card>
        <Form
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          name="varsayilanAyarForm"
          layout="vertical"
          initialValues={{
            stokBirimId: getDefaultSetting('stokBirim'),
            stokKategoriId: getDefaultSetting('stokKategori'),
            stokDepoId: getDefaultSetting('stokDepo'),
            bankaId: getDefaultSetting('banka'),
            basamakSayisi: getDefaultSetting('basamakSayisi'),
            odemeSekli: getDefaultSetting('odemeSekli'),
            dovizId: getDefaultSetting('doviz'),
            kasaId: getDefaultSetting('kasa'),
            bankaHesapId: getDefaultSetting('bankaHesap'),
            subeId: getDefaultSetting('sube'),
          }}
        >
          <Row gutter={24}>
            <Col xl={12} lg={12}>
              <Form.Item
                name="stokBirimId"
                label={strings.stokBirimSelectOption.stokBirimBaslik}
                rules={[{ required: true }]}
              >
                <StokBirimSelectOption />
              </Form.Item>
              <Form.Item
                name="stokKategoriId"
                label={strings.stokKategoriSelectOption.stokKategoriBaslik}
                rules={[{ required: true }]}
              >
                <StokKategoriSelectOption />
              </Form.Item>
              <Form.Item name="stokDepoId" label={localization.stokDepo} rules={[{ required: true }]}>
                <DepoSelectOption />
              </Form.Item>
              <Form.Item name="bankaId" label={localization.banka} rules={[{ required: true }]}>
                <BankaSelectOption />
              </Form.Item>

              <Form.Item name="basamakSayisi" label={localization.basamakSayisi} rules={[{ required: true }]}>
                <Select placeholder={localization.basamakSayisiPlaceHolder}>
                  <Option value="0">0</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12}>
              <Form.Item name="odemeSekli" label={localization.odemeSekli} rules={[{ required: true }]}>
                <OdemeSekliSelectOption />
              </Form.Item>
              <Form.Item name="dovizId" label={localization.doviz} rules={[{ required: true }]}>
                <DovizSelectOption />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.dovizId !== currentValues.dovizId}
              >
                {({ getFieldValue }) => {
                  const currentDovizId = getFieldValue('dovizId')
                  return (
                    <>
                      <Form.Item name="kasaId" label={localization.kasa}>
                        <KasaSelectOption
                          requestBody={{
                            Data: {
                              dovizId: currentDovizId,
                            },
                          }}
                          disabled={currentDovizId === undefined ? true : false}
                        />
                      </Form.Item>
                      <Form.Item name="bankaHesapId" label={localization.bankaHesap}>
                        <BankaHesapSelectOption
                          requestBody={{
                            Data: {
                              dovizId: currentDovizId,
                            },
                          }}
                          disabled={currentDovizId === undefined ? true : false}
                        />
                      </Form.Item>
                    </>
                  )
                }}
              </Form.Item>
              <Form.Item name="subeId" label={localization.sube} rules={[{ required: true }]}>
                <SubeSelectOption />
              </Form.Item>
            </Col>
          </Row>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            icon={<SaveOutlined />}
            style={{ float: 'right' }}
            loading={fetchLoading}
          >
            {localization.kaydet}
          </Button>
        </Form>
      </Card>
    </Space>
  )
}

export default VarsayilanAyarlar
