import { message } from "antd";
import useApi from "./useApi";

const useEvrakNo = () => {
  const api = useApi();

  const getCariIslemEvrakNo = async () => {
    try {
      const response = await api.post("/cariislem/getnewevrakno", {});
      if (response.status === 200) {
        return String(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const getFaturaEvrakNo = async () => {
    try {
      const response = await api.post("/fatura/getnewfaturano", {});
      if (response.status === 200) {
        return String(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const getKasaIslemEvrakNo = async () => {
    try {
      const response = await api.post("/kasaislem/getnewevrakno", {});
      if (response.status === 200) {
        return String(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const getBankaHesapIslemEvrakNo = async () => {
    try {
      const response = await api.post("/bankahesapislem/getnewevrakno", {});
      if (response.status === 200) {
        return String(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const getStokIslemEvrakNo = async () => {
    try {
      const response = await api.post("/stokislem/getnewevrakno", {});
      if (response.status === 200) {
        return String(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const getTeklifEvrakNo = async () => {
    try {
      const response = await api.post("/teklifsiparis/getnewteklifno", {});
      if (response.status === 200) {
        return String(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const getSiparisEvrakNo = async () => {
    try {
      const response = await api.post("/teklifsiparis/getnewsiparisno", {});
      if (response.status === 200) {
        return String(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return {
    getCariIslemEvrakNo,
    getFaturaEvrakNo,
    getKasaIslemEvrakNo,
    getBankaHesapIslemEvrakNo,
    getStokIslemEvrakNo,
    getTeklifEvrakNo,
    getSiparisEvrakNo,
  };
};

export default useEvrakNo;
