import React from "react";
import { Button, Card, CardProps, Divider, List, Row, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

interface IProps extends CardProps {
  title: string;
  price: string;
  onClick: () => void;
  checkColorGreen: boolean;
  listData: any[];
  preferred: boolean;
}

const PriceCard: React.FC<IProps> = ({
  title,
  preferred,
  price,
  listData,
  checkColorGreen,
  onClick,
  ...rest
}) => {
  return (
    <Card
      {...rest}
      title={
        <Title level={3} style={{ color: "#000000bf" }}>
          {title}
        </Title>
      }
      style={{
        padding: 8,
        borderRadius: 12,
        cursor: "default",
        borderColor: preferred ? "#2175d894" : "#cacaca",
        borderWidth: 4,
      }}
      hoverable
    >
      <Row
        style={{
          alignItems: "baseline",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Title level={1} style={{ fontSize: 55 }}>
          {price}
        </Title>
        <Text
          type="secondary"
          style={{ fontSize: 18, fontWeight: "bold", marginLeft: 4 }}
        >
          /Aylık
        </Text>
      </Row>
      <Divider style={{ marginTop: 0, marginBottom: 0 }} />
      <List
        itemLayout="horizontal"
        dataSource={listData}
        renderItem={(item) => (
          <List.Item style={{ minHeight: 100 }}>
            <List.Item.Meta
              avatar={
                <CheckOutlined
                  style={{
                    fontSize: 21,
                    color: checkColorGreen ? "#30b260" : "#000",
                  }}
                />
              }
              title={
                <Text strong style={{ fontSize: 16 }}>
                  {item.title}
                </Text>
              }
              description={
                <span style={{ color: "#000", fontSize: 15 }}>
                  {item.description}
                </span>
              }
            />
          </List.Item>
        )}
      />
      <Divider style={{ marginTop: 0 }} />
      <Button
        type="primary"
        shape="round"
        size="large"
        onClick={onClick}
        block
        style={{
          borderColor: " #2175d8",
          background: "#2175d8",
          borderRadius: 8,
        }}
      >
        Abone Ol
      </Button>
    </Card>
  );
};

export default PriceCard;
