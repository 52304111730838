import React, { useState } from 'react'
import { Button, Space, Popconfirm, message, notification, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import strings from '../../utils/Localization'
import { useNavigate } from 'react-router-dom'
import CursorTableItem from '../toolbox/CursorTableItem'
import ListFetchContent from '../toolbox/FetchListContent'
import useApi from '../../hooks/useApi'
import {GelirGiderGrupDuzenleModal} from '../gelirGiderGrup'

export default function GelirGiderGrupList() {
    const [dataSource, setDataSource] = useState({})
    const [gelirGiderGrupDuzenleModalVisible, setGelirGiderGrupDuzenleModalVisible] = useState(false)
    const [duzenleData, setDuzenleData] = useState(null)
    const navigate= useNavigate()
    const { gelirGiderGrupListTable } = strings.gelirGiderGrupListe.components

    const api = useApi()

    const columns = [
        {
            title: gelirGiderGrupListTable.gelirGiderAdi,
            dataIndex: 'ad',
            key: 'ad',
            render: (text, row, index) => (
                <CursorTableItem text={`${text} (${row.islemSayisi})`} onClick={() => navigate(`/GelirGiderDetay/${row.id}`)} />
            ),
        },
        {
            title: gelirGiderGrupListTable.islemler,
            dataIndex: 'islemler',
            key: 'islemler',
            render: (text, row, index) => (
                <Space size="middle">
                    <Tooltip title={gelirGiderGrupListTable.gelirGiderDetay}>
                        <Button
                            onClick={() => navigate(`/GelirGiderDetay/${row.id}`)}
                            type="default"
                            shape="circle"
                            icon={<SearchOutlined />}
                            size={'large'}
                        />
                    </Tooltip>
                    <Tooltip title={gelirGiderGrupListTable.gelirGiderDuzenle}>
                        <Button
                            onClick={() => {
                                setDuzenleData(row)
                                setGelirGiderGrupDuzenleModalVisible(true)
                            }}
                            type="default"
                            shape="circle"
                            icon={<EditOutlined />}
                            size={'large'}
                        />
                    </Tooltip>
                    <Tooltip title={gelirGiderGrupListTable.gelirGiderSil}>
                        <Popconfirm
                            title={strings.mesaj.silmeIslemUyari}
                            onConfirm={() => {
                                if (row.islemSayisi > 0) {
                                    message.error({
                                        content: strings.mesaj.gelirGiderSilme,
                                        style: { marginTop: '20vh' },
                                    })
                                } else remove(row.id)
                            }}
                        >
                            <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ]

    const remove = async (gelirGiderId) => {
        try {
            const response = await api.delete(`/gelirgidergrup/${gelirGiderId}`, {})
            if (response.status === 200) {
                setDataSource({
                    ...dataSource,
                    data: dataSource.data.filter((x) => x.id !== gelirGiderId),
                })
                notification['success']({
                    message: strings.mesaj.basarliBaslik,
                    description: strings.mesaj.islemBasarili,
                })
            }
        } catch (error) {
            message.error(error.message)
        }
    }

    return (
        <>
            <ListFetchContent
                columns={columns}
                requestUrl="/gelirgidergrup/get"
                requestBody={{
                    SelectColumns: [
                        {
                            TableName: '',
                            ColumnNames: ['ad', 'id'],
                        },
                        {
                            TableName: 'Gelirgider:islemSayisi',
                            Operator: 'Count',
                        },
                    ],
                }}
                onDataSource={(e) => setDataSource(e)}
                dataSource={dataSource}
            />
            <GelirGiderGrupDuzenleModal
                visible={gelirGiderGrupDuzenleModalVisible}
                data={duzenleData}
                handleCancel={() => setGelirGiderGrupDuzenleModalVisible(false)}
            />
        </>
    )
}
