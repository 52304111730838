import React, { useState } from 'react'
import { Form, Input, message, notification, InputNumber, Button, Row, Col, Space, PageHeader, Card } from 'antd'
import strings from '../utils/Localization'
import useApi from '../hooks/useApi'
import StokSelectOption from '../components/toolbox/StokSelectOption'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import StokVaryantGrupSelectOption from '../components/toolbox/StokVaryantGrupSelectOption'
import { useNavigate } from 'react-router-dom'
import { SaveOutlined } from "@ant-design/icons";
import moment from 'moment'

const formSize = 'middle'

const minusIconStyle = { alignItems: 'center', justifyContent: 'center', display: 'flex' }

const UretimReceteEkle = () => {
    const [selectedStok, setSelectedStok] = useState({ Stokvaryantgrup: [] })
    const [fetchLoading, setFetchLoading] = useState(false)
    const [form] = Form.useForm()
    const api = useApi()
    const navigate = useNavigate()
    const localization = strings.uretimReceteEkle

    const onFinish = async (values) => {
        try {
            setFetchLoading(true)

            let stokVaryantGrupId;

            if (selectedStok.Stokvaryantgrup.length > 1) {//ürünün 1'den fazla varyantı varmı kontrolü
                stokVaryantGrupId = values.stokVaryantGrupId
            }
            else {
                stokVaryantGrupId = selectedStok.Stokvaryantgrup[0].id
            }

            //malzeme listesi oluşturma
            const uretimrecetemalzeme = values.uretimrecetemalzeme.filter(x => x?.hidden !== true).map(item => {
                let malzemeStokVaryantGrupId;
                const { selectedStok, stokVaryantGrupId, miktar } = item

                if (selectedStok.Stokvaryantgrup.length > 1) {
                    malzemeStokVaryantGrupId = stokVaryantGrupId
                }
                else {
                    malzemeStokVaryantGrupId = selectedStok.Stokvaryantgrup[0].id
                }
                return {
                    stokVaryantGrupId: malzemeStokVaryantGrupId,
                    miktar: miktar
                }
            })

            const response = await api.post('/uretimrecete', {
                stokVaryantGrupId: stokVaryantGrupId,
                ad: values.ad,
                aciklama: values.aciklama,
                uretimrecetemalzeme: uretimrecetemalzeme
            })

            if (response.status === 200) {
                notification['success']({
                    message: strings.mesaj.basarliBaslik,
                    description: strings.mesaj.islemBasarili,
                })
                form.resetFields()
                navigate("/Uretim", {
                    state: {
                        isRecete: true
                    }
                })
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setFetchLoading(false)
        }
    }

    const onSelectedStok = (e) => {
        setSelectedStok(e)
        form.setFieldsValue({ stokVaryantGrupId: undefined })
    }

    return (
        <Space style={{ width: '100%' }} direction="vertical" size={"large"} >
            <PageHeader onBack={() => navigate(-1)} ghost={false} title={localization.pageHeader.baslik}
                extra={[
                    <Button loading={fetchLoading} form="uretimEkle" htmlType="submit" icon={<SaveOutlined />} type="primary" size="large">{localization.pageHeader.kaydet}</Button>
                ]}
            />
            <Form
                form={form}
                layout="vertical"
                name='uretimEkle'
                onFinish={onFinish}
                size={formSize}
                initialValues={{
                    uretimBaslangicTarihi: moment(),
                    miktar: 1,
                    uretimrecetemalzeme: [{
                        miktar: 1,
                        birimMiktar: 1
                    }]
                }}>
                <Card>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="stokId" label={localization.uretilecekUrun} rules={[{ required: true }]}>
                                <StokSelectOption onSelectedStok={onSelectedStok} />
                            </Form.Item>
                            {selectedStok.Stokvaryantgrup.length > 1 && (
                                <Form.Item label={localization.uretilecekUrunVaryanti} name={"stokVaryantGrupId"} rules={[{ required: true }]}>
                                    <StokVaryantGrupSelectOption stokId={selectedStok.id} />
                                </Form.Item>
                            )}
                            <Form.Item name="ad" label={localization.urunAdi} rules={[{ required: true }]}>
                                <Input placeholder={localization.adPlaceholder} />
                            </Form.Item>
                            <Form.Item name="aciklama" label={localization.aciklama}>
                                <Input.TextArea rows={4} placeholder={localization.aciklamaPlaceHolder} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.List name="uretimrecetemalzeme">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name }) => (
                                            <UretimReceteMalzemeItem name={name} form={form} />
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                {localization.varyantDegerEkle}
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Space>
    )
}

export default UretimReceteEkle

const UretimReceteMalzemeItem = ({ name, form }) => {
    const [selectedStok, setSelectedStok] = useState({ Stokvaryantgrup: [] })
    const [satirHidden, setSatirHidden] = useState(false)
    const localization = strings.uretimReceteEkle

    const onSelectedStok = (e) => {
        setSelectedStok(e)
        form.setFields([
            {
                name: ["uretimrecetemalzeme", name, "selectedStok"],
                value: e
            },
            {
                name: ["uretimrecetemalzeme", name, "stokVaryantGrupId"],
                value: undefined
            }
        ])
    }

    const remove = () => {
        setSatirHidden(true)
        form.setFields([{ name: ['uretimrecetemalzeme', name, 'hidden'], value: true }])
    }

    if (satirHidden) {
        return null
    }

    return (
        <Row gutter={24} >
            <Col span={16}>
                <Form.Item
                    name={[name, 'stokId']}
                    rules={[{ required: true }]}
                    label={localization.uretimdeKullanilacakUrun}
                >
                    <StokSelectOption onSelectedStok={onSelectedStok} />
                </Form.Item>
                {selectedStok.Stokvaryantgrup.length > 1 && (
                    <Form.Item label={localization.uretimdeKullanilacakUrunVaryanti} name={[name, "stokVaryantGrupId"]} rules={[{ required: true }]}>
                        <StokVaryantGrupSelectOption stokId={selectedStok.id} />
                    </Form.Item>
                )}
            </Col>
            <Col span={6}>
                <Form.Item name={[name, 'miktar']} rules={[{ required: true }]} label={localization.miktar} initialValue={1}>
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={2} style={minusIconStyle}>
                <MinusCircleOutlined onClick={() => remove()} />
            </Col>
        </Row>
    )
}
