import React, { useState, useEffect } from "react";
import { Card, Row, Col, Progress, Typography, message } from "antd";
import styled from "styled-components";
import strings from "../../utils/Localization";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";
import useCurrency from "../../hooks/useCurrency";
import { tutariYuzdeyeCevir } from "../../helpers/HesaplamaHelper";
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";

const { Title, Paragraph } = Typography;

const colStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: 24,
  cursor: "pointer",
};

const ChartTitle = styled(Title)`
  padding-bottom: 8px;
`;

const ChartLabel = styled(Paragraph)`
  font-size: 19px;
  margin-top: 16px;
`;
const progressSize = 250;
export default function VadeliOdemeCicleProgress({ title }) {
  const { getDefaultSetting } = useVarsayilanDeger();
  const defaultDovizId = String(getDefaultSetting("doviz"));
  const [dovizId, setDovizId] = useState(defaultDovizId);
  const [chartData, setChartData] = useState({
    vadesiGecmisBakiye: 0,
    vadesiGelecekBakiye: 0,
    toplamBakiye: 0,
  });
  const [fetchLoading, setFetchLoading] = useState(true);
  const [dovizData, setDovizData] = useState([]);
  const navigate= useNavigate();
  const api = useApi();

  useEffect(() => {
    fetch();
  }, [dovizId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = async () => {
    try {
      const response = await api.post(`/cariislem/vadeliodeme/${dovizId}`, {});

      if (response.status === 200) {
        setChartData(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const getDovizSimge = () => {
    const dovizSimge = dovizData.find((x) => x.id === parseInt(dovizId));
    return dovizSimge === undefined ? "" : dovizSimge.simge;
  };

  return (
    <Card
      title={title}
      bordered={true}
      extra={
        <DovizSelectOption
          defaultValue={dovizId}
          onChange={(e) => setDovizId(e)}
          onDovizData={(e) => setDovizData(e)}
        />
      }
      loading={fetchLoading}
    >
      <Row>
        <ProgressCard
          onClick={() => navigate(`/VadeliOdemeDetay/gecmis/${dovizId}`)}
          text={
            strings.panel.components.vadeliOdemeCicleProgress
              .vadesiGecmisOdemeler
          }
          tutar={chartData.vadesiGecmisBakiye}
          percent={tutariYuzdeyeCevir(
            chartData.vadesiGecmisBakiye,
            chartData.toplamBakiye
          )}
          dovizSimge={getDovizSimge()}
        />
        <ProgressCard
          onClick={() => navigate(`/VadeliOdemeDetay/gelecek/${dovizId}`)}
          text={
            strings.panel.components.vadeliOdemeCicleProgress
              .vadesiGelecekOdemeler
          }
          tutar={chartData.vadesiGelecekBakiye}
          percent={tutariYuzdeyeCevir(
            chartData.vadesiGelecekBakiye,
            chartData.toplamBakiye
          )}
          dovizSimge={getDovizSimge()}
        />
        <ProgressCard
          onClick={() => navigate(`/VadeliOdemeDetay/hepsi/${dovizId}`)}
          text={strings.panel.components.vadeliOdemeCicleProgress.genelToplam}
          tutar={chartData.toplamBakiye}
          percent={chartData.toplamBakiye === 0 ? 0 : 100}
          dovizSimge={getDovizSimge()}
        />
      </Row>
    </Card>
  );
}

const ProgressCard = ({ text, tutar, percent, dovizSimge = "", onClick }) => {
  const dangerColor = "#ee7a6b";
  const { paraFormat } = useCurrency();

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={8}
      style={colStyle}
      onClick={onClick}
    >
      <ChartTitle level={5}>{text}</ChartTitle>
      <Progress
        type="circle"
        percent={percent}
        width={progressSize}
        format={(percent) => (
          <ChartLabel>
            {dovizSimge}
            {paraFormat(tutar)}
          </ChartLabel>
        )}
        strokeColor={dangerColor}
        strokeWidth={10}
      />
    </Col>
  );
};
