import React, { useState } from 'react'
import { Button, Space, Card, notification, message, Popconfirm, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import CursorTableItem from '../toolbox/CursorTableItem'
import strings from '../../utils/Localization'
import FetchListContent from '../toolbox/FetchListContent'
import StokBirimEkleModal from '../stokBirim/StokBirimEkleModal'
import StokBirimDuzenleModal from '../stokBirim/StokBirimDuzenleModal'
import useApi from '../../hooks/useApi'

export default function StokBirimListe() {
  const [dataSource, setDataSource] = useState({})
  const [stokBirimEkleModalVisible, setStokBirimEkleModalVisible] = useState(false)
  const [stokBirimDuzenleModalVisible, setStokBirimDuzenleModalVisible] = useState(false)
  const [duzenleData, setDuzenleData] = useState({})
  const { stokBirimListe } = strings.stokBirimListe.components
  const api = useApi()

  const columns = [
    {
      title: stokBirimListe.birimAd,
      dataIndex: 'ad',
      key: 'ad',
      render: (text, row, index) => <CursorTableItem hideHand text={`${text} (${row.stokSayisi})`} />,
    },
    {
      title: stokBirimListe.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={stokBirimListe.stokBirimDuzenle}>
            <Button
              onClick={() => {
                setDuzenleData(row)
                setStokBirimDuzenleModalVisible(true)
              }}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={stokBirimListe.stokBirimSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => {
                if (row.stokSayisi > 0) {
                  message.error({
                    content: strings.mesaj.stokBirimSilme,
                    style: { marginTop: '20vh' },
                  })
                } else remove(row.id)
              }}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const remove = async (stokBirimId) => {
    try {
      const response = await api.delete(`/stokbirim/${stokBirimId}`, {})
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== stokBirimId),
        })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  return (
    <>
      <Card
        title={stokBirimListe.baslik}
        extra={[
          <Button
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => setStokBirimEkleModalVisible(true)}
          >
            {stokBirimListe.birimEkleButom}
          </Button>,
        ]}
      >
        <FetchListContent
          columns={columns}
          requestUrl="/stokbirim/get"
          onDataSource={(e) => setDataSource(e)}
          dataSource={dataSource}
          requestBody={{
            SelectColumns: [
              {
                TableName: '',
                ColumnNames: ['ad', 'id'],
              },
              {
                TableName: 'Stok:stokSayisi',
                Operator: 'Count',
              },
            ],
          }}
        />
      </Card>
      <StokBirimEkleModal
        visible={stokBirimEkleModalVisible}
        handleCancel={() => setStokBirimEkleModalVisible(false)}
      />
      <StokBirimDuzenleModal
        visible={stokBirimDuzenleModalVisible}
        handleCancel={() => setStokBirimDuzenleModalVisible(false)}
        data={duzenleData}
      />
    </>
  )
}
