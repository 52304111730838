import React from "react";
import { Col, Row, PageHeader } from "antd";
import { useNavigate } from "react-router-dom";
import strings from "../utils/Localization";
import { Profil, StokIslemListe, IslemButton } from "../components/stokDetay";

function StokDetay() {
  const navigate= useNavigate();
  const { baslik } = strings.stokDetay;

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => navigate(-1)}
        title={baslik}
        extra={<IslemButton />}
      />
      <br />
      <Row gutter={24}>
        <Col xxl={6} xl={7} lg={24} md={24} xs={24}>
          <Profil />
          <br />
        </Col>
        <Col xxl={18} xl={17} lg={24} md={24} xs={24}>
          <StokIslemListe />
        </Col>
      </Row>
      <br />
    </>
  );
}

export default StokDetay;
