import React from "react";
import { Modal, Row, Col, Divider, Button } from "antd";
import strings from "../../utils/Localization";
import useCurrency from "../../hooks/useCurrency";

const ModelItem = ({ title, text }) => (
  <div style={{ marginBottom: 16 }}>
    <strong style={{ fontSize: 15 }}>{title}</strong>
    <br />
    <span style={{ fontSize: 14 }}>{text}</span>
    <Divider />
  </div>
);

export default function ProfilDetay({
  visible = false,
  kapatOnClick,
  stok = {},
}) {
  const { paraFormat } = useCurrency();
  const localization = strings.stokDetay.profilDetay;

  return (
    <Modal
      width={"70%"}
      title={localization.baslik}
      visible={visible}
      onCancel={kapatOnClick}
      footer={[
        <Button key="submit" type="primary" size="large" onClick={kapatOnClick}>
          {localization.kapatButon}
        </Button>,
      ]}
    >
      <Row gutter={24}>
        <Col xl={8} lg={8} md={12} xs={12}>
          <ModelItem title={localization.stokAdi} text={stok?.ad} />
          <ModelItem title={localization.aciklama} text={stok?.aciklama} />
          <ModelItem
            title={localization.barkod}
            text={stok?.Stokvaryantgrup?.[0]?.barkod}
          />
          <ModelItem
            title={localization.gtin}
            text={stok?.Stokvaryantgrup?.[0]?.gtin}
          />
          <ModelItem
            title={localization.raf}
            text={stok?.Stokvaryantgrup?.[0]?.raf}
          />
          <ModelItem
            title={localization.kod}
            text={stok?.Stokvaryantgrup?.[0]?.kod}
          />
        </Col>
        <Col xl={8} lg={8} md={12} xs={12}>
          <ModelItem
            title={localization.vergilerHaricAlisFiyati}
            text={`${
              stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1].Doviz?.simge
            } ${paraFormat(
              stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]?.fiyat
            )}`}
          />
          <Row>
            <Col span={12}>
              <ModelItem
                title={localization.alisKdvOran}
                text={`${
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]?.Doviz
                    ?.simge
                } ${paraFormat(
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]?.kdvOran
                )}`}
              />
            </Col>
            <Col span={12}>
              <ModelItem
                title={localization.alisKdvTutar}
                text={`${
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]?.Doviz
                    ?.simge
                } ${paraFormat(
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]
                    ?.kdvTutar
                )}`}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <ModelItem
                title={localization.alisOtvOran}
                text={`${
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]?.Doviz
                    ?.simge
                } ${paraFormat(
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]?.otvOran
                )}`}
              />
            </Col>
            <Col span={12}>
              <ModelItem
                title={localization.alisOtvTutar}
                text={`${
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]?.Doviz
                    ?.simge
                } ${paraFormat(
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]
                    ?.otvTutar
                )}`}
              />
            </Col>
          </Row>
          <ModelItem
            title={localization.vergilerDahilAlisFiyati}
            text={`${
              stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]?.Doviz
                ?.simge
            } ${paraFormat(
              stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[1]?.genelToplam
            )}`}
          />
        </Col>
        <Col xl={8} lg={8} md={12} xs={12}>
          <ModelItem
            title={localization.vergilerHaricSatisFiyati}
            text={`${
              stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]?.Doviz
                ?.simge
            } ${paraFormat(
              stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]?.fiyat
            )}`}
          />
          <Row>
            <Col span={12}>
              <ModelItem
                title={localization.satisKdvOran}
                text={`${
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]?.Doviz
                    ?.simge
                } ${paraFormat(
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]?.kdvOran
                )}`}
              />
            </Col>
            <Col span={12}>
              <ModelItem
                title={localization.satisKdvTutar}
                text={`${
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]?.Doviz
                    ?.simge
                } ${paraFormat(
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]
                    ?.kdvTutar
                )}`}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <ModelItem
                title={localization.satisOtvOran}
                text={`${
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]?.Doviz
                    ?.simge
                } ${paraFormat(
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat[0]?.otvOran
                )}`}
              />
            </Col>
            <Col span={12}>
              <ModelItem
                title={localization.satisOtvTutar}
                text={`${
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]?.Doviz
                    ?.simge
                } ${paraFormat(
                  stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]
                    ?.otvTutar
                )}`}
              />
            </Col>
          </Row>
          <ModelItem
            title={localization.vergilerDahilSatisFiyati}
            text={`${
              stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]?.Doviz
                ?.simge
            } ${paraFormat(
              stok?.Stokvaryantgrup?.[0]?.StokvaryantGrupfiyat?.[0]?.genelToplam
            )}`}
          />
        </Col>
      </Row>
    </Modal>
  );
}
