import React from 'react'

const avatarWidthHeight = 100
export default function Avatar({ text = "Mehmet" }) {

    const selectColor = () => {
        const firstCharacter = text[0].toLocaleLowerCase()

        if (firstCharacter === 'a' || firstCharacter === 'b' || firstCharacter === 'c' || firstCharacter === 'ç' || firstCharacter === 'd') {
            return "#ff5722"
        }
        else if (firstCharacter === 'e' || firstCharacter === 'f' || firstCharacter === 'h' || firstCharacter === 'ğ' || firstCharacter === 'h') {
            return "#009688"
        }
        else if (firstCharacter === 'i' || firstCharacter === 'ı' || firstCharacter === 'j' || firstCharacter === 'k' || firstCharacter === 'l') {
            return "#9c27b0"
        }
        else if (firstCharacter === 'm' || firstCharacter === 'n' || firstCharacter === 'o' || firstCharacter === 'öç' || firstCharacter === 'p') {
            return "#3f51b5"
        }
        else if (firstCharacter === 'r' || firstCharacter === 's' || firstCharacter === 'ş' || firstCharacter === 't' || firstCharacter === 'u') {
            return "#2196f3"
        }
        else if (firstCharacter === 'ü' || firstCharacter === 'v' || firstCharacter === 'y' || firstCharacter === 'z' || firstCharacter === 'x') {
            return "#e91e63"
        }
        else{
            return "#607d8b"
        }
    }
    return (
        <>
            <div style={{
                backgroundColor: selectColor(),
                height: avatarWidthHeight,
                width: avatarWidthHeight,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: avatarWidthHeight / 2
            }}>
                <span style={{
                    color: '#fff',
                    fontSize: 42,
                    paddingBottom:4
                }}>
                    {text[0].toUpperCase()}
                </span>
            </div>
        </>
    )
}
