import React, { useState } from "react";
import { Modal, Form, Input, message, notification, Row, Col } from "antd";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useApi from "../../hooks/useApi";
import DatePicker from "../toolbox/DatePicker";
import OdemeSekliSelectOption from "../toolbox/OdemeSekliSelectOption";
import KasaSelectOption from "../toolbox/KasaSelectOption";
import BankaHesapSelectOption from "../toolbox/BankaHesapSelectOption";
import CurrencyInput, { setFieldsAutoClear } from "../toolbox/CurrencyInput";
import { odemeSekliTypes } from "../../types/odemeSekliTypes";
import { useParams } from "react-router-dom";

const formSize = "middle";

export default function GelirGiderIslemEkleModal({ visible = false, handleCancel, gelirMi }) {
    const [fetchLoading, setFetchLoading] = useState(false);
    const [selectedDovizId, setSelectedDovizId] = useState()
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const api = useApi();
    const { gelirGiderGrupId } = useParams()
    const localization = strings.gelirGiderIslem.gelirGiderIslemEkleModal;

    const onFinish = async (values) => {
        try {
            setFetchLoading(true);
            const { islemTarihi, odemeSekli, kasaId, bankaHesapId, aciklama, tutar } = values;

            let requestData = {
                GelirMi: gelirMi,
                GelirGiderGrupId: gelirGiderGrupId,
                Tutar: tutar,
                Aciklama: aciklama,
                IslemTarih: islemTarihi,
            }

            if (odemeSekli === odemeSekliTypes.NAKIT) {
                requestData = {
                    ...requestData,
                    DovizId: selectedDovizId,
                    KasaIslem: {
                        KasaId: kasaId,
                        Aciklama: aciklama,
                        VirmanMi: false,
                        GirisMi: true,
                        Tutar: tutar,
                        IslemTarihi: islemTarihi,
                        Carpan: 1
                    }
                }
            }

            if (odemeSekli !== odemeSekliTypes.NAKIT) {
                requestData = {
                    ...requestData,
                    DovizId: selectedDovizId,
                    Bankahesapislem: {
                        BankaHesapId: bankaHesapId,
                        Aciklama: aciklama,
                        VirmanMi: false,
                        GirisMi: true,
                        Tutar: tutar,
                        IslemTarihi: islemTarihi,
                        Carpan: 1
                    }
                }
            }

            await api.post('/gelirgider', requestData)

            notification["success"]({
                message: strings.mesaj.basarliBaslik,
                description: strings.mesaj.islemBasarili,
            });
            handleCancel()
            form.resetFields();
            dispatch(setRerender());

        } catch (error) {
            message.error(error.message);
        } finally {
            setFetchLoading(false);
        }
    };

    return (
        <Modal
            title={(gelirMi ? localization.gelir : localization.gider) + " " + localization.baslik}
            visible={visible}
            onCancel={handleCancel}
            handleCancel={handleCancel}
            okButtonProps={{
                htmlType: "submit",
                form: "gelirGiderIslemEkleModal",
                size: formSize,
                loading: fetchLoading,
            }}
            cancelButtonProps={{ size: formSize }}
            width="50%"
        >
            <Form
                name="gelirGiderIslemEkleModal"
                form={form}
                onFinish={onFinish}
                layout="vertical"
                size={formSize}
            >
                <Row gutter={24}>
                    <Col flex={1}>
                        <Form.Item
                            name="islemTarihi"
                            label={localization.islemTarihi}
                            rules={[{ required: true }]}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            name="odemeSekli"
                            label={localization.odemeSekli}
                            rules={[{ required: true }]}
                        >
                            <OdemeSekliSelectOption hiddenAcikHesap />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.odemeSekli !== currentValues.odemeSekli
                            }
                        >
                            {({ getFieldValue }) => {
                                const currentOdemeSekli = getFieldValue("odemeSekli");

                                if (currentOdemeSekli === odemeSekliTypes.NAKIT) {
                                    return (
                                        <Form.Item
                                            name="kasaId"
                                            label={localization.kasa}
                                            rules={[{ required: true }]}
                                        >
                                            <KasaSelectOption onSelectedKasa={(e) => setSelectedDovizId(e.doviz.id)} requestBody={{}} />
                                        </Form.Item>
                                    )
                                }
                                else {
                                    return (
                                        <Form.Item
                                            name="bankaHesapId"
                                            label={localization.bankaHesap}
                                            rules={[{ required: true }]}
                                        >
                                            <BankaHesapSelectOption onSelectedBankaHesap={(e) => setSelectedDovizId(e.doviz.id)} requestBody={{}} />
                                        </Form.Item>
                                    )
                                }
                            }}
                        </Form.Item>
                    </Col>
                    <Col flex={1}>
                        <Form.Item label={localization.aciklama} name="aciklama">
                            <Input.TextArea rows={5} />
                        </Form.Item>
                        <Form.Item
                            name="tutar"
                            label={localization.tutar}
                            rules={[{ required: true }]}
                        >
                            <CurrencyInput {...setFieldsAutoClear(form, "tutar")} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
