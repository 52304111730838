import React, { useState } from "react";
import { Button, Card, Checkbox, Form, message } from "antd";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import strings from "../../utils/Localization";
import CariKartSelectOption from "../toolbox/CariKartSelectOption";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import moment from "moment";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";
import useApi from "../../hooks/useApi";

export default function CariEkstreRaporForm() {
  const { cariEkstreRaporForm } = strings.cariEkstreRapor.components;
  const [form] = Form.useForm();
  const [fetchLoading, setFetchLoading] = useState(false);
  const { getDefaultSetting } = useVarsayilanDeger();
  const api = useApi();

  const onFinish = async (values) => {
    try {
      const { tarih, cariKartId, dovizId, gecmisBakiyeDahilMi } = values;

      const baslangicTarihi = moment(tarih[0]).format("YYYY-MM-DD");
      const bitisTarihi = moment(tarih[1]).format("YYYY-MM-DD");

      const pdfWindow = window.open(`${document.location.origin}/#/RaporYukleniyor`);
      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/cariekstre",
        {
          BaslangicTarih: `${baslangicTarihi} 00:00:00`,
          BitisTarih: `${bitisTarihi} 23:59:59`,
          CariKartId: cariKartId === undefined ? 0 : cariKartId,
          DovizId: dovizId === undefined ? 0 : dovizId,
          GecmisBakiyeDahilMi: gecmisBakiyeDahilMi,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        pdfWindow.document.location.href = response.data.data;
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          gecmisBakiyeDahilMi: true,
          tarih: [moment().add("months", -12), moment()],
          dovizId: getDefaultSetting("doviz"),
        }}
      >
        <Form.Item
          name="tarih"
          label={cariEkstreRaporForm.tarihSeciniz}
          rules={[{ required: true }]}
        >
          <DatepickerRanger />
        </Form.Item>
        <Form.Item name="cariKartId" rules={[{ required: true }]}>
          <CariKartSelectOption allowClear />
        </Form.Item>
        <Form.Item
          name="dovizId"
          label={strings.dovizSelectOption.dovizBaslik}
          rules={[{ required: true }]}
        >
          <DovizSelectOption allowClear />
        </Form.Item>
        <Form.Item name="gecmisBakiyeDahilMi" valuePropName="checked">
          <Checkbox>{cariEkstreRaporForm.gecmisBakiyeDahilEt}</Checkbox>
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          style={{ float: "right" }}
          size="large"
          loading={fetchLoading}
        >
          {cariEkstreRaporForm.raporAlButon}
        </Button>
      </Form>
    </Card>
  );
}
