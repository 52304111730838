import * as actionTypes from "./actionTypes";

export const setResetDovizList = () => ({
  type: actionTypes.SET_RESET_DOVIZ_LIST,
});

export const setDovizList = (dovizData) => ({
  type: actionTypes.SET_DOVIZ_LIST,
  payload: dovizData,
});
