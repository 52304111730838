import React, { useState } from 'react'
import { Button, Input, Form, Row, Col, Select, message, notification } from 'antd'
import MaskedInput from 'antd-mask-input'
import { useNavigate } from 'react-router-dom'
import strings from '../../utils/Localization'
import useApi from '../../hooks/useApi'
import useKullanici from '../../hooks/useKullanici'
const md5 = require('md5')
const { Option } = Select

export default function KayitOlForm() {
  const [fetchLoading, setFetchLoading] = useState(false)
  const navigate= useNavigate()
  const api = useApi()
  const { setAuthParameters } = useKullanici()
  const { kayitOlForm } = strings.kayitOl.components

  const onFinish = async (values) => {
    try {
      const { vergiNumarasi, firmaAdi, yetkili, telefon, sektor, eMail, sifre } = values
      setFetchLoading(true)
      const response = await api.post('/auth/register', {
        VergiNo: vergiNumarasi,
        FirmaAdi: firmaAdi,
        Email: eMail,
        Sifre: md5(sifre),
        Yetkili: yetkili,
        TelefonNo: telefon,
        Sektor: sektor,
      })
      if (response.status === 200) {
        const { token, defaultSettings, refreshToken } = response.data.singleData
        setAuthParameters({ token, defaultSettings, refreshToken, eMail })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
        navigate('/')
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  return (
    <Form size="middle" name="basic" onFinish={onFinish} labelAlign="left" labelCol={{ span: 8 }}>
      <Row gutter={24}>
        <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={kayitOlForm.vergiNumarasi}
            name="vergiNumarasi"
            rules={[
              { required: true, min: 10 },
              {
                pattern: /^(?:\d*)$/,
                message: kayitOlForm.kurallar.vergiNumarasiSayiUyari,
              },
            ]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label={kayitOlForm.firmaAdi} name="firmaAdi" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label={kayitOlForm.yetkili} name="yetkili" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label={kayitOlForm.telefon} name="telefon" rules={[{ required: true }]}>
            <MaskedInput mask="(111) 111 11 11" />
          </Form.Item>
        </Col>
        <Col xxl={12} xl={12} md={12} sm={24} xs={24}>
          <Form.Item label={kayitOlForm.sektor} name="sektor" rules={[{ required: true }]}>
            <Select placeholder={kayitOlForm.sektorPlaceHolder}>
              <Option value="Akademik">Akademik</Option>
              <Option value="Anahtarcı ve Çilingir">Anahtarcı ve Çilingir</Option>
              <Option value="Bankacılık/Finans">Bankacılık/Finans</Option>
              <Option value="Bilgisayar">Bilgisayar</Option>
              <Option value="İnternet">İnternet</Option>
              <Option value="İnternet Cafe">İnternet Cafe</Option>
              <Option value="Yazılım">Yazılım</Option>
              <Option value="Bilgi İşlem Hiz.">Bilgi İşlem Hiz.</Option>
              <Option value="Biyoteknoloji">Biyoteknoloji</Option>
              <Option value="Danışmanlık">Danışmanlık</Option>
              <Option value="Eğitim">Eğitim</Option>
              <Option value="Elektrik/Elektronik">Elektrik/Elektronik</Option>
              <Option value="Enerji">Enerji</Option>
              <Option value="Gayrimenkul">Gayrimenkul</Option>
              <Option value="Gıda">Gıda</Option>
              <Option value="Giyim">Giyim</Option>
              <Option value="Sanayi">Sanayi</Option>
              <Option value="Güvenlik">Güvenlik</Option>
              <Option value="Halkla İlişkiler">Halkla İlişkiler</Option>
              <Option value="Hızlı Tüketim Malları">Hızlı Tüketim Malları</Option>
              <Option value="Hizmet">Hizmet</Option>
              <Option value="Holding">Holding</Option>
              <Option value="Hukuk">Hukuk</Option>
              <Option value="İlaç Endüstrisi">İlaç Endüstrisi</Option>
              <Option value="İnsan Kaynakları">İnsan Kaynakları</Option>
              <Option value="İnşaat">İnşaat</Option>
              <Option value="İthalat/İhracat">İthalat/İhracat</Option>
              <Option value="Kimya">Kimya</Option>
              <Option value="Kozmetik">Kozmetik</Option>
              <Option value="Kırtasiye">Kırtasiye</Option>
              <Option value="Madencilik">Madencilik</Option>
              <Option value="Mağazacılık">Mağazacılık</Option>
              <Option value="Medya">Medya</Option>
              <Option value="Mimarlık">Mimarlık</Option>
              <Option value="Mobilyacılık/Ağaç İşleri">Mobilyacılık/Ağaç İşleri</Option>
              <Option value="Muhasebe">Muhasebe</Option>
              <Option value="Mühendislik">Mühendislik</Option>
              <Option value="Otelcilik">Otelcilik</Option>
              <Option value="Otomotiv">Otomotiv</Option>
              <Option value="Perakende/Toptan">Perakende/Toptan</Option>
              <Option value="Personel">Personel</Option>
              <Option value="Petrol/Petrokimya">Petrol/Petrokimya</Option>
              <Option value="Reklamcılık">Reklamcılık</Option>
              <Option value="Restaurant">Restaurant</Option>
              <Option value="Sağlık/Tip">Sağlık/Tip</Option>
              <Option value="Satınalma">Satınalma</Option>
              <Option value="Satış/Pazarlama">Satış/Pazarlama</Option>
              <Option value="Sigorta">Sigorta</Option>
              <Option value="Sosyal Hizmetler">Sosyal Hizmetler</Option>
              <Option value="Tarım/Orman/Balıkçılık">Tarım/Orman/Balıkçılık</Option>
              <Option value="Tasarım/Grafik Tasarım">Tasarım/Grafik Tasarım</Option>
              <Option value="Taşımacılık/Kurye">Taşımacılık/Kurye</Option>
              <Option value="Tekstil">Tekstil</Option>
              <Option value="Ticaret">Ticaret</Option>
              <Option value="Telekomünikasyon">Telekomünikasyon</Option>
              <Option value="Turizm">Turizm</Option>
              <Option value="Ulaştırma">Ulaştırma</Option>
              <Option value="Üretim/İmalat">Üretim/İmalat</Option>
            </Select>
          </Form.Item>
          <Form.Item label={kayitOlForm.eMail} name="eMail" rules={[{ required: true }, { type: 'email' }]}>
            <Input />
          </Form.Item>

          <Form.Item label={kayitOlForm.sifre} name="sifre" rules={[{ required: true }, { min: 6 }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={kayitOlForm.sifreTekrar}
            name="sifreTekrar"
            dependencies={['sifre']}
            rules={[
              { required: true, message: kayitOlForm.kurallar.sifreZorunlu },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('sifre') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(kayitOlForm.kurallar.sifreOnay)
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit" size="large" style={{ float: 'right' }} loading={fetchLoading}>
        {kayitOlForm.kayitOlButon}
      </Button>
    </Form>
  )
}
