import React, { useState } from 'react'
import { Button, message, notification, Popconfirm, Space, Tag, Form, Row, Col, Radio, Card, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import strings from '../../utils/Localization'
import FetchListContent from '../toolbox/FetchListContent'
import moment from 'moment'
import { useParams, useNavigate } from 'react-router-dom'
import VaryantliStokIslemDuzenleModal from '../varyantliStokIslem/VaryantliStokIslemDuzenleModal'
import { useDispatch } from 'react-redux'
import { setRerender } from '../../redux/actions/rerenderAction'
import { SearchOutlined, ClearOutlined } from '@ant-design/icons'
import DatepickerRanger from '../toolbox/DatepickerRanger'
import DepoSelectOption from '../toolbox/DepoSelectOption'
import StokVaryantGrupSelectOption from '../toolbox/StokVaryantGrupSelectOption'
import CurrencyInput from '../toolbox/CurrencyInput'
import useCurrency from '../../hooks/useCurrency'
import useApi from '../../hooks/useApi'

function StokIslemListe() {
  const { stokId } = useParams()
  const navigate= useNavigate()
  const { paraFormat } = useCurrency()
  const api = useApi()

  const initialFilterRequestData = {
    Data: {
      StokId: stokId,
      StokVaryantGrupIds: [],
      StokIslem: {
        GirisMi: null,
        StokDepoId: null,
      },
    },
    BetweenOptions: [
      {
        PropertyName: 'Miktar',
        First: null,
        Last: null,
      },
    ],
    DateOptions: [
      {
        DatePropertyName: 'IslemTarihi',
        FirstDate: null,
        LastDate: null,
      },
    ],
  }
  const [filterRequestData, setFilterRequestData] = useState({
    ...initialFilterRequestData,
  })
  const [detailSearchForm] = Form.useForm()
  const [dataSource, setDataSource] = useState([])
  const [islemDuzenleModalVisible, setIslemDuzenleModalVisible] = useState(false)
  const [duzenleData, setDuzenleData] = useState(null)
  const { stokIslemListe: localization, detayliArama } = strings.varyantliStokDetay.components
  const dispatch = useDispatch()

  const columns = [
    {
      title: localization.islemTarihi,
      dataIndex: 'islemTarihi',
      key: 'islemTarihi',
      sorter: (a, b) => a.islemTarihi.length - b.islemTarihi.length,
      render: (text) => moment(text).format('DD.MM.YYYY'),
      defaultSortOrder: 'descend',
    },
    {
      title: localization.aciklama,
      dataIndex: 'aciklama',
      key: 'aciklama',
      render: (text, row, index) => (
        <>
          {row.faturaSatirId !== null && <Tag color="geekblue">{localization.faturaIslemi}</Tag>}
          {` ${text !== null ? text : ''}`}
        </>
      ),
    },
    {
      title: localization.depo,
      dataIndex: 'stokDepoId',
      key: 'stokDepoId',
      render: (text, row, index) => row.StokDepo.ad,
    },
    {
      title: localization.birimFiyat,
      dataIndex: 'birimFiyat',
      key: 'birimFiyat',
      render: (text, row, index) => ` ${row.Doviz.simge}${paraFormat(row.birimFiyat)}`,
    },
    {
      title: localization.miktar,
      dataIndex: 'miktar',
      key: 'miktar',
      sorter: (a, b) => a.miktar - b.miktar,
      render: (text, row, index) => {
        return (
          <Tag
            style={{
              fontSize: 14,
              padding: 4,
              paddingRight: 8,
              paddingLeft: 8,
            }}
            color={row.girisMi ? 'green' : 'red'}
          >
            {`${row.girisMi ? '+' : '-'}${text} ${row.StokVaryantGrup.Stok.StokBirim.ad}`}
          </Tag>
        )
      },
    },
    {
      title: localization.varyantGrup,
      dataIndex: 'varyantGrup',
      key: 'varyantGrup',
      render: (text, row, index) => {
        return (
          <Tag
            style={{
              fontSize: 14,
              padding: 4,
              paddingRight: 8,
              paddingLeft: 8,
            }}
            color={'blue'}
          >
            {row.StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger.map((item) => item.StokVaryantDeger.ad).join('-')}
          </Tag>
        )
      },
    },
    {
      title: localization.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={localization.stokIslemDetay}>
            <Button
              type="default"
              shape="circle"
              icon={<SearchOutlined />}
              size={'large'}
              onClick={() => navigate(`/StokIslemDetay/${row.id}`)}
            />
          </Tooltip>
          <Tooltip title={localization.stokIslemDuzenle}>
            <Button
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
              onClick={() => {
                if (row.faturaSatirId !== null) {
                  return notification['warning']({
                    message: strings.mesaj.stokIslemFaturaDuzenleme,
                  })
                }
                setDuzenleData(row)
                setIslemDuzenleModalVisible(true)
              }}
            />
          </Tooltip>
          <Tooltip title={localization.stokIslemSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => {
                if (row.faturaSatirId !== null) {
                  message.error({
                    content: strings.mesaj.stokIslemSilme,
                    style: { marginTop: '20vh' },
                  })
                } else remove(row.id)
              }}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const remove = async (stokIslemId) => {
    try {
      const response = await api.delete(`/stokislem/${stokIslemId}`, {})
      if (response.status === 200) {
        dispatch(setRerender())
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  const detayliAramaOnFinish = async (props) => {
    const { islemTarihi, depoId, stokVaryantGrupId, islemTipi, minMiktar, maxMiktar } = props

    let newData = { ...initialFilterRequestData }

    newData = {
      Data: {
        StokId: stokId,
        StokVaryantGrupIds: stokVaryantGrupId === undefined ? [] : [stokVaryantGrupId],
        Stokislem: {
          StokDepoId: depoId !== undefined ? depoId : null,
          GirisMi: islemTipi !== undefined ? islemTipi : null,
        },
      },
      BetweenOptions: [
        {
          PropertyName: 'Miktar',
          First: (minMiktar !== undefined) & (minMiktar !== 0) ? minMiktar : null,
          Last: (maxMiktar !== undefined) & (maxMiktar !== 0) ? maxMiktar : null,
        },
      ],
      DateOptions: [
        {
          DatePropertyName: 'IslemTarihi',
          FirstDate: islemTarihi !== undefined ? `${moment(islemTarihi[0]).format('YYYY-MM-DD')} 00:00:00` : null,
          LastDate: islemTarihi !== undefined ? `${moment(islemTarihi[1]).format('YYYY-MM-DD')} 23:59:59` : null,
        },
      ],
    }

    await setFilterRequestData(newData)
    dispatch(setRerender())
  }
  const detayliAramaReset = async () => {
    await setFilterRequestData({ ...initialFilterRequestData })
    detailSearchForm.resetFields()

    dispatch(setRerender())
  }
  return (
    <>
      <FetchListContent
        columns={columns}
        dataSource={dataSource}
        onDataSource={(e) => setDataSource(e)}
        requestUrl="/stokislem/getstokislemlist"
        requestBody={{
          SortOptions: {
            ColumnName: 'IslemTarihi',
            SortType: 'Desc',
          },
          Data: {
            StokId: stokId,
          },
          SelectColumns: [
            {
              ColumnNames: [
                'id',
                'stokVaryantGrupId',
                'miktar',
                'aciklama',
                'girisMi',
                'islemTarihi',
                'evrakNo',
                'stokDepoId',
                'stokDepoId',
                'faturaSatirId',
                'birimFiyat',
              ],
              TableName: '',
            },
            {
              ColumnNames: ['id', 'ad'],
              TableName: 'StokVaryantGrup.Stok.StokBirim',
            },
            {
              ColumnNames: ['id', 'ad'],
              TableName: 'StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger',
            },
            {
              TableName: 'StokDepo',
              ColumnNames: ['id', 'ad'],
            },
            {
              TableName: 'Doviz',
              ColumnNames: ['id', 'ad', 'simge', 'kod'],
            },
          ],
          ...filterRequestData,
        }}
        detailSearch={
          <Card title={detayliArama.baslik}>
            <Form
              form={detailSearchForm}
              name="detailSearchForm"
              layout="vertical"
              onFinish={detayliAramaOnFinish}
              initialValues={{
                islemTipi: null,
              }}
            >
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name="islemTarihi" label={detayliArama.islemTarihi}>
                    <DatepickerRanger />
                  </Form.Item>
                  <Form.Item name="stokVaryantGrupId" label={detayliArama.varyantGrup}>
                    <StokVaryantGrupSelectOption stokId={stokId} />
                  </Form.Item>
                  <Form.Item label={detayliArama.depo} name="depoId">
                    <DepoSelectOption />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Space direction="vertical" align="center" style={{ width: '100%' }}>
                    <Form.Item name="islemTipi" label={detayliArama.islemTipi}>
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio value={null}>{detayliArama.tumIslemTipleriniGoster}</Radio>
                          <Radio value={true}>{detayliArama.girisIslemleriniGoster}</Radio>
                          <Radio value={false}>{detayliArama.cikisIslemleriniGoster}</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space direction="vertical">
                    <Form.Item name="minMiktar" label={detayliArama.minMiktar}>
                      <CurrencyInput />
                    </Form.Item>
                    <Form.Item name="maxMiktar" label={detayliArama.maxMiktar}>
                      <CurrencyInput />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
              <Space align="baseline" style={{ float: 'right' }}>
                <Button
                  type="default"
                  size="middle"
                  icon={<ClearOutlined />}
                  style={{ float: 'right' }}
                  onClick={detayliAramaReset}
                >
                  {detayliArama.temizle}
                </Button>
                <Button
                  type="default"
                  size="middle"
                  icon={<SearchOutlined />}
                  style={{ float: 'right' }}
                  form="detailSearchForm"
                  htmlType="submit"
                >
                  {detayliArama.aramayiBaslat}
                </Button>
              </Space>
            </Form>
          </Card>
        }
      />
      <VaryantliStokIslemDuzenleModal
        visible={islemDuzenleModalVisible}
        handleCancel={() => setIslemDuzenleModalVisible(false)}
        data={duzenleData}
      />
    </>
  )
}

export default StokIslemListe
