import React, { useState } from 'react'
import { Button, Space, Card, Popconfirm, message, notification, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import CursorTableItem from '../toolbox/CursorTableItem'
import strings from '../../utils/Localization'
import FetchListContent from '../toolbox/FetchListContent'
import StokKatergoriEkleModal from '../stokKategori/StokKatergoriEkleModal'
import StokKategoriDuzenleModal from '../stokKategori/StokKategoriDuzenleModal'
import useApi from '../../hooks/useApi'

export default function StokKategoriListe() {
  const [dataSource, setDataSource] = useState({})
  const [stokKategoriEkleModalVisible, setStokKategoriEkleModalVisible] = useState(false)
  const [stokKategoriDuzenleModalVisible, setStokKategoriDuzenleModalVisible] = useState(false)
  const [duzenleData, setDuzenleData] = useState({})
  const { stokKategoriListe } = strings.stokKategoriListe.components
  const api = useApi()

  const columns = [
    {
      title: stokKategoriListe.kategoriAd,
      dataIndex: 'ad',
      key: 'ad',
      render: (text, row, index) => <CursorTableItem hideHand text={`${text} (${row.stokSayisi})`} />,
    },
    {
      title: stokKategoriListe.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={stokKategoriListe.stokKategoriDuzenle}>
            <Button
              onClick={() => {
                setDuzenleData(row)
                setStokKategoriDuzenleModalVisible(true)
              }}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={stokKategoriListe.stokKategoriSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => {
                if (row.stokSayisi > 0) {
                  message.error({
                    content: strings.mesaj.stokKategoriSilme,
                    style: { marginTop: '20vh' },
                  })
                } else remove(row.id)
              }}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const remove = async (stokKategoriId) => {
    try {
      const response = await api.delete(`/stokkategori/${stokKategoriId}`, {})
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== stokKategoriId),
        })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  return (
    <>
      <Card
        title={stokKategoriListe.baslik}
        extra={[
          <Button
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => setStokKategoriEkleModalVisible(true)}
          >
            {stokKategoriListe.stokKategoriEkleButon}
          </Button>,
        ]}
      >
        <FetchListContent
          columns={columns}
          requestUrl="/stokkategori/get"
          onDataSource={(e) => setDataSource(e)}
          dataSource={dataSource}
          requestBody={{
            SelectColumns: [
              {
                TableName: '',
                ColumnNames: ['ad', 'id'],
              },
              {
                TableName: 'Stok:stokSayisi',
                Operator: 'Count',
              },
            ],
          }}
        />
      </Card>
      <StokKategoriDuzenleModal
        visible={stokKategoriDuzenleModalVisible}
        handleCancel={() => setStokKategoriDuzenleModalVisible(false)}
        data={duzenleData}
      />
      <StokKatergoriEkleModal
        visible={stokKategoriEkleModalVisible}
        handleCancel={() => setStokKategoriEkleModalVisible(false)}
      />
    </>
  )
}
