import { Form, Input, message, Modal } from 'antd'
import React, { useState } from 'react'
import useApi from '../../hooks/useApi'
import strings from '../../utils/Localization'
import CariKartSelectOption from '../toolbox/CariKartSelectOption'

function EFaturaGelenKaydetModal({ visible = false, handleCancel, selectedFatura, onSuccess }) {
  const [fetchLoading, setFetchLoading] = useState(false)
  const [form] = Form.useForm()
  const api = useApi()
  const localization = strings.eFaturaKaydetModal

  const onFinish = async (values) => {
    try {
      setFetchLoading(true)
      const { cariKartId } = values
      const response = await api.post('/fatura/saveefatura', {
        InvoiceEttn: selectedFatura?.ettn,
        CariKartId: cariKartId,
      })
      if (response.status === 200) {
        Modal.success({
          title: strings.mesaj.basarliBaslik,
          content: strings.mesaj.islemBasarili,
          onOk: () => {
            form.resetFields()
            onSuccess(true)
            handleCancel()
          },
        })
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  return (
    <Modal
      title={localization.title}
      visible={visible}
      onCancel={() => {
        form.resetFields()
        handleCancel()
      }}
      okButtonProps={{
        htmlType: 'submit',
        form: 'eFaturaKaydetModal',
        loading: fetchLoading,
      }}
    >
      <Form form={form} onFinish={onFinish} name="eFaturaKaydetModal" labelCol={{ span: 6 }} labelAlign="left">
        <Form.Item label={localization.faturaNo}>
          <Input disabled value={selectedFatura?.docNo} />
        </Form.Item>
        <Form.Item name={'cariKartId'} rules={[{ required: true }]} label={localization.cariKartSecimi}>
          <CariKartSelectOption />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EFaturaGelenKaydetModal
