import React, { useState, useEffect } from 'react'
import { Form, Input, message, notification, InputNumber, Button, Row, Col, Spin, Space, PageHeader, Card } from 'antd'
import strings from '../utils/Localization'
import useApi from '../hooks/useApi'
import StokSelectOption from '../components/toolbox/StokSelectOption'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import StokVaryantGrupSelectOption from '../components/toolbox/StokVaryantGrupSelectOption'
import { SaveOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from 'react-router-dom'

const formSize = 'middle'

const minusIconStyle = { alignItems: 'center', justifyContent: 'center', display: 'flex' }

const UretimReceteDuzenle = () => {
    const [selectedStok, setSelectedStok] = useState({ Stokvaryantgrup: [] })
    const [fetchReceteLoading, setFetchReceteLoading] = useState(false)
    const [saveFetchLoading, setSaveFetchLoading] = useState(false)
    const [firstRender, setFirstRender] = useState(true)

    const [form] = Form.useForm()
    const api = useApi()
    const navigate = useNavigate()
    const { receteId } = useParams()

    const localization = strings.uretimReceteDuzenle

    useEffect(() => {
        if (receteId) {
            fetchRecete(receteId)
        }
    }, [receteId])// eslint-disable-line react-hooks/exhaustive-deps


    const onFinish = async (values) => {
        try {
            let stokVaryantGrupId;

            if (selectedStok.Stokvaryantgrup.length > 1) {//ürünün 1'den fazla varyantı varmı kontrolü
                stokVaryantGrupId = values.stokVaryantGrupId
            }
            else {
                stokVaryantGrupId = selectedStok.Stokvaryantgrup[0].id
            }

            //malzeme listesi oluşturma
            const uretimrecetemalzeme = values.uretimrecetemalzeme.filter(x => x?.hidden !== true).map(item => {
                let malzemeStokVaryantGrupId;
                const { selectedStok, stokVaryantGrupId, miktar } = item

                if (selectedStok.Stokvaryantgrup.length > 1) {
                    malzemeStokVaryantGrupId = stokVaryantGrupId
                }
                else {
                    malzemeStokVaryantGrupId = selectedStok.Stokvaryantgrup[0].id
                }
                return {
                    ...item,
                    stokVaryantGrupId: malzemeStokVaryantGrupId,
                    miktar: miktar
                }
            })

            setSaveFetchLoading(true)
            const response = await api.put('/uretimrecete', {
                Id: values.id,
                StokVaryantGrupId: stokVaryantGrupId,
                Ad: values.ad,
                Aciklama: values.aciklama,
                Uretimrecetemalzeme: uretimrecetemalzeme
            })

            if (response.status === 200) {
                notification['success']({
                    message: strings.mesaj.basarliBaslik,
                    description: strings.mesaj.islemBasarili,
                })

                navigate("/Uretim", {
                    state: {
                        isRecete: true
                    }
                })

            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setSaveFetchLoading(false)
        }
    }

    const fetchRecete = async (receteId) => {
        try {
            setFetchReceteLoading(true)
            const response = await api.post('/uretimrecete/get', {
                Data: {
                    Id: receteId,
                },
                SelectColumns: [
                    {
                        ColumnNames: ['id', 'ad', 'stokVaryantGrupId', 'aciklama'],
                        TableName: '',
                    },
                    {
                        ColumnNames: ['id', 'stokId'],
                        TableName: 'stokVaryantGrup',
                    },
                    {
                        ColumnNames: ['id', 'stokVaryantGrupId', 'miktar'],
                        TableName: 'uretimrecetemalzeme',
                    },
                    {
                        ColumnNames: ['id', 'stokId'],
                        TableName: 'uretimrecetemalzeme.StokVaryantGrup',
                    },
                ],
            })

            if (response.status === 200) {
                const data = response.data.data[0]
                form.setFieldsValue({
                    id: data.id,
                    ad: data.ad,
                    aciklama: data.aciklama,
                    stokId: data.stokVaryantGrup.stokId,
                    stokVaryantGrupId: String(data.stokVaryantGrupId),
                    uretimrecetemalzeme: data.uretimrecetemalzeme.map((item) => ({
                        id: item.id,
                        stokId: item.StokVaryantGrup.stokId,
                        stokVaryantGrupId: String(item.stokVaryantGrupId),
                        miktar: item.miktar
                    }))
                })
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setFetchReceteLoading(false)
        }
    }

    const onSelectedStok = (e) => {
        setSelectedStok(e)
        if (!firstRender) {//ilk derleme ise 1'den fazla varyant var ise seçili varyantı silmeme kontrolü
            form.setFieldsValue({ stokVaryantGrupId: undefined })
        }
        setFirstRender(false)

    }

    return (
        <Space style={{ width: '100%' }} direction="vertical" size={"large"} >
            <PageHeader onBack={() => navigate(-1)} ghost={false} title={localization.pageHeader.baslik}
                extra={[
                    <Button loading={saveFetchLoading} form="uretimReceteDuzenle" htmlType="submit" icon={<SaveOutlined />} type="primary" size="large">{localization.pageHeader.kaydetButon}</Button>
                ]}
            />
            <Spin spinning={fetchReceteLoading}>

                <Form name="uretimReceteDuzenle" form={form} onFinish={onFinish} layout="vertical" size={formSize}>
                    <Card>
                        <Form.Item name="id" hidden />
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item name="stokId" label={localization.uretilecekUrun} rules={[{ required: true }]}>
                                    <StokSelectOption onSelectedStok={onSelectedStok} />
                                </Form.Item>
                                {selectedStok.Stokvaryantgrup.length > 1 && (
                                    <Form.Item label={localization.uretilecekUrunVaryanti} name={"stokVaryantGrupId"} rules={[{ required: true }]}>
                                        <StokVaryantGrupSelectOption stokId={selectedStok.id} />
                                    </Form.Item>
                                )}
                                <Form.Item name="ad" label={localization.urunAdi} rules={[{ required: true }]}>
                                    <Input placeholder={localization.adPlaceholder} />
                                </Form.Item>
                                <Form.Item name="aciklama" label={localization.aciklama}>
                                    <Input.TextArea rows={4} placeholder={localization.aciklamaPlaceHolder} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.List name="uretimrecetemalzeme">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name }) => (
                                                <UretimReceteMalzemeItem name={name} form={form} />
                                            ))}
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </Spin>
        </Space>
    )
}

export default UretimReceteDuzenle

const UretimReceteMalzemeItem = ({ name, form }) => {
    const [selectedStok, setSelectedStok] = useState({ Stokvaryantgrup: [] })
    const [firstRender, setFirstRender] = useState(true)
    const [satirHidden, setSatirHidden] = useState(false)
    const localization = strings.uretimReceteDuzenle


    const onSelectedStok = (e) => {
        setSelectedStok(e)
        if (firstRender) {//ilk derleme ise 1'den fazla varyant var ise seçili varyantı silmeme kontrolü
            form.setFields([
                {
                    name: ["uretimrecetemalzeme", name, "selectedStok"],
                    value: e
                }
            ])
        }
        else {
            form.setFields([
                {
                    name: ["uretimrecetemalzeme", name, "selectedStok"],
                    value: e
                },
                {
                    name: ["uretimrecetemalzeme", name, "stokVaryantGrupId"],
                    value: undefined
                }
            ])
        }

        setFirstRender(false)
    }

    const remove = () => {
        setSatirHidden(true)
        form.setFields([{ name: ['uretimrecetemalzeme', name, 'hidden'], value: true }])
    }

    if (satirHidden) {
        return null
    }

    return (
        <Row gutter={24} >
            <Form.Item name={[name, 'id']} hidden />
            <Col span={16}>
                <Form.Item
                    name={[name, 'stokId']}
                    rules={[{ required: true }]}
                    label={localization.uretimdeKullanilacakUrun}
                >
                    <StokSelectOption onSelectedStok={onSelectedStok} />
                </Form.Item>
                {selectedStok.Stokvaryantgrup.length > 1 && (
                    <Form.Item label={localization.uretimdeKullanilacakUrunVaryanti} name={[name, "stokVaryantGrupId"]} rules={[{ required: true }]}>
                        <StokVaryantGrupSelectOption stokId={selectedStok.id} />
                    </Form.Item>
                )}
            </Col>
            <Col span={6}>
                <Form.Item
                    name={[name, 'miktar']}
                    rules={[{ required: true }]}
                    label={localization.miktar}
                    initialValue={1}
                >
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={2} style={minusIconStyle}>
                <MinusCircleOutlined onClick={() => remove(name)} />
            </Col>
        </Row>
    )
}
