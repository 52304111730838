import React, { useEffect, useState } from 'react'
import { Modal, Form, message, Radio, Select, Spin, Result, Typography } from 'antd'
import strings from '../../utils/Localization'
import { useDispatch } from 'react-redux'
import { setRerender } from '../../redux/actions/rerenderAction'
import useApi from '../../hooks/useApi'
import useKullanici from '../../hooks/useKullanici'
import { eFaturaTypes, senderTypes } from '../../types/eFaturaTypes'

const E_ARVIS_VERGI_NO = '1111111111'
const initialKullaniciMusteriMukellefData = {
  musteri: { firmaAdi: '', vergiNo: '', pkAlias: [], gbAlias: [] },
  kullanici: { firmaAdi: '', vergiNo: '', pkAlias: [], gbAlias: [] },
}
const formSize = 'middle'

function EFaturaEkleModal({ visible = false, handleCancel, musteriVergiNo, faturaId }) {
  const [fetchLoading, setFetchLoading] = useState(false)
  const [kullaniciMusteriMukellefData, setKullaniciMusteriMukellefData] = useState({
    ...initialKullaniciMusteriMukellefData,
  })
  const [invoiceSeriNoData, setInvoiceSeriNoData] = useState([])
  const [spinning, setSpinning] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { getVergiNumarasi } = useKullanici()
  const api = useApi()
  const localization = strings.eFaturaEkleModal
  const eArsivMi = musteriVergiNo === E_ARVIS_VERGI_NO

  const onFinish = async (values) => {
    try {
      setFetchLoading(true)
      const { gbAlias, prefix, invoiceType, pkAlias, profile, senderType } = values

      const response = await api.post('/fatura/createefatura', {
        FaturaId: faturaId, //bizim sistemdeki faturanın idsi
        DocumentType: eArsivMi ? eFaturaTypes.E_ARSIV_FATURA : eFaturaTypes.E_FATURA, //EARSIVFATURA//EFATURA
        Profile: profile, //TEMELFATURA//TICARIFATURA//EARSIVFATURA//
        SenderType: senderType, //ELEKTRONIK//KAGIT//(E-Arşiv fatura için kullanılan gönderim tipi bilgisidir.)
        GbAlias: gbAlias, //bizim kullanıcımızın mükellef sorguladan dönen gbaliaslardan seçilen boş gönderilirse sistemde default tanımlı olan alınır
        PkAlias: pkAlias, //eğer efatura ise earsiv değilse karşı tarafın mükellef sorguladan dönen pkaliaslardan seçilmeli
        InvoiceType: invoiceType, //SATIS//IADE//(Fatura tipi 'IADE' ise,profile değeri 'TEMELFATURA' olmalıdır.)//şimdilik sadece satıs gönderelim diğerlerini kullanmayalım
        Prefix: prefix,
      })

      if (response.status === 200) {
        Modal.success({
          message: strings.mesaj.basarliBaslik,
          content: strings.mesaj.islemBasarili,
          onOk: () => {
            handleCancel()
            dispatch(setRerender())
          },
        })
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  useEffect(() => {
    if (visible) {
      if (!eArsivMi) {
        fetchEFaturaData()
      } else {
        fetchEArsivData()
      }
    } else {
      setKullaniciMusteriMukellefData({ ...initialKullaniciMusteriMukellefData })
      setSpinning(true)
      setErrorMessage('')
      form.resetFields()
    }
  }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchEFaturaData = async () => {
    try {
      setSpinning(true)
      const musteriData = await musteriMukellefSorgula()
      const kullaniciData = await kullaniciMukellefSorgula()
      const invoiceSeriNoData = await fetchEFaturaSeriNo()

      setKullaniciMusteriMukellefData({ kullanici: kullaniciData, musteri: musteriData })
      setInvoiceSeriNoData(invoiceSeriNoData)
      form.setFieldsValue({
        gbAlias: kullaniciData?.gbAlias?.[0],
        pkAlias: musteriData?.pkAlias?.[0],
        profile: eFaturaTypes.TEMEL_FATURA,
      })
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setSpinning(false)
    }
  }
  const fetchEArsivData = async () => {
    try {
      setSpinning(true)
      const kullaniciData = await kullaniciMukellefSorgula()
      const invoiceSeriNoData = await fetchEFaturaSeriNo()

      setKullaniciMusteriMukellefData({ kullanici: kullaniciData, musteri: {} })
      setInvoiceSeriNoData(invoiceSeriNoData)
      form.setFieldsValue({
        gbAlias: kullaniciData?.gbAlias?.[0],
        pkAlias: '',
        profile: eFaturaTypes.E_ARSIV_FATURA,
        senderType: senderTypes.ELEKTRONIK,
      })
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setSpinning(false)
    }
  }

  const musteriMukellefSorgula = async () => {
    const response = await api.post(`/fatura/mukellefsorgula/${musteriVergiNo}`)
    return response.data.data
  }

  const kullaniciMukellefSorgula = async () => {
    const response = await api.post(`/fatura/mukellefsorgula/${getVergiNumarasi}`)
    return response.data.data
  }

  const fetchEFaturaSeriNo = async () => {
    const response = await api.post(`/efaturaseri/get`, {
      Data: {
        Tip: eArsivMi ? 'EARSIV' : 'EFATURA', //EARSIV//EFATURA
      },
      SelectColumns: [
        {
          TableName: '',
          ColumnNames: ['Id', 'ad', 'tip'],
        },
      ],
    })
    return response.data.data
  }

  return (
    <Modal
      title={`${eArsivMi ? localization.eArsiv : localization.eFatura} ${localization.title}`}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: 'submit',
        form: 'eFaturaEkleModal',
        size: formSize,
        loading: fetchLoading,
        disabled: errorMessage,
      }}
      okText={localization.okText}
      cancelButtonProps={{ size: formSize }}
    >
      <Spin spinning={spinning}>
        {errorMessage ? (
          <Result status="warning" subTitle={<Typography.Text>{errorMessage}</Typography.Text>} />
        ) : (
          <Form
            name="eFaturaEkleModal"
            form={form}
            onFinish={onFinish}
            layout="horizontal"
            size={formSize}
            wrapperCol={{ span: 13 }}
            labelCol={{ span: 6 }}
            labelAlign="left"
          >
            <Form.Item name="profile" label={localization.senaryo} rules={[{ required: true }]}>
              <Radio.Group>
                {eArsivMi ? (
                  <Radio.Button value={eFaturaTypes.E_ARSIV_FATURA}>{localization.eArsivFatura}</Radio.Button>
                ) : (
                  <>
                    <Radio.Button value={eFaturaTypes.TICARI_FATURA}>{localization.ticariEFatura}</Radio.Button>
                    <Radio.Button value={eFaturaTypes.TEMEL_FATURA}>{localization.temelEFatura}</Radio.Button>
                  </>
                )}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={'invoiceType'}
              label={localization.faturaTip}
              initialValue={'SATIS'}
              rules={[{ required: true }]}
            >
              <Select>
                <Select.Option value={'SATIS'}>{localization.satisFaturasi}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name={'gbAlias'} label={localization.gondericiBirim} rules={[{ required: true }]}>
              <Select>
                {kullaniciMusteriMukellefData?.kullanici?.gbAlias.map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {eArsivMi && (
              <Form.Item name={'senderType'} label={localization.gonderimTipi} rules={[{ required: true }]}>
                <Select>
                  <Select.Option value={senderTypes.ELEKTRONIK}>{localization.elektronik}</Select.Option>
                  <Select.Option value={senderTypes.KAGIT}>{localization.kagit}</Select.Option>
                </Select>
              </Form.Item>
            )}
            {!eArsivMi && (
              <Form.Item name={'pkAlias'} label={localization.postaKutusu} rules={[{ required: true }]}>
                <Select>
                  {kullaniciMusteriMukellefData.musteri.pkAlias.map((item, index) => (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item name={'prefix'} label={localization.faturaSeriNo}>
              <Select>
                {invoiceSeriNoData.map((item, index) => (
                  <Select.Option key={index} value={item.ad}>
                    {item.ad}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </Modal>
  )
}

export default EFaturaEkleModal
