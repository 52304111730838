import React, { useEffect, useState } from "react";
import { message, Select, SelectProps } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";

const { Option } = Select;

interface IProps extends SelectProps<any> {}

const CariGrupSelectOption: React.FC<IProps> = ({ placeholder, ...rest }) => {
  const [cariGrupData, setCariGrupData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { cariGrupPlaceHolder }: any = strings.cariGrupSelectOption;
  const api = useApi();

  useEffect(() => {
    fetchCariGrup();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCariGrup = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/carigrup/get", {});
      if (response.status === 200) {
        setCariGrupData(response.data.data);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Select
      {...rest}
      placeholder={placeholder || cariGrupPlaceHolder}
      loading={fetchLoading}
    >
      {cariGrupData.map((item: any, index) => (
        <Option key={index} value={String(item.id)}>
          {item.ad}
        </Option>
      ))}
    </Select>
  );
};

export default CariGrupSelectOption;
