import { Routes, Route, Navigate } from 'react-router-dom'
import { OdemeLayout, PanelLayout, SifremiUnuttumLayout } from './layout'

//Kimlik Doğrulama
import GirisYap from './screens/GirisYap'
import KayitOl from './screens/KayitOl'
import SifremiUnuttumMailDogrulama from './screens/SifremiUnuttumMailDogrulama'
import SifremiUnuttumKodDogrulama from './screens/SifremiUnuttumKodDogrulama'
import SifremiUnuttumYeniSifre from './screens/SifremiUnuttumYeniSifre'

import Panel from './screens/Panel'

//CariKart
import Cari from './screens/Cari'
import CariKartDetay from './screens/CariKartDetay'
import CariIslemDetay from './screens/CariIslemDetay'

//Fatura
import Fatura from './screens/Fatura'
import FaturaEkle from './screens/FaturaEkle'
import FaturaDuzenle from './screens/FaturaDuzenle'
import FaturaRapor from './screens/FaturaRapor'

//Teklif Siparis
import TeklifSiparis from './screens/TeklifSiparis'
import TeklifSiparisEkle from './screens/TeklifSiparisEkle'
import TeklifSiparisDuzenle from './screens/TeklifSiparisDuzenle'

//Stok
import Stok from './screens/Stok'
import StokEkle from './screens/StokEkle'
import StokDuzenle from './screens/StokDuzenle'
import StokDetay from './screens/StokDetay'
import VaryantliStokEkle from './screens/VaryantliStokEkle'
import VaryantliStokDuzenle from './screens/VaryantliStokDuzenle'
import VaryantliStokDetay from './screens/VaryantliStokDetay'
import StokVaryantGrupEkle from './screens/StokVaryantGrupEkle'
import StokRapor from './screens/StokRapor'
import StokHareketRapor from './screens/StokHareketRapor'
import StokEkstreRapor from './screens/StokEkstreRapor'
import StokVaryantGrupDuzenle from './screens/StokVaryantGrupDuzenle'
import StokIslemDetay from './screens/StokIslemDetay'

//Kasa
import KasaListe from './screens/KasaListe'
import KasaDetay from './screens/KasaDetay'

//Banka Hesap
import BankaHesapListe from './screens/BankaHesapListe'
import BankaHesapDetay from './screens/BankaHesapDetay'

//Genel
import FirmaBilgileri from './screens/FirmaBilgileri'
import SifremiDegistir from './screens/SifremiDegistir'
import VarsayilanAyarlar from './screens/VarsayilanAyarlar'
import SubeListe from './screens/SubeListe'
import DovizListe from './screens/DovizListe'

//Kullanici
import KullaniciListe from './screens/KullaniciListe'
import KullaniciDuzenle from './screens/KullaniciDuzenle'

//Raporlar
import CariRapor from './screens/CariRapor'
import CariEkstreRapor from './screens/CariEkstreRapor'
import CariIslemRapor from './screens/CariIslemRapor'
import KasaRapor from './screens/KasaRapor'
import BankaRapor from './screens/BankaRapor'
import DepoStokRapor from './screens/DepoStokRapor'
import RaporYukleniyor from './screens/RaporYukleniyor'

import Page404 from './screens/Page404'

import VadeliTahsilatDetay from './screens/VadeliTahsilatDetay'
import VadeliOdemeDetay from './screens/VadeliOdemeDetay'

//Paket - Ödeme
import Paketler from './screens/Paketler'
import FaturaAdresi from './screens/FaturaAdresi'
import OdemeYap from './screens/OdemeYap'
import OdemeDurum from './screens/OdemeDurum'
import useKullanici from './hooks/useKullanici'

//Gelir Gider
import GelirGiderGrupListe from './screens/GelirGiderGrupListe'
import GelirGiderDetay from './screens/GelirGiderDetay'

//Üretim
import Uretim from './screens/Uretim'
import UretimEkle from './screens/UretimEkle'
import UretimDuzenle from './screens/UretimDuzenle'

//Üretim Reçete
import UretimReceteEkle from './screens/UretimReceteEkle'
import UretimReceteDuzenle from './screens/UretimReceteDuzenle'


const PrivateRoute = ({ children }) => {
  const { getToken } = useKullanici()

  if (!getToken) {
    return <Navigate to={"/GirisYap"} />
  }
  return children
}

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/GirisYap" element={<GirisYap />} />
      <Route path="/KayitOl" element={<KayitOl />} />
      <Route element={<SifremiUnuttumLayout />}>
        <Route element={<SifremiUnuttumMailDogrulama />} path="SifremiUnuttumMailDogrulama" />
        <Route element={<SifremiUnuttumKodDogrulama />} path="SifremiUnuttumKodDogrulama" />
        <Route element={<SifremiUnuttumYeniSifre />} path="SifremiUnuttumYeniSifre" />
      </Route>
      <Route path='/' element={<PrivateRoute> <PanelLayout /></PrivateRoute>} >
        <Route element={<Panel />} index={true} />
        <Route element={<Fatura />} path="Fatura" />
        <Route element={<FaturaRapor />} path="FaturaRapor" />
        <Route element={<Stok />} path="Stok" />
        <Route element={<StokEkle />} path="StokEkle" />
        <Route element={<VaryantliStokEkle />} path="VaryantliStokEkle" />
        <Route element={<VaryantliStokDuzenle />} path="VaryantliStokDuzenle/:stokId" />
        <Route element={<VaryantliStokDetay />} path="VaryantliStokDetay/:stokId" />
        <Route element={<StokDuzenle />} path="StokDuzenle/:stokId" />
        <Route element={<StokVaryantGrupDuzenle />} path="StokVaryantGrupDuzenle/:stokVaryantGrupId" />
        <Route element={<StokIslemDetay />} path="StokIslemDetay/:stokIslemId" />
        <Route element={<StokDetay />} path="StokDetay/:stokId/:varyantGrupId" />
        <Route element={<StokVaryantGrupEkle />} path="StokVaryantGrupEkle/:stokId" />
        <Route element={<StokRapor />} path="StokRapor" />
        <Route element={<StokHareketRapor />} path="StokHareketRapor" />
        <Route element={<StokEkstreRapor />} path="StokEkstreRapor" />
        <Route element={<Cari />} path="Cari" />
        <Route element={<CariKartDetay />} path="CariDetay/:cariKartId" />
        <Route element={<CariIslemDetay />} path="CariIslemDetay/:cariIslemTur/:cariIslemId" />
        <Route element={<KasaListe />} path="KasaListe" />
        <Route element={<KasaDetay />} path="KasaDetay/:kasaId" />
        <Route element={<BankaHesapListe />} path="BankaHesapListe" />
        <Route element={<BankaHesapDetay />} path="BankaHesapDetay/:bankaHesapId" />
        <Route element={<FirmaBilgileri />} path="FirmaBilgileri" />
        <Route element={<SifremiDegistir />} path="SifremiDegistir" />
        <Route element={<CariRapor />} path="CariRapor" />
        <Route element={<CariEkstreRapor />} path="CariEkstreRapor" />
        <Route element={<CariIslemRapor />} path="CariIslemRapor" />
        <Route element={<KasaRapor />} path="KasaRapor" />
        <Route element={<BankaRapor />} path="BankaRapor" />
        <Route element={<DepoStokRapor />} path="DepoStokRapor" />
        <Route element={<FaturaEkle />} path="FaturaEkle" />
        <Route element={<FaturaDuzenle />} path="FaturaDuzenle/:faturaId" />
        <Route element={<TeklifSiparis />} path="TeklifSiparis" />
        <Route element={<TeklifSiparisEkle />} path="TeklifSiparisEkle" />
        <Route element={<TeklifSiparisDuzenle />} path="TeklifSiparisDuzenle/:teklifSiparisId" />
        <Route element={<VadeliTahsilatDetay />} path="VadeliTahsilatDetay/:islemListTip/:dovizId" />
        <Route element={<VadeliOdemeDetay />} path="VadeliOdemeDetay/:islemListTip/:dovizId" />
        <Route element={<VarsayilanAyarlar />} path="VarsayilanAyarlar" />
        <Route element={<SubeListe />} path="SubeListe" />
        <Route element={<DovizListe />} path="DovizListe" />
        <Route element={<KullaniciListe />} path="KullaniciListe" />
        <Route element={<KullaniciDuzenle />} path="KullaniciDuzenle/:kullaniciId" />
        <Route element={<RaporYukleniyor />} path="RaporYukleniyor" />
        <Route element={<GelirGiderGrupListe />} path="GelirGiderGrupListe" />
        <Route element={<GelirGiderDetay />} path="GelirGiderDetay/:gelirGiderGrupId" />
        <Route element={<Uretim />} path="Uretim" />
        <Route element={<UretimEkle />} path="UretimEkle" />
        <Route element={<UretimDuzenle />} path="UretimDuzenle/:uretimId" />
        <Route element={<UretimReceteEkle />} path="UretimReceteEkle" />
        <Route element={<UretimReceteDuzenle />} path="UretimReceteDuzenle/:receteId" />
      </Route>
      <Route element={<PrivateRoute><OdemeLayout /></PrivateRoute>}>
        <Route element={<Paketler />} path='/Paketler' />
        <Route element={<FaturaAdresi />} path="FaturaAdresi" />
        <Route element={<OdemeYap />} path="OdemeYap" />
      </Route>
      <Route element={<OdemeDurum />} path="/OdemeDurum/:durum" />
      <Route element={<Page404 />} path="*" />
    </Routes>
  )
}
