import React, { useState, useEffect } from "react";
import { Modal, Form, message, notification, Input } from "antd";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useApi from "../../hooks/useApi";

const formSize = "middle";

export default function StokKategoriDuzenleModal({
  visible = false,
  handleCancel,
  data = {},
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const { baslik, kategoriAd, kategoriAdPlaceHolder } =
    strings.stokKategoriDuzenleModal;
  const dispatch = useDispatch();
  const api = useApi();

  useEffect(() => {
    if (data !== {}) {
      form.setFieldsValue({
        id: data.id,
        ad: data.ad,
      });
    } else {
      form.setFieldsValue({
        id: null,
        ad: "",
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      setFetchLoading(true);
      const { ad } = values;

      const response = await api.put("/stokkategori", {
        Id: data.id,
        ad: ad,
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      title={baslik}
      okButtonProps={{
        htmlType: "submit",
        form: "stokKategoriDuzenleForm",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
    >
      <Form
        form={form}
        id="stokKategoriDuzenleForm"
        onFinish={onFinish}
        layout="vertical"
        size="large"
      >
        <Form.Item name="ad" label={kategoriAd} rules={[{ required: true }]}>
          <Input placeholder={kategoriAdPlaceHolder} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
