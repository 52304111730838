import { Select, SelectProps, message } from 'antd'
import React, { useState, useEffect } from 'react'
import strings from '../../utils/Localization'
import { SearchOutlined } from '@ant-design/icons'
import useApi from '../../hooks/useApi'

const { Option } = Select

type selectOptionItemType = {
  value: string
  text: string
  data: {}
}

interface IProps extends SelectProps<any> {
  onSelectedCariKart: (selectedCariKart: any) => void
}

const CariKartSelectOption: React.FC<IProps> = ({ onSelectedCariKart = () => {}, placeholder, value, ...rest }) => {
  const [cariKartData, setCariKartData] = useState<selectOptionItemType[]>([])
  const api = useApi()
  const { cariPlaceHolder }: any = strings.cariKartSelectOption

  useEffect(() => {
    if (value !== undefined) {
      fetchCariKart('', value)
    }
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCariKart = async (searchText?: string, cariKartId?: number) => {
    try {
      const response = await api.post('/carikart/get', {
        Data: {
          Id: cariKartId === undefined ? 0 : cariKartId,
        },
        SearchText: {
          PropertyNames: null,
          Value: searchText,
        },
        SelectColumns: [
          {
            TableName: '',
            ColumnNames: [
              'id',
              'firmaAdi',
              'yetkili',
              'vergIdairesi',
              'vergiNumarasi',
              'telefon',
              'cepTelefonu',
              'eMail',
              'il',
              'ilce',
              'adres',
              'sevkAdresi',
            ],
          },
        ],
        PagingOptions: {
          PageSize: 10,
          PageNumber: 1,
        },
      })
      if (response.status === 200) {
        let data: selectOptionItemType[] = []
        response.data.data.map((item: any) =>
          data.push({
            text: item.firmaAdi,
            value: item.id,
            data: item,
          })
        )
        setCariKartData(data)
        if (cariKartId !== undefined) {
          onSelectedCariKart(data[0].data)
        }
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }
  const onSearch = (searchText = '') => {
    if (searchText.length === 0) {
      setCariKartData([])
    } else {
      fetchCariKart(searchText)
    }
  }

  return (
    <Select
      {...rest}
      value={value}
      suffixIcon={<SearchOutlined size={24} />}
      showSearch
      onSearch={onSearch}
      notFoundContent={null}
      defaultActiveFirstOption={false}
      showArrow={true}
      filterOption={false}
      placeholder={placeholder || cariPlaceHolder}
      onSelect={(e) => {
        const selectedCariKart = cariKartData.find((x) => x.value === e)?.data
        selectedCariKart !== undefined && onSelectedCariKart(selectedCariKart)
      }}
      onClear={() => onSelectedCariKart({})}
    >
      {cariKartData.map((item: any) => (
        <Option value={item.value} key={item.value}>
          {item.text}
        </Option>
      ))}
    </Select>
  )
}

export default CariKartSelectOption
