import React, { useState } from 'react'
import {
  Card,
  Button,
  Space,
  Tag,
  Popconfirm,
  message,
  notification,
  Typography,
  Form,
  Col,
  Row,
  Radio,
  Tooltip,
} from 'antd'
import strings from '../../utils/Localization'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import IslemButton from './IslemButton'
import { useParams } from 'react-router-dom'
import useCurrency from '../../hooks/useCurrency'
import FetchListContent from '../toolbox/FetchListContent'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import BankaHesapIslemDuzenle from '../bankaHesapIslem/BankaHesapIslemDuzenle'
import BankaVirmanDuzenleModal from '../bankaHesapIslem/BankaVirmanDuzenleModal'
import CariIslemDuzenleModal from '../cariIslem/CariIslemDuzenleModal'
import { cariIslemTurTypes } from '../../types/cariIslemTurTypes'
import { setRerender } from '../../redux/actions/rerenderAction'
import DatepickerRanger from '../toolbox/DatepickerRanger'
import { SearchOutlined, ClearOutlined } from '@ant-design/icons'
import CurrencyInput from '../toolbox/CurrencyInput'
import useApi from '../../hooks/useApi'

export default function BankaHesapIslemListe() {
  const { bankaHesapId } = useParams()
  const api = useApi()

  const initialFilterRequestData = {
    Data: {
      BankaHesapId: bankaHesapId,
      GirisMi: null,
      VirmanMi: null,
    },
    DateOptions: [
      {
        DatePropertyName: 'IslemTarihi',
        FirstDate: null,
        LastDate: null,
      },
    ],
    BetweenOptions: [
      {
        PropertyName: 'Tutar',
        First: null,
        Last: null,
      },
    ],
  }
  const [filterRequestData, setFilterRequestData] = useState({
    ...initialFilterRequestData,
  })
  const [dataSource, setDataSource] = useState([])
  const [duzenleModalVisible, setDuzenleModalVisible] = useState(false)
  const [cariIslemDuzenleModalVisible, setCariIslemDuzenleModalVisible] = useState(false)
  const [duzenleData, setDuzenleData] = useState({})
  const [virmaDuzenleVisible, setVirmaDuzenleVisible] = useState(false)
  const [virmanBankaHesapIslemId, setVirmanBankaHesapIslemId] = useState(null)
  const { paraFormat } = useCurrency()
  const [detailSearchForm] = Form.useForm()
  const { bankaHesapIslemListe, detayliArama } = strings.bankaHesapDetay.components
  const { data: selectedBankaHesap } = useSelector((state) => state.selectedBankaHesapReducer)
  const dispatch = useDispatch()

  const columns = [
    {
      title: bankaHesapIslemListe.tarih,
      dataIndex: 'islemTarihi',
      key: 'islemTarihi',
      sorter: (a, b) => a.islemTarihi.length - b.islemTarihi.length,
      render: (text) => moment(text).format('DD/MM/YYYY'),
      defaultSortOrder: 'descend',
    },
    {
      title: bankaHesapIslemListe.evrakNo,
      dataIndex: 'evrakNo',
      key: 'evrakNo',
    },
    {
      title: bankaHesapIslemListe.aciklama,
      dataIndex: 'aciklama',
      key: 'aciklama',
      render: (text, row, index) => {
        let virmanText = ''

        //Banka hesaplar arası virman
        if (row.virmanMi === 1) {
          if (row.girisMi === true) {
            const { ad: bankaHesapAd } =
              row.BankahesapislemHasBankahesapislemBankaHesapIslemGiris[0].BankaHesapIslemCikis.BankaHesap

            virmanText = `${bankaHesapAd} => ${selectedBankaHesap.ad}`
          } else {
            const { ad: bankaHesapAd } =
              row.BankahesapislemHasBankahesapislemBankaHesapIslemCikis[0].BankaHesapIslemGiris.BankaHesap

            virmanText = `${selectedBankaHesap.ad} => ${bankaHesapAd}`
          }
        }
        // banka hesap ve kasa arası virman
        if (row.virmanMi === 2) {
          const { ad: kasaAd } = row.KasaislemHasBankahesapislem[0].KasaIslem.Kasa
          if (row.girisMi === true) {
            virmanText = `${kasaAd}a=> ${selectedBankaHesap.ad}`
          } else {
            virmanText = `${selectedBankaHesap.ad} => ${kasaAd}`
          }
        }
        return (
          <>
            {row.virmanMi > 0 && (
              <Tag color={'processing'} style={{ whiteSpace: 'normal' }}>
                {bankaHesapIslemListe.virmanIslemi} ( {virmanText})
              </Tag>
            )}
            {text}
          </>
        )
      },
    },
    {
      title: bankaHesapIslemListe.unvan,
      dataIndex: 'unvan',
      key: 'unvan',
      render: (text, row, index) => {
        const { CariKart } = row.Cariislembaglanti
        if (CariKart.id === 0) return '-'
        return (
          <Tag style={{ fontSize: 14, padding: 8, whiteSpace: 'normal' }} color="default">
            {CariKart.firmaAdi}
          </Tag>
        )
      },
    },
    {
      title: bankaHesapIslemListe.tutar,
      dataIndex: 'tutar',
      key: 'tutar',
      render: (text, row, index) => (
        <Typography.Title
          level={5}
          style={{
            padding: 8,
            color: row.girisMi ? 'green' : 'red',
          }}
        >{`${row.BankaHesap.Doviz.simge}${paraFormat(row.tutar)}`}</Typography.Title>
      ),
    },
    {
      title: bankaHesapIslemListe.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => {
        const { CariKart } = row.Cariislembaglanti

        return (
          <Space size="middle">
            <Tooltip title={bankaHesapIslemListe.bankaHesapIslemDuzenle}>
              <Button
                onClick={() => {
                  if (row.virmanMi >= 1) {
                    setVirmanBankaHesapIslemId(row.id)
                    setVirmaDuzenleVisible(true)
                  } else {
                    setDuzenleData(row)
                    if (row.Cariislembaglanti.CariKart.id) {
                      setCariIslemDuzenleModalVisible(true)
                    } else {
                      setDuzenleModalVisible(true)
                    }
                  }
                }}
                type="default"
                shape="circle"
                icon={<EditOutlined />}
                size={'large'}
              />
            </Tooltip>
            <Tooltip title={bankaHesapIslemListe.bankaHesapIslemSil}>
              <Popconfirm
                title={
                  CariKart.id === 0
                    ? strings.mesaj.silmeIslemUyari
                    : strings.mesaj.kasaBankaHesapCariIslem(CariKart.firmaAdi)
                }
                onConfirm={() => {
                  if (row.Cariislembaglanti.cariIslemId) {
                    removeCariIslem(row.Cariislembaglanti.cariIslemId)
                  } else remove(row.id)
                }}
              >
                <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      },
    },
  ]
  const remove = async (bankaHesapIslemId) => {
    try {
      const response = await api.delete(`/bankahesapislem/${bankaHesapIslemId}`, {})
      if (response.status === 200) {
        dispatch(setRerender())
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  const removeCariIslem = async (cariIslemId) => {
    try {
      const response = await api.delete(`/cariislem/${cariIslemId}`)
      if (response.status === 200) {
        dispatch(setRerender())
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  const detayliAramaOnFinish = async (props) => {
    const { islemTarihi, islemTipi, virmanTipi, minTutar, maxTutar } = props

    let newData = { ...initialFilterRequestData }

    newData = {
      Data: {
        BankaHesapId: bankaHesapId,
        GirisMi: islemTipi !== undefined ? islemTipi : null,
        VirmanMi: virmanTipi !== undefined ? virmanTipi : null,
      },
      BetweenOptions: [
        {
          First: (minTutar !== undefined) & (minTutar !== 0) ? minTutar : null,
          Last: (maxTutar !== undefined) & (maxTutar !== 0) ? maxTutar : null,
          PropertyName: 'Tutar',
        },
      ],
      DateOptions: [
        {
          FirstDate: islemTarihi !== undefined ? `${moment(islemTarihi[0]).format('YYYY-MM-DD')} 00:00:00` : null,
          LastDate: islemTarihi !== undefined ? `${moment(islemTarihi[1]).format('YYYY-MM-DD')} 23:59:59` : null,
          DatePropertyName: 'IslemTarihi',
        },
      ],
    }

    await setFilterRequestData(newData)
    dispatch(setRerender())
  }
  const detayliAramaReset = async () => {
    await setFilterRequestData({ ...initialFilterRequestData })
    detailSearchForm.resetFields()

    dispatch(setRerender())
  }
  return (
    <>
      <Card title={bankaHesapIslemListe.baslik} extra={<IslemButton />}>
        <FetchListContent
          columns={columns}
          requestUrl="/bankahesapislem/get"
          requestBody={{
            SortOptions: {
              ColumnName: 'IslemTarihi',
              SortType: 'Desc',
            },
            SelectColumns: [
              {
                TableName: '',
                ColumnNames: ['id', 'evrakNo', 'aciklama', 'virmanMi', 'girisMi', 'tutar', 'islemTarihi'],
              },
              {
                TableName: 'Cariislembaglanti',
                ColumnNames: ['id', 'cariIslemId'],
              },
              {
                TableName: 'Cariislembaglanti.CariKart',
                ColumnNames: ['id', 'firmaAdi'],
              },
              {
                TableName: 'BankahesapislemHasBankahesapislemBankaHesapIslemCikis.BankaHesapIslemGiris.BankaHesap',
                ColumnNames: ['id', 'ad'],
              },
              {
                TableName: 'BankahesapislemHasBankahesapislemBankaHesapIslemGiris.BankaHesapIslemCikis.BankaHesap',
                ColumnNames: ['id', 'ad'],
              },
              {
                TableName: 'KasaislemHasBankahesapislem.KasaIslem.Kasa',
                ColumnNames: ['id', 'ad'],
              },
              {
                TableName: 'BankaHesap.Doviz',
                ColumnNames: ['id', 'ad', 'kod', 'simge'],
              },
            ],
            ...filterRequestData,
          }}
          dataSource={dataSource}
          onDataSource={(e) => setDataSource(e)}
          detailSearch={
            <Card title={detayliArama.baslik}>
              <Form
                form={detailSearchForm}
                name="detailSearchForm"
                layout="vertical"
                onFinish={detayliAramaOnFinish}
                initialValues={{
                  islemTipi: null,
                  virmanTipi: null,
                }}
              >
                <Row gutter={24}>
                  <Col span={10}>
                    <Form.Item name="islemTarihi" label={detayliArama.islemTarihi}>
                      <DatepickerRanger />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Form.Item name="islemTipi" label={detayliArama.islemTipi}>
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value={null}>{detayliArama.tumIslemTipleriniGoster}</Radio>
                            <Radio value={true}>{detayliArama.girisIslemleriniGoster}</Radio>
                            <Radio value={false}>{detayliArama.cikisIslemleriniGoster}</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item name="virmanTipi" label={detayliArama.virmanTipi}>
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value={null}>{detayliArama.tumVirmanTipleriniGoster}</Radio>
                            <Radio value={1}>{detayliArama.bankayaVirmanIslemleriniGoster}</Radio>
                            <Radio value={2}>{detayliArama.kasayaVirmanIslemleriniGoster}</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Space direction="vertical" align="center">
                      <Form.Item name="minTutar" label={detayliArama.minTutar}>
                        <CurrencyInput />
                      </Form.Item>
                      <Form.Item name="maxTutar" label={detayliArama.maxTutar}>
                        <CurrencyInput />
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
                <Space align="baseline" style={{ float: 'right' }}>
                  <Button
                    type="default"
                    size="middle"
                    icon={<ClearOutlined />}
                    style={{ float: 'right' }}
                    onClick={detayliAramaReset}
                  >
                    {detayliArama.temizle}
                  </Button>
                  <Button
                    type="default"
                    size="middle"
                    icon={<SearchOutlined />}
                    style={{ float: 'right' }}
                    form="detailSearchForm"
                    htmlType="submit"
                  >
                    {detayliArama.aramayiBaslat}
                  </Button>
                </Space>
              </Form>
            </Card>
          }
        />
      </Card>
      <BankaHesapIslemDuzenle
        visible={duzenleModalVisible}
        handleCancel={() => setDuzenleModalVisible(false)}
        data={duzenleData}
      />
      <BankaVirmanDuzenleModal
        visible={virmaDuzenleVisible}
        handleCancel={() => setVirmaDuzenleVisible(false)}
        bankahesapIslemId={virmanBankaHesapIslemId}
      />
      <CariIslemDuzenleModal
        visible={cariIslemDuzenleModalVisible}
        handleCancel={() => setCariIslemDuzenleModalVisible(false)}
        data={
          duzenleData.id === undefined
            ? {}
            : {
                id: duzenleData.Cariislembaglanti.CariKart.id,
                vadeTarih: duzenleData.islemTarihi,
                islemTarih: duzenleData.islemTarihi,
                tutar: duzenleData.tutar,
                doviz: { id: duzenleData.BankaHesap.Doviz.id },
                evrakNo: duzenleData.evrakNo,
                aciklama: duzenleData.aciklama,
                cariIslemTur: duzenleData.girisMi === true ? cariIslemTurTypes.TAHSILAT : cariIslemTurTypes.ODEME,
              }
        }
      />
    </>
  )
}
