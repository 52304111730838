import React, { useState, useEffect } from "react";
import { Form, message, notification } from "antd";
import { useLocation } from "react-router-dom";
import { TeklifSiparisLayout } from "../components/teklifSiparisEkle";
import { teklifSiparisTypes } from "../types/teklifSiparisTypes";
import strings from "../utils/Localization";
import useEvrakNo from "../hooks/useEvrakNo";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useApi";
import moment from "moment";

function TeklifSiparisEkle() {
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = Form.useForm();
  const { state = { teklifSiparisTip: teklifSiparisTypes.ALINAN_TEKLIF } } = useLocation();
  const { teklifSiparisEkle: localization } = strings;
  const { getTeklifEvrakNo, getSiparisEvrakNo } = useEvrakNo();
  const navigate= useNavigate();
  const api = useApi();

  useEffect(() => {
    form.setFieldsValue({ teklifSiparisTip: state.teklifSiparisTip });
    if (
      state.teklifSiparisTip === teklifSiparisTypes.ALINAN_TEKLIF ||
      state.teklifSiparisTip === teklifSiparisTypes.VERILEN_TEKLIF
    ) {
      getTeklifEvrakNo().then((evrakNo) => {
        form.setFieldsValue({ evrakNo: evrakNo });
      });
    } else if (
      state.teklifSiparisTip === teklifSiparisTypes.ALINAN_SIPARIS ||
      state.teklifSiparisTip === teklifSiparisTypes.VERILEN_SIPARIS
    ) {
      getSiparisEvrakNo().then((evrakNo) => {
        form.setFieldsValue({ evrakNo: evrakNo });
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        teklifSiparisTip,
        cariKartId,
        dovizId,
        evrakNo,
        durumId,
        duzenlemeTarihi,
        gecerlilikTarihi,
        aciklama,
        stopajOran,
        stopajTutar,
        tevkifatOran,
        tevkifatTutar,
        toplamSatirIskontoTutar,
        araToplamTutar,
        araIskontoOran,
        araIskontoTutar,
        araIskontoTip,
        genelToplamTutar,
        teklifSiparisSatir = [
          {
            aciklama: "",
            stokId: null,
            varyantId: null,
            miktar: 1,
            birimFiyat: 0,
            kdvOran: 0,
            kdvTutar: 0,
            otvOran: 0,
            otvTutar: 0,
            toplam: 0,
            iskontoOran: 0,
            iskontoTip: "yuzde",
            iskontoTutar: 0,
          },
        ],
        firmaAdi,
        yetkili,
        vergiDairesi,
        vergiNumarasi,
        telefon,
        cepTelefonu,
        eMail,
      } = values;

      let requestData = {
        CariKartId: cariKartId,
        DovizId: dovizId,
        Tip: teklifSiparisTip,
        FirmaAdi: firmaAdi,
        Yetkili: yetkili,
        VergIdairesi: vergiDairesi,
        VergiNumarasi: vergiNumarasi,
        Telefon: telefon,
        CepTelefonu: cepTelefonu,
        EMail: eMail,
        TeklifSiparisNo: evrakNo,
        TeklifSiparisTarihi: duzenlemeTarihi,
        GecerlilikTarihi: gecerlilikTarihi,
        Aciklama: aciklama,
        StopajOran: stopajOran,
        StopajTutar: stopajTutar,
        TevkifatOran: tevkifatOran,
        TevkifatTutar: tevkifatTutar,
        Toplam: araToplamTutar,
        SatirIskontoToplamTutar: toplamSatirIskontoTutar,
        AraToplamIskontoOran: araIskontoOran,
        AraToplamIskontoTutar: araIskontoTutar,
        AraToplamIskontoTip: araIskontoTip === "yuzde" ? "Oran" : "Tutar",
        GenelToplam: genelToplamTutar,
        Teklifsiparissatir: teklifSiparisSatir
          .filter((x) => x.hidden === false)
          .map((item) => ({
            StokVaryantGrupId: item.stokVaryantGrupId,
            StokBirimId: item.stokBirimId,
            UrunAdi: item.urunAdi,
            Miktar: item.miktar,
            BirimFiyat: item.birimFiyat,
            Barkod: item.barkod,
            Aciklama: item.aciklama,
            OtvTutar: item.otvTutar,
            OtvOran: item.otvOran,
            GenelToplam: item.toplam,
            // OivTutar: 20,
            // OivOran: 25,
            KdvTutar: item.kdvTutar,
            KdvOran: item.kdvOran,
            Teklifsiparissatiriskonto: item.iskontoOran
              ? [
                {
                  Oran: item.iskontoYuzde,
                  Tutar: item.iskontoTutar,
                  IskontoTip: item.iskontoTip === "yuzde" ? "Oran" : "Tutar",
                  UygulananTutar: item.iskontoUygulananTutar,
                  SiraNo: 0,
                },
              ]
              : null,
          })),
        Teklifsiparisdurum: [
          {
            TeklifSiparisDurumTipId: durumId,
            // Aciklama: "Test",
            IslemTarihi: moment().format("YYYY-MM-DD HH:MM:SS")
          }
        ]

      };
      setSaveLoading(true);
      const response = await api.post("/teklifSiparis", requestData);
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <>
      <TeklifSiparisLayout
        form={form}
        title={state.teklifSiparisTip}
        tip={state.teklifSiparisTip}
        saveButtonTitle={localization.kaydetButon}
        saveButtonLoading={saveLoading}
        onFinish={onFinish}
      />
    </>
  );
}

export default TeklifSiparisEkle;
