import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Row, Col, message, notification } from "antd";
import moment from "moment";
import strings from "../../utils/Localization";
import { setRerender } from "../../redux/actions/rerenderAction";
import { useDispatch } from "react-redux";
import Datepicker from "../toolbox/DatePicker";
import CurrencyInput from "../toolbox/CurrencyInput";
import useApi from "../../hooks/useApi";

const formSize = "large";

function KasaVirmanDuzenleModal({
  visible = false,
  handleCancel,
  bankahesapIslemId = null,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { virmanDuzenleModal: localization } = strings.kasaIslem;
  const api = useApi();

  useEffect(() => {
    if (bankahesapIslemId !== null) {
      bankaHesapIslemGetVirman();
    }
  }, [bankahesapIslemId]); // eslint-disable-line react-hooks/exhaustive-deps

  const bankaHesapIslemGetVirman = async () => {
    try {
      const response = await api.post("/bankahesapislem/get", {
        Data: { Id: bankahesapIslemId },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: [
              "id",
              "bankaHesapId",
              "evrakNo",
              "aciklama",
              "virmanMi",
              "girisMi",
              "tutar",
              "carpan",
              "islemTarihi",
            ],
          },
          {
            TableName: "KasaislemHasBankahesapislem.KasaIslem",
            ColumnNames: ["tutar", "id"],
          },
          {
            TableName: "KasaislemHasBankahesapislem.KasaIslem.Kasa",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName: "KasaislemHasBankahesapislem.KasaIslem.Kasa.Doviz", //VirmanMi 2 ise bunu
            ColumnNames: [
              "id",
              "simge",
              "kod",
              "ad",
            ],
          },
          {
            TableName: "BankaHesap",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName: "BankaHesap.Doviz",
            ColumnNames: [
              "id",
              "simge",
              "kod",
              "ad",
            ],
          },
          {
            TableName:
              "BankahesapislemHasBankahesapislemBankaHesapIslemCikis.BankaHesapIslemGiris",
            ColumnNames: ["tutar", "id"],
          },
          {
            TableName:
              "BankahesapislemHasBankahesapislemBankaHesapIslemCikis.BankaHesapIslemGiris.BankaHesap",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName:
              "BankahesapislemHasBankahesapislemBankaHesapIslemCikis.BankaHesapIslemGiris.BankaHesap.Banka",
            ColumnNames: [
              "id",
              "ad",
            ],
          },
          {
            TableName:
              "BankahesapislemHasBankahesapislemBankaHesapIslemCikis.BankaHesapIslemGiris.BankaHesap.Doviz",
            ColumnNames: [
              "id",
              "simge",
              "kod",
              "ad",
            ],
          },
          {
            TableName:
              "BankahesapislemHasBankahesapislemBankaHesapIslemGiris.BankaHesapIslemCikis",
            ColumnNames: ["tutar", "id"],
          },
          {
            TableName:
              "BankahesapislemHasBankahesapislemBankaHesapIslemGiris.BankaHesapIslemCikis.BankaHesap",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName:
              "BankahesapislemHasBankahesapislemBankaHesapIslemGiris.BankaHesapIslemCikis.BankaHesap.Banka",
            ColumnNames: [
              "id",
              "ad",
            ],
          },
          {
            TableName:
              "BankahesapislemHasBankahesapislemBankaHesapIslemGiris.BankaHesapIslemCikis.BankaHesap.Doviz",
            ColumnNames: [
              "id",
              "simge",
              "kod",
              "ad",
            ],
          },
        ],
      });

      if (response.status === 200) {
        const {
          girisMi,
          id,
          BankaHesap,
          tutar,
          carpan,
          evrakNo,
          aciklama,
          islemTarihi,
          virmanMi,
        } = response.data.data[0];

        if (girisMi === true) {
          form.setFieldsValue({
            aliciId: id,
            aliciAdi: BankaHesap.ad,
            aliciDoviz: BankaHesap.Doviz,
            girenTutar: tutar,
            carpan: carpan,
            islemTarihi: moment(islemTarihi),
            evrakNo: evrakNo,
            aciklama: aciklama,
            virmanMi,
            girisMi,
          });

          if (virmanMi === 1) {
            const { id, BankaHesap, tutar } =
              response.data.data[0]
                .BankahesapislemHasBankahesapislemBankaHesapIslemGiris[0]
                .BankaHesapIslemCikis;
            form.setFieldsValue({
              gondericiId: id,
              gondericiAdi: BankaHesap.ad,
              cikanTutar: tutar,
              gondericiDoviz: BankaHesap.Doviz,
            });
          } else if (virmanMi === 2) {
            const { id, Kasa, tutar } =
              response.data.data[0].KasaislemHasBankahesapislem[0].KasaIslem;

            form.setFieldsValue({
              gondericiId: id,
              gondericiAdi: Kasa.ad,
              cikanTutar: tutar,
              gondericiDoviz: Kasa.Doviz,
            });
          }
        } else {
          form.setFieldsValue({
            gondericiId: id,
            gondericiAdi: BankaHesap.ad,
            gondericiDoviz: BankaHesap.Doviz,
            cikanTutar: tutar,
            carpan: carpan,
            islemTarihi: moment(islemTarihi),
            evrakNo: evrakNo,
            aciklama: aciklama,
            virmanMi,
            girisMi,
          });
          if (virmanMi === 1) {
            const { id, BankaHesap, tutar } =
              response.data.data[0]
                .BankahesapislemHasBankahesapislemBankaHesapIslemCikis[0]
                .BankaHesapIslemGiris;
            form.setFieldsValue({
              aliciId: id,
              aliciAdi: BankaHesap.ad,
              girenTutar: tutar,
              aliciDoviz: BankaHesap.Doviz,
            });
          } else if (virmanMi === 2) {
            const { id, Kasa, tutar } =
              response.data.data[0].KasaislemHasBankahesapislem[0].KasaIslem;
            form.setFieldsValue({
              aliciId: id,
              aliciAdi: Kasa.ad,
              girenTutar: tutar,
              aliciDoviz: Kasa.Doviz,
            });
          }
        }
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const onFinish = (values) => {
    const { virmanMi, girisMi } = values;
    if (virmanMi === 1) {
      bankaToBankaUpdate(values);
    } else if (virmanMi === 2) {
      if (girisMi === true) {
        kasaToBankaUpdate(values);
      } else {
        bankaToKasaUpdate(values);
      }
    }
  };
  const bankaToBankaUpdate = async (values) => {
    try {
      const {
        gondericiId,
        evrakNo,
        aciklama,
        cikanTutar,
        gondericiDoviz,
        aliciDoviz,
        carpan,
        islemTarihi,
      } = values;
      setFetchLoading(true);
      const response = await api.put(
        "/bankahesapislem/virman/updatebankatobanka",
        {
          Id: gondericiId,
          EvrakNo: evrakNo,
          Aciklama: aciklama,
          CikanBankaHesapTutar: cikanTutar,
          GirenBankaHesapTutar:
            gondericiDoviz.id !== aliciDoviz.id
              ? cikanTutar * carpan
              : cikanTutar,
          IslemTarihi: islemTarihi,
          Carpan: carpan,
        }
      );

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const bankaToKasaUpdate = async (values) => {
    try {
      const {
        gondericiId,
        evrakNo,
        aciklama,
        cikanTutar,
        gondericiDoviz,
        aliciDoviz,
        carpan,
        islemTarihi,
      } = values;
      setFetchLoading(true);
      const response = await api.put(
        "/bankahesapislem/virman/updatebankatokasa",
        {
          BankaHesapIslemId: gondericiId,
          EvrakNo: evrakNo,
          Aciklama: aciklama,
          CikanBankaHesapTutar: cikanTutar,
          GirenKasaTutar:
            gondericiDoviz.id !== aliciDoviz.id
              ? cikanTutar * carpan
              : cikanTutar,
          IslemTarihi: islemTarihi,
          Carpan: carpan,
        }
      );

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const kasaToBankaUpdate = async (values) => {
    try {
      const {
        aliciId,
        evrakNo,
        aciklama,
        cikanTutar,
        gondericiDoviz,
        aliciDoviz,
        carpan,
        islemTarihi,
      } = values;
      setFetchLoading(true);
      const response = await api.put(
        "/bankahesapislem/virman/updatekasatobanka",
        {
          BankaHesapIslemId: aliciId,
          EvrakNo: evrakNo,
          Aciklama: aciklama,
          CikanKasaTutar: cikanTutar,
          GirenBankaHesapTutar:
            gondericiDoviz.id !== aliciDoviz.id
              ? cikanTutar * carpan
              : cikanTutar,
          IslemTarihi: islemTarihi,
          Carpan: carpan,
        }
      );

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const kurHesapla = (fieldName) => {
    const { cikanTutar, carpan, girenTutar } = form.getFieldValue();

    if (fieldName === "cikanTutar") {
      const currentGirenTutar = parseFloat(cikanTutar * carpan).toFixed(2);
      form.setFieldsValue({
        girenTutar: currentGirenTutar,
      });
    } else if (fieldName === "carpan") {
      const currentGirenTutar = parseFloat(cikanTutar * carpan).toFixed(2);
      form.setFieldsValue({
        girenTutar: currentGirenTutar,
      });
    } else if (fieldName === "girenTutar") {
      const currentCikanTutar = parseFloat(girenTutar / carpan).toFixed(2);
      form.setFieldsValue({
        cikanTutar: currentCikanTutar,
      });
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "bankaVirmanDuzenleForm",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      width="60%"
    >
      <Form
        form={form}
        id="bankaVirmanDuzenleForm"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={12}>
          <Col xxl={12} xl={12} md={12} sm={12} xs={24}>
            <Form.Item name="islemTarihi" label={localization.islemTarihi}>
              <Datepicker />
            </Form.Item>
            <Form.Item name="gondericiAdi" label={localization.gonderici}>
              <Input readOnly />
            </Form.Item>
            <Form.Item name="aliciAdi" label={localization.alici}>
              <Input readOnly />
            </Form.Item>
            <Form.Item name="evrakNo" label={localization.evrakNo}>
              <Input />
            </Form.Item>
            <Form.Item name="aciklama" label={localization.aciklama}>
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} md={12} sm={12} xs={24}>
            <Form.Item
              name="cikanTutar"
              label={localization.cikanTutar}
              rules={[{ required: true }]}
            >
              <CurrencyInput
                onChange={() => kurHesapla("cikanTutar")}
                addonAfter={
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      let kod = "";
                      let simge = "";
                      const gondericiDoviz = getFieldValue("gondericiDoviz");
                      if (gondericiDoviz !== undefined) {
                        kod = gondericiDoviz.kod;
                        simge = gondericiDoviz.simge;
                      }

                      return `${kod} (${simge})`;
                    }}
                  </Form.Item>
                }
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const aliciDoviz = getFieldValue("aliciDoviz");
                const gondericiDoviz = getFieldValue("gondericiDoviz");
                if (gondericiDoviz === undefined || aliciDoviz === undefined)
                  return null;

                const dovizFarkliMi = aliciDoviz.id !== gondericiDoviz.id;

                return (
                  <>
                    <Form.Item
                      name="carpan"
                      label={localization.kur}
                      rules={[{ required: true }]}
                      hidden={!dovizFarkliMi}
                    >
                      <Input
                        type="number"
                        onChange={() => kurHesapla("carpan")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="girenTutar"
                      label={localization.girenTutar}
                      rules={[{ required: true }]}
                      hidden={!dovizFarkliMi}
                    >
                      <CurrencyInput
                        onChange={() => kurHesapla("girenTutar")}
                        addonAfter={
                          <Form.Item shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              let kod = "";
                              let simge = "";
                              const gondericiDoviz =
                                getFieldValue("aliciDoviz");
                              if (gondericiDoviz !== undefined) {
                                kod = gondericiDoviz.kod;
                                simge = gondericiDoviz.simge;
                              }

                              return `${kod} (${simge})`;
                            }}
                          </Form.Item>
                        }
                      />
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="gondericiDoviz" hidden noStyle />
        <Form.Item name="aliciDoviz" hidden noStyle />
        <Form.Item name="aliciId" hidden noStyle />
        <Form.Item name="gondericiId" hidden noStyle />
        <Form.Item name="virmanMi" hidden noStyle />
        <Form.Item name="girisMi" hidden noStyle />
      </Form>
    </Modal>
  );
}

export default KasaVirmanDuzenleModal;
