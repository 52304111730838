import React, { useState } from 'react'
import { Button, Card, Form, message, Radio, Space } from 'antd'
import StokKategoriSelectOption from '../toolbox/StokKategoriSelectOption'
import strings from '../../utils/Localization'
import DepoSelectOption from '../toolbox/DepoSelectOption'
// import useApi from '../../hooks/useApi'

export default function DepoStokRaporForm() {
  const [fetchLoading, setFetchLoading] = useState(false)
  const { depoStokRaporForm: localization } = strings.depoStokRapor.components
  // const api = useApi()

  const onFinish = async (values) => {
    try {
      // const { depoId, stokKategoriId, fiyatTip } = values
      // const pdfWindow = window.open(`${document.location.origin}/#/RaporYukleniyor`)
      setFetchLoading(true)
      // const response = await api.post(
      //   '/rapordosya/cari',
      //   {},
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   }
      // )
    } catch (error) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  return (
    <Card>
      <Form layout="vertical" onFinish={onFinish} size="middle" initialValues={{ fiyatTip: 1 }}>
        <Form.Item name={'stokKategoriId'} label={localization.stokKategori}>
          <StokKategoriSelectOption />
        </Form.Item>
        <Form.Item name={'depoId'} label={localization.depo}>
          <DepoSelectOption />
        </Form.Item>
        <Form.Item name={'fiyatTip'} label={localization.fiyatTipi}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={1}>{localization.girisFiyatTipi}</Radio>
              <Radio value={2}>{localization.guncelFiyatTipi}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Button type="primary" htmlType="submit" style={{ float: 'right' }} size="large" loading={fetchLoading}>
          {localization.raporAlButon}
        </Button>
      </Form>
    </Card>
  )
}
