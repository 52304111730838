import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Space,
  Divider,
  Dropdown,
  Menu,
  Button,
  Input,
  Select,
  Form,
  Radio,
} from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import useCurrency from "../../hooks/useCurrency";
import strings from "../../utils/Localization";

const { Text } = Typography;
const { Option } = Select;

const MyDivider = () => <Divider style={{ marginTop: 12, marginBottom: 12 }} />;
const FooterRow = ({
  title,
  tutar,
  space,
  parent,
  addonAfter = null,
  hidden = false,
  content = null,
}) => (
  <>
    <Row
      gutter={"24"}
      style={{
        marginBottom: space ? 20 : 0,
        letterSpacing: 0.8,
        display: hidden ? "none" : null,
      }}
    >
      <Col xxl={8} xl={12} lg={12}>
        <Text style={{ fontSize: 16 }}> {title}</Text>
      </Col>
      <Col xxl={16} xl={12} lg={12}>
        <Space direction="horizontal" size="small" style={{ float: "right" }}>
          {content ? (
            content
          ) : (
            <Text
              style={{
                fontSize: parent ? 20 : 17,
                fontWeight: parent ? "600" : null,
                float: "right",
              }}
            >
              {tutar}
            </Text>
          )}

          <div
            style={{
              width: 50,
              marginLeft: 8,
              display: "inline-flex",
              justifyContent: "center",
            }}
          >
            {addonAfter}
          </div>
        </Space>
      </Col>
    </Row>
  </>
);
const FooterCurrencyContent = ({ value, parent }) => {
  const { paraFormat } = useCurrency();
  return (
    <Text
      style={{
        fontSize: parent ? 20 : 17,
        fontWeight: parent ? "600" : null,
        float: "right",
      }}
    >
      {paraFormat(value)}
    </Text>
  );
};

export default function TeklifSiparisFooter({ form, setFooterFields }) {
  const [araIskontoHidden, setAraIskontoHidden] = useState(false);
  const { teklifSiparisFooter: localization } = strings.teklifSiparisLayout;

  useEffect(() => {
    setAraIskontoHidden(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card style={{ width: "40%", float: "right" }}>
      <FooterRow
        title={localization.araToplam}
        content={
          <Form.Item name="araToplamTutar" noStyle>
            <FooterCurrencyContent />
          </Form.Item>
        }
        addonAfter={
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item
                  key="0"
                  disabled={!araIskontoHidden}
                  onClick={() => setAraIskontoHidden(false)}
                >
                  {localization.araToplamIndirimEkle}
                </Menu.Item>
                <Menu.Divider />
                <Form.Item name="stopajOran">
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio.Button style={{ borderWidth: 0 }} value={20}>
                        %20 {localization.stopajUygula}
                      </Radio.Button>
                      <Radio.Button style={{ borderWidth: 0 }} value={17}>
                        %17 {localization.stopajUygula}
                      </Radio.Button>
                      <Radio.Button style={{ borderWidth: 0 }} value={10}>
                        %10 {localization.stopajUygula}
                      </Radio.Button>
                      <Radio.Button style={{ borderWidth: 0 }} value={3}>
                        %3 {localization.stopajUygula}
                      </Radio.Button>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Menu>
            }
          >
            <Button icon={<PlusOutlined />}></Button>
          </Dropdown>
        }
        space
      />
      <FooterRow
        title={localization.satirIndirimi}
        content={
          <Form.Item name="toplamSatirIskontoTutar" noStyle>
            <FooterCurrencyContent />
          </Form.Item>
        }
        space
      />
      <Form.Item name="araIskontoOran" noStyle hidden />
      <Form.Item name="araIskontoTutar" noStyle hidden />
      <FooterRow
        title={localization.araToplamIndirim}
        content={
          <Form.Item name="araIskontoDeger" noStyle>
            <Input
              type="number"
              minLength={1}
              addonAfter={
                <Form.Item name="araIskontoTip" noStyle>
                  <Select>
                    <Option value="yuzde">%</Option>
                    <Option value="tutar">{localization.tutar}</Option>
                  </Select>
                </Form.Item>
              }
            />
          </Form.Item>
        }
        space
        addonAfter={
          <Button
            onClick={() => {
              form.setFields([
                { name: "araIskontoDeger", value: 0 },
                { name: "araIskontoOran", value: 0 },
                { name: "araIskontoTip", value: "yuzde" },
                { name: "araIskontoTutar", value: 0 },
              ]);
              setFooterFields();
              setAraIskontoHidden(true);
            }}
            icon={<CloseOutlined />}
          />
        }
        hidden={araIskontoHidden}
      />
      <FooterRow
        title={localization.brutToplam}
        content={
          <Form.Item name="brutToplamTutar" noStyle>
            <FooterCurrencyContent parent />
          </Form.Item>
        }
        space
        parent
      />
      <MyDivider />
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.stopajOran !== currentValues.stopajOran
        }
      >
        {({ getFieldValue }) => {
          const currentStopajOran = getFieldValue("stopajOran");
          let hidden = false;
          if (currentStopajOran !== undefined) {
            if (currentStopajOran > 0) {
              hidden = false;
            } else {
              hidden = true;
            }
          }

          return (
            <FooterRow
              title={
                <Form.Item name="stopajOran" noStyle>
                  {`${localization.stopajOran} (%${currentStopajOran})`}
                </Form.Item>
              }
              content={
                <Form.Item name="stopajTutar" noStyle>
                  <FooterCurrencyContent />
                </Form.Item>
              }
              space
              addonAfter={
                <Form.Item noStyle name="stopajOran">
                  <Radio.Group buttonStyle="outline">
                    <Radio.Button value={0}>
                      <CloseOutlined />
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              }
              hidden={hidden}
            />
          );
        }}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.toplamOtvTutar !== currentValues.toplamOtvTutar
        }
      >
        {({ getFieldValue }) => {
          const currentToplamOtvTutar = getFieldValue("toplamOtvTutar");
          let hidden = false;
          if (currentToplamOtvTutar !== undefined) {
            if (currentToplamOtvTutar > 0) {
              hidden = false;
            } else {
              hidden = true;
            }
          }
          return (
            <FooterRow
              title={localization.toplamOtv}
              content={
                <Form.Item name="toplamOtvTutar" noStyle>
                  <FooterCurrencyContent />
                </Form.Item>
              }
              space
              hidden={hidden}
            />
          );
        }}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.toplamKdv01Tutar !== currentValues.toplamKdv01Tutar
        }
      >
        {({ getFieldValue }) => {
          const currentToplamKdv01Tutar = getFieldValue("toplamKdv01Tutar");
          let hidden = false;
          if (currentToplamKdv01Tutar !== undefined) {
            if (currentToplamKdv01Tutar > 0) {
              hidden = false;
            } else {
              hidden = true;
            }
          }
          return (
            <FooterRow
              title={`${localization.toplamKdv} (%1)`}
              tutar={"0"}
              content={
                <Form.Item name="toplamKdv01Tutar" noStyle>
                  <FooterCurrencyContent />
                </Form.Item>
              }
              hidden={hidden}
            />
          );
        }}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.toplamKdv08Tutar !== currentValues.toplamKdv08Tutar
        }
      >
        {({ getFieldValue }) => {
          const currentToplamKdv08Tutar = getFieldValue("toplamKdv08Tutar");
          let hidden = false;
          if (currentToplamKdv08Tutar !== undefined) {
            if (currentToplamKdv08Tutar > 0) {
              hidden = false;
            } else {
              hidden = true;
            }
          }
          return (
            <FooterRow
              title={`${localization.toplamKdv} (%8)`}
              content={
                <Form.Item name="toplamKdv08Tutar" noStyle>
                  <FooterCurrencyContent />
                </Form.Item>
              }
              hidden={hidden}
            />
          );
        }}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.toplamKdv18Tutar !== currentValues.toplamKdv18Tutar
        }
      >
        {({ getFieldValue }) => {
          const currentToplamKdv18Tutar = getFieldValue("toplamKdv18Tutar");
          let hidden = false;
          if (currentToplamKdv18Tutar !== undefined) {
            if (currentToplamKdv18Tutar > 0) {
              hidden = false;
            } else {
              hidden = true;
            }
          }
          return (
            <FooterRow
              title={`${localization.toplamKdv} (%18)`}
              tutar={"0"}
              content={
                <Form.Item name="toplamKdv18Tutar" noStyle>
                  <FooterCurrencyContent />
                </Form.Item>
              }
              hidden={hidden}
            />
          );
        }}
      </Form.Item>
      <FooterRow
        title={localization.toplamKdv}
        content={
          <Form.Item name="toplamKdvTutar" noStyle>
            <FooterCurrencyContent parent />
          </Form.Item>
        }
        space
        parent
        addonAfter={
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Form.Item name="tevkifatOran">
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio.Button style={{ borderWidth: 0 }} value={100}>
                        {localization.tumuneTevkifatUygula}
                      </Radio.Button>
                      <Radio.Button style={{ borderWidth: 0 }} value={90}>
                        9/10 {localization.tevkifatUygula}
                      </Radio.Button>
                      <Radio.Button style={{ borderWidth: 0 }} value={70}>
                        7/10 {localization.tevkifatUygula}
                      </Radio.Button>
                      <Radio.Button style={{ borderWidth: 0 }} value={50}>
                        5/10 {localization.tevkifatUygula}
                      </Radio.Button>
                      <Radio.Button style={{ borderWidth: 0 }} value={30}>
                        3/10 {localization.tevkifatUygula}
                      </Radio.Button>
                      <Radio.Button style={{ borderWidth: 0 }} value={20}>
                        2/10 {localization.tevkifatUygula}
                      </Radio.Button>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Menu>
            }
          >
            <Button icon={<PlusOutlined />}></Button>
          </Dropdown>
        }
      />
      <MyDivider />
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.tevkifatOran !== currentValues.tevkifatOran
        }
      >
        {({ getFieldValue }) => {
          const currentTevkifatOran = getFieldValue("tevkifatOran");
          let hidden = false;
          if (currentTevkifatOran !== undefined) {
            if (currentTevkifatOran > 0) {
              hidden = false;
            } else {
              hidden = true;
            }
          }
          return (
            <FooterRow
              title={
                <Form.Item name="tevkifatOran" noStyle>
                  {`${localization.kdvTevkifati} (%${currentTevkifatOran})`}
                </Form.Item>
              }
              content={
                <Form.Item name="tevkifatTutar" noStyle>
                  <FooterCurrencyContent />
                </Form.Item>
              }
              space
              addonAfter={
                <Form.Item noStyle name="tevkifatOran">
                  <Radio.Group buttonStyle="outline">
                    <Radio.Button value={0}>
                      <CloseOutlined />
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              }
              hidden={hidden}
            />
          );
        }}
      </Form.Item>
      <FooterRow
        title={localization.genelToplam}
        content={
          <Form.Item name="genelToplamTutar">
            <FooterCurrencyContent parent />
          </Form.Item>
        }
        space
        parent
      />
    </Card>
  );
}
