import React, { useEffect, useState } from "react";
import { message, Select, SelectProps } from "antd";
import strings from "../../utils/Localization";
import { useDispatch, useSelector } from "react-redux";
import { setDovizList } from "../../redux/actions/dovizAction";
import useApi from "../../hooks/useApi";

const { Option } = Select;

interface IProps extends SelectProps<any> {
  showKod?: boolean;
  onDovizData?: (dovizData: []) => void;
  onSelect: (value: any) => void;
}

const DovizSelectOption: React.FC<IProps> = ({
  showKod,
  onDovizData = () => {},
  onSelect = () => {},
  placeholder,
  ...rest
}) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const dispatch = useDispatch();
  const api = useApi();
  const { dovizPlaceHolder }: any = strings.dovizSelectOption;
  const dovizListReducer: any = useSelector<any>(
    (state) => state.dovizListReducer
  );

  useEffect(() => {
    if (dovizListReducer.length === 0) {
      fetchDovizGrup();
    } else {
      onDovizData(dovizListReducer);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDovizGrup = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/doviz/get", {
        SortOptions: {
          ColumnName: "Id",
          SortType: "Asc",
        },
      });
      if (response.status === 200) {
        dispatch(setDovizList(response.data.data));
        onDovizData(response.data.data);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Select
      {...rest}
      placeholder={dovizPlaceHolder}
      loading={fetchLoading}
      onSelect={onSelect}
      onClear={() => onSelect(null)}
    >
      {dovizListReducer.map((item: any, index: number) => (
        <Option key={index} value={String(item.id)}>
          {showKod ? item.kod : item.ad}
        </Option>
      ))}
    </Select>
  );
};

export default DovizSelectOption;
