import { message } from "antd";
import * as actionTypes from "./actionTypes";
import axios from 'axios'

export const fetchKasa = (kasaId, api = axios) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_KASA_REQUEST });
    const response1 = await api.post("kasa/get", {
      Includes: ["Doviz"],
      Data: {
        Id: kasaId,
      },
    });
    if (response1.status === 200) {
      dispatch({
        type: actionTypes.FETCH_KASA_SUCCESS,
        payload: response1.data.data[0],
      });
    }
    const response2 = await api.post(`/kasa/getsonbakiye/${kasaId}`);
    if (response2.status === 200) {
      dispatch({
        type: actionTypes.FETCH_KASA_SUCCESS,
        payload: response2.data.data,
      });
    }
  } catch (error) {
    message.error(error.message);
    dispatch({ type: actionTypes.FETCH_KASA_FAILURE, payload: error.message });
  }
};