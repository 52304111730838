import React, { useState } from 'react'
import { Button, message, notification, PageHeader, Popconfirm, Space, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import strings from '../utils/Localization'
import FetchListContent from '../components/toolbox/FetchListContent'
import { EditOutlined, DeleteOutlined, UserAddOutlined } from '@ant-design/icons'
import { KullaniciEkleModal } from '../components/kullanici'
import { useDispatch } from 'react-redux'
import { setRerender } from '../redux/actions/rerenderAction'
import useApi from '../hooks/useApi'

function KullaniciListe() {
  const [data, setData] = useState([])
  const [kullaniciEkleModalVisible, setKullaniciEkleModalVisible] = useState(false)
  const navigate= useNavigate()
  const dispatch = useDispatch()
  const api = useApi()
  const { kullaniciListe: localization } = strings

  const remove = async (kullaniciId) => {
    try {
      const response = await api.delete(`/kullanici/${kullaniciId}`, {})
      if (response.status === 200) {
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
        dispatch(setRerender())
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  const columns = [
    {
      title: localization.kullaniciList.adSoyad,
      dataIndex: 'adSoyad',
      key: 'adSoyad',
    },
    {
      title: localization.kullaniciList.eMail,
      dataIndex: 'eMail',
      key: 'eMail',
    },
    {
      title: localization.kullaniciList.telefonNo,
      dataIndex: 'telefonNo',
      key: 'telefonNo',
    },
    {
      title: localization.kullaniciList.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={localization.kullaniciList.kullaniciDuzenle}>
            <Button
              onClick={() => {
                navigate(`/KullaniciDuzenle/${row.id}`)
                // setSelectedKullaniciId(row.id)
                // setKullaniciDuzenleMoldalVisible(true)
              }}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={localization.kullaniciList.kullaniciSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => {
                remove(row.id)
              }}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <>
      <PageHeader
        onBack={() => navigate(-1)}
        ghost={false}
        title={localization.baslik}
        extra={
          <Button
            icon={<UserAddOutlined />}
            onClick={() => setKullaniciEkleModalVisible(true)}
            type="primary"
            size="large"
          >
            {localization.kullaniciEkleButton}
          </Button>
        }
      />
      <br />
      <FetchListContent
        requestUrl={'/kullanici/get'}
        requestBody={{
          SortOptions: {
            ColumnName: 'Id',
            SortType: 'Asc',
          },
        }}
        columns={columns}
        dataSource={data}
        onDataSource={(data) => setData(data)}
      />
      <KullaniciEkleModal
        visible={kullaniciEkleModalVisible}
        handleCancel={() => {
          setKullaniciEkleModalVisible(false)
        }}
      />
    </>
  )
}

export default KullaniciListe
