import React, { useState, useEffect } from 'react'
import { Modal, Form, message, notification } from 'antd'
import strings from '../../utils/Localization'
import TahsilatOdemeForm from '../cariIslem/TahsilatOdemeForm'
import { cariIslemTurTypes } from '../../types/cariIslemTurTypes'
import { useDispatch } from 'react-redux'
import { setRerender } from '../../redux/actions/rerenderAction'
import useEvrakNo from '../../hooks/useEvrakNo'
import useApi from '../../hooks/useApi'

function CariAltOdemeEkle({
  visible = false,
  handleCancel,
  cariKartId = null,
  cariIslemId = null,
  dovizId,
  kalanTutar = 0,
}) {
  const [fetchLoading, setFetchLoading] = useState(false)
  const [selectedDovizId, setSelectedDovizId] = useState(null)
  const [selectedCariKartId, setSelectedCariKartId] = useState(null)
  const [selectedCariIslemId, setSelectedCariIslemId] = useState(null)
  const [form] = Form.useForm()
  const { odemeEkle } = strings.cariIslem
  const dispatch = useDispatch()
  const { getCariIslemEvrakNo } = useEvrakNo()
  const api = useApi()

  useEffect(() => {
    getCariIslemEvrakNo().then((evrakNo) => {
      form.setFieldsValue({ evrakNo: evrakNo })
    })
    setSelectedDovizId(String(dovizId))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedCariIslemId(cariIslemId)
    setSelectedCariKartId(cariKartId)
    form.setFieldsValue({ tutar: kalanTutar })
  }, [cariIslemId, cariKartId, kalanTutar]) // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const { islemTarihi, evrakNo, aciklama, dovizId, odemeSekli, kasaId, bankaHesapId, tutar } = values

      if (tutar > kalanTutar) {
        return form.setFields([{ name: 'tutar', value: tutar, errors: [odemeEkle.tutarUyari(kalanTutar)] }])
      }

      setFetchLoading(true)
      const response = await api.post('/cariislem/add', {
        CariKartId: selectedCariKartId,
        CariIslemId: selectedCariIslemId,
        DovizId: dovizId,
        BankaHesapId: bankaHesapId,
        KasaId: kasaId,
        CariIslemTur: cariIslemTurTypes.ODEME,
        EvrakNo: evrakNo,
        Aciklama: aciklama,
        Carpan: 1,
        Tutar: tutar,
        VadeTarih: islemTarihi,
        IslemTarih: islemTarihi,
        OdemeSekli: odemeSekli,
      })
      if (response.status === 200) {
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
        form.resetFields()
        getCariIslemEvrakNo().then((evrakNo) => {
          form.setFieldsValue({ evrakNo: evrakNo })
        })
        dispatch(setRerender())
        handleCancel()
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  return (
    <Modal
      title={odemeEkle.baslik}
      visible={visible}
      onCancel={handleCancel}
      width={'60%'}
      okButtonProps={{
        size: 'large',
        form: 'odemeEkleForm',
        htmlType: 'submit',
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: 'large' }}
      centered
    >
      <TahsilatOdemeForm
        form={form}
        id="odemeEkleForm"
        onFinish={onFinish}
        dovizDisabled={true}
        initialValues={{
          dovizId: selectedDovizId,
        }}
      />
    </Modal>
  )
}

export default CariAltOdemeEkle
