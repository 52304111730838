import React, { useState } from "react";
import { Card, Col, PageHeader, Space, Form, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import DatepickerRanger from "../components/toolbox/DatepickerRanger";
import StokSelectOption from "../components/toolbox/StokSelectOption";
import StokKategoriSelectOption from "../components/toolbox/StokKategoriSelectOption";
import strings from "../utils/Localization";
import moment from "moment";
import useApi from "../hooks/useApi";

function StokHareketRapor() {
  const [form] = Form.useForm();
  const [stokHidden, setStokHidden] = useState(true);
  const [stokRequestBody, setStokRequestBody] = useState({});
  const [fetchLoading, setFetchLoading] = useState(false);
  const navigate= useNavigate();
  const api = useApi();
  const { stokHareketRapor: localization } = strings;

  const onFinish = async (values) => {
    try {
      const { tarih, stokKategoriId, stokId } = values;

      const baslangicTarihi = moment(tarih[0]).format("YYYY-MM-DD");
      const bitisTarihi = moment(tarih[1]).format("YYYY-MM-DD");

      const pdfWindow = window.open(`${document.location.origin}/#/RaporYukleniyor`);
      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/stokhareket",
        {
          BaslangicTarih: `${baslangicTarihi} 00:00:00`,
          BitisTarih: `${bitisTarihi} 23:59:59`,
          StokId: stokId,
          StokKategoriId: stokKategoriId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        pdfWindow.document.location.href = response.data.data;
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.stokKategoriId !== undefined) {
      setStokHidden(false);
      setStokRequestBody({
        Data: {
          StokKategoriId: changedValues.stokKategoriId,
        },
      });
      form.setFieldsValue({
        stokId: undefined,
      });
    }
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <PageHeader
        title={localization.baslik}
        onBack={() => navigate(-1)}
        ghost={false}
      />
      <Col xxl={8} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card>
          <Form
            form={form}
            layout="vertical"
            size="middle"
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            initialValues={{
              tarih: [moment().add("months", -12), moment()],
            }}
          >
            <Form.Item
              name="tarih"
              label={localization.tarihSeciniz}
              rules={[{ required: true }]}
            >
              <DatepickerRanger />
            </Form.Item>
            <Form.Item
              name="stokKategoriId"
              label={strings.stokKategoriSelectOption.stokKategoriBaslik}
              rules={[{ required: true }]}
            >
              <StokKategoriSelectOption />
            </Form.Item>
            <Form.Item name="stokId" hidden={stokHidden}>
              <StokSelectOption requestBody={stokRequestBody} />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                style={{ float: "right" }}
                type="primary"
                size="large"
                htmlType="submit"
                loading={fetchLoading}
              >
                {localization.stokHareketRaporuAl}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Space>
  );
}

export default StokHareketRapor;
