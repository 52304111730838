import React, { useState, useEffect } from "react";
import { Card, Form, message, notification } from "antd";
import { TeklifSiparisLayout } from "../components/teklifSiparisDuzenle";
import { useParams } from "react-router-dom";
import moment from "moment";
import strings from "../utils/Localization";
import useApi from "../hooks/useApi";

function TeklifSiparisDuzenle() {
  const [form] = Form.useForm();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { teklifSiparisId } = useParams();
  const api = useApi();
  const { teklifSiparisDuzenle: localization } = strings;

  useEffect(() => {
    fetchTeklifSiparis();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        teklifSiparisId,
        evrakNo,
        durumId,
        firmaAdi,
        yetkili,
        vergiDairesi,
        vergiNumarasi,
        telefon,
        cepTelefonu,
        eMail,
        duzenlemeTarihi,
        gecerlilikTarihi,
        aciklama,
        stopajOran,
        stopajTutar,
        tevkifatOran,
        tevkifatTutar,
        araToplamTutar,
        araIskontoOran,
        araIskontoTutar,
        araIskontoTip,
        toplamSatirIskontoTutar,
        genelToplamTutar,
        teklifSiparisSatir,
      } = values;

      let requestData = {
        Id: teklifSiparisId,
        FirmaAdi: firmaAdi,
        Yetkili: yetkili,
        VergiDairesi: vergiDairesi,
        VergiNumarasi: vergiNumarasi,
        Telefon: telefon,
        CepTelefonu: cepTelefonu,
        EMail: eMail,
        TeklifSiparisNo: evrakNo,
        TeklifSiparisTarihi: moment(duzenlemeTarihi),
        GecerlilikTarihi: moment(gecerlilikTarihi),
        Aciklama: aciklama,
        StopajOran: stopajOran,
        StopajTutar: stopajTutar,
        TevkifatOran: tevkifatOran,
        TevkifatTutar: tevkifatTutar,
        Toplam: araToplamTutar,
        AraToplamIskontoOran: araIskontoOran,
        AraToplamIskontoTutar: araIskontoTutar,
        AraToplamIskontoTip: araIskontoTip === "yuzde" ? "Oran" : "Tutar",
        SatirIskontoToplamTutar: toplamSatirIskontoTutar,
        GenelToplam: genelToplamTutar,
        UpdatedAt: moment(),
        Teklifsiparissatir: teklifSiparisSatir
          .filter((x) => x.hidden === false)
          .map((teklifSiparisSatirItem) => {
            if (teklifSiparisSatirItem.id != null) {
              return {
                //eğer güncellenen satir ise
                Id: teklifSiparisSatirItem.id,
                Miktar: teklifSiparisSatirItem.miktar,
                BirimFiyat: teklifSiparisSatirItem.birimFiyat,
                Aciklama: teklifSiparisSatirItem.aciklama,
                OtvTutar: teklifSiparisSatirItem.otvTutar,
                OtvOran: teklifSiparisSatirItem.otvOran,
                KdvTutar: teklifSiparisSatirItem.kdvTutar,
                KdvOran: teklifSiparisSatirItem.kdvOran,
                UrunAdi: teklifSiparisSatirItem.urunAdi,
                UpdatedAt: moment(),
                Teklifsiparissatiriskonto: teklifSiparisSatirItem.iskontoOran
                  ? [
                    {
                      Id: teklifSiparisSatirItem.iskontoId,
                      Oran: teklifSiparisSatirItem.iskontoYuzde,
                      Tutar: teklifSiparisSatirItem.iskontoTutar,
                      IskontoTip:
                        teklifSiparisSatirItem.iskontoTip === "yuzde"
                          ? "Oran"
                          : "Tutar",
                      UygulananTutar:
                        teklifSiparisSatirItem.iskontoUygulananTutar,
                      SiraNo: teklifSiparisSatirItem.iskontoSiraNo,
                    },
                  ]
                  : null,
                GenelToplam: teklifSiparisSatirItem.toplam,
              };
            } else {
              return {
                //eğer yeni eklenen satir ise
                StokVaryantGrupId: teklifSiparisSatirItem.stokVaryantGrupId,
                StokBirimId: teklifSiparisSatirItem.stokBirimId,
                UrunAdi: teklifSiparisSatirItem.urunAdi,
                Miktar: teklifSiparisSatirItem.miktar,
                BirimFiyat: teklifSiparisSatirItem.birimFiyat,
                Barkod: teklifSiparisSatirItem.barkod,
                Aciklama: teklifSiparisSatirItem.aciklama,
                OtvTutar: teklifSiparisSatirItem.otvTutar,
                OtvOran: teklifSiparisSatirItem.otvOran,
                KdvTutar: teklifSiparisSatirItem.kdvTutar,
                KdvOran: teklifSiparisSatirItem.kdvOran,
                Teklifsiparissatiriskonto: teklifSiparisSatirItem.iskontoOran
                  ? [
                    {
                      Oran: teklifSiparisSatirItem.iskontoYuzde,
                      Tutar: teklifSiparisSatirItem.iskontoTutar,
                      IskontoTip:
                        teklifSiparisSatirItem.iskontoTip === "yuzde"
                          ? "Oran"
                          : "Tutar",
                      UygulananTutar:
                        teklifSiparisSatirItem.iskontoUygulananTutar,
                      SiraNo: 0,
                    },
                  ]
                  : null,
                GenelToplam: teklifSiparisSatirItem.toplam,
              };
            }
          }),
        Teklifsiparisdurum: [
          {
            TeklifSiparisDurumTipId: durumId,
            // Aciklama: "Test",
            IslemTarihi: moment().format("YYYY-MM-DD HH:MM:SS")
          }
        ]
      };
      setFetchLoading(true);
      const response = await api.put("/teklifSiparis", requestData);

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        fetchTeklifSiparis();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const fetchTeklifSiparis = async () => {
    try {
      setLoading(true);
      const response = await api.post("/teklifsiparis/get", {
        Data: {
          Id: teklifSiparisId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: [
              "id",
              "cariKartId",
              "dovizId",
              "tip",
              "firmaAdi",
              "yetkili",
              "vergiDairesi",
              "vergiNumarasi",
              "telefon",
              "cepTelefonu",
              "eMail",
              "cariKod",
              "teklifSiparisNo",
              "teklifSiparisTarihi",
              "gecerlilikTarihi",
              "aciklama",
              "stopajOran",
              "stopajTutar",
              "tevkifatOran",
              "tevkifatTutar",
              "toplam",
              "satirIskontoToplamTutar",
              "araToplamIskontoOran",
              "araToplamIskontoTutar",
              "araToplamIskontoTip",
              "genelToplam",
              "teklifSiparisNo"
            ],
          },
          {
            TableName: "Teklifsiparissatir",
            ColumnNames: [
              "id",
              "teklifSiparisId",
              "stokVaryantGrupId",
              "stokBirimId",
              "urunAdi",
              "miktar",
              "birimFiyat",
              "barkod",
              "aciklama",
              "otvTutar",
              "otvOran",
              "oivTutar",
              "oivOran",
              "kdvTutar",
              "kdvOran",
              "genelToplam",
            ],
          },
          {
            TableName: "Teklifsiparissatir.Teklifsiparissatiriskonto",
            ColumnNames: [
              "id",
              "teklifSiparisSatirId",
              "oran",
              "tutar",
              "iskontoTip",
              "siraNo",
              "uygulananTutar",
            ],
          },
          {
            TableName:
              "Teklifsiparissatir.StokVaryantGrup.StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger",
            ColumnNames: [
              "id",
              "stokVaryantId",
              "ad",
            ],
          },
          {
            TableName: "Teklifsiparisdurum",
            ColumnNames: [
              "Id",
              "aciklama"
            ],
            Operator: "Last"
          },
          {
            TableName: "Teklifsiparisdurum.Teklifsiparisdurumtip",
            ColumnNames: [
              "Id",
              "tip",
              "ad"
            ]
          }
        ],
      });

      if (response.status === 200) {
        const result = response.data.data[0];

        form.setFieldsValue({
          teklifSiparisId: result.id,
          teklifSiparisTip: result.tip,
          cariKartId: String(result.cariKartId),
          dovizId: String(result.dovizId),
          duzenlemeTarihi: moment(result.duzenlemeTarihi),
          gecerlilikTarihi: moment(result.gecerlilikTarihi),
          evrakNo: result.teklifSiparisNo,
          durumId: result?.Teklifsiparisdurum ? String(result.Teklifsiparisdurum.Teklifsiparisdurumtip.Id) : null,
          firmaAdi: result.firmaAdi,
          yetkili: result.yetkili,
          vergiDairesi: result.vergiDairesi,
          vergiNumarasi: result.vergiNumarasi,
          telefon: result.telefon,
          cepTelefonu: result.cepTelefonu,
          eMail: result.eMail,
          aciklama: result.aciklama,
          tip: result.tip,
          teklifSiparisSatir: result.Teklifsiparissatir.map((satirItem) => {
            let satiriskontoLength = satirItem.Teklifsiparissatiriskonto.length;

            return {
              id: satirItem.id,
              stokBirimId: satirItem.stokBirimId,
              urunAdi: satirItem.urunAdi,
              barkod: satirItem.barkod,
              stokId: satirItem.stokVaryantGrupId,
              stokVaryantGrupId: satirItem.stokVaryantGrupId,
              aciklama: satirItem.aciklama,
              miktar: satirItem.miktar,
              birimFiyat: satirItem.birimFiyat,
              otvTutar: satirItem.otvTutar,
              otvOran: satirItem.otvOran,
              kdvTutar: satirItem.kdvTutar,
              kdvOran: satirItem.kdvOran,
              toplam: satirItem.genelToplam,
              iskontoId:
                satiriskontoLength === 0
                  ? null
                  : satirItem.Teklifsiparissatiriskonto[0].id,
              iskontoOran:
                satiriskontoLength === 0
                  ? 0
                  : satirItem.Teklifsiparissatiriskonto[0].oran,
              iskontoTip:
                satiriskontoLength === 0
                  ? "yuzde"
                  : satirItem.Teklifsiparissatiriskonto[0].iskontoTip === "Oran"
                    ? "yuzde"
                    : "tutar",
              iskontoTutar:
                satiriskontoLength === 0
                  ? 0
                  : satirItem.Teklifsiparissatiriskonto[0].tutar,
              iskontoSiraNo:
                satiriskontoLength === 0
                  ? 0
                  : satirItem.Teklifsiparissatiriskonto[0].siraNo,
              iskontoUygulananTutar:
                satiriskontoLength === 0
                  ? null
                  : satirItem.Teklifsiparissatiriskonto[0].uygulananTutar,
              iskontoYuzde:
                satiriskontoLength === 0
                  ? 0
                  : satirItem.Teklifsiparissatiriskonto[0].oran,
              yeniSatirMi: false,
            };
          }),
          araIskontoOran: result.araToplamIskontoOran,
          araIskontoTip:
            result.araToplamIskontoTip === "Oran" ? "yuzde" : "tutar",
          araIskontoTutar: result.araToplamIskontoTutar,
          araIskontoDeger:
            result.araToplamIskontoTip === "Oran"
              ? result.araToplamIskontoOran
              : result.araToplamIskontoTutar,
          stopajOran: result.stopajOran,
          stopajTutar: result.stopajTutar,
          tevkifatOran: result.tevkifatOran,
          tevkifatTutar: result.tevkifatTutar,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <Card loading={loading} />;
  }

  return (
    <>
      <TeklifSiparisLayout
        form={form}
        title={localization.baslik}
        saveButtonTitle={localization.guncelleButon}
        onFinish={onFinish}
        saveButtonLoading={fetchLoading}
      />
    </>
  );
}

export default TeklifSiparisDuzenle;
