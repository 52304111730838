import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import strings from "../../utils/Localization";
import { RangePickerBaseProps } from "antd/lib/date-picker/generatePicker";

interface IProps extends RangePickerBaseProps<any> {}

const DatepickerRanger: React.FC<IProps> = ({ placeholder, ...rest }) => {
  moment.updateLocale("en", {
    weekdaysMin: strings.tarih.hafta,
    monthsShort: strings.tarih.ay,
  });

  return (
    <DatePicker.RangePicker
      {...rest}
      placeholder={
        placeholder || [strings.tarih.baslangicTarih, strings.tarih.bitisTarihi]
      }
      style={{ width: "100%" }}
      format={strings.tarih.format}
    />
  );
};

export default DatepickerRanger;
