import React, { useState, useEffect } from "react";
import { Modal, Form, Radio, Input, message, notification } from "antd";
import Datepicker from "../toolbox/DatePicker";
import moment from "moment";
import { useParams } from "react-router-dom";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import CurrencyInput from "../toolbox/CurrencyInput";
import useApi from "../../hooks/useApi";

const formSize = "middle";

function BankaHesapIslemDuzenle({ visible = false, handleCancel, data = {} }) {
  const [form] = Form.useForm();
  const { bankaHesapId } = useParams();
  const dispatch = useDispatch();
  const [fetchLoading, setFetchLoading] = useState(false);
  const { BankaHesapIslemDuzenleModal: localization } = strings.bankaHesapIslem;
  const api = useApi();
  
  useEffect(() => {
    if (data !== {}) {
      const { id, islemTarihi, girisMi, evrakNo, aciklama, tutar } = data;
      form.setFieldsValue({
        id,
        islemTarihi: moment(islemTarihi),
        girisMi,
        evrakNo,
        aciklama,
        tutar,
      });
    } else form.resetFields();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const { id, islemTarihi, girisMi, evrakNo, aciklama, tutar } = values;
      setFetchLoading(true);
      const response = await api.put("/bankahesapislem/", {
        Id: id,
        BankaHesapId: bankaHesapId,
        EvrakNo: evrakNo,
        Aciklama: aciklama,
        VirmanMi: 0,
        GirisMi: girisMi ? 1 : 0,
        Tutar: tutar,
        IslemTarihi: islemTarihi,
        Carpan: 1,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "bankaHesapIslemDuzenleForm",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      width="50%"
    >
      <Form
        form={form}
        id="bankaHesapIslemDuzenleForm"
        size={formSize}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="islemTarihi"
          label={localization.islemTarihi}
          rules={[{ required: true }]}
        >
          <Datepicker />
        </Form.Item>
        <Form.Item
          name="girisMi"
          label={localization.islemTipi}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>{localization.islemTipiSelect.giris}</Radio>
            <Radio value={false}>{localization.islemTipiSelect.cikis}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="evrakNo" label={localization.evrakNo}>
          <Input />
        </Form.Item>
        <Form.Item name="aciklama" label={localization.aciklama}>
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item
          name="tutar"
          label={localization.tutar}
          rules={[{ required: true }]}
        >
          <CurrencyInput />
        </Form.Item>
        <Form.Item name="id" noStyle hidden />
      </Form>
    </Modal>
  );
}

export default BankaHesapIslemDuzenle;
