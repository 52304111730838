import React, { useState } from "react";
import { Button, Space } from "antd";
import strings from "../../utils/Localization";
import TeklifSiparisDurumTipEkleModal from "./TeklifSiparisDurumTipEkleModal";

export default function IslemButton() {
  const [teklifSiparisDurumEkleVisible, setTeklifSiparisDurumEkleVisible] = useState(false)
  const { islemButton } = strings.teklifSiparisDurum;

  return (
    <>
      <Space direction="horizontal">
        <Button
          type="primary"
          size="large"
          onClick={() => setTeklifSiparisDurumEkleVisible(true)}
        >
          {islemButton.durumTipEkle}
        </Button>
      </Space>
      <TeklifSiparisDurumTipEkleModal handleCancel={() => setTeklifSiparisDurumEkleVisible(false)} visible={teklifSiparisDurumEkleVisible} />
    </>
  );
}
