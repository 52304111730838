import React from "react";
import { DatePicker as MyDatePicker } from "antd";
import moment from "moment";
import strings from "../../utils/Localization";
import { PickerBaseProps } from "antd/lib/date-picker/generatePicker";

interface IProps extends PickerBaseProps<any> {
  style: React.CSSProperties;
}

const DatePicker: React.FC<IProps> = ({ placeholder, style, ...rest }) => {
  moment.updateLocale("en", {
    weekdaysMin: strings.tarih.hafta,
    monthsShort: strings.tarih.ay,
  });
  
  moment.fn.toJSON = function () {
    return this.format("YYYY-MM-DD");
  };
  
  return (
    <MyDatePicker
      {...rest}
      placeholder={placeholder || strings.tarih.tarihSeciniz}
      format={strings.tarih.format}
      style={{ width: "100%", ...style }}
    />
  );
};

export default DatePicker;
