import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};
const selectedBankaHesapReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BANKA_HESAP_REQUEST:
      return { data: [], error: "", isLoading: true };
    case actionTypes.FETCH_BANKA_HESAP_SUCCESS:
      return {
        data: { ...state.data, ...action.payload },
        error: "",
        isLoading: false,
      };
    case actionTypes.FETCH_BANKA_HESAP_FAILURE:
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

export default selectedBankaHesapReducer;
