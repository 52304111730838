import React, { useState } from "react";
import { PageHeader } from "antd";
import { useNavigate } from "react-router-dom";
import strings from "../utils/Localization";
import FetchListContent from "../components/toolbox/FetchListContent";

export default function DovizListe() {
  const [data, setData] = useState([]);
  const navigate= useNavigate();
  const { dovizListesi: localization } = strings;

  const columns = [
    {
      title: localization.dovizList.dovizAdi,
      dataIndex: "ad",
      key: "ad",
    },
    {
      title: localization.dovizList.dovizKodu,
      dataIndex: "kod",
      key: "kod",
    },
    {
      title: localization.dovizList.dovizSimgesi,
      dataIndex: "simge",
      key: "simge",
    },
  ];

  return (
    <>
      <PageHeader
        onBack={() => navigate(-1)}
        ghost={false}
        title={localization.baslik}
      />
      <br />
      <FetchListContent
        requestUrl={"/doviz/get"}
        requestBody={{
          SortOptions: {
            ColumnName: "Id",
            SortType: "Asc",
          },
        }}
        columns={columns}
        dataSource={data}
        onDataSource={(data) => setData(data)}
      />
    </>
  );
}
