export const eFaturaTypes = {
    E_ARSIV_FATURA: "EARSIVFATURA",
    E_FATURA: "EFATURA",
    TEMEL_FATURA: "TEMELFATURA",
    TICARI_FATURA: "TICARIFATURA",
  };

  export const senderTypes={
    ELEKTRONIK:"ELEKTRONIK",
    KAGIT:"KAGIT"
  }
  