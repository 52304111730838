import React from 'react'
import { Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import trFlag from '../../../assets/images/countryFlags/tr.png'
import enFlag from '../../../assets/images/countryFlags/en.png'
import { useSelector, useDispatch } from 'react-redux'
import { changeLanguage } from '../../../redux/actions/languageAction'

const DilSecim = (props) => {
    const dipatch = useDispatch()
    const currentLanguage = useSelector(state => state.languageReducer)

    const _changeLanguage = (e) => {
        dipatch(changeLanguage(e.key))
    }

    const displayLanguage = () => {
        switch (currentLanguage) {
            case 'tr':
                return "Turkish"
            case 'en':
                return "English"
            default:
                return null
        }
    }

    const displayCountryFlag = () => {
        const style = { marginRight: 6 }
        switch (currentLanguage) {
            case 'tr':
                return <img src={trFlag} width={24} style={style} alt="geçerli dili" />
            case 'en':
                return <img src={enFlag} width={24} style={style} alt="current language" />
            default:
                return null
        }
    }

    const languageMenu = (
        <Menu onClick={(e) => _changeLanguage(e)}>
            <Menu.Item key="tr">
                Turkish
            </Menu.Item>
            <Menu.Item key="en">
                English
            </Menu.Item>
        </Menu>
    )

    const contentStyle = { cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: props.alignRight ? 'flex-end' : 'flex-start' }
    return (
        <Dropdown overlay={languageMenu} trigger={['click']}>
            <div
                style={contentStyle}
                onClick={e => e.preventDefault()}
            >
                {displayCountryFlag()}
                {displayLanguage()} <DownOutlined style={{ marginLeft: 6 }} />
            </div>
        </Dropdown>
    )
}

export default DilSecim
