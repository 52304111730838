import React, { useEffect, useState } from 'react'
import { message, Select, SelectProps } from 'antd'
import strings from '../../utils/Localization'
import useApi from '../../hooks/useApi'

const { Option } = Select

interface IProps extends SelectProps<any> {
  requestBody: {}
  tip: string
}

const TeklifSiparisDurumTipSelectOption: React.FC<IProps> = ({ placeholder, tip, ...rest }) => {
  const [fetchLoading, setFetchLoading] = useState(false)
  const [durumData, setDurumData] = useState([])
  const api = useApi()

  useEffect(() => {
    fetchDurum()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDurum = async () => {
    try {
      setFetchLoading(true)
      const response = await api.post('/teklifsiparisdurumtip/get', {
        SortOptions: {
          ColumnName: 'Id',
          SortType: 'Asc',
        },
        SelectColumns: [
          {
            ColumnNames: ['id', 'tip', 'ad '],
            TableName: '',
          },
        ],
        Data: {
          Tip: tip,
        },
      })
      if (response.status === 200) {
        setDurumData(response.data.data)
      }
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  return (
    <Select
      {...rest}
      placeholder={placeholder || strings?.teklifSiparisDurumSelectOption?.placeholder}
      loading={fetchLoading}
    >
      {durumData.map((item: any, index: number) => (
        <Option key={index} value={String(item.id)}>
          {String(item?.ad).toLowerCase()}
        </Option>
      ))}
    </Select>
  )
}

export default TeklifSiparisDurumTipSelectOption
