import React from "react";
import { /* Alert,*/ Layout } from "antd";
import SideBar from "../components/navi/SideBar";
import styled, { ThemeProvider } from "styled-components";
import NavBar from "../components/navi/navBar/Index";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom"

const { Header, Content } = Layout;

const MyContent = styled(Content)`
  padding: 24px;
`;

const PanelLayout = () => {
  const currentTheme = useSelector((state) => state.themeReducer);
  const selectedTheme = { selectedTheme: currentTheme };

  return (
    <ThemeProvider theme={selectedTheme}>
      <Layout>
        <SideBar />
        <Layout>
          <Header>
            <NavBar />
          </Header>
          <MyContent>
            {/* <Alert
              type="warning"
              showIcon
              message="Deneme sürümünüzün bitmesine 8 gün kaldı"
              closable
              style={{ fontSize: 15, marginBottom: 21 }}
              banner
            /> */}
            <Outlet />
            <br />
            <br />
            <br />
          </MyContent>
        </Layout>
      </Layout>
    </ThemeProvider>
  );
};

export default PanelLayout;
