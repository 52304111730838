import React, { useState } from "react";
import { Modal, Form, Row, Col, Input, message, notification } from "antd";
import strings from "../../utils/Localization";
import CariGrupSelectOption from "../toolbox/CariGrupSelectOption";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import MaskInput from "antd-mask-input";
import useApi from "../../hooks/useApi";

const formSize = "middle";

function CariKartEkleModal({ visible = false, handleCancel }) {
  const [form] = Form.useForm();
  const [fetchLoading, setFetchLoading] = useState(false);
  const dispatch = useDispatch();
  const { cariKartEkleModal: localization } = strings.cariKart;
  const api = useApi();

  const onFinish = async (values) => {
    try {
      const {
        adres,
        cariGrupId,
        cepTelefonu,
        eMail,
        firmaAdi,
        il,
        ilce,
        sevkAdresi,
        telefon,
        vergiDairesi,
        vergiNumarasi,
        yetkili,
      } = values;
      setFetchLoading(true);

      const response = await api.post("/carikart", {
        CariGrupId: cariGrupId,
        FirmaAdi: firmaAdi,
        Yetkili: yetkili,
        VergIdairesi: vergiDairesi,
        VergiNumarasi: vergiNumarasi,
        Telefon: telefon,
        EMail: eMail,
        CepTelefonu: cepTelefonu,
        Il: il,
        Ilce: ilce,
        Adres: adres,
        SevkAdresi: sevkAdresi,
      });

      if (response.status === 200) {
        form.resetFields();
        dispatch(setRerender());
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "cariKartEkleForm",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      width="60%"
      centered
    >
      <Form
        form={form}
        name="cariKartEkleForm"
        layout="vertical"
        size={formSize}
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col xl={8} lg={8}>
            <Form.Item
              name="firmaAdi"
              label={localization.firmaAdi}
              rules={[{ required: true }]}
            >
              <Input placeholder={localization.firmaAdiPlaceHolder} />
            </Form.Item>
            <Form.Item name="yetkili" label={localization.yetkili}>
              <Input placeholder={localization.yetkiliPlaceHolder} />
            </Form.Item>
            <Form.Item
              name="cariGrupId"
              label={strings.cariGrupSelectOption.cariGrupBaslik}
              rules={[{ required: true }]}
            >
              <CariGrupSelectOption />
            </Form.Item>
            <Form.Item name="vergiDairesi" label={localization.vergiDairesi}>
              <Input placeholder={localization.vergiDairesiPlaceHolder} />
            </Form.Item>
            <Form.Item name="vergiNumarasi" label={localization.vergiNumarasi}>
              <Input placeholder={localization.vergiNumarasiPlaceHolder} />
            </Form.Item>
          </Col>
          <Col xl={8} lg={8}>
            <Form.Item name="telefon" label={localization.telefon}>
              <MaskInput
                mask="(111) 111 11 11"
                placeholder={localization.telefonPlaceHolder}
              />
            </Form.Item>
            <Form.Item name="cepTelefonu" label={localization.cepTelefonu}>
              <MaskInput
                mask="(111) 111 11 11"
                placeholder={localization.cepTelefonuPlaceHolder}
              />
            </Form.Item>
            <Form.Item
              name="eMail"
              label={localization.eMail}
              rules={[{ type: "email" }]}
            >
              <Input placeholder={localization.eMailPlaceHolder} />
            </Form.Item>
          </Col>
          <Col xl={8} lg={8}>
            <Form.Item name="il" label={localization.il}>
              <Input placeholder={localization.ilPlaceHolder} />
            </Form.Item>
            <Form.Item name="ilce" label={localization.ilce}>
              <Input placeholder={localization.ilcePlaceHolder} />
            </Form.Item>
            <Form.Item name="adres" label={localization.adres}>
              <Input.TextArea
                rows={5}
                placeholder={localization.adresPlaceHolder}
              />
            </Form.Item>
            <Form.Item name="sevkAdresi" label={localization.sevkAdresi}>
              <Input.TextArea
                rows={5}
                placeholder={localization.sevkAdresiPlaceHolder}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CariKartEkleModal;
