import { Select, message, SelectProps } from 'antd'
import React, { useState, useEffect } from 'react'
import strings from '../../utils/Localization'
import { SearchOutlined } from '@ant-design/icons'
import useApi from '../../hooks/useApi'

const { Option } = Select

type selectOptionItemType = {
  value: string
  text: string
  data: {}
}

interface IProps extends SelectProps<any> {
  requestBody: {}
  onSelectedStok: (selectedStok: any) => void
}

const StokSelectOption: React.FC<IProps> = ({
  requestBody = {},
  onSelectedStok = () => {},
  placeholder,
  value,
  ...rest
}) => {
  const [selectOptionData, setSelectOptionData] = useState([])
  const [stokData, setStokData] = useState([])
  const api = useApi()
  const { stokPlaceHolder }: any = strings.stokSelectOption

  useEffect(() => {
    if (value) {
      fetchStok('', value).then((res) => {
        onSelectedStok({ ...res[0] })
      })
    }
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStok = async (searchText?: string, stokId?: number) => {
    try {
      const response = await api.post('/stok/get', {
        Data: {
          Id: stokId === undefined ? 0 : stokId,
        },
        SearchText: {
          PropertyNames: ['Ad', 'Aciklama', 'Stokvaryantgrup.Barkod', 'Stokvaryantgrup.Kod'],
          Value: searchText,
        },
        PagingOptions: {
          PageSize: 10,
          PageNumber: 1,
        },
        SelectColumns: [
          {
            TableName: '',
            ColumnNames: ['id', 'stokKategoriId', 'stokBirimId', 'ad', 'aciklama'],
          },
          {
            TableName: 'Stokvaryantgrup',
            ColumnNames: ['id', 'barkod', 'aciklama', 'gtin', 'raf', 'kod'],
          },
          {
            TableName: 'Stokvaryantgrup.StokvaryantGrupfiyat',
            ColumnNames: ['id', 'kdvOran', 'kdvTutar', 'otvOran', 'otvTutar', 'satisMi', 'fiyat', 'genelToplam'],
          },
          {
            TableName: 'Stokvaryantgrup.StokvaryantGrupfiyat.Doviz',
            ColumnNames: ['ad', 'simge', 'kod'],
          },
          {
            TableName: 'Stokvaryantgrup.StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger',
            ColumnNames: ['ad'],
          },
        ],
        ...requestBody,
      })
      if (response.status === 200) {
        let data: any = []
        response.data.data.map((item: any) =>
          data.push({
            text: item.ad,
            value: item.id,
          })
        )
        setStokData(response.data.data)
        setSelectOptionData(data)
        return response.data.data
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }
  const onSearch = (searchText = '') => {
    if (searchText.length === 0) {
      setSelectOptionData([])
    } else {
      fetchStok(searchText)
    }
  }

  return (
    <Select
      {...rest}
      value={value}
      suffixIcon={<SearchOutlined size={24} />}
      showSearch
      onSearch={onSearch}
      notFoundContent={null}
      defaultActiveFirstOption={false}
      showArrow={true}
      filterOption={false}
      placeholder={placeholder || stokPlaceHolder}
      onSelect={(e: any) => {
        const selectedStok = stokData.find((x: any) => x.id === parseInt(e))
        selectedStok !== undefined && onSelectedStok(selectedStok)
      }}
      onClear={() => onSelectedStok({})}
    >
      {selectOptionData.map((item: selectOptionItemType) => (
        <Option value={item.value} key={item.value}>
          {item.text}
        </Option>
      ))}
    </Select>
  )
}

export default StokSelectOption
