import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Layout,
  message,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CardPaketFaturaBilgi,
  HataModal,
  SozlesmeModal,
} from "../components/odemeYap";
import useApi from "../hooks/useApi";

const { Content } = Layout

function OdemeYap() {
  const [form] = Form.useForm();
  const [hataModalVisible, setHataModalVisible] = useState(false);
  const [sozlesmeModalVisible, setSozlesmeModalVisible] = useState(false);
  const [odemeYapConfirm, setOdemeYapConfirm] = useState(false);
  const odemeReducer = useSelector((state) => state.odemeReducer);
  const navigate = useNavigate();
  const api = useApi();

  useEffect(() => {
    if (!odemeReducer?.paket?.ad || !odemeReducer?.faturaAdresi?.adSoyad) {
      setHataModalVisible(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (props) => {
    try {
      setOdemeYapConfirm(true);
      const response = await api.post("/odeme", {
        EPosta: odemeReducer?.faturaAdresi?.eMail,
        AdSoyad: odemeReducer?.faturaAdresi?.adSoyad,
        Adres: odemeReducer?.faturaAdresi?.adres,
        Telefon: odemeReducer?.faturaAdresi?.telefon,
        UrunPaketId: odemeReducer?.paket?.id,
      });

      if (response.status === 200) {
        window.location.replace(response.data.data.iframeUrl);
      }
    } catch (error) {
      message.error(error.message);
      setOdemeYapConfirm(false);
    }
  };

  if (odemeYapConfirm) {
    return (
      <Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Space direction="vertical" align="center" size={"large"}>
          <Spin spinning size="large" />
          <Typography.Title level={3}>
            Ödeme sayfası yükleniyor...
          </Typography.Title>
        </Space>
      </Content>
    );
  }

  return (
    <Content >
      <br />
      <Row style={{ justifyContent: 'center' }}>
        <Col xxl={8} xl={11} lg={16} md={20} xs={22}>
          <Card>
            <CardPaketFaturaBilgi />
            <Divider />
            <Form form={form} onFinish={onFinish}>
              <Row align="middle" justify="space-between">
                <Form.Item
                  name={"sozlesme"}
                  valuePropName="checked"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || value === false) {
                          return Promise.reject(
                            "Lütfen mesafeli satış sözleşmesi'ni onaylayın"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Checkbox>
                    <Button
                      onClick={() => setSozlesmeModalVisible(true)}
                      type="link"
                      style={{ padding: 0, paddingRight: 4 }}
                    >
                      Mesafeli Satış Sözleşmesi'ni
                    </Button>
                    okudum ve onaylıyorum.
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#36c227", borderWidth: 0 }}
                  >
                    Ödeme Yap
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Card>
        </Col>
        <HataModal
          visible={hataModalVisible}
          closable={false}
          footer={
            <Button size="large" onClick={() => navigate("/Paketler")}>
              Tamam
            </Button>
          }
        />
        <SozlesmeModal
          title="Mesafeli Satış Sözleşmesi"
          visible={sozlesmeModalVisible}
          closable={false}
          onCancel={() => {
            setSozlesmeModalVisible(false);
            form.setFieldsValue({ sozlesme: false });
          }}
          onOk={() => {
            setSozlesmeModalVisible(false);
            form.setFieldsValue({ sozlesme: true });
          }}
          okText="Sözleşmeyi Onayla"
          width={"80%"}
        />
      </Row>
    </Content>
  );
}

export default OdemeYap;
