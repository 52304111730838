import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import languageReducer from "./languageReducer";
import tokenReducer from "./tokenReducer";
import rerenderReducer from "./rerenderReducer";
import selectedKasaReducer from "./selectedKasaReducer";
import selectedBankaHesapReducer from "./selectedBankaHesapReducer";
import dovizListReducer from "./dovizListReducer";
import odemeReducer from "./odemeReducer";

export default combineReducers({
  themeReducer,
  languageReducer,
  tokenReducer,
  rerenderReducer,
  selectedKasaReducer,
  selectedBankaHesapReducer,
  dovizListReducer,
  odemeReducer
});
