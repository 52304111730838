import React from "react";
import { Col, Layout, Row } from "antd";

const rowStyle = { minHeight: "100vh" };

export default function Container({ children }) {
  return (
    <Layout>
      <Row type="flex" justify="center" align="middle" style={rowStyle}>
        <Col>{children}</Col>
      </Row>
    </Layout>
  );
}
