import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Row, Col, message, notification } from "antd";
import Datepicker from "../toolbox/DatePicker";
import moment from "moment";
import strings from "../../utils/Localization";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import BankaHesapSelectOption from "../toolbox/BankaHesapSelectOption";
import useEvrakNo from "../../hooks/useEvrakNo";
import CurrencyInput, { setFieldsAutoClear } from "../toolbox/CurrencyInput";
import useApi from "../../hooks/useApi";

const formSize = "middle";
const aliciBankaHesapInitialState = {
  doviz: { kod: "", simge: "", id: null },
};
function KasadanBankayaVirmanEkleModal({ visible = false, handleCancel }) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [aliciBankaHesap, setAliciBankahesap] = useState({
    ...aliciBankaHesapInitialState,
  });
  const [form] = Form.useForm();
  const { kasaId } = useParams();
  const dispatch = useDispatch();
  const selectedKasaReducer = useSelector((state) => state.selectedKasaReducer);
  const { doviz: gonderiKasaDoviz = { simge: "", kod: "", id: null } } =
    selectedKasaReducer.data;
  const { bankayaVirman } = strings.kasaIslem;
  const { getKasaIslemEvrakNo } = useEvrakNo();
  const api = useApi();

  useEffect(() => {
    getKasaIslemEvrakNo().then((evrakNo) => {
      form.setFieldsValue({
        evrakNo: evrakNo,
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        islemTarihi,
        evrakNo,
        aciklama,
        aliciBankaHesapId,
        cikanTutar,
        carpan,
        girenTutar,
      } = values;
      setFetchLoading(true);
      const response = await api.post("/kasaislem/virman/addkasatobanka", {
        CikanKasaId: kasaId,
        GirenBankaHesapId: aliciBankaHesapId,
        EvrakNo: evrakNo,
        Aciklama: aciklama,
        CikanKasaTutar: cikanTutar,
        GirenBankaHesapTutar:
          (gonderiKasaDoviz.id !== aliciBankaHesap.doviz.id) &
          (aliciBankaHesap.doviz.id !== null)
            ? girenTutar
            : cikanTutar,
        IslemTarihi: islemTarihi,
        Carpan: carpan,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        getKasaIslemEvrakNo().then((evrakNo) => {
          form.setFieldsValue({
            evrakNo: evrakNo,
          });
        });
        setAliciBankahesap({ ...aliciBankaHesapInitialState });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const kurHesapla = (fieldName) => {
    const { cikanTutar, carpan, girenTutar } = form.getFieldValue();

    if (fieldName === "cikanTutar") {
      const currentGirenTutar = parseFloat(cikanTutar * carpan).toFixed(2);
      form.setFieldsValue({
        girenTutar: currentGirenTutar,
      });
    } else if (fieldName === "carpan") {
      const currentGirenTutar = parseFloat(cikanTutar * carpan).toFixed(2);
      form.setFieldsValue({
        girenTutar: currentGirenTutar,
      });
    } else if (fieldName === "girenTutar") {
      const currentCikanTutar = parseFloat(girenTutar / carpan).toFixed(2);
      form.setFieldsValue({
        cikanTutar: currentCikanTutar,
      });
    }
  };
  return (
    <Modal
      title={bankayaVirman.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "kasadanBankkayaVirmanEkleForm",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      width="60%"
    >
      <Form
        form={form}
        id="kasadanBankkayaVirmanEkleForm"
        size={formSize}
        layout="vertical"
        initialValues={{
          islemTarihi: moment(),
          cikanTutar: 0,
          carpan: 1,
          girenTutar: 0,
        }}
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col xxl={12} xl={12} md={12} sm={12} xs={24}>
            <Form.Item
              label={bankayaVirman.islemTarihi}
              name="islemTarihi"
              rules={[{ required: true }]}
            >
              <Datepicker />
            </Form.Item>
            <Form.Item label={bankayaVirman.evrakNo} name="evrakNo">
              <Input />
            </Form.Item>
            <Form.Item label={bankayaVirman.aciklama} name="aciklama">
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} md={12} sm={12} xs={24}>
            <Form.Item label={bankayaVirman.gondericiKasa}>
              <Input
                readOnly
                value={`${selectedKasaReducer.data.ad} (${gonderiKasaDoviz.kod})`}
              />
            </Form.Item>
            <Form.Item
              name="aliciBankaHesapId"
              label={bankayaVirman.aliciBankaHesap}
              rules={[{ required: true }]}
            >
              <BankaHesapSelectOption
                requestBody={{}}
                showDovizKod
                size={formSize}
                onSelectedBankaHesap={(e) => setAliciBankahesap(e)}
              />
            </Form.Item>
            <Row gutter={12}>
              <Col flex={1}>
                <Form.Item
                  name="cikanTutar"
                  label={
                    (gonderiKasaDoviz.id !== aliciBankaHesap.doviz.id) &
                    (aliciBankaHesap.doviz.id !== null)
                      ? bankayaVirman.cikanTutar
                      : bankayaVirman.tutar
                  }
                  rules={[{ required: true }]}
                >
                  <CurrencyInput
                    onChange={() => kurHesapla("cikanTutar")}
                    {...setFieldsAutoClear(form, "cikanTutar")}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={bankayaVirman.doviz}>
                  <Input value={gonderiKasaDoviz.kod} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.aliciBankaHesapId !== currentValues.aliciBankaHesapId
              }
            >
              {({ getFieldValue }) => {
                if (
                  (gonderiKasaDoviz.id !== aliciBankaHesap.doviz.id) &
                  (aliciBankaHesap.doviz.id !== null)
                ) {
                  return (
                    <>
                      <Form.Item
                        name="carpan"
                        label={bankayaVirman.kur}
                        rules={[{ required: true }]}
                      >
                        <Input
                          type="number"
                          onChange={() => kurHesapla("carpan")}
                        />
                      </Form.Item>
                      <Row gutter={12}>
                        <Col flex={1}>
                          <Form.Item
                            name="girenTutar"
                            label={bankayaVirman.girenTutar}
                            rules={[{ required: true }]}
                          >
                            <CurrencyInput
                              {...setFieldsAutoClear(form, "girenTutar")}
                              onChange={() => kurHesapla("girenTutar")}
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item label={bankayaVirman.doviz}>
                            <Input value={aliciBankaHesap.doviz.kod} readOnly />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  );
                }
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default KasadanBankayaVirmanEkleModal;
