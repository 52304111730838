import React, { useState, useEffect } from "react";
import Avatar from "../toolbox/Avatar";
import { Card, Divider, message, Space, Typography } from "antd";
import strings from "../../utils/Localization";
import useCurrency from "../../hooks/useCurrency";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";

const { Title } = Typography;

const gridStyle = {
  width: "50%",
  textAlign: "center",
  padding: 12,
};

const MyDivider = () => <Divider style={{ marginTop: 12, marginBottom: 12 }} />;

export default function Profil() {
  const [kasaSonBakiye, setkasaSonBakiye] = useState({
    giren: 0,
    cikan: 0,
    bakiye: 0,
  });
  const { data, isLoading } = useSelector((state) => state.selectedKasaReducer);
  const { doviz = { simge: "", ad: "" } } = data;
  const { kasaId } = useParams();
  const rerenderReducer = useSelector((state) => state.rerenderReducer);

  const { paraFormat } = useCurrency();
  const api = useApi();
  const { profil } = strings.kasaDetay.components;

  useEffect(() => {
    fetchKasaSonBakiye();
  }, [rerenderReducer]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchKasaSonBakiye = async () => {
    try {
      const response = await api.post(`kasa/getsonbakiye/${kasaId}`, {});
      if (response.status === 200) {
        setkasaSonBakiye(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <Card
        bodyStyle={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        loading={isLoading}
      >
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <Avatar text={data.ad} />
          <Title level={4} style={{ marginTop: 8, marginBottom: 4 }}>
            {data.ad}
          </Title>
          <Title level={5} style={{ marginTop: 0, fontSize: 14 }}>
            {profil.dovizTuru} {doviz.ad}({doviz.simge})
          </Title>
        </Space>
        <MyDivider />

        <Card>
          <Card.Grid style={gridStyle}>{profil.giris}</Card.Grid>
          <Card.Grid style={gridStyle}>
            {doviz.simge}
            {paraFormat(kasaSonBakiye.giren)}
          </Card.Grid>
          <Card.Grid style={gridStyle}>{profil.cikis}</Card.Grid>
          <Card.Grid style={gridStyle}>
            {doviz.simge}
            {paraFormat(kasaSonBakiye.cikan)}
          </Card.Grid>
          <Card.Grid style={gridStyle}>{profil.toplam}</Card.Grid>
          <Card.Grid style={gridStyle}>
            {doviz.simge}
            {paraFormat(kasaSonBakiye.bakiye)}
          </Card.Grid>
        </Card>
      </Card>
    </>
  );
}
