import React, { useEffect, useState } from "react";
import { message, Select, SelectProps } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";

const { Option } = Select;

interface IProps extends SelectProps<any> {}

const BankaSelectOption: React.FC<IProps> = ({ placeholder, ...rest }) => {
  const [bankaData, setBankaData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const api = useApi();
  const { bankaPlaceHolder }: any = strings.bankaSelectOption;

  useEffect(() => {
    fetchBanka();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBanka = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/banka/get", {});
      if (response.status === 200) {
        setBankaData(response.data.data);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Select
      {...rest}
      placeholder={placeholder || bankaPlaceHolder}
      loading={fetchLoading}
    >
      {bankaData.map((item: any, index) => (
        <Option key={index} value={String(item.id)}>
          {item.ad}
        </Option>
      ))}
    </Select>
  );
};

export default BankaSelectOption;
