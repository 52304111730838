import React, { useState } from 'react'
import { Button, Space, Popconfirm, message, notification, Typography, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import strings from '../../utils/Localization'
import { useNavigate } from 'react-router-dom'
import CursorTableItem from '../toolbox/CursorTableItem'
import ListFetchContent from '../toolbox/FetchListContent'
import useCurrency from '../../hooks/useCurrency'
import KasaDuzenleModal from '../kasa/KasaDuzenleModal'
import useApi from '../../hooks/useApi'

export default function KasaListTable() {
  const [dataSource, setDataSource] = useState({})
  const [kasaDuzenleModalVisible, setKasaDuzenleModalVisible] = useState(false)
  const [duzenleData, setDuzenleData] = useState(null)
  const navigate= useNavigate()
  const { KasaListTable } = strings.kasaListe.components
  const { paraFormat } = useCurrency()
  const api = useApi()

  const columns = [
    {
      title: KasaListTable.kasaAdi,
      dataIndex: 'ad',
      key: 'ad',
      render: (text, row, index) => (
        <CursorTableItem text={`${text} (${row.islemSayisi})`} onClick={() => navigate(`/KasaDetay/${row.id}`)} />
      ),
    },
    {
      title: KasaListTable.bakiye,
      dataIndex: 'bakiye',
      key: 'bakiye',
      render: (text, row, index) => {
        const { GirenKasaIslemTutar, CikanKasaIslemTutar } = row
        const bakiye = parseFloat(GirenKasaIslemTutar - CikanKasaIslemTutar)
        return (
          <Typography.Title
            level={5}
            style={{
              padding: 8,
              color: bakiye >= 0 ? 'green' : 'red',
            }}
          >{`${row.doviz.simge}${paraFormat(bakiye)}`}</Typography.Title>
        )
      },
    },
    {
      title: KasaListTable.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={KasaListTable.kasaDetay}>
            <Button
              onClick={() => navigate(`/KasaDetay/${row.id}`)}
              type="default"
              shape="circle"
              icon={<SearchOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={KasaListTable.kasaDuzenle}>
            <Button
              onClick={() => {
                setDuzenleData(row)
                setKasaDuzenleModalVisible(true)
              }}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={KasaListTable.kasaSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => {
                if (row.islemSayisi > 0) {
                  message.error({
                    content: strings.mesaj.kasaSilme,
                    style: { marginTop: '20vh' },
                  })
                } else remove(row.id)
              }}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const remove = async (kasaId) => {
    try {
      const response = await api.delete(`/kasa/${kasaId}`, {})
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== kasaId),
        })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  return (
    <>
      <ListFetchContent
        columns={columns}
        requestUrl="/kasa/get"
        requestBody={{
          SelectColumns: [
            {
              TableName: '',
              ColumnNames: ['ad', 'id'],
            },
            {
              TableName: 'doviz',
              ColumnNames: ['simge', 'kod', 'id'],
            },
            {
              TableName: 'Kasaislem:GirenKasaIslemTutar',
              ColumnNames: ['Tutar'],
              Conditions: [
                {
                  Property: 'GirisMi',
                  Value: 'true',
                  Operator: 'Equal',
                },
              ],
              Operator: 'Sum',
            },
            {
              TableName: 'Kasaislem:CikanKasaIslemTutar',
              ColumnNames: ['Tutar'],
              Conditions: [
                {
                  Property: 'GirisMi',
                  Value: 'false',
                  Operator: 'Equal',
                },
              ],
              Operator: 'Sum',
            },
            {
              TableName: 'Kasaislem:islemSayisi',
              Operator: 'Count',
            },
          ],
        }}
        onDataSource={(e) => setDataSource(e)}
        dataSource={dataSource}
      />
      <KasaDuzenleModal
        visible={kasaDuzenleModalVisible}
        handleCancel={() => setKasaDuzenleModalVisible(false)}
        data={duzenleData}
      />
    </>
  )
}
