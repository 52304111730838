import React, { useEffect, useState } from "react";
import { message, Select, SelectProps } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";

const { Option } = Select;

type selectOptionItemType = {
  label: string;
  value: string;
  barkod: string;
  stokvaryantgrupfiyat: {};
};

interface IProps extends SelectProps<any> {
  stokId: number;
  onSelectedVaryantGrup: (selectedVaryantGrup: any) => void;
}

const StokVaryantGrupSelectOption: React.FC<IProps> = ({
  stokId = null,
  onSelectedVaryantGrup = () => {},
  placeholder,
  ...rest
}) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [stokVaryantGrupData, setStokVaryantGrupData] = useState([]);
  const api = useApi();

  useEffect(() => {
    fetchStokVaryantGrup();
  }, [stokId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStokVaryantGrup = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/stokvaryantgrup/get", {
        Data: {
          StokId: stokId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["id", "barkod"],
          },
          {
            TableName: "StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName:
              "StokvaryantgrupHasStokvaryantdeger.StokVaryantDeger.StokVaryant",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName: "Stokvaryantgrupfiyat",
            ColumnNames: [
              "id",
              "fiyat",
              "otvTutar",
              "otvOran",
              "oivTutar",
              "oivOran",
              "kdvTutar",
              "kdvOran",
              "genelToplam",
              "satisMi",
            ],
          },
          {
            TableName: "Stokvaryantgrupfiyat.Doviz",
            ColumnNames: ["Id", "Ad", "Kod", "Simge"],
          },
        ],
      });
      if (response.status === 200) {
        setStokVaryantGrupData(
          response.data.data.map((item: any) => {
            return {
              label: item.StokvaryantgrupHasStokvaryantdeger.map(
                (varyantGrupItem: any) => {
                  return varyantGrupItem.StokVaryantDeger.ad;
                }
              ).join(" "),
              value: item.id,
              barkod: item.barkod,
              stokvaryantgrupfiyat: item.Stokvaryantgrupfiyat,
            };
          })
        );
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Select
      {...rest}
      placeholder={
        placeholder || strings?.stokVaryantGrupSelectOption?.placeHolder
      }
      loading={fetchLoading}
      onSelect={(e: any) => {
        const selectedStokVaryantGrup = stokVaryantGrupData.find(
          (x: any) => x.value === parseInt(e)
        );
        onSelectedVaryantGrup(selectedStokVaryantGrup);
      }}
      onClear={() => onSelectedVaryantGrup({})}
    >
      {stokVaryantGrupData.map((item: selectOptionItemType, index) => (
        <Option key={index} value={String(item.value)}>
          {`${item.label}`}
        </Option>
      ))}
    </Select>
  );
};

export default StokVaryantGrupSelectOption;
