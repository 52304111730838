import React, { useState } from "react";
import { Space, Menu } from "antd";
import { StokListe } from "../components/stokListe";
import { StokKategoriListe } from "../components/stokKategoriListe";
import { StokBirimListe } from "../components/stokBirimListe";
import { StokDepoListe } from "../components/stokDepoListe";
import StokVaryantListe from "../components/stokVaryant/StokVaryantListe";
import strings from "../utils/Localization";

export default function Stok() {
  const [selectedMenu, setSelectedMenu] = useState("1");
  const { menu } = strings.stok.components;

  const contentRender = () => {
    switch (selectedMenu.key) {
      case "1":
        return <StokListe />;
      case "2":
        return <StokKategoriListe />;
      case "3":
        return <StokBirimListe />;
      case "4":
        return <StokVaryantListe />;
      case "5":
        return <StokDepoListe />;
      default:
        return <StokListe />;
    }
  };

  return (
    <>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Menu
          onClick={(e) => setSelectedMenu(e)}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="horizontal"
        >
          <Menu.Item key="1">{menu.stokListesi}</Menu.Item>
          <Menu.Item key="2">{menu.stokKategorileri}</Menu.Item>
          <Menu.Item key="3">{menu.stokBirimleri}</Menu.Item>
          <Menu.Item key="4">{menu.stokVaryantlari}</Menu.Item>
          <Menu.Item key="5">{menu.depoListesi}</Menu.Item>
        </Menu>
        {contentRender()}
      </Space>
    </>
  );
}
