import * as actionTypes from "../actions/actionTypes";

const initialState = false;

const rerenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_RERENDER:
      return !state;
    default:
      return state;
  }
};

export default rerenderReducer;
