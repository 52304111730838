import React, { useState } from "react";
import { PageHeader as MyPageHeader, Button } from "antd";
import { useNavigate } from "react-router-dom";
import strings from "../../utils/Localization";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { GelirGiderIslemEkleModal } from "../gelirGiderIslem";

export default function PageHeader() {
    const [gelirGiderIslemEkleModalVisible, setGelirGiderIslemEkleModalVisible] = useState(false)
    const [gelirMi, setGelirMi] = useState()
    const navigate= useNavigate();
    const { pageHeader } = strings.gelirGiderDetay.components;

    return (
        <>
            <MyPageHeader
                ghost={false}
                onBack={() => navigate(-1)}
                title={pageHeader.baslik}
                subTitle={pageHeader.altBaslik}
                extra={[
                    <Button
                        onClick={() => {
                            setGelirMi(false)
                            setGelirGiderIslemEkleModalVisible(true) 
                        }}
                        type="primary"
                        size="large"
                        icon={<MinusOutlined />}
                    >
                        {pageHeader.giderEkleButon}
                    </Button>,
                    <Button
                        onClick={() => {
                            setGelirMi(true)
                            setGelirGiderIslemEkleModalVisible(true) 
                        }}
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                    >
                        {pageHeader.gelirEkleButon}
                    </Button>
                ]}
            />
            <GelirGiderIslemEkleModal
                visible={gelirGiderIslemEkleModalVisible}
                handleCancel={() => setGelirGiderIslemEkleModalVisible(false)}
                gelirMi={gelirMi}
            />
        </>
    );
}
