import React, { useState, useEffect } from "react";
import { Form, Input, message, notification, Row, Col, InputNumber, Button, Card, PageHeader, Space, Spin } from "antd";
import strings from "../utils/Localization";
import { useNavigate, useParams } from 'react-router-dom'
import useApi from "../hooks/useApi";
import StokSelectOption from "../components/toolbox/StokSelectOption";
import DatePicker from "../components/toolbox/DatePicker";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { SaveOutlined } from "@ant-design/icons";
import moment from "moment";
import StokVaryantGrupSelectOption from "../components/toolbox/StokVaryantGrupSelectOption";

const formSize = "middle";

const minusIconStyle = { alignItems: 'center', justifyContent: 'center', display: 'flex' }

const UretimDuzenle = () => {
    const [fetchSaveLoading, setFetchSaveLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false)
    const [form] = Form.useForm();
    const api = useApi();
    const navigate = useNavigate()
    const { uretimId } = useParams()
    const localization = strings.uretimDuzenle

    useEffect(() => {
        fetchUretim()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    const onFinish = async (values) => {
        try {
            const uretimMalzeme = values.uretimMalzeme.filter(x => x?.hidden !== true).map(item => {
                let malzemeStokVaryantGrupId;
                const { stokVaryantGrupId, selectedStok } = item

                if (selectedStok.Stokvaryantgrup.length > 1) {
                    malzemeStokVaryantGrupId = stokVaryantGrupId
                }
                else {
                    malzemeStokVaryantGrupId = selectedStok.Stokvaryantgrup[0].id
                }

                return {
                    Id: item.id,
                    StokVaryantGrupId: malzemeStokVaryantGrupId,
                    BirimMiktar: item.birimMiktar,
                    Miktar: item.miktar,
                    Aciklama: item.aciklama,
                }
            })
            setFetchSaveLoading(true);
            const response = await api.put("/uretim", {
                Id: values.id,
                EvrakNo: values.evrakNo,
                Baslik: values.baslik,
                Aciklama: values.aciklama,
                Miktar: values.miktar,
                UretimBaslangicTarihi: values.uretimBaslangicTarihi,
                UretimBitisTarihi: values.uretimBitisTarihi,
                Uretimmalzeme: uretimMalzeme
            });

            if (response.status === 200) {
                notification["success"]({
                    message: strings.mesaj.basarliBaslik,
                    description: strings.mesaj.islemBasarili,
                });
            }
        } catch (error) {
            message.error(error.message);
        } finally {
            setFetchSaveLoading(false);
        }
    };

    const onValuesChange = (changedValues, allValues) => {
        if (changedValues?.miktar !== undefined) {
            const yeniMalzemePlanlama = allValues.uretimMalzeme.map(item => ({
                ...item,
                miktar: parseFloat(item.birimMiktar) * parseFloat(changedValues?.miktar)
            }))

            form.setFieldsValue({
                uretimMalzeme: yeniMalzemePlanlama
            })
        }
    }

    const fetchUretim = async () => {
        try {
            setFetchLoading(true)
            const response = await api.post("/uretim/get", {
                Data: {
                    Id: uretimId
                },
                SelectColumns: [
                    {
                        ColumnNames: [
                            "id",
                            "baslik",
                            "miktar",
                            "evrakNo",
                            "uretimBaslangicTarihi",
                            "uretimBitisTarihi",
                            "aciklama",
                        ],
                        TableName: ""
                    },
                    {
                        ColumnNames: [
                            "id",
                            "stokVaryantGrupId",
                            "birimMiktar",
                            "miktar",
                            "aciklama"
                        ],
                        TableName: "uretimmalzeme"
                    },
                    {
                        ColumnNames: [
                            "id",
                            "stokId",
                        ],
                        TableName: "uretimmalzeme.StokVaryantGrup"
                    }
                ]
            })

            const responseData = response.data.data[0]
            const uretimBaslangicTarihi = responseData.uretimBaslangicTarihi;
            const uretimBitisTarihi = responseData.uretimBitisTarihi;

            responseData.uretimBaslangicTarihi = uretimBaslangicTarihi ? moment(uretimBaslangicTarihi) : undefined
            responseData.uretimBitisTarihi = uretimBitisTarihi ? moment(uretimBitisTarihi) : undefined

            form.setFieldsValue({
                ...responseData,
                uretimMalzeme: responseData.uretimmalzeme.map(item => ({
                    ...item,
                    stokId: item.StokVaryantGrup.stokId,
                    stokVaryantGrupId: String(item.StokVaryantGrup.id),
                }))
            })

        } catch (error) {
            message.error(error.message)
        }
        finally {
            setFetchLoading(false)
        }
    }

    return (
        <Spin spinning={fetchLoading}>
            <Space style={{ width: '100%' }} direction="vertical" size={"large"}>
                <PageHeader
                    title={localization.pageHeader.baslik}
                    onBack={() => navigate(-1)}
                    extra={[
                        <Button loading={fetchSaveLoading} form="uretimDuzenleModal" htmlType="submit" icon={<SaveOutlined />} type="primary" size="large">{localization.pageHeader.kaydetButon}</Button>
                    ]}
                    style={{ padding: 0 }}
                />
                <Form
                    name="uretimDuzenleModal"
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    size={formSize}
                    onValuesChange={onValuesChange}
                >
                    <Form.Item hidden name={"id"} />
                    <Card title={localization.uretimBilgileri}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="baslik"
                                    label={localization.uretimAdi}
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder={localization.uretimAdiPlaceHolder} />
                                </Form.Item>
                                <Form.Item
                                    name="miktar"
                                    label={localization.miktar}
                                    rules={[{ required: true }]}
                                >
                                    <InputNumber style={{ width: '100%' }} placeholder={localization.miktarPlaceHolder} min={0} />
                                </Form.Item>
                                <Form.Item
                                    name="evrakNo"
                                    label={localization.evrakNo}
                                >
                                    <Input placeholder={localization.evrakNoPlaceHolder} />
                                </Form.Item>

                            </Col>
                            <Col span={12}>

                                <Form.Item
                                    name="uretimBaslangicTarihi"
                                    label={localization.uretimBaslangicTarihi}
                                    rules={[{ required: true }]}
                                >
                                    <DatePicker allowClear={false} />
                                </Form.Item>
                                <Form.Item
                                    name="uretimBitisTarihi"
                                    label={localization.uretimBitisTarihi}
                                    rules={[{ required: true }]}
                                >
                                    <DatePicker allowClear={false} />
                                </Form.Item>
                                <Form.Item
                                    name="aciklama"
                                    label={localization.aciklama}
                                >
                                    <Input.TextArea rows={4} placeholder={localization.aciklamaPlaceHolder} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <br />
                    <Card title={localization.malzemeListesi}>
                        <Form.List name="uretimMalzeme">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name }) => (
                                        <UretimmalzemeItem name={name} form={form} />
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            {localization.uretimMalzemesiEkle}
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Card>
                </Form>
            </Space>
        </Spin>
    )
}

export default UretimDuzenle

const UretimmalzemeItem = ({ name, form }) => {
    const [selectedStok, setSelectedStok] = useState({ Stokvaryantgrup: [] })
    const [firstRender, setFirstRender] = useState(true)
    const [satirHidden, setSatirHidden] = useState(false)
    const localization = strings.uretimDuzenle

    const onSelectedStok = (e) => {
        setSelectedStok(e)
        if (firstRender) {//ilk derleme ise 1'den fazla varyant var ise seçili varyantı silmeme kontrolü
            form.setFields([
                {
                    name: ["uretimMalzeme", name, "selectedStok"],
                    value: e
                }
            ])
        }
        else {
            form.setFields([
                {
                    name: ["uretimMalzeme", name, "selectedStok"],
                    value: e
                },
                {
                    name: ["uretimMalzeme", name, "stokVaryantGrupId"],
                    value: undefined
                }
            ])
        }

        setFirstRender(false)
    }

    const remove = () => {
        setSatirHidden(true)
        form.setFields([{ name: ['uretimMalzeme', name, 'hidden'], value: true }])
    }

    const onMalzemeListeBirimMiktarChange = (index, value) => {
        const yeniMalzemePlanlama = form.getFieldValue("uretimMalzeme")
        yeniMalzemePlanlama[index].miktar = parseFloat(form.getFieldValue("miktar")) * value
        form.setFieldsValue({ uretimMalzeme: yeniMalzemePlanlama })
    }

    if (satirHidden) {
        return null
    }

    return (
        <Row gutter={12}>
            <Form.Item name={[name, "id"]} hidden />
            <Col span={5}>
                <Form.Item name={[name, "stokId"]} label={localization.Uretimmalzeme.urun} rules={[{ required: true }]}>
                    <StokSelectOption onSelectedStok={onSelectedStok} />
                </Form.Item>
                {selectedStok.Stokvaryantgrup.length > 1 && (
                    <Form.Item label={localization.Uretimmalzeme.urunVaryanti} name={[name, "stokVaryantGrupId"]} rules={[{ required: true }]}>
                        <StokVaryantGrupSelectOption stokId={selectedStok.id} />
                    </Form.Item>
                )}
            </Col>
            <Col span={5}>
                <Form.Item name={[name, "birimMiktar"]} label={localization.Uretimmalzeme.birimMiktar} rules={[{ required: true }]}>
                    <InputNumber style={{ width: '100%' }} placeholder={localization.Uretimmalzeme.birimMiktar} onChange={(e) => { onMalzemeListeBirimMiktarChange(name, e) }} min={0} />
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item name={[name, "miktar"]} label={localization.Uretimmalzeme.miktar} rules={[{ required: true }]}>
                    <InputNumber style={{ width: '100%' }} placeholder={localization.Uretimmalzeme.miktarPlaceHolder} disabled />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name={[name, "aciklama"]} label={localization.Uretimmalzeme.aciklama} >
                    <Input.TextArea rows={4} placeholder={localization.Uretimmalzeme.aciklamaPlaceHolder} />
                </Form.Item>
            </Col>
            <Col span={1} style={minusIconStyle}>
                <MinusCircleOutlined onClick={() => remove(name)} />
            </Col>
        </Row>

    )

}