import React from "react";
import { Select, SelectProps } from "antd";
import { faturaTypes } from "../../types/faturaTypes";
import strings from "../../utils/Localization";

const { Option } = Select;

interface IProps extends SelectProps<any> {}

const FaturaTipSelectOption: React.FC<IProps> = ({ placeholder, ...rest }) => {
  const { faturaSelectOption: localization } = strings;
  return (
    <Select
      {...rest}
      placeholder={placeholder || localization?.faturaTipPlaceHolder}
    >
      <Option value={faturaTypes.SATIS_FATURASI}>
        {localization?.satisFaturasi}
      </Option>
      <Option value={faturaTypes.ALIS_FATURASI}>
        {localization?.alisFaturasi}
      </Option>
      <Option value={faturaTypes.SATIS_IADE_FATURASI}>
        {localization?.satisIadeFaturasi}
      </Option>
      <Option value={faturaTypes.ALIS_IADE_FATURASI}>
        {localization?.alisIadeFaturasi}
      </Option>
    </Select>
  );
};

export default FaturaTipSelectOption;
