import React, { useEffect } from 'react'
import { Result } from 'antd'
import { useParams } from 'react-router-dom'
import useKullanici from '../hooks/useKullanici'
import MetaTags from 'react-meta-tags'
import { isBrowser } from 'react-device-detect'

function OdemeDurum() {
  const { durum } = useParams()
  const { cikisYap } = useKullanici()

  useEffect(() => {
    if (durum === 'Basarili') {
      window?.ReactNativeWebView?.postMessage(true)
      if (isBrowser) {
        setTimeout(() => {
          cikisYap()
        }, 1500)
      }
    } else {
      window?.ReactNativeWebView?.postMessage(false)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderResult = () => {
    if (durum === 'Basarili') {
      return (
        <Result status="success" title="Ödeme İşlemi Başarılı!" subTitle="giriş ekranına yönlendiriliyorsunuz..." />
      )
    } else if (durum === 'Basarisiz') {
      return (
        <Result
          status="error"
          title="Ödeme İşlemi Başarısız!"
          subTitle="Ödeme işlemi sırasında hata oluştu. Lütfen daha sonra tekrar deneyiniz."
        />
      )
    } else {
      return <Result status="warning" title="Hatalı Istek!" subTitle="Atmış olduğunuz istek hatalı." />
    }
  }

  return (
    <>
      <MetaTags>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </MetaTags>
      {renderResult()}
    </>
  )
}

export default OdemeDurum
