import React from "react";
import {
  PageHeader,
  FirmaBilgileriForm,
  FirmaLogoUpload,
} from "../components/firmaBilgileri";
import { Card, Col, Row, Space } from "antd";

export default function FirmaBilgileri() {
  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <PageHeader />
        <Row gutter={24}>
          <Col flex={18}>
            <FirmaBilgileriForm />
            <br/>
          </Col>
          <Col flex={6}>
            <Card>
              <FirmaLogoUpload />
            </Card>
          </Col>
        </Row>
      </Space>
    </>
  );
}
