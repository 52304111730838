import React, { useState } from 'react'
import { Button, Space, Tag, Popconfirm, notification, message, Spin, Card, Form, Row, Col, Radio, Tooltip, PageHeader } from 'antd'
import useCurrency from '../../hooks/useCurrency'
import strings from '../../utils/Localization'
import CursorTableItem from '../toolbox/CursorTableItem'
import {
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
  SearchOutlined,
  ClearOutlined,
  SyncOutlined,
  CheckOutlined,
  PaperClipOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import FetchListContent from '../toolbox/FetchListContent'
import moment from 'moment'
import { teklifSiparisTypes } from '../../types/teklifSiparisTypes'
import { setRerender } from '../../redux/actions/rerenderAction'
import { useDispatch } from 'react-redux'
import DatepickerRanger from '../toolbox/DatepickerRanger'
import DovizSelectOption from '../toolbox/DovizSelectOption'
import CurrencyInput from '../toolbox/CurrencyInput'
import { faturaTypes } from '../../types/faturaTypes'
import useApi from '../../hooks/useApi'
import IslemButton from './IslemButton'
import { TekliSiparisDurumGecmisListModal } from '../teklifSiparisDurum'

const initialFilterRequestData = {
  Data: {
    Tip: null,
    DovizId: null,
  },
  DateOptions: [
    {
      DatePropertyName: 'TeklifSiparisTarihi',
      FirstDate: null,
      LastDate: null,
    },
    {
      DatePropertyName: 'GecerlilikTarihi',
      FirstDate: null,
      LastDate: null,
    },
  ],
  BetweenOptions: [
    {
      PropertyName: 'GenelToplam',
      First: null,
      Last: null,
    },
  ],
}

function TeklifSiparisListeTable() {
  const [dataSource, setDataSource] = useState([])
  const [filterRequestData, setFilterRequestData] = useState({
    ...initialFilterRequestData,
  })
  const [spinnig, setSpinnig] = useState(false)
  const [durumGecmisListModalVisible, setDurumGecmisListModalVisible] = useState(false)
  const [selectedTeklifSiparisId, setSelectedTeklifSiparisId] = useState()
  const { paraFormat } = useCurrency()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [detailSearchForm] = Form.useForm()
  const api = useApi()
  const { teklifSiparisListeTable: localization, detayliArama } = strings.teklifSipariListe.components
  const { baslik } = strings.teklifSipariListe

  const columns = [
    {
      title: localization.firmaAdi,
      dataIndex: 'firmaAdi',
      key: 'firmaAdi',
      render: (text, row, index) => (
        <CursorTableItem text={text} onClick={() => navigate(`/TeklifSiparisDuzenle/${row.id}`, {
          state: {
            teklifSiparisTip: row.tip
          }
        })} />
      ),
    },
    {
      title: localization.evrakNo,
      dataIndex: 'teklifSiparisNo',
      key: 'teklifSiparisNo',
    },
    {
      title: localization.evrakTipi,
      dataIndex: 'tip',
      key: 'tip',
      filters: [
        {
          text: localization.evrakTip.alinanTeklif,
          value: teklifSiparisTypes.ALINAN_TEKLIF,
        },
        {
          text: localization.evrakTip.verilenTeklif,
          value: teklifSiparisTypes.VERILEN_TEKLIF,
        },
        {
          text: localization.evrakTip.alinanSiparis,
          value: teklifSiparisTypes.ALINAN_SIPARIS,
        },
        {
          text: localization.evrakTip.verilenSiparis,
          value: teklifSiparisTypes.verilenSiparis,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.tip.indexOf(value) === 0,
      render: (text, row, index) => {
        let teklifHasSiparis = false
        let siparisHasFatura = false

        if (row.SiparisHasFatura.length > 0) {
          siparisHasFatura = true
        }
        if (row.TeklifHasSiparisTeklif.length > 0) {
          teklifHasSiparis = true
        }

        return (
          <Space direction="vertical">
            <Tag color={getTeklifSiparisTipColor(text)} style={{ fontSize: 14 }}>
              {text}
            </Tag>
            {teklifHasSiparis && (
              <Tag icon={<SyncOutlined />} color="default" style={{ fontSize: 14 }}>
                {localization.sipariseDonusturuldu}
              </Tag>
            )}
            {siparisHasFatura && (
              <Tag icon={<SyncOutlined />} color="default" style={{ fontSize: 14 }}>
                {localization.faturalandi}
              </Tag>
            )}
          </Space>
        )
      },
    },
    {
      title: localization.durum,
      render: (text, row, index) => {
        if (row.Teklifsiparisdurum) {
          return <Tag onClick={() => showDurumGecmisListModal(row.id)} icon={<PaperClipOutlined />} color={"default"} style={{ fontSize: 14, cursor: "pointer" }}>
            {row.Teklifsiparisdurum.Teklifsiparisdurumtip.ad}
          </Tag>
        }
        return null
      },
    },
    {
      title: localization.evrakTarihi,
      dataIndex: 'teklifSiparisTarihi',
      key: 'teklifSiparisTarihi',
      render: (text) => moment(text).format('DD.MM.YYYY'),
    },
    {
      title: localization.genelToplam,
      dataIndex: 'genelToplam',
      key: 'genelToplam',
      render: (text, row, index) => `${row.Doviz.simge}${paraFormat(text)}`,
    },
    {
      title: localization.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => {
        let siparisMi = false
        if (row.tip === teklifSiparisTypes.ALINAN_SIPARIS || row.tip === teklifSiparisTypes.VERILEN_SIPARIS) {
          siparisMi = true
        }

        let siparisHasFatura_Or_teklifHasSiparis = false

        if (row.SiparisHasFatura.length > 0) {
          siparisHasFatura_Or_teklifHasSiparis = true
        }
        if (row.TeklifHasSiparisTeklif.length > 0) {
          siparisHasFatura_Or_teklifHasSiparis = true
        }

        return (
          <Space size="middle">
            {siparisHasFatura_Or_teklifHasSiparis ? (
              <Button
                onClick={() => message.warning(localization.donustumeIslemiUyarisi)}
                type="default"
                shape="circle"
                icon={<CheckOutlined />}
                size={'large'}
              />
            ) : (
              <Tooltip title={siparisMi ? localization.siparisiFaturayaCevir : localization.teklifiSipariseCevir}>
                <Popconfirm
                  title={siparisMi ? localization.siparisiFaturayaCevirUyari : localization.teklifSipariseCevirUyari}
                  onConfirm={() => {
                    if (siparisMi) {
                      siparisiFaturayaCevir(row)
                    } else {
                      teklifiSipariseCevir(row)
                    }
                  }}
                >
                  <Button type="default" shape="circle" icon={<SyncOutlined />} size={'large'} />
                </Popconfirm>
              </Tooltip>
            )}
            <Tooltip title={localization.teklifSiparisYazdir}>
              <Button
                onClick={() => fetchTeklifSiparisMabuz(row.id, row.tip)}
                type="default"
                shape="circle"
                icon={<FileTextOutlined />}
                size={'large'}
              />
            </Tooltip>
            <Tooltip title={localization.teklifSiparisDuzenle}>
              <Button
                onClick={() => navigate(`/TeklifSiparisDuzenle/${row.id}`)}
                type="default"
                shape="circle"
                icon={<EditOutlined />}
                size={'large'}
              />
            </Tooltip>
            <Tooltip title={localization.teklifSiparisSil}>
              <Popconfirm title={strings.mesaj.silmeIslemUyari} onConfirm={() => remove(row.id)}>
                <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      },
    },
  ]
  const showDurumGecmisListModal = (teklifSiparisId) => {
    setDurumGecmisListModalVisible(true)
    setSelectedTeklifSiparisId(teklifSiparisId)
  }
  const getTeklifSiparisTipColor = (tip) => {
    switch (tip) {
      case teklifSiparisTypes.ALINAN_TEKLIF:
        return 'cyan'
      case teklifSiparisTypes.VERILEN_TEKLIF:
        return 'magenta'
      case teklifSiparisTypes.ALINAN_SIPARIS:
        return 'purple'
      case teklifSiparisTypes.VERILEN_SIPARIS:
        return 'volcano'
      default:
        return 'geekblue'
    }
  }
  const remove = async (teklifSiparisId) => {
    try {
      const response = await api.delete(`/teklifsiparis/${teklifSiparisId}`, {})
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== teklifSiparisId),
        })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  const fetchTeklifSiparisMabuz = async (teklifSiparisId, teklifSiparisTip) => {
    try {
      const pdfWindow = window.open(`${document.location.origin}/#/RaporYukleniyor`)
      setSpinnig(true)
      const response = await api.post(
        `/rapordosya/${teklifSiparisTip === teklifSiparisTypes.VERILEN_TEKLIF ||
          teklifSiparisTip === teklifSiparisTypes.ALINAN_TEKLIF
          ? 'teklifmakbuz'
          : 'siparismakbuz'
        }`,
        teklifSiparisTip === teklifSiparisTypes.VERILEN_TEKLIF || teklifSiparisTip === teklifSiparisTypes.ALINAN_TEKLIF
          ? { TeklifId: teklifSiparisId, format: 'pdf' }
          : { SiparisId: teklifSiparisId, format: 'pdf' }
      )

      if (response.status === 200) {
        pdfWindow.document.location.href = response.data.data
      } else message.warning(strings.mesaj.islemBasarisiz)
    } catch (error) {
      message.error(error.message)
    } finally {
      setSpinnig(false)
    }
  }
  const detayliAramaOnFinish = async (props) => {
    const { islemTarihi, evrakTipi, minTutar, maxTutar, dovizId, gecerlilikTarihi } = props

    let newData = { ...initialFilterRequestData }

    newData = {
      Data: {
        DovizId: dovizId !== undefined ? dovizId : null,
        Tip: evrakTipi !== undefined ? evrakTipi : null,
      },
      DateOptions: [
        {
          DatePropertyName: 'TeklifSiparisTarihi',
          FirstDate: islemTarihi !== undefined ? `${moment(islemTarihi[0]).format('YYYY-MM-DD')} 00:00:00` : null,
          LastDate: islemTarihi !== undefined ? `${moment(islemTarihi[1]).format('YYYY-MM-DD')} 23:59:59` : null,
        },
        {
          DatePropertyName: 'GecerlilikTarihi',
          FirstDate:
            gecerlilikTarihi !== undefined ? `${moment(gecerlilikTarihi[0]).format('YYYY-MM-DD')} 00:00:00` : null,
          LastDate:
            gecerlilikTarihi !== undefined ? `${moment(gecerlilikTarihi[1]).format('YYYY-MM-DD')} 23:59:59` : null,
        },
      ],
      BetweenOptions: [
        {
          PropertyName: 'GenelToplam',
          First: (minTutar !== undefined) & (minTutar !== 0) ? minTutar : null,
          Last: (maxTutar !== undefined) & (maxTutar !== 0) ? maxTutar : null,
        },
      ],
    }
    await setFilterRequestData(newData)
    dispatch(setRerender())
  }
  const detayliAramaReset = async () => {
    await setFilterRequestData({ ...initialFilterRequestData })
    detailSearchForm.resetFields()

    dispatch(setRerender())
  }
  const teklifiSipariseCevir = async (selectedTeklif) => {
    try {
      setSpinnig(true)
      const response = await api.post(`/teklifsiparis/tekliftosiparis/${selectedTeklif.id}`)
      if (response.status === 200) {
        dispatch(setRerender())
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setSpinnig(false)
    }
  }
  const siparisiFaturayaCevir = (selectedSiparis) => {
    navigate('/FaturaEkle', {
      state: {
        faturaTip: getFaturaTip(selectedSiparis.tip),
        siparisId: selectedSiparis.id,
      },
    })
  }
  const getFaturaTip = (siparisTip) => {
    switch (siparisTip) {
      case teklifSiparisTypes.ALINAN_SIPARIS:
        return faturaTypes.SATIS_FATURASI
      case teklifSiparisTypes.VERILEN_SIPARIS:
        return faturaTypes.ALIS_FATURASI
      default:
        return undefined
    }
  }

  return (
    <>
      <PageHeader
        onBack={() => navigate(-1)}
        title={baslik}
        ghost={false}
        extra={<IslemButton />}
      />
      <br />
      <Spin spinning={spinnig}>
        <FetchListContent
          detailSearch={
            <Card title={detayliArama.baslik}>
              <Form
                form={detailSearchForm}
                name="detailSearchForm"
                layout="vertical"
                onFinish={detayliAramaOnFinish}
                initialValues={{
                  evrakTipi: null,
                }}
              >
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item name="islemTarihi" label={detayliArama.evrakTarihi}>
                      <DatepickerRanger />
                    </Form.Item>
                    <Form.Item name="gecerlilikTarihi" label={detayliArama.gecerlilikTarihi}>
                      <DatepickerRanger />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Space direction="vertical" align="center" style={{ width: '100%' }}>
                      <Form.Item name="evrakTipi" label={detayliArama.evrakTipi}>
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value={null}>{detayliArama.tumEvraklariGoster}</Radio>
                            <Radio value={teklifSiparisTypes.ALINAN_TEKLIF}>{detayliArama.alinanTeklifleriGoster}</Radio>
                            <Radio value={teklifSiparisTypes.VERILEN_TEKLIF}>
                              {detayliArama.verilenTeklifleriGoster}
                            </Radio>
                            <Radio value={teklifSiparisTypes.ALINAN_SIPARIS}>
                              {detayliArama.alinanSiparisleriGoster}
                            </Radio>
                            <Radio value={teklifSiparisTypes.VERILEN_SIPARIS}>
                              {detayliArama.verilenSiparisleriGoster}
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Space direction="vertical">
                      <Space size="small">
                        <Form.Item name="minTutar" label={detayliArama.minTutar}>
                          <CurrencyInput />
                        </Form.Item>
                        <Form.Item name="dovizId" label={detayliArama.doviz}>
                          <DovizSelectOption />
                        </Form.Item>
                      </Space>
                      <Space size="small">
                        <Form.Item name="maxTutar" label={detayliArama.maxTutar}>
                          <CurrencyInput />
                        </Form.Item>
                        <Form.Item name="dovizId" label={detayliArama.doviz}>
                          <DovizSelectOption />
                        </Form.Item>
                      </Space>
                    </Space>
                  </Col>
                </Row>
                <Space align="baseline" style={{ float: 'right' }}>
                  <Button
                    type="default"
                    size="middle"
                    icon={<ClearOutlined />}
                    style={{ float: 'right' }}
                    onClick={detayliAramaReset}
                  >
                    {detayliArama.temizle}
                  </Button>
                  <Button
                    type="default"
                    size="middle"
                    icon={<SearchOutlined />}
                    style={{ float: 'right' }}
                    form="detailSearchForm"
                    htmlType="submit"
                  >
                    {detayliArama.aramayiBaslat}
                  </Button>
                </Space>
              </Form>
            </Card>
          }
          columns={columns}
          requestUrl="/teklifsiparis/get"
          requestBody={{
            SelectColumns: [
              {
                ColumnNames: ['id', 'firmaAdi', 'teklifSiparisNo ', 'tip', 'teklifSiparisTarihi', 'genelToplam'],
                TableName: '',
              },
              {
                TableName: 'Doviz',
                ColumnNames: ['id', 'ad', 'simge'],
              },
              {
                TableName: 'SiparisHasFatura',
                ColumnNames: ['siparisId', 'faturaId'],
              },
              {
                TableName: 'TeklifHasSiparisSiparis',
                ColumnNames: ['teklifId', 'siparisId'],
              },
              {
                TableName: 'TeklifHasSiparisTeklif',
                ColumnNames: ['teklifId', 'siparisId'],
              },
              {
                "TableName": "Teklifsiparisdurum",
                "ColumnNames": [
                  "Id",
                  "aciklama"
                ],
                "Operator": "Last"
              },
              {
                "TableName": "Teklifsiparisdurum.Teklifsiparisdurumtip",
                "ColumnNames": [
                  "Id",
                  "tip",
                  "ad"
                ]
              }
            ],
            ...filterRequestData,
          }}
          dataSource={dataSource}
          onDataSource={(e) => setDataSource(e)}
        />
      </Spin>
      <TekliSiparisDurumGecmisListModal
        handleCancel={() => setDurumGecmisListModalVisible(false)}
        visible={durumGecmisListModalVisible}
        teklifSiparisId={selectedTeklifSiparisId}
      />
    </>
  )
}

export default TeklifSiparisListeTable
