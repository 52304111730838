import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  PageHeader,
  Row,
  Col,
  Typography,
  Divider,
  Space,
  message,
  Table,
  Alert
} from "antd";
import {
  FileTextOutlined,
  FileSearchOutlined,
  UserOutlined,
  CalendarOutlined,
  NumberOutlined,
  UnorderedListOutlined,
  SwapOutlined,
  BankOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import useCurrency from "../hooks/useCurrency";
import moment from "moment";
import { cariIslemTurTypes } from "../types/cariIslemTurTypes";
import strings from "../utils/Localization";
import CariAltOdemeEkle from "../components/toolbox/CariAltOdemeEkle";
import CariAltTahsilatEkle from "../components/toolbox/CariAltTahsilatEkle";
import { useSelector } from "react-redux";
import useApi from "../hooks/useApi";

const { Title } = Typography;

function CariIslemDetay() {
  const navigate= useNavigate();
  const { cariIslemId = null } = useParams();
  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(false);
  const [cariIslemData, setCariIslemData] = useState({});
  const [tahsilatEkleVisible, setTahsilatEkleVisible] = useState(false);
  const [odemeEkleVisible, setOdemeEkleVisible] = useState(false);
  const { paraFormat } = useCurrency();
  const rerenderReducer = useSelector((state) => state.rerenderReducer);
  const api = useApi();

  useEffect(() => {
    getCariIslem();
  }, [rerenderReducer, cariIslemId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCariIslem = async () => {
    try {
      const response = await api.post("/cariislem/get", {
        Data: {
          id: cariIslemId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: [
              "islemTarih",
              "vadeTarih",
              "evrakNo",
              "cariIslemTur",
              "aciklama",
              "odemeSekli",
              "tutar",
            ],
          },
          {
            TableName: "CariKart",
            ColumnNames: ["firmaAdi", "id"],
          },
          {
            TableName: "Doviz",
            ColumnNames: ["id", "ad", "simge"],
          },
          {
            TableName: "Cariislembaglanti",
            ColumnNames: ["faturaId"],
          },
          {
            TableName: "Cariislembaglanti.Kasa",
            ColumnNames: ["ad"],
          },
          {
            TableName: "Cariislembaglanti.BankaHesap",
            ColumnNames: ["ad"],
          },
          {
            TableName: "Cariislembaglanti.Fatura",
            ColumnNames: ["faturaTip"],
          },
          {
            TableName: "AltCariIslem",
            ColumnNames: [
              "id",
              "evrakNo",
              "aciklama",
              "tutar",
              "cariIslemTur",
              "islemTarih",
              "girisMi",
            ],
          },
          {
            TableName: "AltCariIslem.Cariislembaglanti.Kasa",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName: "AltCariIslem.Cariislembaglanti.BankaHesap",
            ColumnNames: ["id", "ad"],
          },
        ],
      });
      if (response.status === 200) {
        setCariIslemData(response.data.data[0]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTahsilatMakbuz = async () => {
    try {
      const pdfWindow = window.open(`${document.location.origin}/#/RaporYukleniyor`);
      setReportLoading(true);
      const response = await api.post("/rapordosya/tahsilatmakbuz", {
        CariIslemId: cariIslemId,
        format: "pdf",
      });
      if (response.status === 200)
        pdfWindow.document.location.href = response.data.data;
      else message.error(strings.mesaj.islemBasarisiz);
    } catch (error) {
      message.error(error.message);
    } finally {
      setReportLoading(false);
    }
  };

  const fetchFaturaMakbuz = async () => {
    try {
      const pdfWindow = window.open(`${document.location.origin}/#/RaporYukleniyor`);
      setReportLoading(true);
      const response = await api.post("/rapordosya/faturamakbuz", {
        FaturaId: cariIslemData.Cariislembaglanti.faturaId,
        format: "pdf",
      });

      if (response.status === 200) {
        pdfWindow.document.location.href = response.data.data;
      } else message.error(strings.mesaj.islemBasarisiz);
    } catch (error) {
      message.error(error.message);
    } finally {
      setReportLoading(false);
    }
  };

  const altIslemEkle = () => {
    if (cariIslemData.cariIslemTur === cariIslemTurTypes.ALACAK) {
      setOdemeEkleVisible(true);
    } else if (cariIslemData.cariIslemTur === cariIslemTurTypes.BORC) {
      setTahsilatEkleVisible(true);
    }
  };

  const columns = [
    {
      title: "İşlem Tarihi",
      dataIndex: "islemTarih",
      key: "islemTarih",
      defaultSortOrder: "descend",
      render: (text) => moment(text).format("DD.MM.YYYY"),
    },
    {
      title: "Evrak No",
      dataIndex: "evrakNo",
      key: "evrakNo",
    },
    {
      title: "İşlendiği Hesap",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => {
        if (row.Cariislembaglanti.Kasa.id) {
          return row.Cariislembaglanti.Kasa.ad;
        } else {
          return row.Cariislembaglanti.BankaHesap.ad;
        }
      },
    },
    {
      title: "Açıklama",
      dataIndex: "aciklama",
      key: "aciklama",
    },
    {
      title: "Tutar",
      dataIndex: "tutar",
      key: "tutar",
      render: (text, row, index) => (
        <Typography.Title
          level={5}
          style={{
            padding: 8,
            color: row.girisMi ? "green" : "red",
          }}
        >
          {cariIslemData.Doviz.simge}
          {paraFormat(text)}
        </Typography.Title>
      ),
    },
    {
      title: "İşlemler",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => {
        return (
          <Button
            onClick={() =>
              navigate(`/CariIslemDetay/${row.cariIslemTur}/${row.id}`)
            }
            type="default"
            shape="circle"
            icon={<SearchOutlined />}
            size={"large"}
          />
        );
      },
    },
  ];

  if (loading) {
    return <Card loading={true} />;
  }

  const yapilanTahsilatOdeme = cariIslemData.AltCariIslem.reduce(
    (toplam, item) => (toplam += item.tutar),
    0
  );

  const kalanTutar = parseFloat(cariIslemData.tutar - yapilanTahsilatOdeme);

  return (
    <>
      <PageHeader
        title="Cari İşlem"
        onBack={() => navigate(-1)}
        style={{ paddingLeft: 0 }}
      />
      <Card
        title={
          <>
            {cariIslemData.Cariislembaglanti.faturaId !== null ? (
              <Title level={4}>
                <FileTextOutlined />
                {` ${cariIslemData.Cariislembaglanti.Fatura.faturaTip}`}
              </Title>
            ) : (
              <Title level={4}>
                <FileSearchOutlined />
                {` ${cariIslemData.cariIslemTur}`}
              </Title>
            )}
          </>
        }
        extra={
          <>
            {cariIslemData.cariIslemTur === cariIslemTurTypes.TAHSILAT && (
              <Button
                onClick={fetchTahsilatMakbuz}
                type="primary"
                size="large"
                icon={<FileTextOutlined />}
                loading={reportLoading}
              >
                Tahsilat Makbuzu Yazdır
              </Button>
            )}
            {cariIslemData.Cariislembaglanti.faturaId !== null && (
              <Button
                onClick={fetchFaturaMakbuz}
                type="primary"
                size="large"
                icon={<FileTextOutlined />}
                loading={reportLoading}
              >
                Fatura Yazdır
              </Button>
            )}
          </>
        }
      >
        <>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <UserOutlined /> Cari Kart
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>{cariIslemData.CariKart.firmaAdi}</Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <CalendarOutlined /> İşlem Tarihi
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>
                  {moment(cariIslemData.islemTarih).format("DD.MM.YYYY")}
                </Title>
              </Col>
            </Row>
            {(cariIslemData.cariIslemTur === cariIslemTurTypes.ALACAK ||
              cariIslemData.cariIslemTur === cariIslemTurTypes.BORC) && (
              <Row gutter={24}>
                <Col span={8}>
                  <Title level={5} type="secondary">
                    <CalendarOutlined /> Vade Tarihi
                  </Title>
                </Col>
                <Col span={16}>
                  <Title level={5}>
                    {moment(cariIslemData.vadeTarih).format("DD.MM.YYYY")}
                  </Title>
                </Col>
              </Row>
            )}
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <NumberOutlined /> Evrak No
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>{cariIslemData.evrakNo}</Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <UnorderedListOutlined /> Ödeme Şekli
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>{cariIslemData.odemeSekli}</Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <SwapOutlined /> Döviz Türü
                </Title>
              </Col>
              <Col span={16}>
                <Title
                  level={5}
                >{`${cariIslemData.Doviz.ad} ${cariIslemData.Doviz.simge}`}</Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <BankOutlined /> İşlendiği Hesap
                </Title>
              </Col>
              <Col span={16}>
                {(cariIslemData.cariIslemTur === cariIslemTurTypes.BORC ||
                  cariIslemData.cariIslemTur === cariIslemTurTypes.ALACAK) && (
                  <Title level={5}>Açık Hesap</Title>
                )}

                {cariIslemData.Cariislembaglanti.Kasa.ad !== null && (
                  <Title level={5}>
                    {cariIslemData.Cariislembaglanti.Kasa.ad}
                  </Title>
                )}
                {cariIslemData.Cariislembaglanti.BankaHesap.ad !== null && (
                  <Title level={5}>
                    {cariIslemData.Cariislembaglanti.BankaHesap.ad}
                  </Title>
                )}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Title level={5} type="secondary">
                  <InfoCircleOutlined /> Açıklama
                </Title>
              </Col>
              <Col span={16}>
                <Title level={5}>{cariIslemData.aciklama}</Title>
              </Col>
            </Row>
          </Space>
          <Divider />
          <Row justify="end">
            <Row gutter={24}>
              <Col>
                <Title level={4} type="secondary">
                  İşlem Tutarı
                </Title>
              </Col>
              <Col>
                <Title level={4}>{`${cariIslemData.Doviz.simge}${paraFormat(
                  cariIslemData.tutar
                )}`}</Title>
              </Col>
            </Row>
          </Row>
        </>
      </Card>
      {(cariIslemData.cariIslemTur === cariIslemTurTypes.ALACAK ||
        cariIslemData.cariIslemTur === cariIslemTurTypes.BORC) && (
        <>
          <br />
          <Card
            title={
              <Title level={4}>
                <UnorderedListOutlined />
                {` ${
                  cariIslemData.cariIslemTur === cariIslemTurTypes.ALACAK
                    ? "Ödemeler"
                    : "Tahsilatlar"
                }`}
              </Title>
            }
            loading={loading}
            extra={
              <Button
                onClick={altIslemEkle}
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                disabled={kalanTutar === 0 ? true : false}
              >
                {cariIslemData.cariIslemTur === cariIslemTurTypes.ALACAK
                  ? "Ödeme Ekle"
                  : "Tahsilat Ekle"}
              </Button>
            }
          >
            <Alert closable message= {cariIslemData.cariIslemTur === cariIslemTurTypes.ALACAK
                  ? "Alacaklandırma ile ilişkili eklemiş olduğunuz Ödeme işlemleri, Alacaklandırma işleminin silinmesi durumunda otomatik olarak silinir."
                  : "Borçlandırma ile ilişkili eklemiş olduğunuz Tahsilat işlemleri, Borçlandırma işleminin silinmesi durumunda otomatik olarak silinir."}/>
            <br/>
            <Table
              dataSource={cariIslemData.AltCariIslem}
              columns={columns}
              pagination={false}
              size="large"
            />
            <Divider />
            <Row justify="end">
              <Row gutter={24}>
                <Col>
                  <Title level={4} type="secondary">
                    {cariIslemData.cariIslemTur === cariIslemTurTypes.BORC
                      ? "Toplam Tahsilat"
                      : "Toplam Ödeme"}
                  </Title>
                </Col>
                <Col>
                  <Title level={4}>
                    {` ${cariIslemData.Doviz.simge}${paraFormat(
                      yapilanTahsilatOdeme
                    )}`}
                  </Title>
                </Col>
              </Row>
            </Row>
            <Row justify="end">
              <Row gutter={24}>
                <Col>
                  <Title level={4} type="secondary">
                    Kalan Tutar
                  </Title>
                </Col>
                <Col>
                  <Title level={4}>
                    {` ${cariIslemData.Doviz.simge}${paraFormat(kalanTutar)}`}
                  </Title>
                </Col>
              </Row>
            </Row>
          </Card>
        </>
      )}
      <CariAltTahsilatEkle
        visible={tahsilatEkleVisible}
        dovizId={cariIslemData.Doviz.id}
        handleCancel={() => setTahsilatEkleVisible(false)}
        cariIslemId={cariIslemId}
        cariKartId={cariIslemData.CariKart.id}
        kalanTutar={kalanTutar}
      />
      <CariAltOdemeEkle
        visible={odemeEkleVisible}
        dovizId={cariIslemData.Doviz.id}
        handleCancel={() => setOdemeEkleVisible(false)}
        cariIslemId={cariIslemId}
        cariKartId={cariIslemData.CariKart.id}
        kalanTutar={kalanTutar}
      />
    </>
  );
}

export default CariIslemDetay;
