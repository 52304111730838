import React, { useEffect } from 'react'
import { Button, Space, Tag, Spin, message, Table, Result } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import useCurrency from '../../hooks/useCurrency'
import strings from '../../utils/Localization'
import { useState } from 'react'
import moment from 'moment'
import useApi from '../../hooks/useApi'
import PageHeaderGeleEFaturaListe from './PageHeaderGeleEFaturaListe'
import EFaturaGelenKaydetModal from './EFaturaGelenKaydetModal'
import useKullanici from '../../hooks/useKullanici'
import { useNavigate } from 'react-router-dom'

function EFaturaGelenListe() {
  const [dataSource, setDataSource] = useState([])
  const [spinning, setSpinning] = useState(false)
  const [eFaturaGelenKaydetModalVisible, setEFaturaGelenKaydetModalVisible] = useState(false)
  const [selectedFatura, setSelectedFatura] = useState({})
  const [listRefresh, setListRefresh] = useState(false)
  const { paraFormat } = useCurrency()
  const api = useApi()
  const { getEFaturaAktifMi } = useKullanici()
  const navigate= useNavigate()
  const localization = strings.eFaturaGelenListe.eFaturaGelenListe

  useEffect(() => {
    if (getEFaturaAktifMi) {
      fetchData()
    }
  }, [listRefresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    try {
      setSpinning(true)
      const response = await api.post('/fatura/getnewefatura', {
        AfterValue: 0,
        Limit: 100,
        TenantIdentifierNumber: '',
      })
      if (response.status === 200) {
        setDataSource(response.data.data.fatura)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setSpinning(false)
    }
  }

  const columns = [
    {
      title: localization.firmaAdi,
      dataIndex: 'accountName',
      key: 'accountName',
      sorter: (a, b) =>  a.accountName.localeCompare(b.accountName),
    },
    {
      title: localization.faturaNo,
      dataIndex: 'docNo',
      key: 'docNo',
      sorter: (a, b) => a.docNo.localeCompare(b.docNo),
    },
    {
      title: localization.faturaTipi,
      dataIndex: 'profile',
      key: 'profile',
      sorter: (a, b) => a.profile.length - b.profile.length,
      render: (text) => (
        <Tag color={'blue'} style={{ fontSize: 14 }}>
          {text}
        </Tag>
      ),
    },
    {
      title: localization.faturaTarihi,
      dataIndex: 'docDate',
      key: 'docDate',
      sorter: (a, b) => moment(a.docDate).unix() - moment(b.docDate).unix(),
      render: (text) => moment(text).format('DD.MM.YYYY'),
    },
    {
      title: localization.genelToplam,
      dataIndex: 'payableAmount',
      key: 'payableAmount',
      sorter: (a, b) => a.payableAmount - b.payableAmount,
      render: (text, row, index) => `${row.currencyCode} ${paraFormat(text)}`,
    },
    {
      title: localization.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle">
          <Button
            type="ghost"
            onClick={() => {
              setSelectedFatura(row)
              setEFaturaGelenKaydetModalVisible(true)
            }}
            icon={<SaveOutlined />}
          >
            {localization.faturayiKaydet}
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <PageHeaderGeleEFaturaListe />
      <br />
      {getEFaturaAktifMi ? (
        <Spin spinning={spinning}>
          <Table dataSource={dataSource} columns={columns} />
        </Spin>
      ) : (
        <Result
          status="info"
          title={localization.eFaturaUyari}
          extra={
            <Button type="primary" key="console" onClick={() => navigate(-1)}>
              {localization.geriDon}
            </Button>
          }
        />
      )}
      <EFaturaGelenKaydetModal
        visible={eFaturaGelenKaydetModalVisible}
        selectedFatura={selectedFatura}
        handleCancel={() => setEFaturaGelenKaydetModalVisible(false)}
        onSuccess={() => {
          setListRefresh(!listRefresh)
        }}
      />
    </>
  )
}

export default EFaturaGelenListe
