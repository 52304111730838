import { PageHeader, Form, Card, Button, Space, Col, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StokKategoriSelectOption from "../components/toolbox/StokKategoriSelectOption";
import useApi from "../hooks/useApi";
import strings from "../utils/Localization";

function StokRapor() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const navigate= useNavigate();
  const api = useApi();
  const { stokRapor: localization } = strings;

  const onFinish = async (values) => {
    try {
      const { stokKategoriId } = values;

      const pdfWindow = window.open(`${document.location.origin}/#/RaporYukleniyor`);
      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/stokliste",
        {
          StokKategoriId: stokKategoriId === undefined ? 0 : stokKategoriId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        pdfWindow.document.location.href = response.data.data;
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <PageHeader
        onBack={() => navigate(-1)}
        title={localization.baslik}
        ghost={false}
      />
      <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Card>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="stokKategoriId"
              label={strings.stokKategoriSelectOption.stokKategoriBaslik}
            >
              <StokKategoriSelectOption />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: "right" }}
                size="large"
                loading={fetchLoading}
              >
                {localization.stokRaporuAl}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Space>
  );
}

export default StokRapor;
