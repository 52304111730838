import React, { useState } from "react";
import { PageHeader as MyPageHeader, Button } from "antd";
import { useNavigate } from "react-router-dom";
import strings from "../../utils/Localization";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { GelirGiderGrupEkleModal } from "../gelirGiderGrup";

export default function PageHeader() {
  const [gelirGiderGrupEkleModalVisible, setGelirGiderGrupEkleModalVisible] = useState(false)
  const navigate= useNavigate();
  const { pageHeader } = strings.gelirGiderGrupListe.components;

  return (
    <>
      <MyPageHeader
        ghost={false}
        onBack={() => navigate(-1)}
        title={pageHeader.baslik}
        subTitle={pageHeader.altBaslik}
        extra={[
          <Button
            onClick={() => setGelirGiderGrupEkleModalVisible(true)}
            type="primary"
            size="large"
            icon={<MinusOutlined />}
          >
            {pageHeader.giderGrupEkleButon}
          </Button>,
          <Button
            onClick={() => setGelirGiderGrupEkleModalVisible(true)}
            type="primary"
            size="large"
            icon={<PlusOutlined />}
          >
            {pageHeader.gelirGrupEkleButon}
          </Button>
        ]}
      />
      <GelirGiderGrupEkleModal
        visible={gelirGiderGrupEkleModalVisible}
        handleCancel={() => setGelirGiderGrupEkleModalVisible(false)}
      />
    </>
  );
}
