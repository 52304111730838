import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, message, Card, Space, Checkbox, notification, Collapse } from 'antd'
import strings from '../../utils/Localization'
import MaskInput from 'antd-mask-input'
import useApi from '../../hooks/useApi'
import SubeSelectOption from '../toolbox/SubeSelectOption'
import { useParams } from "react-router-dom";

const { Panel } = Collapse

const KullaniciDuzenleForm = ({ onSaveLoading = () => { } }) => {
    const [form] = Form.useForm()
    const [fetchLoadingKullanici, setFetchLoadingKullanici] = useState(true)
    const [fetchLoadingYetki, setFetchLoadingYetki] = useState(true)
    const [yetkiData, setYetkiData] = useState([])
    const api = useApi()
    const { kullaniciDuzenleForm: localization } = strings.kullaniciDuzenle.components
    const { kullaniciId } = useParams()

    useEffect(() => {
        getKullanici()
        getYetki()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getKullanici = async () => {
        try {
            const response = await api.post('/kullanici/get', {
                Data: {
                    Id: kullaniciId,
                },
                SelectColumns: [
                    {
                        TableName: '',
                        ColumnNames: ['id', 'adSoyad', 'telefonNo', 'eMail'],
                    },
                    {
                        TableName: 'KullaniciHasSube.Sube',
                        ColumnNames: ['id'],
                    },
                ],
            })

            const selectedKullanici = response.data.data[0]
            form.setFieldsValue({
                id: selectedKullanici.id,
                adSoyad: selectedKullanici.adSoyad,
                eMail: selectedKullanici.eMail,
                telefonNo: selectedKullanici.telefonNo,
                subeId: selectedKullanici.KullaniciHasSube.map((item) => String(item.Sube.id)),
            })

        } catch (error) {
            message.error(error.message)
        }
        finally {
            setFetchLoadingKullanici(false)
        }
    }

    const getYetki = async () => {
        try {
            const response = await api.post("/yetki/get", {
                Data: {},
                SelectColumns: [
                    {
                        TableName: "",
                        ColumnNames: [
                            "id",
                            "goruntulenecekAd",
                            "ad"
                        ]
                    },
                    {
                        TableName: "KullaniciHasYetki",
                        ColumnNames: [
                            "KullaniciId",
                            "YetkiId"
                        ],
                        Conditions: [
                            {
                                Property: "KullaniciId",
                                Value: kullaniciId,
                                Operator: "Equal"
                            }
                        ]
                    }
                ],
                SortOptions: {
                    ColumnName: 'GoruntulenecekAd',
                    SortType: 'Asc',
                },
            })

            const data = groupBy(response.data.data, x => x.ad.split('.')[0])
            setYetkiData(Array.from(data))
        } catch (error) {
            message.error(error.message)
        }
        finally {
            setFetchLoadingYetki(false)
        }
    }

    const onFinish = async (data) => {
        try {
            const { id, eMail, adSoyad, telefonNo, subeId } = data

            const kullaniciHasYetki = []

            data.yetki.map(parentItem => {
                parentItem.map(item => {
                    if (item.durum === true)
                        kullaniciHasYetki.push({
                            YetkiId: item.id
                        })
                    return true
                })
                return true
            })
            onSaveLoading(true)
            await api.put('/kullanici', {
                Id: id,
                AdSoyad: adSoyad,
                EMail: eMail,
                TelefonNo: telefonNo,
                KullaniciHasSube: subeId.map((item) => ({ SubeId: item })),
                KullaniciHasYetki: kullaniciHasYetki
            })

            notification['success']({
                message: strings.mesaj.basarliBaslik,
                description: strings.mesaj.islemBasarili,
            })

        } catch (error) {
            message.error(error.message)
        }
        finally {
            onSaveLoading(false)
        }

    }

    const groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    return (
        <Form form={form} name="kullaniciDuzenleForm" layout="vertical" onFinish={onFinish}>
            <Form.Item name="id" hidden />
            <Space direction='vertical' style={{ width: '100%' }} size={"large"}>
                <Card title={localization.kullaniciAyarlari} loading={fetchLoadingKullanici}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name="eMail" label={localization.eMail} rules={[{ type: 'email', required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="adSoyad" label={localization.adSoyad} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="telefonNo" label={localization.telefonNumarasi} rules={[{ required: true }]}>
                                <MaskInput mask="(111) 111 11 11" />
                            </Form.Item>
                            <Form.Item name="subeId" label={localization.sube} rules={[{ required: true }]}>
                                <SubeSelectOption mode="multiple" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title={localization.yetkiAyarlari} loading={fetchLoadingYetki}>
                    <Collapse defaultActiveKey={['0']}>
                        {yetkiData.map((parentItem, parentIndex) => {
                            return (
                                <Panel header={parentItem[0].match(/[A-Z][a-z]+/g).map(item => item + " ")} key={parentIndex} forceRender={true}>
                                    <Row gutter={24}>
                                        {
                                            parentItem[1].map((item, index) => {
                                                return (
                                                    <Col span={6}>
                                                        <Form.Item name={["yetki", parentIndex, index, "id"]} initialValue={item.id} hidden />
                                                        <Form.Item name={["yetki", parentIndex, index, "durum"]} initialValue={item.KullaniciHasYetki.length > 0 ? true : false} valuePropName="checked" >
                                                            <Checkbox >{item.goruntulenecekAd}</Checkbox>
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Panel>
                            )
                        })}
                    </Collapse>
                </Card>
            </Space>
        </Form>
    )
}

export default KullaniciDuzenleForm