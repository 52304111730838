import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import useKullanici from './useKullanici'
import strings from '../utils/Localization'

let refreshing_token = null

const useApi = () => {
  const navigate= useNavigate()
  const { getToken, getRefreshToken, setToken, setRefreshToken } = useKullanici()
  let token = getToken || ''

  const api = axios.create({
    baseURL: 'https://api-ehpro.aymyazilim.com/api',
    withCredentials: true,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    validateStatus: (status) => {
      return [200, 402].indexOf(status) !== -1
    },
  })

  const refresh_token = () => {
    return api.post('/auth/refreshtoken', {
      RefreshToken: getRefreshToken,
    })
  }

  api.interceptors.response.use(
    (response) => {
      if (response.status === 402) {
        navigate('/Paketler')
        return Promise.resolve(response)
      }
      return response
    },
    async (error) => {
      const config = error.config

      if (config.url === '/auth/refreshtoken') {
        navigate(' /GirisYap')
        return false
      }

      if (error.response && error.response.status === 401 && !config._retry && config.url !== '/auth/refreshtoken') {
        config._retry = true
        try {
          refreshing_token = refreshing_token ? refreshing_token : refresh_token()
          let res = await refreshing_token
          refreshing_token = null
          if (res.data.singleData) {
            const { token, refreshToken } = res.data.singleData
            setToken(token)
            setRefreshToken(refreshToken)

            api.defaults.headers['Authorization'] = 'Bearer ' + token
            config.headers['Authorization'] = 'Bearer ' + token
          }
          return api(config)
        } catch (err) {
          return false
        }
      }

      let responseMessage = undefined
      if (error.response) {
        if (typeof error?.response?.data == 'string') {
          responseMessage = error.response.data
        } else {
          responseMessage = error.response.data.message
        }
      }

      return Promise.reject(new Error(responseMessage || strings.mesaj.islemBasarisiz))
    }
  )

  return api
}

export default useApi
