import React from 'react'
import { Image, Row } from 'antd'
import Logo104 from '../../assets/images/logo104.png'

const imageStyle = { paddingBottom: 24 }

export default function Logo() {
    return (
        <Row
            type="flex"
            justify="center"
            align="middle"
        >
            <Image
                src={Logo104}
                width={100}
                style={imageStyle}
            />
        </Row>
    )
}
