import React from 'react'
import { PageHeader as MyPageHeader } from 'antd'
import { useNavigate } from 'react-router-dom'
import strings from '../../utils/Localization'

export default function PageHeader() {
    const navigate= useNavigate()
    const { pageHeader } = strings.sifremiDegistir.components

    return (
        <MyPageHeader
            ghost={false}
            onBack={() => navigate(-1)}
            title={pageHeader.baslik}
            subTitle={pageHeader.altBaslik}
        />
    )
}
