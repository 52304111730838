import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, notification, Button } from "antd";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useApi from "../../hooks/useApi";

const formSize = "middle";

function StokVaryantDegerDuzenleModal({
  visible = false,
  handleCancel,
  data = null,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [deleteFetchLoading, setDeleteFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const api = useApi();

  useEffect(() => {
    if (data !== null) {
      form.setFieldsValue({
        id: data.id,
        varyantDegerAdi: data.ad,
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();
  const { stokVaryantDegerDuzenle: localization } =
    strings.stokVaryantDeger.components;

  const onFinish = async (values) => {
    try {
      setFetchLoading(true);
      const { id, varyantDegerAdi } = values;

      const response = await api.put("/stokvaryantdeger", {
        Id: id,
        Ad: varyantDegerAdi,
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const remove = async () => {
    try {
      if (data.stokSayisi > 0) {
        return message.error({
          content: strings.mesaj.stokVaryantDegerSilme,
          style: { marginTop: "20vh" },
        });
      }
      setDeleteFetchLoading(true);
      const { id } = form.getFieldValue();

      const response = await api.delete(`/stokvaryantdeger/${id}`, {});

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
        handleCancel();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setDeleteFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      footer={[
        <Button size={formSize} loading={deleteFetchLoading} onClick={remove}>
          {localization.silButton}
        </Button>,
        <Button
          htmlType="submit"
          form="varyantDegerDuzenleModal"
          size={formSize}
          loading={fetchLoading}
          type="primary"
        >
          {localization.guncelleButton}
        </Button>,
      ]}
    >
      <Form
        name="varyantDegerDuzenleModal"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size={formSize}
        initialValues={{
          varyantDegerAdi: "",
        }}
      >
        <Form.Item name="id" hidden noStyle />
        <Form.Item
          name="varyantDegerAdi"
          label={localization.varyantDegerAdi}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default StokVaryantDegerDuzenleModal;
