import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import useApi from "./useApi";
import useKullanici from "./useKullanici";

const useStokFBStorage = () => {
  const { getVergiNumarasi } = useKullanici();
  const storage = getStorage();
  const api= useApi()

  const updateStokImage = async (blob, imageName) => {
    const vergiNumarasi=await fetchVergiNumarasi()
    const stokRef = ref(storage, `/${vergiNumarasi}/stok/${imageName}`);
    await uploadBytes(stokRef, blob).then((snapshot) => {
      // console.log('Uploaded a blob or file!')
    });

    const downloadUrl = await getStokImage(imageName);
    return downloadUrl;
  };
  const deleteStokImage = async (imageName) => {
      const resimVarMi = await getStokImage(imageName);
      if (resimVarMi == null) return true;

      const vergiNumarasi=await fetchVergiNumarasi()
      const stokRef = ref(storage, `/${vergiNumarasi}/stok/${imageName}`);
      await deleteObject(stokRef);
      return true;
  };
  const getStokImage = async (imageName) => {
      const stokRef = ref(storage, `/${getVergiNumarasi}/stok/${imageName}`);
      const downloadUrl = await getDownloadURL(stokRef);
      return downloadUrl;
  };

  const fetchVergiNumarasi = async () => {
    const response = await api.post("/firma/get", {
      SelectColumns: [
        {
          TableName: "",
          ColumnNames: ["vergiNumarasi"],
        },
      ],
    });

    if (response.status === 200) {
      const { vergiNumarasi } = response.data.data[0];
      return vergiNumarasi;
    }
  };

  return { updateStokImage, deleteStokImage, getStokImage };
};

export default useStokFBStorage;
