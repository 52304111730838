import React, { useEffect, useState } from "react";
import { message, Select, Tag, SelectProps } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";

const { Option } = Select;

interface IProps extends SelectProps<any> {
  requestBody: {};
  onSelectedVaryant: (selectedVaryant: any) => void;
}

const StokVaryantSelectOption: React.FC<IProps> = ({
  requestBody = {},
  onSelectedVaryant = () => {},
  placeholder,
  mode = "multiple",
  ...rest
}) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [varyantData, setVaryantData] = useState([]);
  const api = useApi();

  useEffect(() => {
    fetchStokVaryant();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStokVaryant = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/stokvaryant/get", {
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["Id", "Ad"],
          },
          {
            TableName: "Stokvaryantdeger",
            ColumnNames: ["Ad", "Id", "StokVaryantId"],
          },
        ],
        ...requestBody,
      });
      if (response.status === 200) {
        let data: any = [];
        response.data.data.map((varyant: any, index: number) => {
          return data.push({
            index,
            label: varyant.Ad,
            value: varyant.Id,
            child: varyant.Stokvaryantdeger.map((varyantDeger: any) => ({
              label: varyantDeger.Ad,
              value: {
                Ad: varyantDeger.Ad,
                Id: varyantDeger.Id,
                StokVaryantId: varyant.Id,
              },
            })),
          });
        });
        setVaryantData(data);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Select
      {...rest}
      placeholder={placeholder || strings?.stokVaryantSelectOption?.placeHolder}
      loading={fetchLoading}
      onSelect={(e) => {
        const selectedVaryant = varyantData.find(
          (x: any) => x.id === parseInt(e)
        );
        onSelectedVaryant(selectedVaryant);
      }}
      onClear={() => onSelectedVaryant({})}
      mode={mode}
    >
      {varyantData.map((item: any, index) =>
        item.child.map((child: any) => (
          <Option key={child.value} value={JSON.stringify(child.value)}>
            <Tag> {item.label}</Tag> {child.label}
          </Option>
        ))
      )}
    </Select>
  );
};

export default StokVaryantSelectOption;
