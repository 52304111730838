import React, { useState } from 'react'
import { Card, Button, Space, Popconfirm, message, notification, Tooltip } from 'antd'
import strings from '../../utils/Localization'
import FetchListContent from '../toolbox/FetchListContent'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { PlusOutlined } from '@ant-design/icons'
import CursorTableItem from '../toolbox/CursorTableItem'
import useApi from '../../hooks/useApi'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const UretimListe = () => {
  const [dataSource, setDataSource] = useState({})
  const api = useApi()
  const navigate = useNavigate()
  const { uretimListe: localization } = strings

  const columns = [
    {
      title: localization.baslik,
      dataIndex: 'baslik',
      key: 'baslik',
      sorter: (a, b) => a.baslik.localeCompare(b.baslik),
      render: (text, row, index) => <CursorTableItem hideHand text={row.baslik} />,
    },
    {
      title: localization.uretimBaslangicTarihi,
      dataIndex: 'uretimBaslangicTarihi',
      key: 'uretimBaslangicTarihi',
      render: (text) => text ? moment(text).format('DD.MM.YYYY') : "-",
    },
    {
      title: localization.uretimBitisTarihi,
      dataIndex: 'uretimBitisTarihi',
      key: 'uretimBitisTarihi',
      render: (text) => text ? moment(text).format('DD.MM.YYYY') : "-",
    },
    {
      title: localization.islemler,
      key: 'edit',
      dataIndex: 'edit',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={localization.uretimDuzenle}>
            <Button
              onClick={() =>navigate(`/UretimDuzenle/${row.id}`)}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={localization.uretimSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => remove(row.id)}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const remove = async (uretimId) => {
    try {
      const response = await api.delete(`/uretim/${uretimId}`, {})
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== uretimId),
        })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  return (
    <Card
      title={localization.uretimListesi}
      extra={[
        <Button
          key="1"
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          onClick={() => navigate("/UretimEkle")}
        >
          {localization.uretimEkle}
        </Button>,
      ]}
    >
      <FetchListContent
        columns={columns}
        requestUrl="uretim/get"
        dataSource={dataSource}
        onDataSource={(e) => setDataSource(e)}
        requestBody={{
          "SelectColumns": [
            {
              "ColumnNames": [
                "id",
                "baslik",
                "uretimBaslangicTarihi",
                "uretimBitisTarihi",
                "evrakNo"
              ],
              "TableName": ""
            }
          ]
        }}
      />
    </Card>
  )
}

export default UretimListe
