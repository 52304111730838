import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { message, Modal, Space, Spin, Typography } from 'antd'
import useApi from '../../../hooks/useApi'
import useKullanici from '../../../hooks/useKullanici'
import SubeSelectOption from '../../toolbox/SubeSelectOption'
import strings from '../../../utils/Localization'

function SubeSecim() {
  const api = useApi()
  const navigate= useNavigate()
  const location = useLocation()
  const { getSubeId, getEMail, setAuthParameters } = useKullanici()
  const localization = strings.navBar.subeSecim

  const onChangeSube = async (value) => {
    try {
      const response = await api.post('/auth/subechange', {
        SubeId: value,
      })
      if (response.status === 200) {
        const { token, defaultSettings, refreshToken } = response.data.singleData
        setAuthParameters({ token, defaultSettings, refreshToken, eMail: getEMail })

        const modal = Modal.info({
          title: 'Şube Değişimi',
          content: (
            <Space align="center"  direction="vertical" style={{width:'100%'}}>
              <br />
              <Spin size="large" />
              <Typography.Title level={4}>{localization.subeYonlendirme}</Typography.Title>
              <br />
            </Space>
          ),
          okButtonProps: { hidden: true },
        })

        setTimeout(() => {
          if (location.pathname === '/') {
            navigate(0)
          } else {
            navigate('/')
          }

          modal.destroy()
        }, 1000)
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  return <SubeSelectOption bordered={false} defaultValue={getSubeId} onChange={onChangeSube} />
}

export default SubeSecim
