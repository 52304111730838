import React, { useState } from 'react'
import { Button, Space, Popconfirm, message, notification, Typography, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import strings from '../../utils/Localization'
import { useNavigate } from 'react-router-dom'
import CursorTableItem from '../toolbox/CursorTableItem'
import FetchListContent from '../toolbox/FetchListContent'
import useCurrency from '../../hooks/useCurrency'
import BankaHesapDuzenleModal from '../bankaHesap/BankaHesapDuzenleModal'
import useApi from '../../hooks/useApi'

export default function BankHesapListTable() {
  const [dataSource, setDataSource] = useState({})
  const [bankaHesapDuzenleModalVisible, setBankaHesapDuzenleModalVisible] = useState(false)
  const [duzenleData, setDuzenleData] = useState(null)

  const navigate= useNavigate()
  const { paraFormat } = useCurrency()
  const api = useApi()
  const { bankaHesapListTable } = strings.bankaHesapListe.components

  const columns = [
    {
      title: bankaHesapListTable.hesapAdi,
      dataIndex: 'ad',
      key: 'ad',
      render: (text, row, index) => (
        <CursorTableItem
          text={`${text} (${row.islemSayisi})`}
          onClick={() => navigate(`/BankaHesapDetay/${row.id}`)}
        />
      ),
    },
    {
      title: bankaHesapListTable.bankaAdi,
      dataIndex: 'bankaAdi',
      key: 'bankaAdi',
      render: (text, row, index) => row.banka.ad,
    },
    {
      title: bankaHesapListTable.sube,
      dataIndex: 'subeAd',
      key: 'subeAd',
    },
    {
      title: bankaHesapListTable.hesapNo,
      dataIndex: 'hesapNo',
      key: 'hesapNo',
    },
    {
      title: bankaHesapListTable.bakiye,
      dataIndex: 'bakiye',
      key: 'bakiye',
      render: (text, row, index) => {
        const { GirenBankaIslemTutar, CikanBankaIslemTutar } = row
        const bakiye = parseFloat(GirenBankaIslemTutar - CikanBankaIslemTutar)
        return (
          <Typography.Title level={5} style={{ padding: 8, color: bakiye >= 0 ? 'green' : 'red' }}>{`${
            row.doviz.simge
          }${paraFormat(bakiye)}`}</Typography.Title>
        )
      },
    },
    {
      title: bankaHesapListTable.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={bankaHesapListTable.bankaHesapDetay}>
            <Button
              onClick={() => navigate(`/BankaHesapDetay/${row.id}`)}
              type="default"
              shape="circle"
              icon={<SearchOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={bankaHesapListTable.bankaHesapDuzenle}>
            <Button
              onClick={() => {
                setDuzenleData(row)
                setBankaHesapDuzenleModalVisible(true)
              }}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={bankaHesapListTable.bankaHesapSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => {
                if (row.islemSayisi > 0) {
                  message.error({
                    content: strings.mesaj.bankaHesapSilme,
                    style: { marginTop: '20vh' },
                  })
                } else remove(row.id)
              }}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const remove = async (bankahesapId) => {
    try {
      const response = await api.delete(`/bankaHesap/${bankahesapId}`, {})
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== bankahesapId),
        })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  return (
    <>
      <FetchListContent
        columns={columns}
        requestUrl="/bankahesap/get"
        requestBody={{
          SelectColumns: [
            {
              TableName: '',
              ColumnNames: ['ad', 'id', 'hesapNo', 'subeAd', 'subeNo', 'ibanNo'],
            },
            {
              TableName: 'banka',
              ColumnNames: ['id', 'ad'],
            },
            {
              TableName: 'doviz',
              ColumnNames: ['simge', 'kod', 'id'],
            },
            {
              TableName: 'Bankahesapislem:GirenBankaIslemTutar',
              ColumnNames: ['Tutar'],
              Conditions: [
                {
                  Property: 'GirisMi',
                  Value: 'true',
                  Operator: 'Equal',
                },
              ],
              Operator: 'Sum',
            },
            {
              TableName: 'Bankahesapislem:CikanBankaIslemTutar',
              ColumnNames: ['Tutar'],
              Conditions: [
                {
                  Property: 'GirisMi',
                  Value: 'false',
                  Operator: 'Equal',
                },
              ],
              Operator: 'Sum',
            },
            {
              TableName: 'Bankahesapislem:islemSayisi',
              Operator: 'Count',
            },
          ],
        }}
        onDataSource={(e) => setDataSource(e)}
        dataSource={dataSource}
      />
      <BankaHesapDuzenleModal
        visible={bankaHesapDuzenleModalVisible}
        handleCancel={() => setBankaHesapDuzenleModalVisible(false)}
        data={duzenleData}
      />
    </>
  )
}
