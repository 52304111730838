import { Card, Divider, message, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import strings from "../../utils/Localization";
import { StokImage } from "../stok";
import StokBakiye from "./StokBakiye";

const { Title } = Typography;

const gridStyle = {
  width: "50%",
  textAlign: "center",
  padding: 12,
};

const MyDivider = () => <Divider style={{ marginTop: 12, marginBottom: 12 }} />;

function Profil() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [stokData, setStokData] = useState({
    StokKategori: { ad: "" },
    StokBirim: { ad: "" },
  });
  const [stokVaryantGrupIds, setStokVaryantGrupIds] = useState([]);
  const { profil: localization } = strings.varyantliStokDetay.components;

  const { stokId } = useParams();
  const api = useApi();

  useEffect(() => {
    fetchStok();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStok = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/stok/get", {
        Data: {
          Id: stokId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["ad", "aciklama", "id"],
          },
          {
            TableName: "StokKategori",
            ColumnNames: ["ad", "id"],
          },
          {
            TableName: "StokBirim",
            ColumnNames: ["ad", "id"],
          },
          {
            TableName: "Stokvaryantgrup",
            ColumnNames: ["id", "resimAd"],
          },
        ],
      });
      if (response.status === 200) {
        setStokData(response.data.data[0]);
        setStokVaryantGrupIds(
          response.data.data[0].Stokvaryantgrup.map((item) => item.id)
        );
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Card
      bodyStyle={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      loading={fetchLoading}
    >
      <Space direction="vertical" align="center" style={{ width: "100%" }}>
        <StokImage imageName={stokData?.Stokvaryantgrup?.[0]?.resimAd} />
        <Title level={4} style={{ marginTop: 8, marginBottom: 4 }}>
          {stokData.ad}
        </Title>
      </Space>
      <MyDivider />
      <Card>
        <Card.Grid style={gridStyle}>{localization.stokKategori}</Card.Grid>
        <Card.Grid style={gridStyle}>{stokData.StokKategori.ad}</Card.Grid>
        <Card.Grid style={gridStyle}>{localization.stokBirim}</Card.Grid>
        <Card.Grid style={gridStyle}>{stokData.StokBirim.ad}</Card.Grid>
        <Card.Grid style={gridStyle}>{localization.aciklama}</Card.Grid>
        <Card.Grid style={gridStyle}>{stokData.aciklama}</Card.Grid>
      </Card>
      <MyDivider />
      <StokBakiye stokVaryantGrupIds={stokVaryantGrupIds} />
    </Card>
  );
}

export default Profil;
