import useVarsayilanDeger from "./useVarsayilanDeger";

var currencyFormatter = require("currency-formatter");

const useCurrency = () => {
  const { getDefaultSetting } = useVarsayilanDeger();
  
  const paraFormat = (tutar) =>
    currencyFormatter.format(tutar, {
      locale: null,
      precision: parseInt(getDefaultSetting("basamakSayisi")),
    });

  return { paraFormat };
};

export default useCurrency;
