import React from "react";
import { PageHeader, Profil, CariIslemList } from "../components/cariKartDetay";
import { Col, Row } from "antd";

export default function CariKartDetay() {
  return (
    <>
      <PageHeader />
      <br />
      <Row gutter={24}>
        <Col xxl={6} xl={7}  lg={24} md={24} xs={24}>
          <Profil />
          <br />
        </Col>
        <Col xxl={18} xl={17} lg={24} md={24} xs={24}>
          <CariIslemList />
        </Col>
      </Row>
      <br />
    </>
  );
}
