import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  message,
  notification,
  Select,
  Row,
  Col,
} from "antd";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import Datepicker from "../toolbox/DatePicker";
import DepoSelectOption from "../toolbox/DepoSelectOption";
import StokVaryantGrupSelectOption from "../toolbox/StokVaryantGrupSelectOption";
import CurrencyInput from "../toolbox/CurrencyInput";
import { useParams } from "react-router-dom";
import strings from "../../utils/Localization";
import moment from "moment";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import useApi from "../../hooks/useApi";

const { Option } = Select;

const formSize = "middle";

function VaryantliStokIslemDuzenleModal({
  visible = false,
  handleCancel,
  data = null,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { stokId } = useParams();
  const api = useApi();
  const { stokIslemDuzenle: localization } =
    strings.varyantliStokIslem.components;

  useEffect(() => {
    if (data !== null) {
      form.setFieldsValue({
        id: data.id,
        stokVaryantGrupId: String(data.stokVaryantGrupId),
        tarih: moment(data.islemTarihi),
        girisMi: data.girisMi,
        evrakNo: data.evrakNo,
        depoId: String(data.stokDepoId),
        miktar: data.miktar,
        aciklama: data.aciklama,
        birimFiyat: data.birimFiyat,
        dovizId: String(data.Doviz.id),
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        id,
        tarih,
        girisMi,
        evrakNo,
        depoId,
        miktar,
        aciklama,
        birimFiyat,
        dovizId,
      } = values;
      setFetchLoading(true);
      const response = await api.put("/stokislem", {
        Id: id,
        StokDepoId: depoId,
        evrakNo: evrakNo,
        BirimFiyat: birimFiyat,
        Miktar: miktar,
        GirisMi: girisMi,
        Aciklama: aciklama,
        IslemTarihi: tarih,
        dovizId: dovizId,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "stokIslemDuzenleModal",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      centered
      width="50%"
    >
      <Form
        name="stokIslemDuzenleModal"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size={formSize}
      >
        <Form.Item hidden name="id" />
        <Row gutter={12}>
          <Col flex={1}>
            <Form.Item
              name="tarih"
              label={localization.tarih}
              rules={[{ required: true }]}
            >
              <Datepicker />
            </Form.Item>
            <Form.Item
              name="stokVaryantGrupId"
              label="Varyant Grup Seçiniz"
              rules={[{ required: true }]}
            >
              <StokVaryantGrupSelectOption stokId={stokId} disabled />
            </Form.Item>
            <Form.Item
              name="girisMi"
              label={localization.islemTipi}
              rules={[{ required: true }]}
            >
              <Select>
                <Option value={true} style={{ color: "green" }}>
                  {localization.islemTipiSelect.giris}
                </Option>
                <Option value={false} style={{ color: "red" }}>
                  {localization.islemTipiSelect.cikis}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="depoId"
              label={localization.depo}
              rules={[{ required: true }]}
            >
              <DepoSelectOption />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="evrakNo" label={localization.evrakNo}>
              <Input />
            </Form.Item>
            <Form.Item name="aciklama" label={localization.aciklama}>
              <Input.TextArea rows={5} />
            </Form.Item>
            <Form.Item
              name="birimFiyat"
              label="Birim Fiyat"
              rules={[{ required: true }]}
            >
              <CurrencyInput
                addonAfter={
                  <Form.Item
                    name="dovizId"
                    noStyle
                    rules={[
                      { required: true, message: strings.mesaj.gerekliBirAlan },
                    ]}
                  >
                    <DovizSelectOption showKod />
                  </Form.Item>
                }
              />
            </Form.Item>
            <Form.Item
              name="miktar"
              label={localization.miktar}
              rules={[{ required: true }]}
            >
              <CurrencyInput />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default VaryantliStokIslemDuzenleModal;
