import React, { useState, useEffect } from "react";
import { Card, message, Table, Tag } from "antd";
import styled from "styled-components";
import strings from "../../utils/Localization";
import useCurrency from "../../hooks/useCurrency";
import CursorTableItem from "../toolbox/CursorTableItem";
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";

const BakiyeText = styled.span`
  font-size: 15px;
  padding: 4px;
`;

export default function KasaBakiyeleri() {
  const [fetchLoading, setFetchLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const { paraFormat } = useCurrency();
  const navigate= useNavigate();
  const api = useApi();

  useEffect(() => {
    fetchKasaBakiye();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      title: strings.panel.components.kasaBakiyeleri.kasaAdi,
      dataIndex: "Ad",
      key: "Ad",
      render: (kasaAdi, row, index) => (
        <CursorTableItem
          text={`${kasaAdi} (${row.islemSayisi})`}
          onClick={() => navigate(`/KasaDetay/${row.Id}`)}
        />
      ),
    },
    {
      title: strings.panel.components.kasaBakiyeleri.bakiye,
      dataIndex: "bakiye",
      key: "bakiye",
      render: (bakiye, row, index) => (
        <Tag color={"cyan"} key={bakiye}>
          <BakiyeText>
            {`${row.Doviz.Simge}${paraFormat(
              parseFloat(row.GirenKasaIslemTutar - row.CikanKasaIslemTutar)
            )}`}
          </BakiyeText>
        </Tag>
      ),
    },
  ];

  const fetchKasaBakiye = async () => {
    try {
      const response = await api.post("/kasa/get", {
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["Ad", "Id"],
          },
          {
            TableName: "Doviz",
            ColumnNames: ["Simge", "Id"],
          },
          {
            TableName: "Kasaislem:GirenKasaIslemTutar",
            ColumnNames: ["Tutar"],
            Conditions: [
              {
                Property: "GirisMi",
                Value: "true",
                Operator: "Equal",
              },
            ],
            Operator: "Sum",
          },
          {
            TableName: "Kasaislem:CikanKasaIslemTutar",
            ColumnNames: ["Tutar"],
            Conditions: [
              {
                Property: "GirisMi",
                Value: "false",
                Operator: "Equal",
              },
            ],
            Operator: "Sum",
          },
          {
            TableName: "Kasaislem:islemSayisi",
            Operator: "Count",
          },
        ],
      });
      if (response.status === 200) {
        setDataSource(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Card
      title={strings.panel.components.kasaBakiyeleri.baslik}
      loading={fetchLoading}
    >
      <Table pagination={false} dataSource={dataSource} columns={columns} />
    </Card>
  );
}
