import React from "react";
import { Card, Form, Input, Row, Col, Divider } from "antd";
import {
  FileTextOutlined,
  UserOutlined,
  SwapOutlined,
  CalendarOutlined,
  NumberOutlined,
  PaperClipOutlined
} from "@ant-design/icons";
import Datepicker from "../toolbox/DatePicker";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import strings from "../../utils/Localization";
import TeklifSiparisDurumTipSelectOption from "../toolbox/TeklifSiparisDurumTipSelectOption";
import { teklifSiparisDurumTypes } from "../../types/teklifSiparisDurumTypes";
import { teklifSiparisTypes } from "../../types/teklifSiparisTypes";

const LabelIcon = ({ Icon, title }) => (
  <div style={{ fontSize: 15 }}>
    <Icon /> {title}
  </div>
);

function TeklifSiparisHeader({ form }) {
  const { teklifsSiparisHeader: localization } = strings.teklifSiparisLayout;
  const tip = form.getFieldValue("tip");
  const isTeklif = (tip === teklifSiparisTypes.ALINAN_TEKLIF || tip === teklifSiparisTypes.VERILEN_TEKLIF) ? true : false

  return (
    <>
      <Form.Item name="cariKartId" hidden />
      <Form.Item name="firmaAdi" noStyle hidden initialValue="" />
      <Form.Item name="yetkili" noStyle hidden initialValue="" />
      <Form.Item name="vergiDairesi" noStyle hidden initialValue="" />
      <Form.Item name="vergiNumarasi" noStyle hidden initialValue="" />
      <Form.Item name="telefon" noStyle hidden initialValue="" />
      <Form.Item name="cepTelefonu" noStyle hidden initialValue="" />
      <Form.Item name="eMail" noStyle hidden initialValue="" />
      <Form.Item name="il" noStyle hidden initialValue="" />
      <Form.Item name="ilce" noStyle hidden initialValue="" />
      <Form.Item name="adres" noStyle hidden initialValue="" />
      <Form.Item name="sevkAdresi" noStyle hidden initialValue="" />
      <Card>
        <Row gutter={24}>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="firmaAdi"
              label={
                <LabelIcon
                  Icon={UserOutlined}
                  title={localization.musteriSeciniz}
                />
              }
            >
              <Input disabled />
            </Form.Item>
            <Divider />
            <Form.Item
              name="aciklama"
              label={
                <LabelIcon
                  Icon={FileTextOutlined}
                  title={localization.aciklama}
                />
              }
            >
              <Input.TextArea
                rows={3}
                placeholder={localization.aciklamaPlaceHolder}
              />
            </Form.Item>
            <Divider />
            <Form.Item
              name="dovizId"
              label={
                <LabelIcon Icon={SwapOutlined} title={localization.dovizTuru} />
              }
              rules={[
                { required: true, message: strings.mesaj.gerekliBirAlan },
              ]}
            >
              <DovizSelectOption disabled />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="duzenlemeTarihi"
              label={
                <LabelIcon
                  Icon={CalendarOutlined}
                  title={localization.duzenlemeTarihi}
                />
              }
              required
            >
              <Datepicker allowClear={false} />
            </Form.Item>
            <Divider />
            <Form.Item
              name="gecerlilikTarihi"
              label={
                <LabelIcon
                  Icon={CalendarOutlined}
                  title={localization.gecerlilikTarihi}
                />
              }
              required
            >
              <Datepicker allowClear={false} />
            </Form.Item>
            <Divider />
            <Form.Item
              name="evrakNo"
              label={
                <LabelIcon Icon={NumberOutlined} title={localization.evrakNo} />
              }
            >
              <Input placeholder={localization.evrakNoGiriniz} />
            </Form.Item>
            <Form.Item
              name="durumId"
              label={
                <LabelIcon Icon={PaperClipOutlined} title={localization.durum} />
              }
              rules={[{ required: true, message: localization.durumSeciniz }]}
            >
              <TeklifSiparisDurumTipSelectOption tip={isTeklif ? teklifSiparisDurumTypes.TEKLIF : teklifSiparisDurumTypes.SIPARIS} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default TeklifSiparisHeader;
