import { Card } from "antd";
import React, { useState } from "react";
import strings from "../../utils/Localization";
import StokIslemListe from "./StokIslemListe";
import StokVaryantGrupListe from "./StokVaryantGrupListe";

export default function StokIslemVeVaryantListe() {
  const [selectedTabKey, setSelectedTabKey] = useState("islemler");
  const { stokIslemVeVaryantListe } = strings.varyantliStokDetay.components;
  const tabContent = {
    islemler: <StokIslemListe />,
    varyantlar: <StokVaryantGrupListe />,
  };

  return (
    <Card
      tabList={[
        { key: "islemler", tab: stokIslemVeVaryantListe.islemler },
        { key: "varyantlar", tab: stokIslemVeVaryantListe.varyantlar },
      ]}
      activeTabKey={selectedTabKey}
      onTabChange={(key) => setSelectedTabKey(key)}
    >
      {tabContent[selectedTabKey]}
    </Card>
  );
}
