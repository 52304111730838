import React from "react";
import { Modal, ModalProps } from "antd";

interface IProps extends ModalProps {}

const SozlesmeModal: React.FC<IProps> = ({ ...rest }) => {
  return (
    <Modal {...rest}>
      <iframe
        title="sozlesmeIFrame"
        src="https://www.eminhesap.com/wp-content/uploads/2022/01/MOBIL-UYGULAMA-GIZLILIK-BILDIRIMI.pdf"
        style={{ borderWidth: 0, width: "100%", height: 600 }}
      />
    </Modal>
  );
};

export default SozlesmeModal;
