import React, { useState } from "react";
import { Menu, Image, Layout } from "antd";
import styled from "styled-components";
import logo from "../../assets/images/logo32.png";
import {
  DesktopOutlined,
  UserOutlined,
  ProfileOutlined,
  FileSyncOutlined,
  DropboxOutlined,
  DatabaseOutlined,
  BankOutlined,
  ProjectOutlined,
  SwapOutlined,
  BuildOutlined
} from "@ant-design/icons";
import { NavLink, useLocation } from "react-router-dom";
import strings from "../../utils/Localization";

const { Sider } = Layout;
const { SubMenu } = Menu;

const LogoContainer = styled.div`
  height: 38px;
  margin: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const LogoTitle = styled.span`
  color: #ececec;
  font-size: 20px;
  margin-left: 6px;
  margin-bottom: 3px;
  display: ${(props) => (props.collapsed ? "none" : "block")};
`;

const SideBar = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const { sideBar } = strings;
  const location = useLocation();

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="lg"
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <LogoContainer>
        <Image preview={false} src={logo} width={38} />
        <LogoTitle collapsed={collapsed}>EminHesap</LogoTitle>
      </LogoContainer>
      <Menu
        defaultSelectedKeys={["/"]}
        selectedKeys={[location.pathname]}
        defaultOpenKeys={[]}
        mode="inline"
        theme="dark"
      >
        <Menu.Item key="/" icon={<DesktopOutlined />}>
          <NavLink to="/">{sideBar.panel}</NavLink>
        </Menu.Item>
        <Menu.Item key="/Cari" icon={<UserOutlined />}>
          <NavLink to="/Cari">{sideBar.cariKart}</NavLink>
        </Menu.Item>
        <Menu.Item key="/Stok" icon={<DropboxOutlined />}>
          <NavLink to="/Stok">{sideBar.stoklar}</NavLink>
        </Menu.Item>
        <Menu.Item key="/Fatura" icon={<ProfileOutlined />}>
          <NavLink to="/Fatura">{sideBar.faturalar}</NavLink>
        </Menu.Item>
        <Menu.Item key="/TeklifSiparis" icon={<FileSyncOutlined />}>
          <NavLink to="/TeklifSiparis">{sideBar.teklifSiparis}</NavLink>
        </Menu.Item>
        <Menu.Item key="/KasaListe" icon={<DatabaseOutlined />}>
          <NavLink to="/KasaListe">{sideBar.kasalar}</NavLink>
        </Menu.Item>
        <Menu.Item key="/BankaHesapListe" icon={<BankOutlined />}>
          <NavLink to="/BankaHesapListe">{sideBar.bankaHesaplar}</NavLink>
        </Menu.Item>
        <Menu.Item key="/GelirGiderGrupListe" icon={<SwapOutlined />}>
          <NavLink to="/GelirGiderGrupListe">{sideBar.gelirGider}</NavLink>
        </Menu.Item>
        <Menu.Item key="/Uretim" icon={<BuildOutlined />}>
          <NavLink to="/Uretim">{sideBar.uretim}</NavLink>
        </Menu.Item>
        <SubMenu
          key={"raporlar"}
          icon={<ProjectOutlined />}
          title={sideBar.raporlar}
        >
          <Menu.Item key="/CariRapor">
            <NavLink to="/CariRapor">
              {sideBar.raporlarAltMenu.cariRapor}
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/CariIslemRapor">
            <NavLink to="/CariIslemRapor">
              {sideBar.raporlarAltMenu.cariIslemRapor}
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/CariEkstreRapor">
            <NavLink to="/CariEkstreRapor">
              {sideBar.raporlarAltMenu.cariEksteRapor}
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/KasaRapor">
            <NavLink to="/KasaRapor">
              {sideBar.raporlarAltMenu.kasaRapor}
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/BankaRapor">
            <NavLink to="/BankaRapor">
              {sideBar.raporlarAltMenu.bankaRapor}
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/StokRapor">
            <NavLink to="/StokRapor">
              {sideBar.raporlarAltMenu.stokRapor}
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/StokHareketRapor">
            <NavLink to="/StokHareketRapor">
              {sideBar.raporlarAltMenu.stokHareketRapor}
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/StokEkstreRapor">
            <NavLink to="/StokEkstreRapor">
              {sideBar.raporlarAltMenu.stokEkstreRapor}
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item key="/DepoStokRapor">
            <NavLink to="/DepoStokRapor">
              {sideBar.raporlarAltMenu.depodakiStokRaporu}
            </NavLink>
          </Menu.Item> */}
          <Menu.Item key="/FaturaRapor">
            <NavLink to="/FaturaRapor">
              {sideBar.raporlarAltMenu.faturaRapor}
            </NavLink>
          </Menu.Item>
          <Menu.Item />
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default SideBar;
