import React from "react";
import { Button, Space } from "antd";
import { useNavigate } from "react-router-dom";
import strings from "../../utils/Localization";
import { teklifSiparisTypes } from "../../types/teklifSiparisTypes";

export default function IslemButton() {
  const navigate = useNavigate();
  const { islemButton: localization } = strings.teklifSipariListe.components;

  return (
    <>
      <Space direction="horizontal">
        <Button
          type="primary"
          size="large"
          onClick={() =>
            navigate("/TeklifSiparisEkle", {
              state: {
                teklifSiparisTip: teklifSiparisTypes.ALINAN_TEKLIF,
              }
            })
          }
        >
          {localization.yeniAlinanTeklif}
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() =>
            navigate("/TeklifSiparisEkle", {
              state: {
                teklifSiparisTip: teklifSiparisTypes.VERILEN_TEKLIF,
              }
            })
          }
        >
          {localization.yeniVerilenTeklif}
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() =>
            navigate("/TeklifSiparisEkle", {
              state: {
                teklifSiparisTip: teklifSiparisTypes.ALINAN_SIPARIS,
              }
            })
          }
        >
          {localization.yeniAlinanSiparis}
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() =>
            navigate("/TeklifSiparisEkle", {
              state: {
                teklifSiparisTip: teklifSiparisTypes.VERILEN_SIPARIS,
              }
            })
          }
        >
          {localization.yeniVerilenSiparis}
        </Button>
      </Space>
    </>
  );
}
