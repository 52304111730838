import React, { useEffect, useState } from "react";
import { message, Select, SelectProps } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";

const { Option } = Select;

interface IProps extends SelectProps<any> {}

const DepoSelectOption: React.FC<IProps> = ({ placeholder, ...rest }) => {
  const [depoData, setDepoData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { depoPlaceHolder }: any = strings.depoSelectOption;
  const api = useApi();

  useEffect(() => {
    fetchDepo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDepo = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/stokdepo/get", {});
      if (response.status === 200) {
        setDepoData(response.data.data);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Select
      {...rest}
      placeholder={depoPlaceHolder || placeholder}
      loading={fetchLoading}
    >
      {depoData.map((item: any, index) => (
        <Option key={index} value={String(item.id)}>
          {item.ad}
        </Option>
      ))}
    </Select>
  );
};

export default DepoSelectOption;
