import React, { useEffect, useState } from "react";
import { message, Select, SelectProps } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";

const { Option } = Select;

interface IProps extends SelectProps<any> {}

const StokBirimSelectOption: React.FC<IProps> = ({ placeholder, ...rest }) => {
  const [stokBirimData, setStokBirimData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const api = useApi();
  const { stokBirimPlaceHolder }: any = strings.stokBirimSelectOption;

  useEffect(() => {
    fetchStokBirim();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStokBirim = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/stokbirim/get", {
        SortOptions: {
          ColumnName: "Id",
          SortType: "Asc",
        },
      });
      if (response.status === 200) {
        setStokBirimData(response.data.data);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Select
      {...rest}
      placeholder={placeholder || stokBirimPlaceHolder}
      allowClear
      loading={fetchLoading}
    >
      {stokBirimData.map((item: any, index: number) => (
        <Option key={index} value={String(item.id)}>
          {item.ad}
        </Option>
      ))}
    </Select>
  );
};

export default StokBirimSelectOption;
