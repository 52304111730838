import React, { useState } from "react";
import { Col, Input, Modal, Row, Form, message, notification } from "antd";
import strings from "../../utils/Localization";
import BankaSelectOption from "../toolbox/BankaSelectOption";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useApi from "../../hooks/useApi";

const formSize = "middle";

function BankaHesapEkleModal({ visible = false, handleCancel }) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { bankaHesapEkleModal: localization } = strings.bankaHesap;
  const api = useApi();

  const onFinish = async (values) => {
    try {
      const { hesapAdi, bankaId, dovizId, ibanNo, subeAd, subeNo, hesapNo } =
        values;

      setFetchLoading(true);
      const response = await api.post("/bankahesap", {
        BankaId: bankaId,
        DovizId: dovizId,
        Ad: hesapAdi,
        SubeAd: subeAd,
        SubeNo: subeNo,
        HesapNo: hesapNo,
        IbanNo: ibanNo,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "bankaHesapModalEkle",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
      width="50%"
      centered
    >
      <Form
        name="bankaHesapModalEkle"
        onFinish={onFinish}
        form={form}
        layout="vertical"
        size={formSize}
      >
        <Row gutter={24}>
          <Col xl={12}>
            <Form.Item
              name="hesapAdi"
              label={localization.hesapAdi}
              rules={[{ required: true }]}
            >
              <Input placeholder={localization.hesapAdiPlaceHolder} />
            </Form.Item>
            <Form.Item
              name="bankaId"
              label={strings.bankaSelectOption.bankaBaslik}
              rules={[{ required: true }]}
            >
              <BankaSelectOption />
            </Form.Item>
            <Form.Item
              name="dovizId"
              label={strings.dovizSelectOption.dovizBaslik}
              rules={[{ required: true }]}
            >
              <DovizSelectOption />
            </Form.Item>
            <Form.Item name="ibanNo" label={localization.ibanNo}>
              <Input placeholder={localization.ibanNoPlaceHolder} />
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item name="subeAd" label={localization.sube}>
              <Input placeholder={localization.subePlaceHolder} />
            </Form.Item>
            <Form.Item name="subeNo" label={localization.subeNo}>
              <Input placeholder={localization.subeNoPlaceHolder} />
            </Form.Item>
            <Form.Item name="hesapNo" label={localization.hesapNo}>
              <Input placeholder={localization.hesapNoPlaceHolder} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default BankaHesapEkleModal;
