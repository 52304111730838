import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, notification } from "antd";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useApi from "../../hooks/useApi";
import DatePicker from "../toolbox/DatePicker";
import CurrencyInput, { setFieldsAutoClear } from "../toolbox/CurrencyInput";
import { useParams } from "react-router-dom";
import moment from "moment";

const formSize = "middle";

export default function GelirGiderIslemDuzenleModal({ visible = false, handleCancel, data }) {
    const [fetchLoading, setFetchLoading] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const api = useApi();
    const { gelirGiderGrupId } = useParams()
    const localization = strings.gelirGiderIslem.gelirGiderIslemDuzenleModal;

    useEffect(() => {
        if (data) {
            const { islemTarih, aciklama, tutar, id } = data
            form.setFieldsValue({
                id: id,
                islemTarihi: moment(islemTarih),
                aciklama: aciklama,
                tutar: tutar
            })
        }
    }, [data])// eslint-disable-line react-hooks/exhaustive-deps


    const onFinish = async (values) => {
        try {
            setFetchLoading(true);
            const { id, islemTarihi, aciklama, tutar } = values;


            await api.put('/gelirgider', {
                Id: id,
                GelirGiderGrupId: gelirGiderGrupId,
                Tutar: tutar,
                Aciklama: aciklama,
                IslemTarih: islemTarihi
            })

            notification["success"]({
                message: strings.mesaj.basarliBaslik,
                description: strings.mesaj.islemBasarili,
            });
            handleCancel()
            dispatch(setRerender());

        } catch (error) {
            message.error(error.message);
        } finally {
            setFetchLoading(false);
        }
    };

    return (
        <Modal
            title={(data?.gelirMi ? localization.gelir : localization.gider) + " " + localization.baslik}
            visible={visible}
            onCancel={handleCancel}
            handleCancel={handleCancel}
            okButtonProps={{
                htmlType: "submit",
                form: "gelirGiderIslemDuzenleModal",
                size: formSize,
                loading: fetchLoading,
            }}
            cancelButtonProps={{ size: formSize }}
            width="40%"
        >
            <Form
                name="gelirGiderIslemDuzenleModal"
                form={form}
                onFinish={onFinish}
                layout="vertical"
                size={formSize}
            >
                <Form.Item name={"id"} hidden>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="islemTarihi"
                    label={localization.islemTarihi}
                    rules={[{ required: true }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item label={localization.aciklama} name="aciklama">
                    <Input.TextArea rows={5} />
                </Form.Item>
                <Form.Item
                    name="tutar"
                    label={localization.tutar}
                    rules={[{ required: true }]}
                >
                    <CurrencyInput {...setFieldsAutoClear(form, "tutar")} />
                </Form.Item>
            </Form>
        </Modal>
    )
}
