import {
  Button,
  Card,
  Col,
  Form,
  Input,
  PageHeader,
  Divider,
  Dropdown,
  Menu,
  message,
  notification,
  Row,
} from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DovizSelectOption from "../components/toolbox/DovizSelectOption";
import StokKategoriSelectOption from "../components/toolbox/StokKategoriSelectOption";
import StokBirimSelectOption from "../components/toolbox/StokBirimSelectOption";
import useVarsayilanDeger from "../hooks/useVarsayilanDeger";
import strings from "../utils/Localization";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import { yuzdeHesala } from "../helpers/HesaplamaHelper";
import CurrencyInput, {
  setFieldsAutoClear,
} from "../components/toolbox/CurrencyInput";
import moment from "moment";
import useApi from "../hooks/useApi";

function StokEkle() {
  const [showStokBilgileri, setShowStokBilgileri] = useState(false);
  const [digerVergiler, setDigerVergiler] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate= useNavigate();
  const { getDefaultSetting } = useVarsayilanDeger();
  const defaultDovizId = String(getDefaultSetting("doviz"));
  const api = useApi();
  const {
    baslik,
    stokBilgileri,
    stokAlisFiyati,
    stokSatisFiyati,
    urunDetayiAddonAfter,
    digerVergilerAddonAfter,
    kaydet,
  } = strings.stokEkle;

  const onFinish = async (values) => {
    try {
      const {
        urunAdi,
        stokBirimId,
        stokKategoriId,
        barkodNo,
        stokRafi,
        stokKodu,
        gtinNumarasi,
        aciklama,
        vergilerHaricAlisFiyat,
        alisDovizId,
        alisKdvOran,
        alisOtvOran,
        vergilerDahilAlisFiyat,
        vergilerHaricSatisFiyat,
        satisDovizId,
        satisKdvOran,
        satisOtvOran,
        vergilerDahilSatisFiyat,
        baslangicStokMiktari,
      } = values;

      //Satış KDV - OTV tutar
      const satisOtvTutar = yuzdeHesala(satisOtvOran, vergilerHaricSatisFiyat);
      const satisOtvliTutar =
        parseFloat(vergilerHaricSatisFiyat) + satisOtvTutar;
      const satisKdvTutar = yuzdeHesala(satisOtvliTutar, satisKdvOran);

      //Alış KDV - OTV tutar
      const alisOtvTutar = yuzdeHesala(alisOtvOran, vergilerHaricAlisFiyat);
      const alisOtvliTutar = parseFloat(vergilerHaricAlisFiyat) + alisOtvTutar;
      const alisKdvTutar = yuzdeHesala(alisOtvliTutar, alisKdvOran);
      setFetchLoading(true);
      const response = await api.post("/stok", {
        StokKategoriId: stokKategoriId,
        StokBirimId: stokBirimId,
        ad: urunAdi,
        aciklama: aciklama,
        Stokvaryantgrup: [
          {
            Barkod: barkodNo,
            Aciklama: aciklama,
            Kod: stokKodu,
            Gtin: gtinNumarasi,
            Raf: stokRafi,
            ResimAd: moment().valueOf(),
            Stokvaryantgrupfiyat: [
              {
                DovizId: satisDovizId,
                OtvTutar: satisOtvTutar,
                OtvOran: satisOtvOran,
                KdvTutar: satisKdvTutar,
                KdvOran: satisKdvOran,
                Fiyat: vergilerHaricSatisFiyat,
                GenelToplam: vergilerDahilSatisFiyat,
                SatisMi: 1,
              },
              {
                DovizId: alisDovizId,
                OtvTutar: alisOtvTutar,
                OtvOran: alisOtvOran,
                KdvTutar: alisKdvTutar,
                KdvOran: alisKdvOran,
                Fiyat: vergilerHaricAlisFiyat,
                GenelToplam: vergilerDahilAlisFiyat,
                SatisMi: 0,
              },
            ],
            Stokislem:
              baslangicStokMiktari === undefined
                ? null
                : [
                    {
                      StokDepoId: getDefaultSetting("stokDepo"),
                      Miktar: baslangicStokMiktari,
                      GirisMi: true,
                      Aciklama: stokBilgileri.baslangicStokMiktariAciklamasi,
                      IslemTarihi: moment().format("YYYY-MM-DD"),
                      DovizId: alisDovizId,
                      StokBirimId: stokBirimId,
                      BirimFiyat: vergilerDahilAlisFiyat,
                    },
                  ],
          },
        ],
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        form.setFields([
          { name: "vergilerHaricAlisFiyat", value: 0 },
          { name: "alisDovizId", value: defaultDovizId },
          { name: "alisKdvOran", value: 0 },
          { name: "alisOtvOran", value: 0 },
          { name: "vergilerDahilAlisFiyat", value: 0 },

          { name: "vergilerHaricSatisFiyat", value: 0 },
          { name: "satisDovizId", value: defaultDovizId },
          { name: "satisKdvOran", value: 0 },
          { name: "satisOtvOran", value: 0 },
          { name: "vergilerDahilSatisFiyat", value: 0 },
        ]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const alisFiyatHesaplama = (item) => {
    const {
      vergilerHaricAlisFiyat,
      alisKdvOran,
      alisOtvOran,
      vergilerDahilAlisFiyat,
    } = form.getFieldsValue([
      "vergilerHaricAlisFiyat",
      "alisKdvOran",
      "alisOtvOran",
      "vergilerDahilAlisFiyat",
    ]);
    const otvTutar = yuzdeHesala(alisOtvOran, vergilerHaricAlisFiyat);
    const otvliTutar = parseFloat(vergilerHaricAlisFiyat) + otvTutar;
    const kdvTutar = yuzdeHesala(otvliTutar, alisKdvOran);
    if (item === "vergilerHaricAlisFiyat") {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        { name: "vergilerDahilAlisFiyat", value: vergiDahilFiyat },
      ]);
    } else if (item === "vergilerDahilAlisFiyat") {
      const vergiHaricFiyat = parseFloat(
        vergilerDahilAlisFiyat /
          (alisKdvOran / 100 + 1) /
          (alisOtvOran / 100 + 1)
      ).toFixed(2);
      form.setFields([
        { name: "vergilerHaricAlisFiyat", value: vergiHaricFiyat },
      ]);
    } else {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        { name: "vergilerDahilAlisFiyat", value: vergiDahilFiyat },
      ]);
    }
  };
  const satisFiyatHesaplama = (item) => {
    const {
      vergilerHaricSatisFiyat,
      satisKdvOran,
      satisOtvOran,
      vergilerDahilSatisFiyat,
    } = form.getFieldsValue([
      "vergilerHaricSatisFiyat",
      "satisKdvOran",
      "satisOtvOran",
      "vergilerDahilSatisFiyat",
    ]);
    const otvTutar = yuzdeHesala(satisOtvOran, vergilerHaricSatisFiyat);
    const otvliTutar = parseFloat(vergilerHaricSatisFiyat) + otvTutar;
    const kdvTutar = yuzdeHesala(otvliTutar, satisKdvOran);
    if (item === "vergilerHaricSatisFiyat") {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        { name: "vergilerDahilSatisFiyat", value: vergiDahilFiyat },
      ]);
    } else if (item === "vergilerDahilSatisFiyat") {
      const vergiHaricFiyat = parseFloat(
        vergilerDahilSatisFiyat /
          (satisKdvOran / 100 + 1) /
          (satisOtvOran / 100 + 1)
      ).toFixed(2);
      form.setFields([
        { name: "vergilerHaricSatisFiyat", value: vergiHaricFiyat },
      ]);
    } else {
      const vergiDahilFiyat = parseFloat(otvliTutar + kdvTutar).toFixed(2);
      form.setFields([
        { name: "vergilerDahilSatisFiyat", value: vergiDahilFiyat },
      ]);
    }
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title={baslik}
        onBack={() => navigate(-1)}
        extra={
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            style={{ float: "right" }}
            loading={fetchLoading}
            form="myForm"
          >
            {kaydet}
          </Button>
        }
      />
      <br />
      <Form
        form={form}
        onFinish={onFinish}
        size="middle"
        labelCol={{ span: 8 }}
        labelAlign="left"
        initialValues={{
          satisDovizId: defaultDovizId,
          alisDovizId: defaultDovizId,
          vergilerHaricAlisFiyat: 0,
          alisKdvOran: 0,
          alisOtvOran: 0,
          vergilerDahilAlisFiyat: 0,
          vergilerHaricSatisFiyat: 0,
          satisKdvOran: 0,
          satisOtvOran: 0,
          vergilerDahilSatisFiyat: 0,
        }}
        id="myForm"
      >
        <Row gutter={24}>
          <Col flex={1}>
            <Card>
              <Form.Item
                label={stokBilgileri.urunAdi}
                name="urunAdi"
                rules={[{ required: true }]}
              >
                <Input
                  addonAfter={
                    <Button
                      type="text"
                      size="small"
                      icon={<DownOutlined />}
                      disabled={showStokBilgileri}
                      onClick={() => setShowStokBilgileri(true)}
                    >
                      {urunDetayiAddonAfter}
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item
                name="stokBirimId"
                label={strings.stokBirimSelectOption.stokBirimBaslik}
                rules={[{ required: true }]}
              >
                <StokBirimSelectOption />
              </Form.Item>
              <Form.Item
                name="stokKategoriId"
                label={strings.stokKategoriSelectOption.stokKategoriBaslik}
                rules={[{ required: true }]}
              >
                <StokKategoriSelectOption />
              </Form.Item>
              <Form.Item
                label={stokBilgileri.baslangicStokMiktari}
                name="baslangicStokMiktari"
              >
                <CurrencyInput />
              </Form.Item>
              {showStokBilgileri && <Divider />}
              <Form.Item
                hidden={!showStokBilgileri}
                label={stokBilgileri.barkodNUmarasi}
                name="barkodNo"
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden={!showStokBilgileri}
                label={stokBilgileri.stokRafi}
                name="stokRafi"
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden={!showStokBilgileri}
                label={stokBilgileri.stokKodu}
                name="stokKodu"
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden={!showStokBilgileri}
                label={stokBilgileri.gtinNumarasi}
                name="gtinNumarasi"
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden={!showStokBilgileri}
                label={stokBilgileri.aciklama}
                name="aciklama"
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Card>
          </Col>
          <Col flex={1}>
            <Card>
              <Form.Item
                name="vergilerHaricAlisFiyat"
                label={stokAlisFiyati.vergilerHaricAlisFiyati}
                rules={[{ required: true }]}
              >
                <CurrencyInput
                  addonAfter={
                    <Form.Item
                      name="alisDovizId"
                      label={stokAlisFiyati.alisDovizTuru}
                      rules={[{ required: true }]}
                      noStyle
                    >
                      <DovizSelectOption showKod />
                    </Form.Item>
                  }
                  onChange={() => alisFiyatHesaplama("vergilerHaricAlisFiyat")}
                  {...setFieldsAutoClear(form, "vergilerHaricAlisFiyat")}
                />
              </Form.Item>
              <Form.Item
                name="alisKdvOran"
                label={stokAlisFiyati.alisKdvOran}
                rules={[{ required: true }]}
              >
                <CurrencyInput
                  addonAfter={
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          <Menu.Item
                            key="0"
                            disabled={digerVergiler}
                            onClick={() => setDigerVergiler(true)}
                          >
                            {digerVergilerAddonAfter}
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button
                        type="text"
                        size="small"
                        icon={<PlusOutlined />}
                      ></Button>
                    </Dropdown>
                  }
                  onChange={() => alisFiyatHesaplama("alisKdvOran")}
                  {...setFieldsAutoClear(form, "alisKdvOran")}
                />
              </Form.Item>
              <Form.Item
                name="alisOtvOran"
                label={stokAlisFiyati.alisOtvOran}
                rules={[{ required: true }]}
                hidden={!digerVergiler}
              >
                <CurrencyInput
                  onChange={() => alisFiyatHesaplama("alisOtvOran")}
                  {...setFieldsAutoClear(form, "alisOtvOran")}
                />
              </Form.Item>
              <Form.Item
                name="vergilerDahilAlisFiyat"
                label={stokAlisFiyati.vergilerDahilAlisFiyati}
                rules={[{ required: true }]}
              >
                <CurrencyInput
                  addonAfter={
                    <Form.Item
                      name="alisDovizId"
                      label={stokAlisFiyati.alisDovizTuru}
                      rules={[{ required: true }]}
                      noStyle
                    >
                      <DovizSelectOption showKod />
                    </Form.Item>
                  }
                  onChange={() => alisFiyatHesaplama("vergilerDahilAlisFiyat")}
                  {...setFieldsAutoClear(form, "vergilerDahilAlisFiyat")}
                />
              </Form.Item>
              <Divider />
              <Form.Item
                name="vergilerHaricSatisFiyat"
                label={stokSatisFiyati.vergilerHaricSatisFiyati}
                rules={[{ required: true }]}
              >
                <CurrencyInput
                  addonAfter={
                    <Form.Item
                      name="satisDovizId"
                      label={stokAlisFiyati.satisDovizTuru}
                      rules={[{ required: true }]}
                      noStyle
                    >
                      <DovizSelectOption showKod />
                    </Form.Item>
                  }
                  onChange={() =>
                    satisFiyatHesaplama("vergilerHaricSatisFiyat")
                  }
                  {...setFieldsAutoClear(form, "vergilerHaricSatisFiyat")}
                />
              </Form.Item>
              <Form.Item
                name="satisKdvOran"
                label={stokSatisFiyati.satisKdvOran}
                rules={[{ required: true }]}
              >
                <CurrencyInput
                  addonAfter={
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          <Menu.Item
                            key="0"
                            disabled={digerVergiler}
                            onClick={() => setDigerVergiler(true)}
                          >
                            {digerVergilerAddonAfter}
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button
                        type="text"
                        size="small"
                        icon={<PlusOutlined />}
                      ></Button>
                    </Dropdown>
                  }
                  onChange={() => satisFiyatHesaplama("satisKdvOran")}
                  {...setFieldsAutoClear(form, "satisKdvOran")}
                />
              </Form.Item>
              <Form.Item
                name="satisOtvOran"
                label={stokSatisFiyati.satisOtvOran}
                hidden={!digerVergiler}
                rules={[{ required: true }]}
              >
                <CurrencyInput
                  onChange={() => satisFiyatHesaplama("satisOtvOran")}
                  {...setFieldsAutoClear(form, "satisOtvOran")}
                />
              </Form.Item>
              <Form.Item
                name="vergilerDahilSatisFiyat"
                label={stokSatisFiyati.vergilerDahilSatisFiyati}
                rules={[{ required: true }]}
              >
                <CurrencyInput
                  addonAfter={
                    <Form.Item
                      name="satisDovizId"
                      label={stokAlisFiyati.satisDovizTuru}
                      rules={[{ required: true }]}
                      noStyle
                    >
                      <DovizSelectOption showKod />
                    </Form.Item>
                  }
                  onChange={() =>
                    satisFiyatHesaplama("vergilerDahilSatisFiyat")
                  }
                  {...setFieldsAutoClear(form, "vergilerDahilSatisFiyat")}
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default StokEkle;
