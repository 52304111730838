import React, { useState, useEffect } from "react";
import { Modal, Form, notification, message } from "antd";
import strings from "../../utils/Localization";
import AlacakBorcForm from "../cariIslem/AlacakBorcForm";
import { cariIslemTurTypes } from "../../types/cariIslemTurTypes";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useEvrakNo from "../../hooks/useEvrakNo";
import useApi from "../../hooks/useApi";

export default function CariBorclandirmaEkleModal({
  visible = false,
  handleCancel,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const { cariKartId } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { getCariIslemEvrakNo } = useEvrakNo();
  const api = useApi();

  useEffect(() => {
    getCariIslemEvrakNo().then((evrakNo) => {
      form.setFieldsValue({ evrakNo: evrakNo });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      const {
        vadeTarihi,
        islemTarihi,
        evrakNo,
        aciklama,
        dovizId,
        odemeSekli,
        tutar,
      } = values;
      setFetchLoading(true);
      const response = await api.post("/cariislem/add", {
        CariKartId: cariKartId,
        DovizId: dovizId,
        CariIslemTur: cariIslemTurTypes.BORC,
        EvrakNo: evrakNo,
        Aciklama: aciklama,
        Carpan: 1,
        Tutar: tutar,
        VadeTarih: vadeTarihi,
        IslemTarih: islemTarihi,
        OdemeSekli: odemeSekli,
      });
      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        form.resetFields();
        getCariIslemEvrakNo().then((evrakNo) => {
          form.setFieldsValue({ evrakNo: evrakNo });
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={strings.cariIslem.borclandirmaEkle.baslik}
      visible={visible}
      onCancel={handleCancel}
      width="60%"
      centered
      okButtonProps={{
        size: "large",
        form: "borcEkleForm",
        htmlType: "submit",
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <AlacakBorcForm form={form} id="borcEkleForm" onFinish={onFinish} />
    </Modal>
  );
}
