import React, { useState, useEffect } from "react";
import {
  Col,
  message,
  Row,
  Space,
  Typography,
  Spin,
  Radio,
  Layout,
} from "antd";
import { useNavigate } from "react-router-dom";
import { Title } from "../components/odemeLayout";
import { useDispatch } from "react-redux";
import {
  setOdemePaket,
  setResetOdemePaket,
  // setOdemeResetFaturaAdres,
} from "../redux/actions/odemeAction";
import { PriceCard } from "../components/paketler";
import useApi from "../hooks/useApi";

const { Text } = Typography;
const { Content } = Layout;

function Paketler() {
  const [selectedPaket, setSelectedPaket] = useState("yillik");
  const [fetchLoading, setFetchLoading] = useState(false);
  const [urunList, setUrunList] = useState([]);
  const [urunEFaturaList, setUrunEFaturaList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const api = useApi();

  useEffect(() => {
    fetchPaket();
    dispatch(setResetOdemePaket());
    // dispatch(setOdemeResetFaturaAdres());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchPaket = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/urun/get", {
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName: "urunpaket",
            ColumnNames: ["id", "ad", "periyot", "fiyat", "odenecektutar"],
          },
          {
            TableName: "urunpaket.urunpaketozellik",
            ColumnNames: ["id", "baslik", "aciklama"],
          },
        ],
      });

      if (response.status === 200) {
        const { urunpaket } = response.data.data[0];
        setUrunEFaturaList([urunpaket?.[0], urunpaket?.[2]]);
        setUrunList([urunpaket?.[1], urunpaket?.[3]]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Content >
      <Row style={{ justifyContent: 'center' }}>
        <Col xxl={12} xl={14} lg={16} md={20} xs={22}>
          <Title>Kullanım Süreniz Sona Erdi</Title>
          <Text style={{ fontSize: 16 }}>
            EminHesap Pro'yu beğendiğinizi umuyoruz. Paketinizi seçerek
            aboneliğinizi başlatabilir ve uygulamayı kullanmaya devam
            edebilirsiniz.
          </Text>
          <br />
          <br />
          {fetchLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Spin size="large" spinning />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Radio.Group
                value={selectedPaket}
                onChange={(e) => setSelectedPaket(e.target.value)}
                size="large"
                buttonStyle="solid"
              >
                <Radio.Button
                  style={{ borderTopLeftRadius: 16, borderBottomLeftRadius: 16 }}
                  value="aylik"
                >
                  Aylık Abonelik
                </Radio.Button>
                <Radio.Button style={{ borderTopRightRadius: 16, borderBottomRightRadius: 16 }} value="yillik">Yıllık Abonelik</Radio.Button>
              </Radio.Group>
            </div>
          )}
          <br />
          <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
            {selectedPaket === "aylik" ? (
              <Row gutter={36}>
                {urunEFaturaList.map((item, index) => (
                  <Col span={12}>
                    <PriceCard
                      key={index}
                      title={item.ad}
                      price={item.fiyat}
                      checkColorGreen={item.periyot > 30}
                      listData={item.urunpaketozellik.map((item) => ({
                        title: item.baslik,
                        description: item.aciklama,
                      }))}
                      onClick={() => {
                        dispatch(setOdemePaket(item));
                        navigate("/FaturaAdresi");
                      }}
                    />
                    <br />
                  </Col>
                ))}
              </Row>
            ) : (
              <Row gutter={36}>
                {urunList.map((item, index) => (
                  <Col span={12}>
                    <PriceCard
                      key={index}
                      title={item.ad}
                      price={item.fiyat}
                      checkColorGreen={item.periyot > 30}
                      listData={item.urunpaketozellik.map((item) => ({
                        title: item.baslik,
                        description: item.aciklama,
                      }))}
                      onClick={() => {
                        dispatch(setOdemePaket(item));
                        navigate("/FaturaAdresi");
                      }}
                    />
                    <br />
                  </Col>
                ))}
              </Row>
            )}
          </Space>
        </Col>
      </Row>
    </Content>
  );
}

export default Paketler;
