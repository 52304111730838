import React, { useState } from 'react'
import { Space, Menu } from "antd";
import strings from '../utils/Localization';
import { UretimListe } from '../components/uretim';
import { UretimReceteListe } from '../components/uretimRecete';
import { useLocation } from 'react-router-dom';

function Uretim() {
    const { state } = useLocation()
    const defaultSelectedKey=state?.isRecete ? "2" : "1"
    const [selectedMenu, setSelectedMenu] = useState({ key: defaultSelectedKey });
    const { uretim: localization } = strings;


    const contentRender = () => {
        switch (selectedMenu.key) {
            case "1":
                return <UretimListe />;
            case "2":
                return <UretimReceteListe />;
            default:
                return <UretimListe />;
        }
    };


    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Menu
                onClick={(e) => setSelectedMenu(e)}
                defaultSelectedKeys={[defaultSelectedKey]}
                defaultOpenKeys={[defaultSelectedKey]}
                mode="horizontal"
            >
                <Menu.Item key="1">{localization.menu.uretimListesi}</Menu.Item>
                <Menu.Item key="2">{localization.menu.uretimReceteListesi}</Menu.Item>
            </Menu>
            {contentRender()}
        </Space>
    )
}

export default Uretim