import React, { useState, useEffect } from "react";
import { Modal, Form, Input, message, notification } from "antd";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useApi from "../../hooks/useApi";
import TeklifSiparisDurumTipKategoriSelectOption from "../toolbox/TeklifSiparisDurumTipKategoriSelectOption";

const formSize = "middle";
function TeklifSiparisDurumTipDuzenleModal({ visible = false, handleCancel, data }) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const api = useApi();
  const localization = strings.teklifSiparisDurum.durumDuzenleModal;

  useEffect(() => {
    if (data !== undefined) {
      form.setFieldsValue({
        durumId: data.id,
        durumAdi: data.ad,
        durumTip: data.tip
      })
    }
  }, [data])// eslint-disable-line react-hooks/exhaustive-deps


  const onFinish = async (values) => {
    try {
      setFetchLoading(true);
      const { durumId, durumAdi, durumTip } = values;

      const response = await api.put("/teklifsiparisdurumtip", {
        Id: durumId,
        Ad: durumAdi,
        Tip: durumTip,
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "teklifSiparisDurumEkleModal",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
    >
      <Form
        name="teklifSiparisDurumEkleModal"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size={formSize}
      >
        <Form.Item hidden name={"durumId"} />
        <Form.Item
          name="durumAdi"
          label={localization.durumAdi}
          rules={[{ required: true }]}
        >
          <Input placeholder={localization.durumAdiPlaceHolder} />
        </Form.Item>
        <Form.Item
          name="durumTip"
          label={localization.durumTipi}
          rules={[{ required: true }]}
        >
          <TeklifSiparisDurumTipKategoriSelectOption />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default TeklifSiparisDurumTipDuzenleModal