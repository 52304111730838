import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input, message, notification } from 'antd'
import strings from '../../utils/Localization'
import { useDispatch } from 'react-redux'
import { setRerender } from '../../redux/actions/rerenderAction'
import MaskInput from 'antd-mask-input'
import useApi from '../../hooks/useApi'
import SubeSelectOption from '../toolbox/SubeSelectOption'
const md5 = require('md5')

export default function KullaniciEkleModal({ visible = false, handleCancel }) {
  const [form] = Form.useForm()
  const [fetchLoading, setFetchLoading] = useState(false)
  const dispatch = useDispatch()
  const api = useApi()
  const { kullaniciEKle: localization } = strings

  const onFinish = async (values) => {
    try {
      const { eMail, sifre, adSoyad, telefonNo, subeId } = values

      setFetchLoading(true)

      const response = await api.post('/kullanici/ekle', {
        AdSoyad: adSoyad,
        EMail: eMail,
        TelefonNo: telefonNo,
        Sifre: md5(sifre),
        SubeId: subeId.map((item) => item),
      })

      if (response.status === 200) {
        form.resetFields()
        dispatch(setRerender())
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
        handleCancel()
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setFetchLoading(false)
    }
  }

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: 'submit',
        form: 'kullaniciEkleForm',
        loading: fetchLoading,
      }}
      width="60%"
      centered
    >
      <Form form={form} name="kullaniciEkleForm" layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col xl={12} lg={12}>
            <Form.Item name="eMail" label={localization.eMail} rules={[{ type: 'email', required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label={localization.sifre} name="sifre" rules={[{ required: true }, { min: 6 }]}>
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={localization.sifreTekrar}
              name="sifreTekrar"
              dependencies={['sifre']}
              rules={[
                { required: true, message: localization.sifreZorunlu },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('sifre') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(localization.sifreOnay)
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12}>
            <Form.Item name="adSoyad" label={localization.adSoyad} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="telefonNo" label={localization.telefonNumarasi} rules={[{ required: true }]}>
              <MaskInput mask="(111) 111 11 11" />
            </Form.Item>
            <Form.Item rules={[{ required: true }]} label={localization.sube} name="subeId">
              <SubeSelectOption mode="multiple" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
