import { useState } from 'react'
import { FaturaListe, EFaturaGelenListe } from '../components/fatura'
import { Menu, Space } from 'antd'

export default function Fatura() {
  const [selectedMenu, setSelectedMenu] = useState('1')

  const contentRender = () => {
    switch (selectedMenu.key) {
      case '1':
        return <FaturaListe />
      case '2':
        return <EFaturaGelenListe />
      default:
        return <FaturaListe />
    }
  }

  return (
    <>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Menu
          onClick={(e) => setSelectedMenu(e)}
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="horizontal"
        >
          <Menu.Item key="1">{'Fatura Listesi'}</Menu.Item>
          <Menu.Item key="2">{'Gelen e-Fatura Listesi'}</Menu.Item>
        </Menu>
        {contentRender()}
      </Space>
    </>
  )
}
