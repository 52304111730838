import { Card, Button, Space, Popconfirm, message, notification, Typography, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import strings from '../../utils/Localization'
import CursorTableItem from '../toolbox/CursorTableItem'
import FetchListContent from '../toolbox/FetchListContent'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { PlusOutlined } from '@ant-design/icons'
import CariKartEkleModal from '../cariKart/CariKartEkleModal'
import CariKartDuzenleModal from '../cariKart/CariKartDuzenleModal'
import useCurrency from '../../hooks/useCurrency'
import useApi from '../../hooks/useApi'
import CariGrupSelectOption from '../toolbox/CariGrupSelectOption'
import { setRerender } from '../../redux/actions/rerenderAction'
import { useDispatch } from 'react-redux'

const CariKartListe = () => {
  const [dataSource, setDataSource] = useState({})
  const [filterRequestData, setFilterRequestData] = useState({})
  const [cariKartEkleModalVisible, setCariKartEkleModalVisible] = useState(false)
  const [cariKartDuzenleModalVisible, setCariKartDuzenleModalVisible] = useState(false)
  const [duzenleData, setDuzenleData] = useState({})
  const navigate = useNavigate()
  const { paraFormat } = useCurrency()
  const api = useApi()
  const dispatch = useDispatch()
  const { cariKartListe: localization } = strings

  const columns = [
    {
      title: localization.firmaAdi,
      dataIndex: 'firmaAdi',
      key: 'firmaAdi',
      sorter: (a, b) => a.firmaAdi.localeCompare(b.firmaAdi),
      render: (text, row, index) => (
        <Link to={`/CariDetay/${row.id}`}>
          <CursorTableItem text={`${row.firmaAdi} (${row.islemSayisi})`} />
        </Link>
      ),
    },
    {
      title: localization.yetkili,
      dataIndex: 'yetkili',
      key: 'yetkili',
      sorter: (a, b) => {
        a.yetkili = a.yetkili || ''
        b.yetkili = b.yetkili || ''
        return a.yetkili.localeCompare(b.yetkili)
      },
    },
    {
      title: localization.telefon,
      dataIndex: 'telefon',
      key: 'telefon',
    },
    {
      title: localization.vergiNumarasi,
      dataIndex: 'vergiNumarasi',
      key: 'vergiNumarasi',
    },
    {
      title: localization.il,
      dataIndex: 'il',
      key: 'il',
    },
    {
      title: localization.bakiye,
      dataIndex: 'id',
      key: 'id',
      render: (text, row, index) => (
        <Space direction="vertical">
          {row.bakiye.map((item, index) => (
            <Typography.Title
              level={5}
              key={index}
              style={{
                color: item.Tutar >= 0 ? 'green' : 'red',
                fontWeight: '400',
              }}
            >{`${paraFormat(item.Tutar)}  ${item.Doviz}`}</Typography.Title>
          ))}
          {row.bakiye.length === 0 && '-'}
        </Space>
      ),
    },
    {
      title: '',
      key: 'edit',
      dataIndex: 'edit',
      render: (text, row, index) => (
        <Space size="middle">
          <Tooltip title={localization.cariDetay}>
            <Button
              onClick={() => navigate(`/CariDetay/${row.id}`)}
              type="default"
              shape="circle"
              icon={<SearchOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={localization.cariDetay}>
            <Button
              onClick={() => {
                setDuzenleData(row)
                setCariKartDuzenleModalVisible(true)
              }}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={localization.cariSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => {
                if (row.islemSayisi > 0) {
                  message.error({
                    content: strings.mesaj.cariKartSilme,
                    style: { marginTop: '20vh' },
                  })
                } else remove(row.id)
              }}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const remove = async (cariKartId) => {
    try {
      const response = await api.delete(`/carikart/${cariKartId}`, {})
      if (response.status === 200) {
        setDataSource({
          ...dataSource,
          data: dataSource.data.filter((x) => x.id !== cariKartId),
        })
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  const cariGrupDetayliArama = async (cariGrupId) => {
    let newData = {
      Data: {
        CariGrupId: cariGrupId ? cariGrupId : null,
      },
    }
    await setFilterRequestData(newData)
    dispatch(setRerender())
  }
  return (
    <>
      <Card
        title={localization.baslik}
        extra={[
          <Button
            key="1"
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => setCariKartEkleModalVisible(true)}
          >
            {localization.cariEkleButon}
          </Button>,
        ]}
      >
        <FetchListContent
          columns={columns}
          requestUrl="carikartliste/get"
          requestBody={filterRequestData}
          dataSource={dataSource}
          onDataSource={(e) => setDataSource(e)}
          searchAddonBefore={
            <CariGrupSelectOption onChange={cariGrupDetayliArama} allowClear style={{ minWidth: 130 }} />
          }
        />
      </Card>
      <CariKartEkleModal visible={cariKartEkleModalVisible} handleCancel={() => setCariKartEkleModalVisible(false)} />
      <CariKartDuzenleModal
        visible={cariKartDuzenleModalVisible}
        handleCancel={() => setCariKartDuzenleModalVisible(false)}
        data={duzenleData}
      />
    </>
  )
}

export default CariKartListe
