import React, { useState, useEffect } from "react";
import { Modal, Form, Input, message, notification } from "antd";
import strings from "../../utils/Localization";
import { useDispatch } from "react-redux";
import { setRerender } from "../../redux/actions/rerenderAction";
import useApi from "../../hooks/useApi";

const formSize = "middle";

function StokVaryantDuzenleModal({
  visible = false,
  handleCancel,
  data = null,
}) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const api = useApi();
  const { stokVaryantDuzenle: localization } = strings.stokVaryant.components;

  useEffect(() => {
    if (data !== null) {
      form.setFieldsValue({
        id: data.id,
        varyantAdi: data.ad,
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = async (values) => {
    try {
      setFetchLoading(true);
      const { varyantAdi, id } = values;

      const response = await api.put("/stokvaryant", {
        id: id,
        ad: varyantAdi,
      });

      if (response.status === 200) {
        notification["success"]({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        });
        dispatch(setRerender());
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Modal
      title={localization.baslik}
      visible={visible}
      onCancel={handleCancel}
      handleCancel={handleCancel}
      okButtonProps={{
        htmlType: "submit",
        form: "varyantDuzenleModal",
        size: formSize,
        loading: fetchLoading,
      }}
      cancelButtonProps={{ size: formSize }}
    >
      <Form
        name="varyantDuzenleModal"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size={formSize}
        initialValues={{
          id: null,
          varyantAdi: "",
        }}
      >
        <Form.Item hidden noStyle name="id" />
        <Form.Item
          name="varyantAdi"
          label={localization.varyantAdi}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default StokVaryantDuzenleModal;
