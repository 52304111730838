import React, { useState } from "react";
import { Button, Card, Form, message, Radio, Select } from "antd";
import DatepickerRanger from "../toolbox/DatepickerRanger";
import strings from "../../utils/Localization";
import CariKartSelectOption from "../toolbox/CariKartSelectOption";
import CariGrupSelectOption from "../toolbox/CariGrupSelectOption";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import moment from "moment";
import useVarsayilanDeger from "../../hooks/useVarsayilanDeger";
import useApi from "../../hooks/useApi";

const { Option } = Select;

export default function CariRaporForm() {
  const { cariRaporForm } = strings.cariRapor.components;
  const [fetchLoading, setFetchLoading] = useState(false);
  const { getDefaultSetting } = useVarsayilanDeger();
  const api = useApi();

  const onFinish = async (values) => {
    try {
      const { tarih, cariKartId, dovizId, cariGrupId, bakiyeDurum, dizayn } =
        values;
      const baslangicTarihi = moment(tarih[0]).format("YYYY-MM-DD");
      const bitisTarihi = moment(tarih[1]).format("YYYY-MM-DD");

      const pdfWindow = window.open(`${document.location.origin}/#/RaporYukleniyor`);
      setFetchLoading(true);
      const response = await api.post(
        "/rapordosya/cari",
        {
          BaslangicTarih: `${baslangicTarihi} 00:00:00`,
          BitisTarih: `${bitisTarihi} 23:59:59`,
          CariKartId: cariKartId === undefined ? 0 : cariKartId,
          CariGrupId: cariGrupId === undefined ? 0 : cariGrupId,
          DovizId: dovizId === undefined ? 0 : dovizId,
          BakiyeDurum: bakiyeDurum,
          Dizayn: dizayn,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        pdfWindow.document.location.href = response.data.data;
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Card>
      <Form
        layout="vertical"
        onFinish={onFinish}
        size="middle"
        initialValues={{
          tarih: [moment().add("month", -12), moment()],
          dovizId: getDefaultSetting("doviz"),
        }}
      >
        <Form.Item
          name="tarih"
          label={cariRaporForm.tarihSeciniz}
          rules={[{ required: true }]}
        >
          <DatepickerRanger />
        </Form.Item>
        <Form.Item name="cariKartId">
          <CariKartSelectOption allowClear />
        </Form.Item>
        <Form.Item
          name="cariGrupId"
          label={strings.cariGrupSelectOption.cariGrupBaslik}
        >
          <CariGrupSelectOption allowClear />
        </Form.Item>
        <Form.Item
          name="bakiyeDurum"
          label={strings.cariGrupSelectOption.bakiyeDurum}
          initialValue={"Hepsi"}
          rules={[{ required: true }]}
        >
          <Select>
            <Option value="Hepsi">{strings.cariGrupSelectOption.hepsi}</Option>
            <Option value="Borclu">
              {strings.cariGrupSelectOption.borclu}
            </Option>
            <Option value="Alacaklı">
              {strings.cariGrupSelectOption.alacakli}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item name="dovizId" label={strings.dovizSelectOption.dovizBaslik}>
          <DovizSelectOption allowClear />
        </Form.Item>
        <Form.Item
          name={"dizayn"}
          label={strings.cariGrupSelectOption.raporDizayni}
          initialValue={1}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={1}>
              {strings.cariGrupSelectOption.birinciDizayn}
            </Radio>
            <Radio value={2}>{strings.cariGrupSelectOption.ikinciDizayn}</Radio>
          </Radio.Group>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ float: "right" }}
          size="large"
          loading={fetchLoading}
        >
          {cariRaporForm.raporAlButon}
        </Button>
      </Form>
    </Card>
  );
}
