import React, { useEffect, useState } from "react";
import { message, Select, SelectProps } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";

const { Option } = Select;

interface IProps extends SelectProps<any> {}

const StokKategoriSelectOption: React.FC<IProps> = ({
  placeholder,
  ...rest
}) => {
  const [stokKategoriData, setStokKategoriData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const api = useApi();
  const { stokKategoriPlaceHolder }: any = strings.stokKategoriSelectOption;

  useEffect(() => {
    fetchStokKategori();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStokKategori = async () => {
    try {
      setFetchLoading(true);

      const response = await api.post("/stokkategori/get", {
        SortOptions: {
          ColumnName: "Id",
          SortType: "Asc",
        },
      });
      if (response.status === 200) {
        setStokKategoriData(response.data.data);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Select
      {...rest}
      placeholder={placeholder || stokKategoriPlaceHolder}
      loading={fetchLoading}
    >
      {stokKategoriData.map((item: any, index: number) => (
        <Option key={index} value={String(item.id)}>
          {item.ad}
        </Option>
      ))}
    </Select>
  );
};

export default StokKategoriSelectOption;
