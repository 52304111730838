import * as actionTypes from '../actions/actionTypes'
import initialState from './initialState';

const languageReducer = (state = initialState.language, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_LANGUAGE:
            return action.payload
        default:
            return state;
    }
}

export default languageReducer