import React, { useEffect, useState } from "react";
import { Button, Card, Divider, message, Space, Typography } from "antd";
import { useParams } from "react-router-dom";
import strings from "../../utils/Localization";
import { useSelector } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import ProfilDetay from "./ProfilDetay";
import useApi from "../../hooks/useApi";
import { StokImage } from "../stok";
const { Title } = Typography;

const gridStyle = {
  width: "50%",
  textAlign: "center",
  padding: 12,
};

const MyDivider = () => <Divider style={{ marginTop: 12, marginBottom: 12 }} />;

export default function Profil() {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [stokData, setStokData] = useState({});
  const [stokBakiye, setStokBakiye] = useState({
    giren: 0,
    cikan: 0,
    bakiye: 0,
  });
  const [profilDetayVisible, setProfilDetayVisible] = useState(false);
  const { stokId } = useParams();
  const { cikis, giris, kalan, tumBilgileriGoster } = strings.stokDetay.profil;
  const rerenderReducer = useSelector((state) => state.rerenderReducer);
  const api = useApi();

  useEffect(() => {
    fetchStok();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchStokBakiye();
  }, [rerenderReducer]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStok = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/stok/get", {
        Data: {
          Id: stokId,
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: [
              "id",
              "stokKategoriId",
              "stokBirimId",
              "ad",
              "aciklama",
            ],
          },
          {
            TableName: "Stokvaryantgrup",
            ColumnNames: [
              "id",
              "barkod",
              "aciklama",
              "gtin",
              "raf",
              "kod",
              "resimAd",
            ],
          },
          {
            TableName: "Stokvaryantgrup.StokvaryantGrupfiyat",
            ColumnNames: [
              "id",
              "kdvOran",
              "kdvTutar",
              "otvOran",
              "otvTutar",
              "satisMi",
              "fiyat",
              "genelToplam",
            ],
          },
          {
            TableName: "Stokvaryantgrup.StokvaryantGrupfiyat.Doviz",
            ColumnNames: ["ad", "simge", "kod"],
          },
        ],
      });
      if (response.status === 200) {
        setStokData(response.data.data[0]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };
  const fetchStokBakiye = async () => {
    try {
      const response = await api.post(`/stok/getsonbakiye`, {
        stokId: stokId,
      });
      if (response.status === 200) {
        setStokBakiye(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <Card
        bodyStyle={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        loading={fetchLoading}
      >
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <StokImage imageName={stokData?.Stokvaryantgrup?.[0]?.resimAd} />
          <Title level={4} style={{ marginTop: 8, marginBottom: 4 }}>
            {stokData.ad}
          </Title>
        </Space>
        <Button
          onClick={() => setProfilDetayVisible(true)}
          style={{ marginTop: 12 }}
          icon={<InfoCircleOutlined />}
        >
          {tumBilgileriGoster}
        </Button>
        <MyDivider />
        <Card>
          <Card.Grid style={gridStyle}>{giris}</Card.Grid>
          <Card.Grid style={gridStyle}>{stokBakiye.giren}</Card.Grid>
          <Card.Grid style={gridStyle}>{cikis}</Card.Grid>
          <Card.Grid style={gridStyle}>{stokBakiye.cikan}</Card.Grid>
          <Card.Grid style={gridStyle}>{kalan}</Card.Grid>
          <Card.Grid style={gridStyle}>{stokBakiye.bakiye}</Card.Grid>
        </Card>
      </Card>
      <ProfilDetay
        visible={profilDetayVisible}
        stok={stokData}
        kapatOnClick={() => setProfilDetayVisible(false)}
      />
    </>
  );
}
