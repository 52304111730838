import { Button, Space } from "antd";
import React, { useState } from "react";
import strings from "../../utils/Localization";
import StokIslemEkleModal from "../stokIslem/StokIslemEkleModal";

export default function IslemButton() {
  const [stokIslemEkleModalVisible, setStokIslemEkleModalVisible] =
    useState(false);
  const [islemEkleGirisMi, setIslemEkleGirisMi] = useState(null);
  const { islemButton: localization } = strings.stokDetay;
  return (
    <>
      <Space>
        <Button
          onClick={() => {
            setIslemEkleGirisMi(true);
            setStokIslemEkleModalVisible(true);
          }}
          type="primary"
          size="large"
        >
          {localization.girisYap}
        </Button>
        <Button
          onClick={() => {
            setIslemEkleGirisMi(false);
            setStokIslemEkleModalVisible(true);
          }}
          type="primary"
          size="large"
        >
          {localization.cikisYap}
        </Button>
      </Space>
      <StokIslemEkleModal
        visible={stokIslemEkleModalVisible}
        handleCancel={() => setStokIslemEkleModalVisible(false)}
        girisMi={islemEkleGirisMi}
      />
    </>
  );
}
