import React, { useEffect, useState } from "react";
import { message, Select, SelectProps } from "antd";
import strings from "../../utils/Localization";
import useApi from "../../hooks/useApi";

const { Option } = Select;

interface IProps extends SelectProps<any> {
  showDovizKod?: boolean;
  requestBody: {};
  onSelectedKasa: (selectedKasa: any) => void;
}

const KasaSelectOption: React.FC<IProps> = ({
  showDovizKod = false,
  requestBody = {},
  onSelectedKasa = () => {},
  placeholder,
  ...rest
}) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [kasaData, setKasaData] = useState([]);
  const api = useApi();

  useEffect(() => {
    fetchKasa();
  }, [requestBody]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchKasa = async () => {
    try {
      setFetchLoading(true);
      const response = await api.post("/kasa/get", {
        SortOptions: {
          ColumnName: "Id",
          SortType: "Asc",
        },
        SelectColumns: [
          {
            TableName: "",
            ColumnNames: ["id", "ad"],
          },
          {
            TableName: "doviz",
            ColumnNames: ["id", "ad", "kod", "simge"],
          },
        ],
        ...requestBody,
      });
      if (response.status === 200) {
        setKasaData(response.data.data);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  return (
    <Select
      {...rest}
      placeholder={placeholder || strings?.kasaSelectOption?.placeHolder}
      loading={fetchLoading}
      onSelect={(e: any) => {
        const selectedKasa = kasaData.find((x: any) => x.id === parseInt(e));
        selectedKasa && onSelectedKasa(selectedKasa);
      }}
      onClear={() => onSelectedKasa({})}
    >
      {kasaData.map((item: any, index: number) => (
        <Option key={index} value={String(item.id)}>
          {`${item.ad} ${showDovizKod ? "(" + item.doviz.kod + ")" : ""}`}
        </Option>
      ))}
    </Select>
  );
};

export default KasaSelectOption;
