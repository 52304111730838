import React from 'react'
import { Card, Form, Input, Row, Col, Divider, TimePicker } from 'antd'
import {
  FileTextOutlined,
  UserOutlined,
  SwapOutlined,
  CalendarOutlined,
  NumberOutlined,
  OrderedListOutlined,
  DatabaseOutlined,
  BankOutlined,
} from '@ant-design/icons'
import Datepicker from '../toolbox/DatePicker'
import DovizSelectOption from '../toolbox/DovizSelectOption'
import CariKartSelectOption from '../toolbox/CariKartSelectOption'
import OdemeSekliSelectOption from '../toolbox/OdemeSekliSelectOption'
import KasaSelectOption from '../toolbox/KasaSelectOption'
import BankaHesapSelectOption from '../toolbox/BankaHesapSelectOption'
import { odemeSekliTypes } from '../../types/odemeSekliTypes'
import strings from '../../utils/Localization'

const LabelIcon = ({ Icon, title }) => (
  <div style={{ fontSize: 15 }}>
    <Icon /> {title}
  </div>
)

function FaturaHeader({ form }) {
  const { faturaHeader: localization } = strings.faturaLayout
  const onSelectedCariKart = (data) => {
    const { adres, cepTelefonu, eMail, firmaAdi, il, ilce, sevkAdresi, telefon, vergIdairesi, vergiNumarasi, yetkili } =
      data
    form.setFieldsValue({
      firmaAdi: firmaAdi,
      yetkili: yetkili,
      vergiDairesi: vergIdairesi,
      vergiNumarasi: vergiNumarasi,
      telefon: telefon,
      cepTelefonu: cepTelefonu,
      eMail: eMail,
      il: il,
      ilce: ilce,
      adres: adres,
      sevkAdresi: sevkAdresi,
    })
  }

  return (
    <>
      <Form.Item name="firmaAdi" noStyle hidden initialValue="" />
      <Form.Item name="yetkili" noStyle hidden initialValue="" />
      <Form.Item name="vergiDairesi" noStyle hidden initialValue="" />
      <Form.Item name="telefon" noStyle hidden initialValue="" />
      <Form.Item name="cepTelefonu" noStyle hidden initialValue="" />
      <Form.Item name="eMail" noStyle hidden initialValue="" />
      <Form.Item name="il" noStyle hidden initialValue="" />
      <Form.Item name="ilce" noStyle hidden initialValue="" />
      <Form.Item name="sevkAdresi" noStyle hidden initialValue="" />
      <Card>
        <Row gutter={24}>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="cariKartId"
              label={<LabelIcon Icon={UserOutlined} title={localization.musteriSeciniz} />}
              rules={[{ required: true, message: strings.mesaj.gerekliBirAlan }]}
            >
              <CariKartSelectOption
                placeholder={localization.musteriArama}
                onSelectedCariKart={(e) => onSelectedCariKart(e)}
                allowClear
              />
            </Form.Item>
            <Divider />
            <Form.Item
              rules={[{ required: true }]}
              label={<LabelIcon Icon={NumberOutlined } title={localization.vergiNumarasi} />}
              
              name="vergiNumarasi"
              initialValue=""
            >
              <Input />
            </Form.Item>
            <Form.Item name="aciklama" label={<LabelIcon Icon={FileTextOutlined} title={localization.aciklama} />}>
              <Input.TextArea rows={3} placeholder={localization.aciklamaPlaceHolder} />
            </Form.Item>
            <Divider />
            <Form.Item
              name="dovizId"
              label={<LabelIcon Icon={SwapOutlined} title={localization.dovizTuru} />}
              rules={[{ required: true, message: strings.mesaj.gerekliBirAlan }]}
            >
              <DovizSelectOption />
            </Form.Item>
            <Form.Item
              name="odemeSekli"
              label={<LabelIcon Icon={OrderedListOutlined} title={localization.odemeSekli} />}
              rules={[{ required: true, message: strings.mesaj.gerekliBirAlan }]}
            >
              <OdemeSekliSelectOption />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.odemeSekli !== currentValues.odemeSekli || prevValues.dovizId !== currentValues.dovizId
              }
            >
              {({ getFieldValue }) => {
                const currentOdemeSekli = getFieldValue('odemeSekli')
                const currentDovizId = getFieldValue('dovizId')
                if (currentOdemeSekli !== undefined) {
                  if (currentOdemeSekli === odemeSekliTypes.NAKIT) {
                    return (
                      <Form.Item
                        name="kasaId"
                        label={<LabelIcon Icon={DatabaseOutlined} title={localization.kasa} />}
                        rules={[
                          {
                            required: true,
                            message: strings.mesaj.gerekliBirAlan,
                          },
                        ]}
                      >
                        <KasaSelectOption
                          requestBody={{
                            Data: {
                              dovizId: currentDovizId,
                            },
                          }}
                        />
                      </Form.Item>
                    )
                  } else if (
                    currentOdemeSekli === odemeSekliTypes.KREDI_KARTI ||
                    currentOdemeSekli === odemeSekliTypes.HAVALE
                  ) {
                    return (
                      <Form.Item
                        name="bankaHesapId"
                        label={<LabelIcon Icon={BankOutlined} title={localization.bankaHesap} />}
                        rules={[
                          {
                            required: true,
                            message: strings.mesaj.gerekliBirAlan,
                          },
                        ]}
                      >
                        <BankaHesapSelectOption
                          requestBody={{
                            Data: {
                              dovizId: currentDovizId,
                            },
                          }}
                        />
                      </Form.Item>
                    )
                  }
                }
              }}
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              label={<LabelIcon Icon={CalendarOutlined} title={localization.duzenlemeTarihi} />}
              required
              style={{ marginBottom: 0 }}
            >
              <Row>
                <Form.Item name="faturaTarihi" rules={[{ required: true }]} style={{ flex: 1, marginRight: 8 }}>
                  <Datepicker allowClear={false} />
                </Form.Item>
                <Form.Item name="faturaSaati" required>
                  <TimePicker allowClear={false} />
                </Form.Item>
              </Row>
            </Form.Item>
            <Divider style={{ marginTop: 0 }} />
            <Form.Item
              name="vadeTarihi"
              label={<LabelIcon Icon={CalendarOutlined} title={localization.vadeTarihi} />}
              rules={[{ required: true }]}
            >
              <Datepicker allowClear={false} />
            </Form.Item>
            <Divider />
            <Form.Item
              name="sevkTarihi"
              label={<LabelIcon Icon={CalendarOutlined} title={localization.sevkTarihi} />}
              rules={[{ required: true }]}
            >
              <Datepicker allowClear={false} />
            </Form.Item>
            <Divider />
            <Form.Item name="faturaNo" label={<LabelIcon Icon={NumberOutlined} title={localization.faturaNo} />}>
              <Input placeholder={localization.faturaNoGiriniz} />
            </Form.Item>
            <Form.Item name="sevkNo" label={<LabelIcon Icon={NumberOutlined} title={localization.sevkNo} />}>
              <Input placeholder={localization.sevkNoPlaceHolder} />
            </Form.Item>
            <Divider style={{ marginTop: 0 }} />
            <Form.Item name="adres" label={<LabelIcon Icon={FileTextOutlined} title={localization.faturaAdresi} />}>
              <Input.TextArea rows={3} placeholder={localization.faturaAdresiPlaceHolder} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default FaturaHeader
