import React from 'react'
import { Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import strings from '../../../utils/Localization'
import { useNavigate } from 'react-router-dom'
import useKullanici from '../../../hooks/useKullanici'

const MyButton = styled.span`
  cursor: pointer;
`

export default function KullaniciAyarlari() {
  const navigate= useNavigate()
  const { cikisYap, getEMail } = useKullanici()

  const kullaniciMenu = (
    <Menu>
      <Menu.ItemGroup title={strings.navBar.ayarlar}>
        <Menu.Item key="0" onClick={() => navigate('/FirmaBilgileri')}>
          {strings.navBar.kullaniciAyarSecim.firmaAyarlari}
        </Menu.Item>
        <Menu.Item key="1" onClick={() => navigate('/KullaniciListe')}>
          {strings.navBar.kullaniciAyarSecim.kullaniciAyarlari}
        </Menu.Item>
        <Menu.Item key="2" onClick={() => navigate('/SubeListe')}>
          {strings.navBar.kullaniciAyarSecim.subeAyarlari}
        </Menu.Item>
        <Menu.Item key="3" onClick={() => navigate('/VarsayilanAyarlar')}>
          {strings.navBar.kullaniciAyarSecim.varsayilanAyarlar}
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title={strings.navBar.diger}>
        <Menu.Item key="4" onClick={() => navigate('/SifremiDegistir')}>
          {strings.navBar.kullaniciAyarSecim.sifremiDegistir}
        </Menu.Item>
        <Menu.Item key="5" onClick={() => navigate('/DovizListe')}>
          {strings.navBar.kullaniciAyarSecim.dovizListesi}
        </Menu.Item>
        <Menu.Item key="6" onClick={() => cikisYap()}>
          {strings.navBar.kullaniciAyarSecim.cikisYap}
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  return (
    <Dropdown overlay={kullaniciMenu} trigger={['click']} overlayStyle={{ minWidth: 250 }}>
      <MyButton type="text" onClick={(e) => e.preventDefault()}>
        {getEMail} <DownOutlined />
      </MyButton>
    </Dropdown>
  )
}
