import React, { useState, useEffect } from "react";
import Avatar from "../toolbox/Avatar";
import { Button, Card, Divider, message, Space, Typography } from "antd";
import strings from "../../utils/Localization";
import { InfoCircleOutlined } from "@ant-design/icons";
import ProfilDetay from "./ProfilDetay";
import useCurrency from "../../hooks/useCurrency";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";

const { Title } = Typography;

const gridStyle = {
  width: "50%",
  textAlign: "center",
  padding: 12,
};

const MyDivider = () => <Divider style={{ marginTop: 12, marginBottom: 12 }} />;

export default function Profil() {
  const [visible, setVisible] = useState(false);
  const [bankaHesapSonBakiye, setBankaHesapSonBakiye] = useState({
    giren: 0,
    cikan: 0,
    bakiye: 0,
  });
  const { data, isLoading } = useSelector(
    (state) => state.selectedBankaHesapReducer
  );
  const { doviz = { simge: "", ad: "" } } = data;
  const { bankaHesapId } = useParams();
  const rerenderReducer = useSelector((state) => state.rerenderReducer);
  const api = useApi();

  const { paraFormat } = useCurrency();
  const { profil } = strings.bankaHesapDetay.components;

  useEffect(() => {
    fetchBankaHesapSonBakiye();
  }, [rerenderReducer]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBankaHesapSonBakiye = async () => {
    try {
      const response = await api.post(
        `bankahesap/getsonbakiye/${bankaHesapId}`,
        {}
      );
      if (response.status === 200) {
        setBankaHesapSonBakiye(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <Card
        bodyStyle={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        loading={isLoading}
      >
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <Avatar text={data.ad} />
          <Title level={4} style={{ marginTop: 8 }}>
            {data.ad}
          </Title>
          <Title level={5} style={{ marginTop: 0, fontSize: 14 }}>
            {doviz.ad}({doviz.simge})
          </Title>
        </Space>
        <MyDivider />
        <Button
          onClick={() => setVisible(true)}
          style={{ marginBottom: 6, marginTop: 6 }}
          icon={<InfoCircleOutlined />}
          type="default"
          size="middle"
          block
        >
          {profil.tumBilgileriGoster}
        </Button>
        <MyDivider />
        <Card>
          <Card.Grid style={gridStyle}>{profil.giris}</Card.Grid>
          <Card.Grid style={gridStyle}>
            {doviz.simge}
            {paraFormat(bankaHesapSonBakiye.giren)}
          </Card.Grid>
          <Card.Grid style={gridStyle}>{profil.cikis}</Card.Grid>
          <Card.Grid style={gridStyle}>
            {doviz.simge}
            {paraFormat(bankaHesapSonBakiye.cikan)}
          </Card.Grid>
          <Card.Grid style={gridStyle}>{profil.toplam}</Card.Grid>
          <Card.Grid style={gridStyle}>
            {doviz.simge}
            {paraFormat(bankaHesapSonBakiye.bakiye)}
          </Card.Grid>
        </Card>
      </Card>
      <ProfilDetay
        visible={visible}
        kapatOnClick={() => setVisible(!visible)}
        bankaHesap={data}
      />
    </>
  );
}
