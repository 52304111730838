import React from "react";
import { Card, Form, Input, Row, Col, Divider } from "antd";
import {
  FileTextOutlined,
  UserOutlined,
  SwapOutlined,
  CalendarOutlined,
  NumberOutlined,
  PaperClipOutlined
} from "@ant-design/icons";
import Datepicker from "../toolbox/DatePicker";
import DovizSelectOption from "../toolbox/DovizSelectOption";
import CariKartSelectOption from "../toolbox/CariKartSelectOption";
import strings from "../../utils/Localization";
import TeklifSiparisDurumTipSelectOption from "../toolbox/TeklifSiparisDurumTipSelectOption";
import { teklifSiparisTypes } from "../../types/teklifSiparisTypes";
import { teklifSiparisDurumTypes } from "../../types/teklifSiparisDurumTypes";

const LabelIcon = ({ Icon, title }) => (
  <div style={{ fontSize: 15 }}>
    <Icon /> {title}
  </div>
);

function TeklifSiparisHeader({ form, tip }) {
  const { teklifsSiparisHeader: localization } = strings.teklifSiparisLayout;
  const onSelectedCariKart = (data) => {
    const {
      adres,
      cepTelefonu,
      eMail,
      firmaAdi,
      il,
      ilce,
      sevkAdresi,
      telefon,
      vergIdairesi,
      vergiNumarasi,
      yetkili,
    } = data;
    form.setFieldsValue({
      firmaAdi: firmaAdi,
      yetkili: yetkili,
      vergiDairesi: vergIdairesi,
      vergiNumarasi: vergiNumarasi,
      telefon: telefon,
      cepTelefonu: cepTelefonu,
      eMail: eMail,
      il: il,
      ilce: ilce,
      adres: adres,
      sevkAdresi: sevkAdresi,
    });
  };
  const isTeklif = (tip === teklifSiparisTypes.ALINAN_TEKLIF || tip === teklifSiparisTypes.VERILEN_TEKLIF) ? true : false

  return (
    <>
      <Form.Item name="firmaAdi" noStyle hidden initialValue="" />
      <Form.Item name="yetkili" noStyle hidden initialValue="" />
      <Form.Item name="vergiDairesi" noStyle hidden initialValue="" />
      <Form.Item name="vergiNumarasi" noStyle hidden initialValue="" />
      <Form.Item name="telefon" noStyle hidden initialValue="" />
      <Form.Item name="cepTelefonu" noStyle hidden initialValue="" />
      <Form.Item name="eMail" noStyle hidden initialValue="" />
      <Form.Item name="il" noStyle hidden initialValue="" />
      <Form.Item name="ilce" noStyle hidden initialValue="" />
      <Form.Item name="adres" noStyle hidden initialValue="" />
      <Form.Item name="sevkAdresi" noStyle hidden initialValue="" />
      <Card>
        <Row gutter={24}>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="cariKartId"
              label={
                <LabelIcon
                  Icon={UserOutlined}
                  title={localization.musteriSeciniz}
                />
              }
              rules={[
                { required: true, message: strings.mesaj.gerekliBirAlan },
              ]}
            >
              <CariKartSelectOption
                placeholder={localization.musteriArama}
                onSelectedCariKart={onSelectedCariKart}
                allowClear
              />
            </Form.Item>
            <Divider />
            <Form.Item
              name="aciklama"
              label={
                <LabelIcon
                  Icon={FileTextOutlined}
                  title={localization.aciklama}
                />
              }
            >
              <Input.TextArea
                rows={3}
                placeholder={localization.aciklamaPlaceHolder}
              />
            </Form.Item>
            <Divider />
            <Form.Item
              name="dovizId"
              label={
                <LabelIcon Icon={SwapOutlined} title={localization.dovizTuru} />
              }
              rules={[
                { required: true, message: strings.mesaj.gerekliBirAlan },
              ]}
            >
              <DovizSelectOption />
            </Form.Item>
          </Col>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="duzenlemeTarihi"
              label={
                <LabelIcon
                  Icon={CalendarOutlined}
                  title={localization.duzenlemeTarihi}
                />
              }
              required
            >
              <Datepicker allowClear={false} />
            </Form.Item>
            <Divider />
            <Form.Item
              name="gecerlilikTarihi"
              label={
                <LabelIcon
                  Icon={CalendarOutlined}
                  title={localization.gecerlilikTarihi}
                />
              }
              required
            >
              <Datepicker allowClear={false} />
            </Form.Item>
            <Divider />
            <Form.Item
              name="evrakNo"
              label={
                <LabelIcon Icon={NumberOutlined} title={localization.evrakNo} />
              }
            >
              <Input placeholder={localization.evrakNoGiriniz} />
            </Form.Item>
            <Form.Item
              name="durumId"
              label={
                <LabelIcon Icon={PaperClipOutlined} title={localization.durum} />
              }
              rules={[{ required: true, message: localization.durumSeciniz }]}
            >
              <TeklifSiparisDurumTipSelectOption tip={isTeklif ? teklifSiparisDurumTypes.TEKLIF : teklifSiparisDurumTypes.SIPARIS} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default TeklifSiparisHeader;
