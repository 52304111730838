import * as actionTypes from "../actions/actionTypes";

const initialState = {
  paket: {},
  faturaAdresi: {
    adSoyad: "",
    telefon: "",
    eMail: "",
    adres: "",
  },
};

const odemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ODEME_PAKET:
      return { ...state, paket: action.payload };
    case actionTypes.SET_RESET_ODEME_PAKET:
      return { ...state, paket: initialState.paket };
    case actionTypes.SET_ODEME_FATURA_ADRES:
      return { ...state, faturaAdresi: action.payload };
    case actionTypes.SET_RESET_ODEME_FATURA_ADRES:
      return { ...state, faturaAdresi: initialState.faturaAdresi };
    default:
      return state;
  }
};

export default odemeReducer;
