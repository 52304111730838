import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Row, Col, Divider, Space, Card, Select, Typography, Dropdown, Menu } from 'antd'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import StokSelectOption from '../toolbox/StokSelectOption'
import { tutariYuzdeyeCevir, yuzdeHesala } from '../../utils/faturaSatirHesaplama'
import StokVaryantGrupSelectOption from '../toolbox/StokVaryantGrupSelectOption'
import { faturaTypes } from '../../types/faturaTypes'
import strings from '../../utils/Localization'
import DepoSelectOption from '../toolbox/DepoSelectOption'
import useVarsayilanDeger from '../../hooks/useVarsayilanDeger'
import CurrencyInput from '../toolbox/CurrencyInput'

const { Text } = Typography
const { Option } = Select

function FaturaSatir({ formSize = 'middle', form, setFooterFields }) {
  const { faturaSatir: localization } = strings.faturaLayout

  return (
    <Card bodyStyle={{ paddingTop: 12 }}>
      <Row gutter={12}>
        <Col span={8}>
          <Text strong> {localization.urunHizmet}</Text>
        </Col>
        <Col span={2}>
          <Text strong>{localization.depo}</Text>
        </Col>
        <Col span={2}>
          <Text strong>{localization.miktar}</Text>
        </Col>
        <Col span={4}>
          <Text strong>{localization.birimFiyat}</Text>
        </Col>
        <Col span={4}>
          <Text strong>{localization.kdv}</Text>
        </Col>
        <Col span={4}>
          <Text strong>{localization.genelToplam}</Text>
        </Col>
      </Row>
      <Divider style={{ marginTop: 12 }} />
      <Form.List name="faturaSatir">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey }) => {
              return (
                <FormSatirItem
                  form={form}
                  key={key}
                  name={name}
                  formSize={formSize}
                  setFooterFields={setFooterFields}
                />
              )
            })}
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} size="large">
              {localization.yeniHizmetUrunEkle}
            </Button>
          </>
        )}
      </Form.List>
    </Card>
  )
}

export default FaturaSatir

const FormSatirItem = ({ formSize = 'middle', key, name, form, setFooterFields }) => {
  const [satirHidden, setSatirHidden] = useState(false)
  const [aciklamaHidden, setAciklamaHidden] = useState(true)
  const [otvHidden, setOtvHidden] = useState(true)
  const [indirimHidden, setIndirimHidden] = useState(true)
  const [selectedStok, setSelectedStok] = useState({ Stokvaryantgrup: [] })
  const { getDefaultSetting } = useVarsayilanDeger()
  const { faturaSatir: localization } = strings.faturaLayout

  useEffect(() => {
    const { iskontoOran, otvOran, aciklama } = form.getFieldValue('faturaSatir')[name]
    if (aciklama !== '') {
      setAciklamaHidden(false)
    }
    if (iskontoOran > 0) {
      setIndirimHidden(false)
    }
    if (otvOran > 0) {
      setOtvHidden(false)
    }
  }, [selectedStok]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangedFaturaSatir = (index, name) => {
    const fields = form.getFieldsValue()
    const { faturaSatir } = fields
    const {
      miktar = 0,
      birimFiyat = 0,
      kdvOran = 0,
      otvOran = 0,
      toplam = 0,
      iskontoOran = 0,
      iskontoTip = 'yuzde',
    } = faturaSatir[index]

    let yeniBirimFiyat = birimFiyat
    if (name === 'toplam') {
      yeniBirimFiyat = parseFloat(toplam / miktar / (otvOran / 100 + 1) / (kdvOran / 100 + 1))
    }
    const miktarliBirimFiyat = parseFloat(yeniBirimFiyat * miktar)
    let iskontoTutar = 0
    let iskontoYuzde = 0
    if (iskontoTip === 'yuzde') {
      iskontoTutar = yuzdeHesala(miktarliBirimFiyat, iskontoOran)
      iskontoYuzde = iskontoOran
    } else {
      iskontoTutar = parseFloat(iskontoOran)
      iskontoYuzde = tutariYuzdeyeCevir(iskontoOran, miktarliBirimFiyat)
    }
    let indirimliFiyat = parseFloat(miktarliBirimFiyat - iskontoTutar)
    let otvTutar = yuzdeHesala(indirimliFiyat, otvOran)
    let otvliBirimFiyat = parseFloat(indirimliFiyat) + otvTutar
    let kdvTutar = yuzdeHesala(otvliBirimFiyat, kdvOran)
    const vergilerDahilFiyat = parseFloat(otvliBirimFiyat + kdvTutar)

    form.setFields([
      {
        name: ['faturaSatir', index, 'iskontoUygulananTutar'],
        value: miktarliBirimFiyat,
      },
      { name: ['faturaSatir', index, 'iskontoYuzde'], value: iskontoYuzde },
      { name: ['faturaSatir', index, 'iskontoTutar'], value: iskontoTutar },
      { name: ['faturaSatir', index, 'otvTutar'], value: otvTutar },
      { name: ['faturaSatir', index, 'kdvTutar'], value: kdvTutar },
    ])

    if (name === 'toplam') {
      form.setFields([
        {
          name: ['faturaSatir', index, 'birimFiyat'],
          value: parseFloat(yeniBirimFiyat).toFixed(2),
        },
      ])
    } else if (name === 'birimFiyat') {
      let genelToplam = parseFloat(vergilerDahilFiyat).toFixed(2)

      form.setFields([{ name: ['faturaSatir', index, 'toplam'], value: genelToplam }])
    } else {
      let genelToplam = parseFloat(vergilerDahilFiyat).toFixed(2)

      form.setFields([{ name: ['faturaSatir', index, 'toplam'], value: genelToplam }])
    }
    setFooterFields()
  }
  const onSelectedStok = (e) => {
    setSelectedStok(e)
    const satis = e.Stokvaryantgrup[0].StokvaryantGrupfiyat[0]
    const alis = e.Stokvaryantgrup[0].StokvaryantGrupfiyat[1]

    let varyantGrupDeger = ''
    const stokVaryantDegerArray = e.Stokvaryantgrup[0].StokvaryantgrupHasStokvaryantdeger
    if (stokVaryantDegerArray.length > 0) {
      varyantGrupDeger = stokVaryantDegerArray.map((item) => item.StokVaryantDeger.ad).join(' ')
    }

    let varyantGrup = satisMi() === true ? satis : alis

    const siparisSatirMi = form.getFieldValue(['faturaSatir', name, 'siparisSatirMi'])
    const stokDegistiMi = form.getFieldValue(['faturaSatir', name, 'siparisStokDegistiMi'])

    if (siparisSatirMi && !stokDegistiMi) {
      form.setFields([
        {
          name: ['faturaSatir', name, 'stokBirimId'],
          value: e.stokBirimId,
        },
        {
          name: ['faturaSatir', name, 'urunAdi'],
          value: `${e.ad}  ${varyantGrupDeger}`,
        },
        {
          name: ['faturaSatir', name, 'barkod'],
          value: e.Stokvaryantgrup[0].barkod,
        },
        {
          name: ['faturaSatir', name, 'stokVaryantGrupId'],
          value: String(e.Stokvaryantgrup[0].id),
        },

        {
          name: ['faturaSatir', name, 'siparisStokDegistiMi'],
          value: true,
        },
      ])
    } else {
      form.setFields([
        {
          name: ['faturaSatir', name, 'birimFiyat'],
          value: varyantGrup.fiyat,
        },
        {
          name: ['faturaSatir', name, 'kdvOran'],
          value: varyantGrup.kdvOran,
        },
        {
          name: ['faturaSatir', name, 'otvOran'],
          value: varyantGrup.otvOran,
        },
        {
          name: ['faturaSatir', name, 'stokBirimId'],
          value: e.stokBirimId,
        },
        {
          name: ['faturaSatir', name, 'urunAdi'],
          value: `${e.ad}  ${varyantGrupDeger}`,
        },
        {
          name: ['faturaSatir', name, 'barkod'],
          value: e.Stokvaryantgrup[0].barkod,
        },
        {
          name: ['faturaSatir', name, 'stokVaryantGrupId'],
          value: String(e.Stokvaryantgrup[0].id),
        },
      ])
    }

    onChangedFaturaSatir(name, 'stok')
  }
  const onSelectedVaryantGrup = (e) => {
    const satis = e.stokvaryantgrupfiyat[0]
    const alis = e.stokvaryantgrupfiyat[1]
    let varyantGrup = satisMi() === true ? satis : alis

    form.setFields([
      {
        name: ['faturaSatir', name, 'birimFiyat'],
        value: varyantGrup.fiyat,
      },
      {
        name: ['faturaSatir', name, 'kdvOran'],
        value: varyantGrup.kdvOran,
      },
      {
        name: ['faturaSatir', name, 'otvOran'],
        value: varyantGrup.otvOran,
      },
      {
        name: ['faturaSatir', name, 'barkod'],
        value: e.barkod,
      },
      {
        name: ['faturaSatir', name, 'urunAdi'],
        value: `${selectedStok.ad} ${e.label}`,
      },
    ])
    onChangedFaturaSatir(name, 'stok')
  }
  const MenuTitle = ({ children }) => <span style={{ fontSize: 16 }}>{children}</span>
  const satisMi = () => {
    const faturaTip = form.getFieldValue('faturaTip')
    switch (faturaTip) {
      case faturaTypes.SATIS_FATURASI:
        return true
      case faturaTypes.ALIS_FATURASI:
        return false
      case faturaTypes.SATIS_IADE_FATURASI:
        return true
      case faturaTypes.ALIS_IADE_FATURASI:
        return false
      default:
        return null
    }
  }
  const remove = (faturaSatirIndex) => {
    setSatirHidden(true)
    form.setFields([{ name: ['faturaSatir', faturaSatirIndex, 'hidden'], value: true }])
  }

  return (
    <div key={key} style={{ display: satirHidden ? 'none' : null }}>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name={[name, 'hidden']} hidden initialValue={false} />
          <Form.Item name={[name, 'otvTutar']} hidden initialValue={0} />
          <Form.Item name={[name, 'kdvTutar']} hidden initialValue={0} />
          <Form.Item name={[name, 'stokBirimId']} hidden initialValue={null} />
          <Form.Item name={[name, 'barkod']} hidden initialValue={''} />
          <Form.Item name={[name, 'iskontoTutar']} hidden initialValue={0} />
          <Form.Item name={[name, 'iskontoYuzde']} hidden initialValue={0} />
          <Form.Item name={[name, 'iskontoUygulananTutar']} hidden initialValue={0} />
          <Row>
            <Form.Item
              name={[name, 'stokId']}
              rules={[{ required: true, message: strings.mesaj.gerekliBirAlan }]}
              initialValue={null}
              style={{ flex: 1 }}
            >
              <StokSelectOption onSelectedStok={onSelectedStok} />
            </Form.Item>
            <Form.Item
              name={[name, 'stokVaryantGrupId']}
              rules={[{ required: true, message: strings.mesaj.gerekliBirAlan }]}
              initialValue={null}
              hidden={selectedStok.Stokvaryantgrup.length > 1 ? false : true}
              style={{ marginLeft: 8 }}
            >
              {selectedStok.Stokvaryantgrup.length > 1 && (
                <StokVaryantGrupSelectOption stokId={selectedStok.id} onSelectedVaryantGrup={onSelectedVaryantGrup} />
              )}
            </Form.Item>
          </Row>
          <Form.Item
            name={[name, 'urunAdi']}
            initialValue={''}
            hidden={selectedStok.Stokvaryantgrup.length === 0 ? true : false}
          >
            <Input addonBefore={localization.faturadakiUrunAdi} />
          </Form.Item>
          <Form.Item name={[name, 'aciklama']} hidden={aciklamaHidden} initialValue={''}>
            <Input
              addonBefore={localization.aciklama}
              addonAfter={
                <Button
                  hidden={aciklamaHidden}
                  size="small"
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    form.setFields([
                      {
                        name: ['faturaSatir', name, 'aciklama'],
                        value: '',
                      },
                    ])
                    setAciklamaHidden(true)
                  }}
                ></Button>
              }
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item
            name={[name, 'stokDepoId']}
            rules={[{ required: true, message: strings.mesaj.gerekliBirAlan }]}
            initialValue={getDefaultSetting('stokDepo')}
          >
            <DepoSelectOption />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item
            name={[name, 'miktar']}
            rules={[
              {
                required: true,
                message: localization.miktarGerekliBirAlan,
              },
            ]}
            initialValue={1}
          >
            <Input
              placeholder={localization.miktar}
              type="number"
              onChange={(e) => onChangedFaturaSatir(name, 'miktar')}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={[name, 'birimFiyat']}
            rules={[
              {
                required: true,
                message: localization.birimFiyatGerekliBirAlan,
              },
            ]}
            initialValue={0}
          >
            <CurrencyInput
              placeholder={localization.birimFiyat}
              onChange={(e) => onChangedFaturaSatir(name, 'birimFiyat')}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={[name, 'kdvOran']}
            rules={[
              {
                required: true,
                message: localization.kdvGerekliBirAlan,
              },
            ]}
            initialValue={0}
          >
            <Select onChange={(e) => onChangedFaturaSatir(name, 'kdvOran')}>
              <Option value={0}>KDV %0</Option>
              <Option value={1}>KDV %1</Option>
              <Option value={8}>KDV %8</Option>
              <Option value={18}>KDV %18</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={[name, 'iskontoOran']}
            rules={[
              {
                required: true,
                message: localization.indirimGerekliBirAlan,
              },
            ]}
            initialValue={0}
            hidden={indirimHidden}
          >
            <Input
              addonBefore={localization.indirim}
              onChange={(e) => onChangedFaturaSatir(name, 'iskontoOran')}
              addonAfter={
                <Form.Item name={[name, 'iskontoTip']} initialValue={'yuzde'} noStyle>
                  <Select onChange={(e) => onChangedFaturaSatir(name, 'iskontoTip')}>
                    <Option value={'yuzde'}>%</Option>
                    <Option value={'tutar'}>{localization.tutar}</Option>
                  </Select>
                </Form.Item>
              }
            />
          </Form.Item>

          <Form.Item
            name={[name, 'otvOran']}
            rules={[
              {
                required: true,
                message: localization.otvGerekliBirAlan,
              },
            ]}
            initialValue={0}
            hidden={otvHidden}
          >
            <Input
              addonBefore={localization.otv}
              placeholder={localization.otvOran}
              onChange={(e) => onChangedFaturaSatir(name, 'otvOran')}
              addonAfter="%"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Space align="start">
            <Form.Item
              name={[name, 'toplam']}
              rules={[
                {
                  required: true,
                  message: localization.genelToplamGerekliBirAlan,
                },
              ]}
              initialValue={0}
            >
              <CurrencyInput placeholder={localization.toplam} onChange={(e) => onChangedFaturaSatir(name, 'toplam')} />
            </Form.Item>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="0" disabled={!aciklamaHidden} onClick={() => setAciklamaHidden(false)}>
                    <MenuTitle>{localization.aciklamaEkle}</MenuTitle>
                  </Menu.Item>
                  <Menu.Item key="1" disabled={!indirimHidden} onClick={() => setIndirimHidden(false)}>
                    <MenuTitle>{localization.indirimEkle}</MenuTitle>
                  </Menu.Item>
                  <Menu.Item key="2" disabled={!otvHidden} onClick={() => setOtvHidden(false)}>
                    <MenuTitle>{localization.otvEkle}</MenuTitle>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button icon={<PlusOutlined />}></Button>
            </Dropdown>
            <Button
              size={formSize}
              onClick={async () => {
                await remove(name)
                setFooterFields()
              }}
              icon={<CloseOutlined />}
            ></Button>
          </Space>
          <Form.Item hidden={indirimHidden}>
            <Button
              size={formSize}
              icon={<CloseOutlined />}
              onClick={() => {
                form.setFields([
                  {
                    name: ['faturaSatir', name, 'iskontoOran'],
                    value: 0,
                  },
                ])
                onChangedFaturaSatir(name, 'iskontoOran')
                setIndirimHidden(true)
              }}
            ></Button>
          </Form.Item>
          <Form.Item hidden={otvHidden}>
            <Button
              size={formSize}
              icon={<CloseOutlined />}
              onClick={() => {
                form.setFields([
                  {
                    name: ['faturaSatir', name, 'otvOran'],
                    value: 0,
                  },
                ])
                onChangedFaturaSatir(name, 'otvOran')
                setOtvHidden(true)
              }}
            ></Button>
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ marginTop: 0 }} />
    </div>
  )
}
