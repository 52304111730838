import React from "react";
import { PageHeader as MyPageHeader } from "antd";
import { useNavigate } from "react-router-dom";
import strings from "../../utils/Localization";

export default function PageHeader() {
  const navigate= useNavigate();

  return (
    <>
      <MyPageHeader
        ghost={false}
        onBack={() => navigate(-1)}
        title={strings.cariDetay.components.pageHeader.baslik}
        subTitle={strings.cariDetay.components.pageHeader.altBaslik}
      />
    </>
  );
}
