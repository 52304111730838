import React, { useState } from 'react'
import {
  Card,
  Button,
  Space,
  Tag,
  Popconfirm,
  message,
  notification,
  Typography,
  Form,
  Row,
  Col,
  Radio,
  PageHeader,
  Tooltip,
  Modal,
} from 'antd'
import strings from '../../utils/Localization'
import { EditOutlined, DeleteOutlined, SearchOutlined, ClearOutlined } from '@ant-design/icons'
import IslemButton from './IslemButton'
import FetchListContent from '../toolbox/FetchListContent'
import { useParams, useNavigate } from 'react-router-dom'
import useCurrency from '../../hooks/useCurrency'
import CariIslemDuzenleModal from '../cariIslem/CariIslemDuzenleModal'
import { cariIslemTurTypes } from '../../types/cariIslemTurTypes'
import { useDispatch } from 'react-redux'
import { setRerender } from '../../redux/actions/rerenderAction'
import DatepickerRanger from '../toolbox/DatepickerRanger'
import DovizSelectOption from '../toolbox/DovizSelectOption'
import moment from 'moment'
import useWindowSize from '../../hooks/useWindowSize'
import CurrencyInput from '../toolbox/CurrencyInput'
import useApi from '../../hooks/useApi'

export default function CariIslemList() {
  const { cariKartId } = useParams()
  const navigate= useNavigate()
  const windowSize = useWindowSize()
  const api = useApi()

  const initialFilterRequestData = {
    Data: {
      CariKartId: cariKartId,
      cariIslemTur: null,
      DovizId: null,
    },
    DateOptions: [
      {
        DatePropertyName: 'IslemTarih',
        FirstDate: null,
        LastDate: null,
      },
      {
        DatePropertyName: 'VadeTarih',
        FirstDate: null,
        LastDate: null,
      },
    ],
    BetweenOptions: [
      {
        PropertyName: 'Tutar',
        First: null,
        Last: null,
      },
    ],
  }

  const { paraFormat } = useCurrency()
  const [dataSource, setDataSource] = useState({})
  const [selecteData, setSelecteData] = useState({})
  const [filterRequestData, setFilterRequestData] = useState({
    ...initialFilterRequestData,
  })

  const [cariIslemDuzenleModalVisible, setCariIslemDuzenleModalVisible] = useState(false)
  const dispatch = useDispatch()
  const [detailSearchForm] = Form.useForm()
  const { detayliArama } = strings.cariDetay.components

  const remove = async (cariIslemId) => {
    try {
      const response = await api.delete(`/cariislem/${cariIslemId}`, {})
      if (response.status === 200) {
        dispatch(setRerender())
        notification['success']({
          message: strings.mesaj.basarliBaslik,
          description: strings.mesaj.islemBasarili,
        })
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  const onEditCariIslem = (row) => {
    if (row?.Cariislembaglanti?.faturaId) {
      return Modal.warning({
        title: 'Uyarı',
        content: strings.cariDetay.components.cariIslemList.cariIslemDuzenlemeFaturaUyari(row.cariIslemTur),
      })
    }
    setSelecteData(row)
    setCariIslemDuzenleModalVisible(true)
  }

  const columns = [
    {
      title: strings.cariDetay.components.cariIslemList.tarih,
      dataIndex: 'islemTarih',
      key: 'islemTarih',
      sorter: (a, b) => a.islemTarih.length - b.islemTarih.length,
      render: (text) => moment(text).format('DD.MM.YYYY'),
      defaultSortOrder: 'descend',
    },
    {
      title: strings.cariDetay.components.cariIslemList.vadeTarihi,
      dataIndex: 'vadeTarih',
      key: 'vadeTarih',
      sorter: (a, b) => a.vadeTarih.length - b.vadeTarih.length,
      render: (text) => moment(text).format('DD.MM.YYYY'),
    },
    {
      title: strings.cariDetay.components.cariIslemList.evrakNo,
      dataIndex: 'evrakNo',
      key: 'evrakNo',
    },
    {
      title: strings.cariDetay.components.cariIslemList.cariIslemTur,
      dataIndex: 'cariIslemTur',
      key: 'cariIslemTur',
      render: (text, row, index) => {
        const { BankaHesap, Kasa, faturaId } = row.Cariislembaglanti
        let kasaBankaAdi = BankaHesap.id ? BankaHesap.ad : Kasa.ad
        const faturaTagRender = (color = 'green') =>
          faturaId !== null ? <Tag color={color}>{row.Cariislembaglanti.Fatura.faturaTip}</Tag> : null

        switch (row.cariIslemTur) {
          case cariIslemTurTypes.TAHSILAT:
            return (
              <>
                {faturaTagRender('green')}
                <Tag color="cyan">{`${text} => ${kasaBankaAdi}`}</Tag>
              </>
            )
          case cariIslemTurTypes.ODEME:
            return (
              <>
                {faturaTagRender('red')}
                <Tag color="magenta">{`${text} => ${kasaBankaAdi}`}</Tag>
              </>
            )
          case cariIslemTurTypes.ALACAK:
            return (
              <>
                {faturaTagRender('red')}
                <Tag color="geekblue">{text}</Tag>
              </>
            )
          case cariIslemTurTypes.BORC:
            return (
              <>
                {faturaTagRender('green')}
                <Tag color="volcano">{text}</Tag>
              </>
            )
          default:
            return (
              <>
                {faturaTagRender()}
                <Tag color="default">{text}</Tag>
              </>
            )
        }
      },
    },
    {
      title: strings.cariDetay.components.cariIslemList.aciklama,
      dataIndex: 'aciklama',
      key: 'aciklama',
    },
    {
      title: strings.cariDetay.components.cariIslemList.tutar,
      dataIndex: 'tutar',
      key: 'tutar',
      sorter: (a, b) => a.tutar - b.tutar,
      render: (text, row, index) => (
        <Typography.Title
          level={5}
          style={{
            padding: 8,
            color: row.girisMi ? 'green' : 'red',
          }}
        >
          {row.doviz.simge}
          {paraFormat(text)}
        </Typography.Title>
      ),
    },
    {
      title: strings.cariDetay.components.cariIslemList.islemler,
      dataIndex: 'islemler',
      key: 'islemler',
      render: (text, row, index) => (
        <Space size="middle" direction={windowSize.width > 1500 ? 'horizontal' : 'vertical'}>
          <Tooltip title={strings.cariDetay.components.cariIslemList.cariIslemDetay}>
            <Button
              onClick={() => navigate(`/CariIslemDetay/${row.cariIslemTur}/${row.id}`)}
              type="default"
              shape="circle"
              icon={<SearchOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={strings.cariDetay.components.cariIslemList.cariIslemDuzenle}>
            <Button
              onClick={() => onEditCariIslem(row)}
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size={'large'}
            />
          </Tooltip>
          <Tooltip title={strings.cariDetay.components.cariIslemList.cariIslemSil}>
            <Popconfirm
              title={strings.mesaj.silmeIslemUyari}
              onConfirm={() => {
                if (row.Cariislembaglanti.faturaId !== null) {
                  message.error({
                    content: strings.mesaj.cariIslemFaturaSilme,
                    style: { marginTop: '20vh' },
                  })
                } else remove(row.id)
              }}
            >
              <Button type="default" shape="circle" icon={<DeleteOutlined />} size={'large'} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const detayliAramaOnFinish = async (props) => {
    const { islemTarihi, vadeTarihi, islemTipi, minTutar, dovizId, maxTutar } = props

    let newData = { ...initialFilterRequestData }

    newData = {
      Data: {
        CariKartId: initialFilterRequestData.Data.CariKartId,
        DovizId: dovizId !== undefined ? dovizId : null,
        cariIslemTur: islemTipi !== undefined ? islemTipi : null,
      },
      DateOptions: [
        {
          DatePropertyName: 'IslemTarih',
          FirstDate: islemTarihi !== undefined ? `${moment(islemTarihi[0]).format('YYYY-MM-DD')} 00:00:00` : null,
          LastDate: islemTarihi !== undefined ? `${moment(islemTarihi[1]).format('YYYY-MM-DD')} 23:59:59` : null,
        },
        {
          DatePropertyName: 'VadeTarih',
          FirstDate: vadeTarihi !== undefined ? `${moment(vadeTarihi[0]).format('YYYY-MM-DD')} 00:00:00` : null,
          LastDate: vadeTarihi !== undefined ? `${moment(vadeTarihi[1]).format('YYYY-MM-DD')} 23:59:59` : null,
        },
      ],
      BetweenOptions: [
        {
          PropertyName: 'Tutar',
          First: (minTutar !== undefined) & (minTutar !== 0) ? minTutar : null,
          Last: (maxTutar !== undefined) & (maxTutar !== 0) ? maxTutar : null,
        },
      ],
    }

    await setFilterRequestData(newData)
    dispatch(setRerender())
  }

  const detayliAramaReset = async () => {
    await setFilterRequestData({ ...initialFilterRequestData })
    dispatch(setRerender())
  }

  return (
    <>
      <PageHeader extra={<IslemButton />} ghost={false} />
      <br />
      <Card title={strings.cariDetay.components.cariIslemList.baslik}>
        <FetchListContent
          detailSearch={
            <Card title={detayliArama.baslik}>
              <Form
                form={detailSearchForm}
                name="detailSearchForm"
                layout="vertical"
                initialValues={{
                  islemTipi: null,
                }}
                onFinish={detayliAramaOnFinish}
              >
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item name="islemTarihi" label={detayliArama.islemTarihi}>
                      <DatepickerRanger />
                    </Form.Item>
                    <Form.Item name="vadeTarihi" label={detayliArama.vadeTarihi}>
                      <DatepickerRanger />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Space direction="vertical" align="center" style={{ width: '100%' }}>
                      <Form.Item name="islemTipi" label={detayliArama.islemTipi}>
                        <Radio.Group value={1}>
                          <Space direction="vertical">
                            <Radio value={null}>{detayliArama.tumIslemTipleriniGoster}</Radio>
                            <Radio value={cariIslemTurTypes.TAHSILAT}>{detayliArama.tahsilatIslemleriniGoster}</Radio>
                            <Radio value={cariIslemTurTypes.ODEME}>{detayliArama.odemeIslemleriniGoster}</Radio>
                            <Radio value={cariIslemTurTypes.ALACAK}>
                              {detayliArama.alacaklandirmaIslemleriniGoster}
                            </Radio>
                            <Radio value={cariIslemTurTypes.BORC}>{detayliArama.borclandirmaIslemleriniGoster}</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Space direction="vertical">
                      <Space>
                        <Form.Item name="minTutar" label={detayliArama.minTutar}>
                          <CurrencyInput />
                        </Form.Item>
                        <Form.Item name="dovizId" label={detayliArama.doviz}>
                          <DovizSelectOption />
                        </Form.Item>
                      </Space>
                      <Space>
                        <Form.Item name="maxTutar" label={detayliArama.maxTutar}>
                          <CurrencyInput />
                        </Form.Item>
                        <Form.Item name="dovizId" label={detayliArama.doviz}>
                          <DovizSelectOption />
                        </Form.Item>
                      </Space>
                    </Space>
                  </Col>
                </Row>
                <Space align="baseline" style={{ float: 'right' }}>
                  <Button
                    type="default"
                    size="middle"
                    icon={<ClearOutlined />}
                    style={{ float: 'right' }}
                    onClick={detayliAramaReset}
                  >
                    {detayliArama.temizle}
                  </Button>
                  <Button
                    type="default"
                    size="middle"
                    icon={<SearchOutlined />}
                    style={{ float: 'right' }}
                    form="detailSearchForm"
                    htmlType="submit"
                  >
                    {detayliArama.aramayiBaslat}
                  </Button>
                </Space>
              </Form>
            </Card>
          }
          columns={columns}
          requestUrl="cariislem/get"
          requestBody={{
            SortOptions: {
              ColumnName: 'IslemTarih',
              SortType: 'Desc',
            },
            SelectColumns: [
              {
                TableName: '',
                ColumnNames: [
                  'id',
                  'evrakNo',
                  'aciklama',
                  'vadeTarih',
                  'islemTarih',
                  'tutar',
                  'girisMi',
                  'cariIslemTur',
                  'cariKartId',
                ],
              },
              {
                TableName: 'doviz',
                ColumnNames: ['id', 'ad', 'kod', 'simge'],
              },
              {
                TableName: 'Cariislembaglanti.Kasa',
                ColumnNames: ['id', 'ad'],
              },
              {
                TableName: 'Cariislembaglanti.BankaHesap',
                ColumnNames: ['id', 'ad'],
              },
              {
                TableName: 'Cariislembaglanti',
                ColumnNames: ['faturaId'],
              },
              {
                TableName: 'Cariislembaglanti.Fatura',
                ColumnNames: ['faturaTip'],
              },
              {
                ColumnNames: ['Tutar'],
                Operator: 'Sum',
                TableName: 'AltCariIslem:TamamlananTutar',
              },
            ],
            ...filterRequestData,
          }}
          dataSource={dataSource}
          onDataSource={(e) => setDataSource(e)}
        />
      </Card>
      <CariIslemDuzenleModal
        visible={cariIslemDuzenleModalVisible}
        handleCancel={() => setCariIslemDuzenleModalVisible(false)}
        data={selecteData}
      />
    </>
  )
}
