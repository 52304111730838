import React from 'react'
import { PageHeader, SifremiDegistirForm } from '../components/sifremiDegistir'
import { Col, Space } from 'antd'

export default function SifremiDegistir() {
    return (
        <>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <PageHeader />
                <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                    <SifremiDegistirForm />
                </Col>
            </Space>
        </>
    )
}
