import React, { useState } from 'react'
import { PageHeader, KullaniciDuzenleForm } from '../components/kullaniciDuzenle'

const KullaniciDuzenle = () => {
    const [onSaveLoading, setOnSaveLoading] = useState(false)
    return (
        <>
            <PageHeader onSaveLoading={onSaveLoading} />
            <br />
            <KullaniciDuzenleForm onSaveLoading={(value) => setOnSaveLoading(value)} />
        </>
    )
}

export default KullaniciDuzenle